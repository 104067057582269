<template>
  <div class="registration">
    <h2>
      Die Registrierung Ihrer Firma wurde erfolgreich abgeschlossen. Sie können
      sich jetzt <a href="/">hier</a> einloggen.
    </h2>
  </div>
</template>
<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
  name: "RegistrationSuccessView",
});
</script>
<style>
.registration {
  text-align: center;
}
</style>
