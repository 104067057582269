import { Api } from "./api.class";

export class HeartbeatService {
  private api = Api.getInstance();
  private urlPath = "/heartbeat";

  async sendHeartbeat(): Promise<any> {
    const response = await this.api.getBackendApi().post(`${this.urlPath}`);
    return response.data;
  }
}
