export function removeIdFields(obj: any): any {
  if (Array.isArray(obj)) {
    return obj.map(removeIdFields);
  } else if (typeof obj === "object" && obj !== null) {
    return Object.keys(obj).reduce((acc, key) => {
      if (key !== "_id") {
        acc[key] = removeIdFields(obj[key]);
      }
      return acc;
    }, {} as any);
  }
  return obj;
}
