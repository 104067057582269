import { Api } from "./api.class";

export class RegistrationService {
  private api = Api.getInstance();
  private urlPath = "/registration";

  async registerCompany(company: any) {
    const response = await this.api
      .getBackendApi()
      .post(`${this.urlPath}`, company);
    return response.data;
  }
}
