<template>
  <div class="column">
    <div v-show="!isActive" class="spacer-dispo-column"></div>
    <div v-show="isActive" class="header-dispo-column">
      <div>
        <FilterMenu>
          <template #filter-menu-left-column>
            <div class="status-options">
              <v-checkbox
                density="compact"
                v-for="(label, key) in demandStates"
                :key="key"
                v-model="selectedStatuses"
                :label="label"
                :value="String(key)"
                class="ma-0 pa-0"
              ></v-checkbox>
            </div>
          </template>
          <template #filter-menu-right-column>
            <div class="mandants-options">
              <v-checkbox
                density="compact"
                v-for="mandant in mandants"
                :key="mandant.uuid"
                v-model="selectedMandants"
                :label="mandant.name"
                :value="mandant.uuid"
                class="ma-0 pa-0"
              ></v-checkbox>
            </div>
          </template>
        </FilterMenu>
      </div>
      <div>
        <DialogAddEditDemand
          :modalMode="modalAddOrEditMode"
          @loadDemands="loadDemands()"
          @insertUpdatedDemand="insertUpdatedDemand"
          ref="modalAddEditDemandComponent"
        ></DialogAddEditDemand>
      </div>
    </div>
    <draggable
      :list="demands"
      group="demand"
      @start="dragStart"
      @end="dragEnd"
      item-key="demandId"
    >
      <template #item="{ element }">
        <DemandItem
          class="longpress"
          :demand="element"
          :key="element.demandId"
          :isFullyCollapsed="minimizeAllItems"
          @contextmenu.prevent="openContextMenu($event, element)"
          @longpress="openContextMenu($event, element)"
          @loadDemands="loadDemands()"
          @delete="deleteDemand"
        />
      </template>
    </draggable>
  </div>
  <div
    v-if="showContextMenu"
    :style="{
      top: contextMenuPosition.y + 'px',
      left: contextMenuPosition.x + 'px',
    }"
    class="context-menu"
    ref="ContextMenu"
    @click.stop
  >
    <v-list dense>
      <v-list-item @click="editDemand(clickedDemand)">
        <v-icon class="text-medium-emphasis mr-1" size="xs">
          fa-solid fa-pen-to-square
        </v-icon>
        bearbeiten
      </v-list-item>
      <v-list-item @click="confirmDeleteDemand(clickedDemand)">
        <v-icon class="text-medium-emphasis mr-1" size="xs">
          fa-solid fa-trash-can
        </v-icon>
        löschen
      </v-list-item>
      <v-list-item @click="toggleItemsMinimized()">
        <v-icon class="text-medium-emphasis mr-1" size="xs">
          {{
            minimizeAllItems
              ? "fa-solid fa-chevron-up"
              : "fa-solid fa-chevron-down"
          }}
        </v-icon>
        {{ minimizeAllItems ? "Karten normal" : "Karten minimieren" }}
      </v-list-item>
    </v-list>
  </div>
</template>

<script lang="ts">
import { defineComponent, PropType } from "vue";
import { mapGetters } from "vuex";
import draggable from "vuedraggable";
import FilterMenu from "./elements/FilterMenu.vue";
import DemandItem from "@/components/disposition/DemandItem.vue";
import DialogService from "@/services/dialog.service";
import DialogAddEditDemand from "@/components/disposition/elements/DialogAddEditDemand.vue";
import { User } from "@/models/user.model";
import { ModalMode } from "@/enums/dialog-action.enum";
import { Demand } from "@/models/demand.model";
import { Mandant } from "@/models/mandant.model";
import { UserService } from "@/services/api/user.service";
import { DemandService } from "@/services/api/demand.service";
import { ApiResponse } from "@/models/api-response.model";
import { UserHelperService } from "@/services/user-helper.service";
import { SoftwareIntegration } from "@/models/company-config.model";
import { InterComponentMessage } from "@/enums/inter-component-messagin.enum";

export default defineComponent({
  name: "DemandColumn",
  components: {
    FilterMenu,
    DemandItem,
    draggable,
    DialogAddEditDemand,
  },
  props: {
    isActive: {
      type: Boolean,
      required: true,
    },
    interComponentMessage: {
      type: Object as PropType<any>,
      required: true,
    },
    loggedInMandantUuids: {
      type: Object as PropType<string[]>,
      required: true,
    },
    lastUpdateTimeline: {
      type: String,
      required: true,
    },
    mandants: {
      type: Object as PropType<Mandant[]>,
      required: true,
    },
    softwareIntegration: {
      type: Object as PropType<SoftwareIntegration>,
      required: true,
    },
    user: {
      type: Object as PropType<User>,
      required: true,
    },
  },
  data() {
    return {
      contextMenuPosition: { x: 0, y: 0 },
      drag: false,
      demands: [] as Demand[],
      minimizeAllItems: false,
      modalAddOrEditMode: ModalMode.add,
      clickedDemand: {} as any,
      demandService: new DemandService(),
      selectedMandants: [] as string[],
      selectedStatuses: [] as string[],
      showContextMenu: false,
      showFilters: false,
      userService: new UserService(),
      userHelperService: UserHelperService.getInstance(),
    };
  },
  computed: {
    ...mapGetters({
      demandStates: "demandStates",
    }),
  },
  created() {
    this.getFiltersettingsFromStore().then(() => {
      this.loadDemands();
    });
  },
  mounted() {
    this.setSelectedMandantsFromLoggedInMandants();
    document.addEventListener("click", this.handleClickOutside);
  },
  beforeUnmount() {
    document.removeEventListener("click", this.handleClickOutside);
  },
  watch: {
    selectedStatuses: {
      handler(newValue, oldValue) {
        if (JSON.stringify(newValue) !== JSON.stringify(oldValue)) {
          this.loadDemands();
          this.$store
            .dispatch("updateDispatcherBoardFilter", {
              columnName: "columnDemand",
              property: "filterStatus",
              value: newValue,
            })
            .then(() => {
              this.userService.updateUserConfig(
                this.$store.state.company.loggedInUser.config
              );
            });
        }
      },
      deep: true,
    },
    selectedMandants: {
      handler(newValue, oldValue) {
        if (JSON.stringify(newValue) !== JSON.stringify(oldValue)) {
          this.loadDemands();
          this.$store
            .dispatch("updateDispatcherBoardFilter", {
              columnName: "columnDemand",
              property: "filterMandants",
              value: newValue,
            })
            .then(() => {
              this.userService.updateUserConfig(
                this.$store.state.company.loggedInUser.config
              );
            });
        }
      },
      deep: true,
    },
    interComponentMessage: {
      handler(newVal) {
        switch (newVal.message) {
          case InterComponentMessage.matchMeFromGlobalSearch:
            this.filterSearchterm(newVal.payload.searchTerm);
            break;
          default:
            break;
        }
      },
      deep: true,
    },
  },
  methods: {
    async confirmDeleteDemand(demand: Demand) {
      if (this.clickedDemand) {
        const confirmed = await DialogService.confirm(
          "Anfrage Löschen?",
          "Abbrechen",
          "Löschen"
        );

        if (confirmed && demand._id) {
          this.deleteDemand(demand._id);
        }
      }
      this.closeContextMenu();
    },
    closeContextMenu() {
      this.showContextMenu = false;
    },
    async deleteDemand(demandId: string) {
      this.demandService.removeDemand(demandId).then(() => {
        this.loadDemands();
      });
    },
    dragStart() {
      this.drag = true;
    },
    dragEnd() {
      this.drag = false;
    },
    editDemand(demand: Demand) {
      this.modalAddOrEditMode = ModalMode.edit;
      if (this.$refs.modalAddEditDemandComponent) {
        (this.$refs.modalAddEditDemandComponent as any).editDemand(demand);
      }
    },
    async filterSearchterm(searchTerm: string) {
      if (searchTerm === "") {
        this.loadDemands();
        return;
      }
      const terms = searchTerm.toLowerCase().split(" ");

      const filteredDemands = this.demands.filter((demand) => {
        const matches = terms.every((term) =>
          this.searchAllFields(demand, term)
        );

        return matches;
      });

      this.demands = filteredDemands;
    },
    insertUpdatedDemand(updatedDemand: Demand) {
      const index = this.demands.findIndex(
        (demand) => demand._id === updatedDemand._id
      );

      if (index !== -1) {
        this.demands[index] = updatedDemand;
      }
    },
    searchAllFields(object: Demand, term: string): boolean {
      return Object.values(object).some((value) => {
        if (typeof value === "string") {
          return value.toLowerCase().includes(term);
        } else if (typeof value === "object" && value !== null) {
          return this.searchAllFields(value, term);
        } else if (Array.isArray(value)) {
          return value.some((item) => this.searchAllFields(item, term));
        }
        return false;
      });
    },
    async getFiltersettingsFromStore(): Promise<void> {
      return new Promise((resolve) => {
        this.selectedMandants =
          this.user.config.dispatcherBoard.columnDemand.filterMandants;
        this.selectedStatuses =
          this.user.config.dispatcherBoard.columnDemand.filterStatus;
        resolve();
      });
    },
    handleClickOutside(event: MouseEvent) {
      const ContextMenu = this.$refs.ContextMenu as HTMLElement;
      if (
        ContextMenu &&
        !ContextMenu.contains(event.target as Node) &&
        this.showContextMenu
      ) {
        this.closeContextMenu();
        this.clickedDemand = {};
      }
    },
    async loadDemands() {
      this.modalAddOrEditMode = ModalMode.add;
      await this.$nextTick();
      let filteredMandants = [] as string[];
      if (this.selectedMandants.length > 0) {
        filteredMandants = this.selectedMandants;
      }

      try {
        let result = {} as ApiResponse;
        if (filteredMandants.length > 0) {
          result = await this.demandService.getAllDemands(filteredMandants);
        } else {
          result = await this.demandService.getAllDemands();
        }
        if (!result || !Array.isArray(result) || result.length === 0) {
          this.demands = [];
        } else {
          this.demands = result;
        }
      } catch (error) {
        this.demands = [];
      }
    },
    openContextMenu(event: MouseEvent, demand: string) {
      this.clickedDemand = demand;
      event.preventDefault();
      this.showContextMenu = true;
      this.contextMenuPosition = { x: event.clientX, y: event.clientY };
    },
    setSelectedMandantsFromLoggedInMandants() {
      if (this.mandants && this.loggedInMandantUuids) {
        if (
          this.user.config.dispatcherBoard.columnCustomer.filterMandants
            .length > 0
        ) {
          //Do not set if other filters are setted
          return;
        }
        this.selectedMandants = this.mandants
          .filter((mandant: Mandant) =>
            this.loggedInMandantUuids.includes(mandant.uuid)
          )
          .map((mandant: Mandant) => mandant.uuid);
      }
    },
    toggleItemsMinimized() {
      this.minimizeAllItems = !this.minimizeAllItems;
      this.closeContextMenu();
    },
  },
});
</script>

<style scoped>
.context-menu {
  position: fixed;
  z-index: 1000;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
  min-width: 10rem;
}

.context-menu .v-list-item:not(:last-child) {
  border-bottom: 0.1rem solid var(--table-hover);
}

.context-menu .v-list-item--active {
  background-color: var(--table-hover);
}

.context-menu-icons {
  width: 1rem;
  height: 1rem;
  margin-right: 0.5rem;
}
</style>
