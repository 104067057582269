//src/enums/whatsapp.enum.ts
export enum WhatsAppMode {
  experimental = 0,
  official = 1,
}

export enum WhatsAppBot {
  noBot = "Kein Bot",
  application = "Bewerbungsbot",
  backBurner = "Kandidatenpflegebot",
  service = "Servicebot",
}
