export enum ContactStates {
  aktiv = 1,
  inaktiv = 2,
}

export enum CustomerStates {
  Prospect = 1,
  Customer = 2,
  FormerCustomer = 3,
  CompletedProspect = 6,
  Unknown = 10,
}
const customerStateLabels: { [key in CustomerStates]: string } = {
  [CustomerStates.Prospect]: "Interessent",
  [CustomerStates.Customer]: "Aktiver Kunde",
  [CustomerStates.FormerCustomer]: "Ehemaliger Kunde",
  [CustomerStates.CompletedProspect]: "Erledigter Interessent",
  [CustomerStates.Unknown]: "Unbekannt",
};

export const getCustomerStates = (enumObj: {
  [key: number]: string | number;
}): { [key: number]: string } => {
  const entries = Object.entries(enumObj);
  const filteredEntries = entries.filter(([key]) => !isNaN(Number(key)));
  return filteredEntries.reduce<{ [key: number]: string }>(
    (obj, [key, value]) => {
      const enumKey = Number(key) as CustomerStates;
      if (typeof value === "string") {
        obj[enumKey] = customerStateLabels[enumKey] || value;
      }
      return obj;
    },
    {}
  );
};

export const getCustomerStateValue = (
  stateLabel: string
): number | undefined => {
  const labelToEnum: { [label: string]: number } = Object.keys(CustomerStates)
    .filter((key) => isNaN(Number(key)))
    .reduce((obj, key) => {
      const enumKey = CustomerStates[key as keyof typeof CustomerStates];
      const label = customerStateLabels[enumKey];
      if (label) {
        obj[label] = enumKey;
      }
      return obj;
    }, {} as { [key: string]: number });

  return labelToEnum[stateLabel];
};
