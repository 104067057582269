import { Api } from "./api.class";
import { Candidate } from "@/models/candidate.model";
import { CandidateList } from "@/models/candidate-list.model";

export class CandidateService {
  private api = Api.getInstance();
  private urlPath = "/candidate";

  async updateCandidatesStatusAts(
    candidateList: CandidateList[],
    atsDeterminesStatus: boolean,
    searchterm: string
  ) {
    try {
      const payload = {
        candidateList,
        atsDeterminesStatus,
        searchterm,
      };
      const response = await this.api
        .getBackendApi()
        .put(`${this.urlPath}`, payload);

      return response.data.response || {};
    } catch (error) {
      console.error("Error updating candidate status:", error);
      return { data: null, error };
    }
  }

  async getCandidateByUuid(uuid: string) {
    if (uuid) {
      return (
        await this.api.getBackendApi().get(`${this.urlPath}/uuid/${uuid}`)
      ).data.response as Candidate;
    }
    return undefined;
  }

  async getCandidateById(_id: string) {
    if (_id) {
      return (await this.api.getBackendApi().get(`${this.urlPath}/id/${_id}`))
        .data.response as Candidate;
    }
    return undefined;
  }

  async getCandidateList(searchterm: string, mandants?: string[]) {
    try {
      if (!searchterm || searchterm.trim() === "") {
        throw new Error(
          "Invalid search term. It cannot be empty or whitespace."
        );
      }

      const params = new URLSearchParams();

      if (mandants && mandants.length > 0) {
        params.append("mandants", mandants.join(","));
      }

      const response = await this.api
        .getBackendApi()
        .get(
          `${this.urlPath}/${encodeURIComponent(
            searchterm
          )}?${params.toString()}`
        );

      return response.data.response || {};
    } catch (error) {
      console.error("Error fetching candidates by search term:", error);
      return { data: null, error };
    }
  }

  async postCandidate(candidate: Candidate) {
    if (candidate) {
      const response = await this.api
        .getBackendApi()
        .post(`${this.urlPath}`, candidate);
      return response.data.response;
    }
    return undefined;
  }

  async updateCandidateByUuid(uuid: string, candidateData: Candidate) {
    if (uuid && candidateData) {
      const response = await this.api
        .getBackendApi()
        .put(`${this.urlPath}/uuid/${uuid}`, candidateData);
      return response.data;
    }
    return undefined;
  }

  async removeCandidate(id: string) {
    try {
      const response = await this.api
        .getBackendApi()
        .delete(`${this.urlPath}/${id}`);
      return response.data;
    } catch (error) {
      console.error("Error removing candidate:", error);
      return { data: null, error };
    }
  }
}
