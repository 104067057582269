<template>
  <MainHeader></MainHeader>
  <div style="padding: 1rem 0 1rem 1rem">
    <h2 v-if="Object.keys(aiText).length === 0">
      Hallo {{ user.forename }} {{ user.lastname }}, Willkommen auf dem
      Dashboard!
    </h2>
    <h2
      class="ai-text"
      v-else
      @mouseover="showTooltip"
      @mouseleave="hideTooltip"
    >
      {{ aiText.salutation }} {{ aiText.motivation }}
    </h2>
  </div>
  <v-row no-gutters>
    <v-col style="margin-left: 1rem">
      <v-sheet class="sheet1">
        <v-card
          v-if="softwareIntegration.zvooveOne"
          class="sheet1__card"
          title="Mitarbeiter Übersicht"
          subtitle="Fluktuation"
        >
          <v-card-text>
            <strong>{{ zvooveDashboardData.mitarbeiter }}</strong> Mitarbeiter
            insgesamt<br />
            Eintritte: {{ zvooveDashboardData.eintritte }} | Austritte:
            {{ zvooveDashboardData.austritte }}<br />
          </v-card-text>
        </v-card>
        <v-card
          v-if="softwareIntegration.zvooveOne"
          class="sheet1__card"
          subtitle="Einsatzdetails"
        >
          <v-card-text>
            {{ zvooveDashboardData.baldBeginnend }} bald beginnend<br />
            {{ zvooveDashboardData.mitarbeiterInFehlzeit }} Mitarbeiter in
            Fehlzeit ({{
              zvooveDashboardData.mitarbeiterInFehlzeitOhneBeleg
            }}
            ohne Beleg)<br />
            {{ zvooveDashboardData.baldEndend }} beenden Einsatz bald <br />
            {{ zvooveDashboardData.mitarbeiterErreichenHUeD }} erreichen
            Höchstüberlassungsdauer<br />
            {{ zvooveDashboardData.mitarbeiterInEqualPay }} sind in EqualPay<br />
          </v-card-text>
        </v-card>
        <v-card
          v-if="softwareIntegration.zvooveOne"
          class="sheet1__card"
          subtitle="Produktivität"
        >
          <v-card-text>
            im Einsatz: {{ zvooveDashboardData.mitarbeiterMitEinsatz }}<br />
            <strong
              >ohne Einsatz:
              {{ zvooveDashboardData.mitarbeiterOhneEinsatz }}</strong
            >
          </v-card-text>
        </v-card>
      </v-sheet>
      <v-sheet class="pa-2 ma-2">
        <img
          src="@/assets/affe.png"
          alt="multizorst"
          style="width: 200x; height: 200px; cursor: pointer"
          @click="fetchErpInitData"
        />
      </v-sheet>
    </v-col>
    <v-col>
      <v-sheet class="sheet2">
        <v-card
          class="sheet2__card"
          title="30 Tage Vertriebskennzahlen"
          subtitle="Telefonate Unternehmen"
        >
          <v-card-text>
            <i class="fa-solid fa-user"></i>: erreicht:
            {{
              getDynamicKPI("phoneCallCustomer", "30days", "user") -
              getDynamicKPI(
                "phoneCallCustomer",
                "30days",
                "user",
                "nicht erreicht"
              )
            }}
            | nicht erreicht:
            {{
              getDynamicKPI(
                "phoneCallCustomer",
                "30days",
                "user",
                "nicht erreicht"
              )
            }}<br />
            <p v-if="hasLoggedInMandants">
              <i class="fa-solid fa-building"></i>: erreicht:
              {{
                getDynamicKPI("phoneCallCustomer", "30days", "mandant") -
                getDynamicKPI(
                  "phoneCallCustomer",
                  "30days",
                  "mandant",
                  "nicht erreicht"
                )
              }}
              | nicht erreicht:
              {{
                getDynamicKPI(
                  "phoneCallCustomer",
                  "30days",
                  "mandant",
                  "nicht erreicht"
                )
              }}
            </p>
            <i class="fa-solid fa-globe"></i>: erreicht:
            {{
              getDynamicKPI("phoneCallCustomer", "30days", "user") -
              getDynamicKPI(
                "phoneCallCustomer",
                "30days",
                "user",
                "nicht erreicht"
              )
            }}
            | nicht erreicht:
            {{
              getDynamicKPI(
                "phoneCallCustomer",
                "30days",
                "user",
                "nicht erreicht"
              )
            }}<br />
            <br />
            <hr />
            <div class="d-flex">
              <div class="mr-1">
                <i class="fa-solid fa-user"></i>:
                {{ getDynamicKPI("meetingOutside", "30days", "user") }}
              </div>
              <div class="mr-1" v-if="hasLoggedInMandants">
                <i class="fa-solid fa-building"></i>:
                {{ getDynamicKPI("meetingOutside", "30days", "mandant") }}
              </div>
              <div>
                <i class="fa-solid fa-globe"></i>:
                {{ getDynamicKPI("meetingOutside", "30days", "global") }} |
                <strong>Firmenbesuche</strong>
              </div>
            </div>
          </v-card-text>
        </v-card>
        <v-card class="sheet2__card" subtitle="Kandidaten bei Kunden">
          <v-card-text>
            <div class="d-flex">
              <div class="mr-1">
                <i class="fa-solid fa-user"></i>:
                {{ getDynamicKPI("candidateSuggestion", "30days", "user") }}
              </div>
              <div class="mr-1" v-if="hasLoggedInMandants">
                <i class="fa-solid fa-building"></i>:
                {{ getDynamicKPI("candidateSuggestion", "30days", "mandant") }}
              </div>
              <div>
                <i class="fa-solid fa-globe"></i>:
                {{ getDynamicKPI("candidateSuggestion", "30days", "global") }}
                | <strong>Profile verschickt</strong>
              </div>
            </div>
            <div class="d-flex mt-2">
              <div class="mr-1">
                <i class="fa-solid fa-user"></i>:
                {{ getDynamicKPI("interviewExternal", "30days", "user") }}
              </div>
              <div class="mr-1" v-if="hasLoggedInMandants">
                <i class="fa-solid fa-building"></i>:
                {{ getDynamicKPI("interviewExternal", "30days", "mandant") }}
              </div>
              <div>
                <i class="fa-solid fa-globe"></i>:
                {{ getDynamicKPI("interviewExternal", "30days", "global") }} |
                Vorstellungsgespräche
              </div>
            </div>
            <div class="d-flex">
              <div class="mr-1">
                <i class="fa-solid fa-user"></i>:
                {{ getDynamicKPI("trailWorkExternal", "30days", "user") }}
              </div>
              <div class="mr-1" v-if="hasLoggedInMandants">
                <i class="fa-solid fa-building"></i>:
                {{ getDynamicKPI("trailWorkExternal", "30days", "mandant") }}
              </div>
              <div class="mr-1">
                <i class="fa-solid fa-globe"></i>:
                {{ getDynamicKPI("trailWorkExternal", "30days", "global") }} |
                Probearbeiten
              </div>
            </div>
            <div class="d-flex">
              <div class="mr-1">
                <i class="fa-solid fa-user"></i>:
                {{ getDynamicKPI("jobofferExternal", "30days", "user") }}
              </div>
              <div class="mr-1" v-if="hasLoggedInMandants">
                <i class="fa-solid fa-building"></i>:
                {{ getDynamicKPI("jobofferExternal", "30days", "mandant") }}
              </div>
              <div class="mr-1">
                <i class="fa-solid fa-globe"></i>:
                {{ getDynamicKPI("jobofferExternal", "30days", "global") }} |
                Zusagen
              </div>
            </div>
          </v-card-text>
        </v-card>
        <v-card class="sheet2__card" subtitle="Telefonate Kandidaten">
          <v-card-text>
            <i class="fa-solid fa-user"></i>: erreicht:
            {{
              getDynamicKPI("phoneCallCandidate", "30days", "user") -
              getDynamicKPI(
                "phoneCallCandidate",
                "30days",
                "user",
                "nicht erreicht"
              )
            }}
            | nicht erreicht:
            {{
              getDynamicKPI(
                "phoneCallCandidate",
                "30days",
                "user",
                "nicht erreicht"
              )
            }}<br />
            <p v-if="hasLoggedInMandants">
              <i class="fa-solid fa-building"></i>: erreicht:
              {{
                getDynamicKPI("phoneCallCandidate", "30days", "mandant") -
                getDynamicKPI(
                  "phoneCallCandidate",
                  "30days",
                  "mandant",
                  "nicht erreicht"
                )
              }}
              | nicht erreicht:
              {{
                getDynamicKPI(
                  "phoneCallCandidate",
                  "30days",
                  "mandant",
                  "nicht erreicht"
                )
              }}
            </p>
            <i class="fa-solid fa-globe"></i>: erreicht:
            {{
              getDynamicKPI("phoneCallCandidate", "30days", "user") -
              getDynamicKPI(
                "phoneCallCandidate",
                "30days",
                "user",
                "nicht erreicht"
              )
            }}
            | nicht erreicht:
            {{
              getDynamicKPI(
                "phoneCallCandidate",
                "30days",
                "user",
                "nicht erreicht"
              )
            }}<br />
          </v-card-text>
        </v-card>
        <v-card class="sheet2__card" subtitle="Vorstellungstermine">
          <v-card-text>
            <div class="d-flex">
              <div class="mr-1">
                <i class="fa-solid fa-user"></i>:
                {{ getDynamicKPI("interview", "30days", "user") }}
              </div>
              <div class="mr-1" v-if="hasLoggedInMandants">
                <i class="fa-solid fa-building"></i>:
                {{ getDynamicKPI("interview", "30days", "mandant") }}
              </div>
              <div class="mr-1">
                <i class="fa-solid fa-globe"></i>:
                {{ getDynamicKPI("interview", "30days", "global") }} |
                vereinbart
              </div>
            </div>
            <div class="d-flex">
              <div class="mr-1">
                <i class="fa-solid fa-user"></i>:
                {{ getDynamicKPI("interviewCanceled", "30days", "user") }}
              </div>
              <div class="mr-1" v-if="hasLoggedInMandants">
                <i class="fa-solid fa-building"></i>:
                {{ getDynamicKPI("interviewCanceled", "30days", "mandant") }}
              </div>
              <div class="mr-1">
                <i class="fa-solid fa-globe"></i>:
                {{ getDynamicKPI("interviewCanceled", "30days", "global") }} |
                abgesagt
              </div>
            </div>
            <div class="d-flex">
              <div class="mr-1">
                <i class="fa-solid fa-user"></i>:
                {{ getDynamicKPI("interviewNotShownUp", "30days", "user") }}
              </div>
              <div class="mr-1" v-if="hasLoggedInMandants">
                <i class="fa-solid fa-building"></i>:
                {{ getDynamicKPI("interviewNotShownUp", "30days", "mandant") }}
              </div>
              <div class="mr-1">
                <i class="fa-solid fa-globe"></i>:
                {{ getDynamicKPI("interviewNotShownUp", "30days", "global") }} |
                geplatzt
              </div>
            </div>
            <h4 class="mt-2">stattgefunden:</h4>
            <div class="d-flex">
              <div class="mr-1">
                <i class="fa-solid fa-user"></i>:
                {{ getDynamicKPI("interviewCompleted", "30days", "user") }}
              </div>
              <div class="mr-1" v-if="hasLoggedInMandants">
                <i class="fa-solid fa-building"></i>:
                {{ getDynamicKPI("interviewCompleted", "30days", "mandant") }}
              </div>
              <div class="mr-1">
                <i class="fa-solid fa-globe"></i>:
                {{ getDynamicKPI("interviewCompleted", "30days", "global") }}
              </div>
            </div>
          </v-card-text>
        </v-card>
      </v-sheet>
    </v-col>
    <v-col>
      <v-sheet class="sheet2">
        <v-card
          class="sheet2__card"
          title="7 Tage Vertriebskennzahlen"
          subtitle="Telefonate Unternehmen"
        >
          <v-card-text>
            <i class="fa-solid fa-user"></i>: erreicht:
            {{
              getDynamicKPI("phoneCallCustomer", "7days", "user") -
              getDynamicKPI(
                "phoneCallCustomer",
                "7days",
                "user",
                "nicht erreicht"
              )
            }}
            | nicht erreicht:
            {{
              getDynamicKPI(
                "phoneCallCustomer",
                "7days",
                "user",
                "nicht erreicht"
              )
            }}<br />
            <p v-if="hasLoggedInMandants">
              <i class="fa-solid fa-building"></i>: erreicht:
              {{
                getDynamicKPI("phoneCallCustomer", "7days", "mandant") -
                getDynamicKPI(
                  "phoneCallCustomer",
                  "7days",
                  "mandant",
                  "nicht erreicht"
                )
              }}
              | nicht erreicht:
              {{
                getDynamicKPI(
                  "phoneCallCustomer",
                  "7days",
                  "mandant",
                  "nicht erreicht"
                )
              }}
            </p>
            <i class="fa-solid fa-globe"></i>: erreicht:
            {{
              getDynamicKPI("phoneCallCustomer", "7days", "user") -
              getDynamicKPI(
                "phoneCallCustomer",
                "7days",
                "user",
                "nicht erreicht"
              )
            }}
            | nicht erreicht:
            {{
              getDynamicKPI(
                "phoneCallCustomer",
                "7days",
                "user",
                "nicht erreicht"
              )
            }}<br />
            <br />
            <hr />
            <div class="d-flex">
              <div class="mr-1">
                <i class="fa-solid fa-user"></i>:
                {{ getDynamicKPI("meetingOutside", "7days", "user") }}
              </div>
              <div class="mr-1" v-if="hasLoggedInMandants">
                <i class="fa-solid fa-building"></i>:
                {{ getDynamicKPI("meetingOutside", "7days", "mandant") }}
              </div>
              <div>
                <i class="fa-solid fa-globe"></i>:
                {{ getDynamicKPI("meetingOutside", "7days", "global") }} |
                <strong>Firmenbesuche</strong>
              </div>
            </div>
          </v-card-text>
        </v-card>
        <v-card class="sheet2__card" subtitle="Kandidaten bei Kunden">
          <v-card-text>
            <div class="d-flex">
              <div class="mr-1">
                <i class="fa-solid fa-user"></i>:
                {{ getDynamicKPI("candidateSuggestion", "7days", "user") }}
              </div>
              <div class="mr-1" v-if="hasLoggedInMandants">
                <i class="fa-solid fa-building"></i>:
                {{ getDynamicKPI("candidateSuggestion", "7days", "mandant") }}
              </div>
              <div>
                <i class="fa-solid fa-globe"></i>:
                {{ getDynamicKPI("candidateSuggestion", "7days", "global") }}
                | <strong>Profile verschickt</strong>
              </div>
            </div>
            <div class="d-flex mt-2">
              <div class="mr-1">
                <i class="fa-solid fa-user"></i>:
                {{ getDynamicKPI("interviewExternal", "7days", "user") }}
              </div>
              <div class="mr-1" v-if="hasLoggedInMandants">
                <i class="fa-solid fa-building"></i>:
                {{ getDynamicKPI("interviewExternal", "7days", "mandant") }}
              </div>
              <div>
                <i class="fa-solid fa-globe"></i>:
                {{ getDynamicKPI("interviewExternal", "7days", "global") }} |
                Vorstellungsgespräche
              </div>
            </div>
            <div class="d-flex">
              <div class="mr-1">
                <i class="fa-solid fa-user"></i>:
                {{ getDynamicKPI("trailWorkExternal", "7days", "user") }}
              </div>
              <div class="mr-1" v-if="hasLoggedInMandants">
                <i class="fa-solid fa-building"></i>:
                {{ getDynamicKPI("trailWorkExternal", "7days", "mandant") }}
              </div>
              <div class="mr-1">
                <i class="fa-solid fa-globe"></i>:
                {{ getDynamicKPI("trailWorkExternal", "7days", "global") }} |
                Probearbeiten
              </div>
            </div>
            <div class="d-flex">
              <div class="mr-1">
                <i class="fa-solid fa-user"></i>:
                {{ getDynamicKPI("jobofferExternal", "7days", "user") }}
              </div>
              <div class="mr-1" v-if="hasLoggedInMandants">
                <i class="fa-solid fa-building"></i>:
                {{ getDynamicKPI("jobofferExternal", "7days", "mandant") }}
              </div>
              <div class="mr-1">
                <i class="fa-solid fa-globe"></i>:
                {{ getDynamicKPI("jobofferExternal", "7days", "global") }} |
                Zusagen
              </div>
            </div>
          </v-card-text>
        </v-card>
        <v-card class="sheet2__card" subtitle="Telefonate Kandidaten">
          <v-card-text>
            <i class="fa-solid fa-user"></i>: erreicht:
            {{
              getDynamicKPI("phoneCallCandidate", "7days", "user") -
              getDynamicKPI(
                "phoneCallCandidate",
                "7days",
                "user",
                "nicht erreicht"
              )
            }}
            | nicht erreicht:
            {{
              getDynamicKPI(
                "phoneCallCandidate",
                "7days",
                "user",
                "nicht erreicht"
              )
            }}<br />
            <p v-if="hasLoggedInMandants">
              <i class="fa-solid fa-building"></i>: erreicht:
              {{
                getDynamicKPI("phoneCallCandidate", "7days", "mandant") -
                getDynamicKPI(
                  "phoneCallCandidate",
                  "7days",
                  "mandant",
                  "nicht erreicht"
                )
              }}
              | nicht erreicht:
              {{
                getDynamicKPI(
                  "phoneCallCandidate",
                  "7days",
                  "mandant",
                  "nicht erreicht"
                )
              }}
            </p>
            <i class="fa-solid fa-globe"></i>: erreicht:
            {{
              getDynamicKPI("phoneCallCandidate", "7days", "user") -
              getDynamicKPI(
                "phoneCallCandidate",
                "7days",
                "user",
                "nicht erreicht"
              )
            }}
            | nicht erreicht:
            {{
              getDynamicKPI(
                "phoneCallCandidate",
                "7days",
                "user",
                "nicht erreicht"
              )
            }}<br />
          </v-card-text>
        </v-card>
        <v-card class="sheet2__card" subtitle="Vorstellungstermine">
          <v-card-text>
            <div class="d-flex">
              <div class="mr-1">
                <i class="fa-solid fa-user"></i>:
                {{ getDynamicKPI("interview", "7days", "user") }}
              </div>
              <div class="mr-1" v-if="hasLoggedInMandants">
                <i class="fa-solid fa-building"></i>:
                {{ getDynamicKPI("interview", "7days", "mandant") }}
              </div>
              <div class="mr-1">
                <i class="fa-solid fa-globe"></i>:
                {{ getDynamicKPI("interview", "7days", "global") }} | vereinbart
              </div>
            </div>
            <div class="d-flex">
              <div class="mr-1">
                <i class="fa-solid fa-user"></i>:
                {{ getDynamicKPI("interviewCanceled", "7days", "user") }}
              </div>
              <div class="mr-1" v-if="hasLoggedInMandants">
                <i class="fa-solid fa-building"></i>:
                {{ getDynamicKPI("interviewCanceled", "7days", "mandant") }}
              </div>
              <div class="mr-1">
                <i class="fa-solid fa-globe"></i>:
                {{ getDynamicKPI("interviewCanceled", "7days", "global") }} |
                abgesagt
              </div>
            </div>
            <div class="d-flex">
              <div class="mr-1">
                <i class="fa-solid fa-user"></i>:
                {{ getDynamicKPI("interviewNotShownUp", "7days", "user") }}
              </div>
              <div class="mr-1" v-if="hasLoggedInMandants">
                <i class="fa-solid fa-building"></i>:
                {{ getDynamicKPI("interviewNotShownUp", "7days", "mandant") }}
              </div>
              <div class="mr-1">
                <i class="fa-solid fa-globe"></i>:
                {{ getDynamicKPI("interviewNotShownUp", "7days", "global") }} |
                geplatzt
              </div>
            </div>
            <h4 class="mt-2">stattgefunden:</h4>
            <div class="d-flex">
              <div class="mr-1">
                <i class="fa-solid fa-user"></i>:
                {{ getDynamicKPI("interviewCompleted", "7days", "user") }}
              </div>
              <div class="mr-1" v-if="hasLoggedInMandants">
                <i class="fa-solid fa-building"></i>:
                {{ getDynamicKPI("interviewCompleted", "7days", "mandant") }}
              </div>
              <div class="mr-1">
                <i class="fa-solid fa-globe"></i>:
                {{ getDynamicKPI("interviewCompleted", "7days", "global") }}
              </div>
            </div>
          </v-card-text>
        </v-card>
      </v-sheet>
    </v-col>
    <v-col>
      <v-sheet class="sheet2">
        <v-card
          class="sheet2__card"
          title="24 Stunden Vertriebskennzahlen"
          subtitle="Telefonate Unternehmen"
        >
          <v-card-text>
            <i class="fa-solid fa-user"></i>: erreicht:
            {{
              getDynamicKPI("phoneCallCustomer", "24h", "user") -
              getDynamicKPI(
                "phoneCallCustomer",
                "24h",
                "user",
                "nicht erreicht"
              )
            }}
            | nicht erreicht:
            {{
              getDynamicKPI(
                "phoneCallCustomer",
                "24h",
                "user",
                "nicht erreicht"
              )
            }}<br />
            <p v-if="hasLoggedInMandants">
              <i class="fa-solid fa-building"></i>: erreicht:
              {{
                getDynamicKPI("phoneCallCustomer", "24h", "mandant") -
                getDynamicKPI(
                  "phoneCallCustomer",
                  "24h",
                  "mandant",
                  "nicht erreicht"
                )
              }}
              | nicht erreicht:
              {{
                getDynamicKPI(
                  "phoneCallCustomer",
                  "24h",
                  "mandant",
                  "nicht erreicht"
                )
              }}
            </p>
            <i class="fa-solid fa-globe"></i>: erreicht:
            {{
              getDynamicKPI("phoneCallCustomer", "24h", "user") -
              getDynamicKPI(
                "phoneCallCustomer",
                "24h",
                "user",
                "nicht erreicht"
              )
            }}
            | nicht erreicht:
            {{
              getDynamicKPI(
                "phoneCallCustomer",
                "24h",
                "user",
                "nicht erreicht"
              )
            }}<br />
            <br />
            <hr />
            <div class="d-flex">
              <div class="mr-1">
                <i class="fa-solid fa-user"></i>:
                {{ getDynamicKPI("meetingOutside", "24h", "user") }}
              </div>
              <div class="mr-1" v-if="hasLoggedInMandants">
                <i class="fa-solid fa-building"></i>:
                {{ getDynamicKPI("meetingOutside", "24h", "mandant") }}
              </div>
              <div>
                <i class="fa-solid fa-globe"></i>:
                {{ getDynamicKPI("meetingOutside", "24h", "global") }} |
                <strong>Firmenbesuche</strong>
              </div>
            </div>
          </v-card-text>
        </v-card>
        <v-card class="sheet2__card" subtitle="Kandidaten bei Kunden">
          <v-card-text>
            <div class="d-flex">
              <div class="mr-1">
                <i class="fa-solid fa-user"></i>:
                {{ getDynamicKPI("candidateSuggestion", "24h", "user") }}
              </div>
              <div class="mr-1" v-if="hasLoggedInMandants">
                <i class="fa-solid fa-building"></i>:
                {{ getDynamicKPI("candidateSuggestion", "24h", "mandant") }}
              </div>
              <div>
                <i class="fa-solid fa-globe"></i>:
                {{ getDynamicKPI("candidateSuggestion", "24h", "global") }}
                | <strong>Profile verschickt</strong>
              </div>
            </div>
            <div class="d-flex mt-2">
              <div class="mr-1">
                <i class="fa-solid fa-user"></i>:
                {{ getDynamicKPI("interviewExternal", "24h", "user") }}
              </div>
              <div class="mr-1" v-if="hasLoggedInMandants">
                <i class="fa-solid fa-building"></i>:
                {{ getDynamicKPI("interviewExternal", "24h", "mandant") }}
              </div>
              <div>
                <i class="fa-solid fa-globe"></i>:
                {{ getDynamicKPI("interviewExternal", "24h", "global") }} |
                Vorstellungsgespräche
              </div>
            </div>
            <div class="d-flex">
              <div class="mr-1">
                <i class="fa-solid fa-user"></i>:
                {{ getDynamicKPI("trailWorkExternal", "24h", "user") }}
              </div>
              <div class="mr-1" v-if="hasLoggedInMandants">
                <i class="fa-solid fa-building"></i>:
                {{ getDynamicKPI("trailWorkExternal", "24h", "mandant") }}
              </div>
              <div class="mr-1">
                <i class="fa-solid fa-globe"></i>:
                {{ getDynamicKPI("trailWorkExternal", "24h", "global") }} |
                Probearbeiten
              </div>
            </div>
            <div class="d-flex">
              <div class="mr-1">
                <i class="fa-solid fa-user"></i>:
                {{ getDynamicKPI("jobofferExternal", "24h", "user") }}
              </div>
              <div class="mr-1" v-if="hasLoggedInMandants">
                <i class="fa-solid fa-building"></i>:
                {{ getDynamicKPI("jobofferExternal", "24h", "mandant") }}
              </div>
              <div class="mr-1">
                <i class="fa-solid fa-globe"></i>:
                {{ getDynamicKPI("jobofferExternal", "24h", "global") }} |
                Zusagen
              </div>
            </div>
          </v-card-text>
        </v-card>
        <v-card class="sheet2__card" subtitle="Telefonate Kandidaten">
          <v-card-text>
            <i class="fa-solid fa-user"></i>: erreicht:
            {{
              getDynamicKPI("phoneCallCandidate", "24h", "user") -
              getDynamicKPI(
                "phoneCallCandidate",
                "24h",
                "user",
                "nicht erreicht"
              )
            }}
            | nicht erreicht:
            {{
              getDynamicKPI(
                "phoneCallCandidate",
                "24h",
                "user",
                "nicht erreicht"
              )
            }}<br />
            <p v-if="hasLoggedInMandants">
              <i class="fa-solid fa-building"></i>: erreicht:
              {{
                getDynamicKPI("phoneCallCandidate", "24h", "mandant") -
                getDynamicKPI(
                  "phoneCallCandidate",
                  "24h",
                  "mandant",
                  "nicht erreicht"
                )
              }}
              | nicht erreicht:
              {{
                getDynamicKPI(
                  "phoneCallCandidate",
                  "24h",
                  "mandant",
                  "nicht erreicht"
                )
              }}
            </p>
            <i class="fa-solid fa-globe"></i>: erreicht:
            {{
              getDynamicKPI("phoneCallCandidate", "24h", "user") -
              getDynamicKPI(
                "phoneCallCandidate",
                "24h",
                "user",
                "nicht erreicht"
              )
            }}
            | nicht erreicht:
            {{
              getDynamicKPI(
                "phoneCallCandidate",
                "24h",
                "user",
                "nicht erreicht"
              )
            }}<br />
          </v-card-text>
        </v-card>
        <v-card class="sheet2__card" subtitle="Vorstellungstermine">
          <v-card-text>
            <div class="d-flex">
              <div class="mr-1">
                <i class="fa-solid fa-user"></i>:
                {{ getDynamicKPI("interview", "24h", "user") }}
              </div>
              <div class="mr-1" v-if="hasLoggedInMandants">
                <i class="fa-solid fa-building"></i>:
                {{ getDynamicKPI("interview", "24h", "mandant") }}
              </div>
              <div class="mr-1">
                <i class="fa-solid fa-globe"></i>:
                {{ getDynamicKPI("interview", "24h", "global") }} | vereinbart
              </div>
            </div>
            <div class="d-flex">
              <div class="mr-1">
                <i class="fa-solid fa-user"></i>:
                {{ getDynamicKPI("interviewCanceled", "24h", "user") }}
              </div>
              <div class="mr-1" v-if="hasLoggedInMandants">
                <i class="fa-solid fa-building"></i>:
                {{ getDynamicKPI("interviewCanceled", "24h", "mandant") }}
              </div>
              <div class="mr-1">
                <i class="fa-solid fa-globe"></i>:
                {{ getDynamicKPI("interviewCanceled", "24h", "global") }} |
                abgesagt
              </div>
            </div>
            <div class="d-flex">
              <div class="mr-1">
                <i class="fa-solid fa-user"></i>:
                {{ getDynamicKPI("interviewNotShownUp", "24h", "user") }}
              </div>
              <div class="mr-1" v-if="hasLoggedInMandants">
                <i class="fa-solid fa-building"></i>:
                {{ getDynamicKPI("interviewNotShownUp", "24h", "mandant") }}
              </div>
              <div class="mr-1">
                <i class="fa-solid fa-globe"></i>:
                {{ getDynamicKPI("interviewNotShownUp", "24h", "global") }} |
                geplatzt
              </div>
            </div>
            <h4 class="mt-2">stattgefunden:</h4>
            <div class="d-flex">
              <div class="mr-1">
                <i class="fa-solid fa-user"></i>:
                {{ getDynamicKPI("interviewCompleted", "24h", "user") }}
              </div>
              <div class="mr-1" v-if="hasLoggedInMandants">
                <i class="fa-solid fa-building"></i>:
                {{ getDynamicKPI("interviewCompleted", "24h", "mandant") }}
              </div>
              <div class="mr-1">
                <i class="fa-solid fa-globe"></i>:
                {{ getDynamicKPI("interviewCompleted", "24h", "global") }}
              </div>
            </div>
          </v-card-text>
        </v-card>
      </v-sheet>
    </v-col>
  </v-row>
  <v-row no-gutters>
    <v-col>
      <v-sheet class="pa-2 ma-2"> </v-sheet>
    </v-col>
  </v-row>
  <!--<ProfileEditor></ProfileEditor>-->
  <!--<WhatsAppMessenger></WhatsAppMessenger>-->
</template>

<script lang="ts">
import { defineComponent } from "vue";
import MainHeader from "@/components/top-header/Header.vue";
import { mapGetters } from "vuex";
import { ZvooveOneMultiFetch } from "@/services/api/api-integration-one.service";
import { TimelineService } from "@/services/api/timeline.service";
import { LinkingStatus } from "@/enums/dependency.enum";
import { generateDashboardText } from "@/services/ai.service";
import { TooltipService } from "@/services/tooltip.service";
import { PdHubService } from "@/services/api/pd-hub.service";
// import ProfileEditor from "@/components/disposition/elements/ProfileEditor.vue";
// import WhatsAppMessenger from "@/components/whatsapp/WhatsAppMessenger.vue";

export default defineComponent({
  name: "DashboardView",
  components: {
    MainHeader,
    //WhatsAppMessenger,
    //ProfileEditor,
  },
  data() {
    return {
      isLoading: false,
      imagename: "",
      size: 0,
      linkAnimation142x120: "",
      linkAnimation296x120: "",
      showEditor: true,
      salesKPIs: {} as any,
      aiText: {} as any,
      timelineService: new TimelineService(),
    };
  },
  computed: {
    ...mapGetters([
      "candidateToCustomerEvents",
      "candidateToMandantEvents",
      "user",
      "timelineEntryTypes",
      "softwareIntegration",
      "getLoggedInMandantBranchNumbers",
      "allZvooveOneGeschaeftsstelleIds",
      "zvooveDashboardData",
    ]),
    hasLoggedInMandants() {
      if (
        this.user &&
        this.user.config &&
        Array.isArray(this.user.config.loggedInMandants)
      ) {
        return this.user.config.loggedInMandants.length > 0;
      }
      return false;
    },
  },
  mounted() {
    this.fetchActivitiesKPIs();
    this.fetchZvooveOneDashboard();
  },
  watch: {
    getLoggedInMandantBranchNumbers(newVal, oldVal) {
      if (newVal !== oldVal) {
        this.fetchZvooveOneDashboard();
        this.fetchActivitiesKPIs();
      }
    },
  },
  methods: {
    showTooltip(event: MouseEvent) {
      const htmlContent = `<h3>Analyse:</h3>${this.aiText.analyse}<br><hr><br><h3>💡Ideen:</h3>${this.aiText.tips}`;
      TooltipService.showExpiredEvents(event, htmlContent);
    },
    hideTooltip() {
      const existingTooltip = document.getElementById("custom-tooltip");
      if (existingTooltip) {
        existingTooltip.remove();
      }
    },
    async fetchActivitiesKPIs() {
      const notes = {} as any;
      notes[this.timelineEntryTypes.phoneCallCandidate.description] =
        "nicht erreicht";
      notes[this.timelineEntryTypes.phoneCallCustomer.description] =
        "nicht erreicht";
      notes[this.timelineEntryTypes.phoneCallEmployee.description] =
        "nicht erreicht";

      const kpiRequest = {
        mandantUuids: this.user.config ? this.user.config.loggedInMandants : [],
        entryTypes: [
          this.timelineEntryTypes.phoneCallCandidate.description,
          this.timelineEntryTypes.phoneCallCustomer.description,
          this.timelineEntryTypes.phoneCallEmployee.description,
          this.timelineEntryTypes.candidateSuggestion.description,
          this.timelineEntryTypes.meetingOutside.description,
          this.timelineEntryTypes.meetingInhouse.description,
          this.timelineEntryTypes.emailCandidate.description,
          this.timelineEntryTypes.eMailCustomer.description,
          this.timelineEntryTypes.whatsAppCandidate.description,
          this.timelineEntryTypes.whatsAppEmployee.description,
          this.getEventNameByCandidateStatus(
            this.candidateToMandantEvents,
            LinkingStatus.interview
          ),
          this.getEventNameByCandidateStatus(
            this.candidateToMandantEvents,
            LinkingStatus.interviewCanceled
          ),
          this.getEventNameByCandidateStatus(
            this.candidateToMandantEvents,
            LinkingStatus.interviewNotShownUp
          ),
          this.getEventNameByCandidateStatus(
            this.candidateToMandantEvents,
            LinkingStatus.interviewCompleted
          ),
          this.getEventNameByCandidateStatus(
            this.candidateToCustomerEvents,
            LinkingStatus.interviewExternal
          ),
          this.getEventNameByCandidateStatus(
            this.candidateToCustomerEvents,
            LinkingStatus.trailWorkExternal
          ),
          this.getEventNameByCandidateStatus(
            this.candidateToCustomerEvents,
            LinkingStatus.jobofferExternal
          ),
          this.getEventNameByCandidateStatus(
            this.candidateToCustomerEvents,
            LinkingStatus.jobofferAcceptedExternal
          ),
        ],
        notes: notes,
      };
      try {
        this.salesKPIs = await this.timelineService.getKPIs(kpiRequest);
      } catch (error) {
        console.error("Failed to fetch KPIs:", error);
      }
    },

    getEventNameByCandidateStatus(eventsArray: any, status: any) {
      const event = eventsArray.find(
        (event: any) => event.candidateStatus === status
      );
      return event ? event.eventName : null;
    },

    getDynamicKPI(
      typeName: string,
      period: string,
      category: string,
      noteSuffix = ""
    ) {
      let eventName = this.resolveEventName(typeName);

      eventName += noteSuffix ? ` ${noteSuffix}` : "";

      const kpiData = this.salesKPIs[eventName]
        ? this.salesKPIs[eventName][period]
        : null;
      return kpiData ? kpiData[category] : 0;
    },

    resolveEventName(typeName: string) {
      if (this.timelineEntryTypes[typeName]) {
        return this.timelineEntryTypes[typeName].description;
      }
      const event = [
        ...this.candidateToMandantEvents,
        ...this.candidateToCustomerEvents,
      ].find((event) => event.candidateStatus === typeName);
      return event ? event.eventName : typeName;
    },

    async fetchErpInitData() {
      if (
        (!this.softwareIntegration.pdHub ||
          !this.softwareIntegration.zvooveOne) &&
        this.isLoading
      ) {
        return;
      }
      this.isLoading = true;
      try {
        if (this.softwareIntegration.zvooveOne) {
          const zvooveOneMultiFetch = new ZvooveOneMultiFetch();
          await zvooveOneMultiFetch.fetchZvooveOneInitData(
            this.allZvooveOneGeschaeftsstelleIds,
            this.getLoggedInMandantBranchNumbers
          );
        } else if (this.softwareIntegration.pdHub) {
          const pdHubService = PdHubService.getInstance();
          await pdHubService.fetchPdHubInitData();
        } else {
          return;
        }
      } catch (error) {
        console.error("Fehler beim Abrufen der Initialdaten", error);
      }
      this.isLoading = false;
    },

    async fetchZvooveOneDashboard() {
      if (
        !this.softwareIntegration.zvooveOne ||
        this.isLoading ||
        this.getLoggedInMandantBranchNumbers.length === 0
      ) {
        return;
      }
      this.isLoading = true;
      if (this.softwareIntegration.dashboardAiKpiAnalysis) {
        this.aiText = generateDashboardText(
          this.salesKPIs,
          this.zvooveDashboardData
        );
      }
      try {
        const zvooveOneMultiFetch = new ZvooveOneMultiFetch();
        await zvooveOneMultiFetch.fetchZvooveOneDashboard(
          this.getLoggedInMandantBranchNumbers
        );
      } catch (error) {
        console.error("Fehler beim Abrufen der Zvoovedaten:", error);
      }
      this.isLoading = false;
    },
  },
});
</script>

<style scoped lang="scss">
.ai-text {
  cursor: pointer;
}
.sheet1 {
  margin-right: 1rem;

  &__card {
    background-color: var(--color-item);
    margin-bottom: 1rem;
  }
}

.sheet2 {
  margin-right: 1rem;

  &__card {
    background-color: var(--color-item);
    margin-bottom: 1rem;
  }
}
</style>
