import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, renderList as _renderList, Fragment as _Fragment, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = { key: 0 }
const _hoisted_2 = { key: 1 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_icon = _resolveComponent("v-icon")!
  const _component_v_spacer = _resolveComponent("v-spacer")!
  const _component_v_tooltip = _resolveComponent("v-tooltip")!
  const _component_v_btn = _resolveComponent("v-btn")!
  const _component_v_card_title = _resolveComponent("v-card-title")!
  const _component_v_card_subtitle = _resolveComponent("v-card-subtitle")!
  const _component_v_list_item = _resolveComponent("v-list-item")!
  const _component_v_card_text = _resolveComponent("v-card-text")!
  const _component_v_divider = _resolveComponent("v-divider")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_v_card_title, { class: "d-flex" }, {
      default: _withCtx(() => [
        _createVNode(_component_v_icon, {
          class: "mt-1 mr-2 text-medium-emphasis",
          size: "xsmall"
        }, {
          default: _withCtx(() => [
            _createTextVNode("fas fa-user-clock")
          ]),
          _: 1
        }),
        _createTextVNode(" Einsätze "),
        _createVNode(_component_v_spacer),
        _createVNode(_component_v_btn, {
          icon: "",
          variant: "text",
          density: "compact",
          onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.updateAssignments()))
        }, {
          default: _withCtx(() => [
            _createVNode(_component_v_icon, { size: "small" }, {
              default: _withCtx(() => [
                _createTextVNode("fas fa-arrows-rotate")
              ]),
              _: 1
            }),
            _createVNode(_component_v_tooltip, {
              activator: "parent",
              location: "bottom"
            }, {
              default: _withCtx(() => [
                _createTextVNode("Einsätze anhand vom ERP System aktualisieren")
              ]),
              _: 1
            })
          ]),
          _: 1
        })
      ]),
      _: 1
    }),
    _createVNode(_component_v_card_text, null, {
      default: _withCtx(() => [
        (_ctx.isEmployee)
          ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
              _createVNode(_component_v_list_item, {
                onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.askColumnsForMatches(_ctx.currentPlacement.clientName ?? '')))
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_v_card_subtitle, null, {
                    default: _withCtx(() => [
                      _createElementVNode("h3", null, _toDisplayString(_ctx.currentPlacement.clientName) + " als " + _toDisplayString(_ctx.currentPlacement.jobTitle), 1)
                    ]),
                    _: 1
                  }),
                  (_ctx.currentPlacement.to)
                    ? (_openBlock(), _createBlock(_component_v_card_subtitle, { key: 0 }, {
                        default: _withCtx(() => [
                          _createTextVNode(_toDisplayString(_ctx.formatDate(_ctx.currentPlacement.from)) + " bis " + _toDisplayString(_ctx.formatDate(_ctx.currentPlacement.to)), 1)
                        ]),
                        _: 1
                      }))
                    : _createCommentVNode("", true)
                ]),
                _: 1
              })
            ]))
          : _createCommentVNode("", true),
        (_ctx.isCustomer)
          ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.assignments, (assignment) => {
                return (_openBlock(), _createBlock(_component_v_list_item, {
                  key: assignment.assignmentId,
                  class: _normalizeClass({ 'text-disabled': _ctx.isExpired(assignment.to) }),
                  onClick: ($event: any) => (
          _ctx.askColumnsForMatches(`${assignment.firstName} ${assignment.lastName}`)
        )
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_v_card_subtitle, null, {
                      default: _withCtx(() => [
                        _createElementVNode("h3", null, _toDisplayString(assignment.firstName) + " " + _toDisplayString(assignment.lastName) + " als " + _toDisplayString(assignment.jobTitle), 1)
                      ]),
                      _: 2
                    }, 1024),
                    _createVNode(_component_v_card_subtitle, null, {
                      default: _withCtx(() => [
                        _createTextVNode(" von " + _toDisplayString(_ctx.formatDate(assignment.from)) + " bis " + _toDisplayString(_ctx.formatDate(assignment.to)), 1)
                      ]),
                      _: 2
                    }, 1024)
                  ]),
                  _: 2
                }, 1032, ["class", "onClick"]))
              }), 128))
            ]))
          : _createCommentVNode("", true)
      ]),
      _: 1
    }),
    _createVNode(_component_v_divider)
  ], 64))
}