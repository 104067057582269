//src/enumscommunication-type.enum.ts
export enum CommunicationType {
  Phone = 1,
  Mobile = 2,
  Fax = 3,
  Email = 4,
  Facebook = 5,
  LinkedIn = 6,
  Twitter = 7,
  Xing = 8,
  Website = 9,
  Skype = 10,
  WorkMobile = 11,
  WhatsApp = 12,
  EmergencyContact = 13,
}
