<template>
  <div class="header-with-button">
    <h2>5. Vielen Dank Seite</h2>
    <button class="save-button" @click="updateThankYouPage()">
      Änderungen speichern
    </button>
  </div>
  <div class="config-option">
    Titel:<input v-model="thankYouPageCopy.title" type="text" />
  </div>
  <div class="config-option">
    Subtitel:<input v-model="thankYouPageCopy.subtitle" type="text" />
  </div>
  <div
    v-for="(step, index) in thankYouPageCopy.steps"
    :key="index"
    class="config-option"
  >
    <div class="uploadable-image" @click="openFilePicker(`step-${index}`)">
      <img
        v-if="imageUrl[index]"
        :src="imageUrl[index]"
        :alt="'Schritt ' + (index + 1)"
      />
      <i class="fa-solid fa-cloud-arrow-up icon"></i>
      <input
        type="file"
        :id="`file-input-step-${index}`"
        @change="handleFileChange($event, index)"
        style="display: none"
      />
      <!-- uploadable images-->
    </div>
    <input
      type="file"
      :id="`file-input-step-${index}`"
      @change="uploadImage(`step-${index}`, ThankYouPageImageName(index))"
      style="display: none"
    />
    <input
      :id="'step-' + index"
      v-model="thankYouPageCopy.steps[index]"
      type="text"
    />
  </div>
</template>
<script lang="ts">
import { UploadService } from "@/services/upload.service";
import { ThankYouPage } from "@/models/funnel-config.model";
import { PropType, defineComponent, reactive, watchEffect } from "vue";

export default defineComponent({
  data() {
    return {
      uploadService: new UploadService(),
      imageUrl: [
        this.ThankYouPageImagePath(0),
        this.ThankYouPageImagePath(1),
        this.ThankYouPageImagePath(2),
        this.ThankYouPageImagePath(3),
      ],
    };
  },
  name: "FunnelThankYou",
  props: {
    companyName: String,
    thankYouPage: Object as PropType<ThankYouPage>,
    funnelId: String,
  },
  setup(props) {
    let thankYouPageCopy: ThankYouPage = reactive<ThankYouPage>({
      title: "",
      subtitle: "",
      steps: [],
    });

    watchEffect(() => {
      const newVal = props.thankYouPage;
      if (newVal) {
        thankYouPageCopy = newVal;
      }
    });

    return { thankYouPageCopy };
  },
  methods: {
    imageUrlFunc(index: number): any {
      return this.ThankYouPageImagePath(index);
    },
    handleFileChange(event: any, index: number) {
      const file = event.target.files[0];

      if (file) {
        const reader = new FileReader();

        reader.onload = (e) => {
          this.imageUrl[index] = e.target?.result;
        };

        reader.readAsDataURL(file);
      }
      this.uploadImage(`step-${index}`, this.ThankYouPageImageName(index));
    },
    ThankYouPageImagePath(index: number): string {
      const url = `http://localhost:3001/${this.companyName}/${this.funnelId}/`;
      switch (index) {
        case 0:
          return `${url}Erstkontakt.png`;
        case 1:
          return `${url}Treffen.png`;
        case 2:
          return `${url}Jobsuche.png`;
        case 3:
          return `${url}Jobauswahl.png`;
        default:
          return "";
      }
    },

    ThankYouPageImageName(index: number) {
      switch (index) {
        case 0:
          return "Erstkontakt.png";
        case 1:
          return "Treffen.png";
        case 2:
          return `Jobsuche.png`;
        case 3:
          return `Jobauswahl.png`;
        default:
          return "";
      }
    },
    openFilePicker(inputIdSuffix: string) {
      this.uploadService.openFilePicker(inputIdSuffix);
    },
    uploadImage(fileType: any, thankYouPageImageName: any) {
      this.uploadService.setCompanyName(this.companyName as string);
      this.uploadService.uploadImage(fileType, thankYouPageImageName);
    },
    updateThankYouPage() {
      // TODO
    },
  },
});
</script>
