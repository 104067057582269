//src/enums/inter-component-messagin.enum.ts

export enum InterComponentMessage {
  searchCandidate = "searchCandidate",
  filterCustomers = "filterCustomers",
  matchMeFromCandidate = "matchMeCandidate",
  matchMeFromCompany = "matchMeCompany",
  matchMeFromGlobalSearch = "matchMeFromGlobalSearch",
  openCustomer = "openCustomer",
  addCustomerFromZvoove = "addCustomerFromZvoove",
  addCustomerFromIndex = "addCustomerFromIndex",
  addDemandFromPhoneClient = "addDemandFromPhoneClient",
  sendDemandDetailsBackToPhoneClient = "sendDemandDetailsBackToPhoneClient",
}
