<!-- src/components/config/ai/AiConfigCompanyPrompts.vue -->
<template>
  <v-container>
    <h2 class="mb-5">
      <v-icon size="small" class="mr-2">fa-solid fa-building</v-icon>
      Prompts: Unternehmensebene
    </h2>

    <v-form @submit.prevent="handleSubmit">
      <v-row>
        <v-col cols="12" md="6">
          <v-textarea
            v-model="localAiData.prompt.company.weAre"
            label="Unternehmensbeschreibung"
            variant="outlined"
            rows="6"
            class="mb-4"
          ></v-textarea>
        </v-col>
        <v-col cols="12" md="6">
          <v-textarea
            v-model="localAiData.prompt.company.weAreShort"
            label="Wer wir sind (kurz)"
            variant="outlined"
            rows="6"
            class="mb-4"
          ></v-textarea>
        </v-col>
        <v-col cols="12" md="6">
          <v-textarea
            v-model="localAiData.prompt.company.whatsAppCandidate"
            label="WhatsApp an einen Kandidaten"
            variant="outlined"
            rows="6"
            class="mb-4"
          ></v-textarea>
        </v-col>
        <v-col cols="12" md="6">
          <v-textarea
            v-model="localAiData.prompt.company.mailCandidate"
            label="Email an einen Kandidaten"
            variant="outlined"
            rows="6"
            class="mb-4"
          ></v-textarea>
        </v-col>
        <v-col cols="12" md="6">
          <v-textarea
            v-model="localAiData.prompt.company.tagsCandidate"
            label="Tags erstellen für Kandidaten"
            variant="outlined"
            rows="6"
            class="mb-4"
          ></v-textarea>
        </v-col>
        <v-col cols="12" md="6">
          <v-textarea
            v-model="localAiData.prompt.company.tagsCompany"
            label="Tags erstellen für Kunden"
            variant="outlined"
            rows="6"
            class="mb-4"
          ></v-textarea>
        </v-col>
        <v-col cols="12" md="6">
          <v-textarea
            v-model="localAiData.prompt.company.mailCustomer"
            label="Email an einen Kunden"
            variant="outlined"
            rows="6"
            class="mb-4"
          ></v-textarea>
        </v-col>
      </v-row>
      <v-btn class="mt-10" type="submit" color="green-darken-3"
        >Speichern</v-btn
      >
    </v-form>
  </v-container>
</template>

<script lang="ts">
import { AiData } from "@/models/company-config.model";
import { defineComponent, PropType } from "vue";

export default defineComponent({
  name: "AiConfigCompanyPrompts",
  props: {
    modelValue: {
      type: Object as PropType<AiData>,
      required: true,
    },
  },
  data() {
    return {
      localAiData: { ...this.modelValue },
    };
  },
  watch: {
    modelValue(newValue: AiData) {
      this.localAiData = { ...newValue };
    },
  },
  methods: {
    handleSubmit() {
      this.$emit("update:modelValue", this.localAiData);
      this.$emit("save");
    },
  },
});
</script>
