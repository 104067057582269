<!--src/componenets/config/UserProfile.vue-->
<template>
  <v-icon size="small" @click="mainModal = true" class="mx-3 clickable">
    fa-solid fa-user
  </v-icon>
  <v-dialog
    v-model="mainModal"
    :max-width="smAndDown ? 'var(--d-w-sd)' : 'var(--d-w-sm)'"
  >
    <v-card>
      <v-card-title class="title d-flex">
        Profil bearbeiten
        <v-spacer></v-spacer>
        <v-icon @click="mainModal = false" class="close-icon">
          <i class="fa-solid fa-xmark"></i>
        </v-icon>
      </v-card-title>
      <div class="name-title d-flex justify-space-between font-weight-bold">
        <div>{{ user?.forename }} {{ user?.lastname }}</div>
        <div>Firma {{ $store.state.company.name }}</div>
      </div>
      <v-card-text>
        <v-form ref="form" v-model="valid">
          <v-text-field
            v-model="user.tel"
            label="Telefonnummer"
            variant="outlined"
            :rules="[rules.required]"
            @update:model-value="resetError"
          ></v-text-field>
          <v-text-field
            v-model="user.mobilePhone"
            label="Handynummer"
            variant="outlined"
            :rules="[rules.required]"
            @update:model-value="resetError"
          ></v-text-field>
          <v-text-field
            v-model="user.email"
            label="E-Mail"
            variant="outlined"
            :rules="[rules.required, rules.email]"
            @update:model-value="resetError"
          ></v-text-field>
          <div class="d-flex justify-space-between">
            <v-btn
              color="var(--color-primary)"
              style="color: var(--color-font-light)"
              @click="showPasswordModal"
              @update:model-value="resetError"
              >Passwort ändern</v-btn
            >
            <ErrorChip :label="requestError"></ErrorChip>
          </div>
        </v-form>
      </v-card-text>
      <v-card-actions>
        <v-btn color="red-darken-3" @click="mainModal = false">Abbrechen</v-btn>
        <v-spacer></v-spacer>
        <v-btn color="green-darken-4" @click="save">Speichern</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>

  <PasswordDialog
    v-if="passwordModal"
    model-value=""
    :requestError="passwordRequestError"
    @cancel="passwordModal = false"
    @changePassword="changePassword"
    @click:outside="handlePasswordBackgroundClick"
    @dirty="resetPasswordError"
  ></PasswordDialog>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import PasswordDialog from "./dialogs/PasswordDialog.vue";
import { Salutation } from "@/enums/salutation.enum";
import { useDisplay } from "vuetify";
import { UserService } from "@/services/api/user.service";
import { PasswordChange } from "@/models/password-change.model";
import { ApiResponse } from "@/models/api-response.model";
import ErrorChip from "@/helper/ErrorChip.vue";

export default defineComponent({
  name: "UserProfile",
  components: {
    ErrorChip,
    PasswordDialog,
  },
  data() {
    const { smAndDown } = useDisplay();
    return {
      rules: {
        required: (value: string) => !!value || "Erforderlich.",
        email: (value: string) => {
          const pattern = /^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/;
          return pattern.test(value) || "Ungültige E-Mail.";
        },
      },
      smAndDown,
      user: {
        company: {
          name: this.$store.state.company.loggedInUser?.company?.name,
        },
        email: this.$store.state.company.loggedInUser?.email,
        forename: this.$store.state.company.loggedInUser?.forename,
        lastname: this.$store.state.company.loggedInUser?.lastname,
        mobilePhone: this.$store.state.company.loggedInUser?.mobilePhone,
        salutation: this.$store.state.company.loggedInUser?.salutation,
        tel: this.$store.state.company.loggedInUser?.tel,
      },
      valid: false,
      passwordValid: false,
      mainModal: false,
      passwordModal: false,
      passwordRequestError: "",
      requestError: "",
      userService: new UserService(),
    };
  },
  computed: {
    salutations() {
      return Object.entries(Salutation)
        .filter(([, value]) => !isNaN(Number(value)))
        .map(([key, value]) => ({
          text: key,
          value: value as number,
        }));
    },
  },
  methods: {
    handlePasswordBackgroundClick() {
      this.passwordModal = false;
    },
    handleBackgroundClick() {
      this.mainModal = false;
    },
    showPasswordModal() {
      this.passwordModal = true;
    },
    changePassword(passwordChange: PasswordChange) {
      if (passwordChange) {
        this.userService
          .changePassword(passwordChange)
          .then((response: ApiResponse) => {
            if (response.error === "Password object invalid") {
              this.passwordRequestError = "Falsches aktuelles Passwort";
            } else {
              this.passwordModal = false;
            }
          });
      }
    },
    resetError() {
      this.requestError = "";
    },
    resetPasswordError() {
      this.passwordRequestError = "";
    },
    save() {
      if (this.valid) {
        this.userService
          .updateUserProfile({
            email: this.user.email,
            mobilePhone: this.user.mobilePhone,
            tel: this.user.tel,
          })
          .then((response: ApiResponse) => {
            if (!response.error) {
              this.mainModal = false;
            } else {
              this.requestError = response.error;
            }
          });
      } else {
        this.requestError = "Formular unvollständig";
      }
    },
  },
});
</script>

<style scoped>
.clickable {
  cursor: pointer;
}
.clickable:hover {
  transition: all 0.3s ease;
  scale: 1.2;
}
.name-title {
  margin: 0 1.5rem;
}

.title {
  margin: 8px;
}
</style>
