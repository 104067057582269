import { UserHelperService } from "../user-helper.service";
import { Api } from "./api.class";

// Login and get JWT token from backend
export class LoginService {
  private api = Api.getInstance();
  private userService = UserHelperService.getInstance();

  async login(user: any): Promise<any> {
    const response = await this.api.getBackendApi().post("/login", user);
    // TODO: increase security with HttpOnly!
    localStorage.setItem("accessToken", response.data.access_token);
    this.userService.updateIsLoggedIn();
    return response.data;
  }

  async logout(): Promise<boolean> {
    try {
      const accessToken = localStorage.getItem("accessToken");
      if (accessToken) {
        await this.api.getBackendApi().post(
          "/logout",
          {},
          {
            headers: { Authorization: `Bearer ${accessToken}` },
          }
        );
      }
      localStorage.removeItem("accessToken");
      this.userService.updateIsLoggedIn();
      return true;
    } catch (error) {
      console.error(error);
      localStorage.removeItem("accessToken");
      this.userService.updateIsLoggedIn();
      return false;
    }
  }
}
