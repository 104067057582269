<!--I_JobFunnels.vue-->
<template>
  <div class="container">
    <div class="filter-menu-left-column">
      <!--Create on Funnel Item für each Funnel entry in Funnels-->
      <I_JobFunnelItem
        v-for="funnel in company.funnels"
        :key="funnel.id"
        :name="funnel.name"
        :funnelId="funnel.id"
        @edit-funnel="handleEditFunnel"
      ></I_JobFunnelItem>
    </div>
    <div class="filter-menu-right-column">
      <I_JobFunnelEdit :funnelId="funnelIdSelection"></I_JobFunnelEdit>
    </div>
  </div>
</template>

<script lang="ts">
import { mapGetters } from "vuex"; //store.js
import I_JobFunnelItem from "@/components/recruiting/JobFunnelItem.vue";
import I_JobFunnelEdit from "@/components/recruiting/JobFunnelEdit.vue";
import { defineComponent } from "vue";

export default defineComponent({
  name: "JobFunnels",
  components: {
    I_JobFunnelItem,
    I_JobFunnelEdit,
  },
  data() {
    return {
      funnelIdSelection: "",
    };
  },
  computed: {
    ...mapGetters(["company"]), //Get Funnel Data from vuex store.je
  },
  methods: {
    handleEditFunnel(data: any) {
      this.funnelIdSelection = data.funnelId; // Selected Funnel id from I_FunnelItem.vue
    },
  },
});
</script>

<style scoped>
.container {
  display: flex;
}

.filter-menu-left-column {
  width: 30%;
  display: flex;
  align-items: flex-start;
}

.filter-menu-right-column {
  padding-top: 1rem;
  width: 70%;
  padding-left: 20px;
}

iframe {
  width: 100%;
  height: 600px;
}
</style>
