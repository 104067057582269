import { DirectiveBinding } from "vue";

const longpress = {
  beforeMount(el: HTMLElement, binding: DirectiveBinding) {
    if (typeof binding.value !== "function") {
      throw new Error(`Expected a function, got ${typeof binding.value}`);
    }

    let pressTimer: number | null = null;

    const handler = (e: Event) => {
      binding.value(e);
    };

    const start = (e: TouchEvent | MouseEvent) => {
      e.preventDefault();

      if (pressTimer === null) {
        pressTimer = window.setTimeout(() => handler(e), 600);
      }
    };

    const cancel = () => {
      if (pressTimer !== null) {
        clearTimeout(pressTimer);
        pressTimer = null;
      }
    };

    el.addEventListener("mousedown", start);
    el.addEventListener("touchstart", start, { passive: false });
    el.addEventListener("mouseout", cancel);
    el.addEventListener("touchend", cancel);
    el.addEventListener("touchcancel", cancel);
    el.addEventListener("mouseup", cancel);

    (el as any)._cancelLongPress = cancel;
    (el as any)._startLongPress = start;
  },
  unmounted(el: HTMLElement) {
    if ((el as any)._startLongPress) {
      el.removeEventListener("mousedown", (el as any)._startLongPress);
      el.removeEventListener("touchstart", (el as any)._startLongPress);
    }
    if ((el as any)._cancelLongPress) {
      el.removeEventListener("mouseout", (el as any)._cancelLongPress);
      el.removeEventListener("touchend", (el as any)._cancelLongPress);
      el.removeEventListener("touchcancel", (el as any)._cancelLongPress);
      el.removeEventListener("mouseup", (el as any)._cancelLongPress);
    }
  },
};

export default longpress;
