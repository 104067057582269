<template>
  <!-- Bug Icon Button -->
  <v-tooltip bottom>
    <template v-slot:activator="{ on, attrs }">
      <v-icon
        class="bug-icon"
        v-bind="attrs"
        v-on="{ ...on, click: () => (dialog = true) }"
        size="small"
      >
        fa-solid fa-bug
      </v-icon>
    </template>
    <span>Bug melden</span>
  </v-tooltip>

  <!-- Report Bug Dialog -->
  <v-dialog
    v-model="dialog"
    :max-width="smAndDown ? 'var(--d-w-sd)' : 'var(--d-w-s)'"
  >
    <v-card>
      <v-card-title>
        <span class="headline"
          >REPORT-A-BUG: Bugs / Ideen / Features anfragen</span
        >
      </v-card-title>
      <v-card-text>
        <v-form v-model="valid" ref="form">
          <v-select
            variant="outlined"
            v-model="issueType"
            :items="issueTypes"
            label="Select Issue Type"
            :rules="[(v) => !!v || 'Issue type is required']"
            required
          ></v-select>
          <v-text-field
            variant="outlined"
            v-model="title"
            label="Title"
            :rules="[(v) => !!v || 'Title is required']"
            required
          ></v-text-field>
          <v-textarea
            variant="outlined"
            v-model="description"
            label="Description"
            :rules="[(v) => !!v || 'Description is required']"
            required
          ></v-textarea>
        </v-form>
      </v-card-text>
      <v-card-text class="d-flex">
        <v-btn color="red-darken-4" @click="dialog = false">Cancel</v-btn>
        <v-spacer></v-spacer>
        <v-btn
          color="green-darken-3"
          @click="submitBugReport"
          :disabled="!valid"
          >Submit</v-btn
        >
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { GitLabService } from "@/services/api/gitlab.service";
import { DialogResponse } from "@/enums/dialog-action.enum";
import { IssueType } from "@/enums/issue-type.enum";
import ToastService from "@/services/toast.service";
import { useDisplay } from "vuetify";
import { mapGetters } from "vuex";
import { translateBugReport } from "@/services/ai.service";
import { SpinnerService } from "@/services/spinner.service";

export default defineComponent({
  data() {
    const { smAndDown } = useDisplay();
    return {
      smAndDown,
      dialog: false,
      valid: false,
      gitLabService: new GitLabService(),
      issueType: "",
      title: "",
      description: "",
      responseMessage: "",
      responseType: "" as any,
      issueTypes: ["BUG", "CRITICAL BUG", "Idea", "Feature Request"],
    };
  },
  computed: {
    ...mapGetters(["user"]),
  },
  methods: {
    async submitBugReport() {
      SpinnerService.showSpinner();
      let labels: string[] = [];
      let issueType = "issue";

      if (this.issueType === "BUG" || this.issueType === "CRITICAL BUG") {
        labels = ["Bug", `${this.user.forename} ${this.user.lastname}`];
        issueType = IssueType.incident;
      } else if (this.issueType === "Idea") {
        labels = ["Idea", `${this.user.forename} ${this.user.lastname}`];
        issueType = IssueType.test_case;
      } else if (this.issueType === "Feature Request") {
        labels = [
          "Feature Request",
          `${this.user.forename} ${this.user.lastname}`,
        ];
        issueType = IssueType.issue;
      }
      ToastService.showReminder(
        "Ich übersetze das Ticket gerade in Englisch ..."
      );
      const translatedBugReport = await translateBugReport(
        this.title,
        this.description,
        this.issueType
      );
      ToastService.showSuccess(
        `Sende nun das Ticket: ${translatedBugReport.title}- ${translatedBugReport.text}`
      );

      try {
        await this.gitLabService.createBugReport(
          `[${this.issueType}] ${translatedBugReport.title}`,
          translatedBugReport.text,
          labels,
          issueType
        );

        this.responseMessage = `Issue created successfully!`;
        this.responseType = DialogResponse.success;
        ToastService.show(this.responseMessage);
        this.clearForm();
      } catch (error: any) {
        if (error.response) {
          this.responseMessage = error.response.data.message;
        } else {
          this.responseMessage = error.message;
        }
        this.responseType = DialogResponse.failure;
      }
      SpinnerService.removeSpinner();
    },
    clearForm() {
      this.issueType = "";
      this.title = "";
      this.description = "";
      this.valid = false;

      const form = this.$refs.form as HTMLFormElement;
      if (form && form.reset) {
        form.reset();
      }
      this.dialog = false;
    },
  },
});
</script>

<style scoped>
.v-alert {
  margin-top: 20px;
}
.bug-icon:hover {
  transition: all 0.3s ease;
  scale: 1.2;
}
</style>
