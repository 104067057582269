import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, withModifiers as _withModifiers, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "mb-5" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_icon = _resolveComponent("v-icon")!
  const _component_v_text_field = _resolveComponent("v-text-field")!
  const _component_v_col = _resolveComponent("v-col")!
  const _component_v_textarea = _resolveComponent("v-textarea")!
  const _component_v_row = _resolveComponent("v-row")!
  const _component_v_btn = _resolveComponent("v-btn")!
  const _component_v_form = _resolveComponent("v-form")!
  const _component_v_container = _resolveComponent("v-container")!

  return (_openBlock(), _createBlock(_component_v_container, null, {
    default: _withCtx(() => [
      _createElementVNode("h2", _hoisted_1, [
        _createVNode(_component_v_icon, {
          size: "small",
          class: "mr-2"
        }, {
          default: _withCtx(() => [
            _createTextVNode("fa-solid fa-rectangle-ad")
          ]),
          _: 1
        }),
        _createTextVNode("Vorlage Stellenanzeigen ")
      ]),
      _createVNode(_component_v_form, {
        onSubmit: _withModifiers(_ctx.handleSubmit, ["prevent"])
      }, {
        default: _withCtx(() => [
          _createVNode(_component_v_row, null, {
            default: _withCtx(() => [
              _createVNode(_component_v_col, {
                cols: "12",
                md: "6"
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_v_text_field, {
                    modelValue: _ctx.localAiData.template.jobAd.Bezeichnung,
                    "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.localAiData.template.jobAd.Bezeichnung) = $event)),
                    label: "Bezeichnung",
                    variant: "outlined",
                    class: "mb-4"
                  }, null, 8, ["modelValue"])
                ]),
                _: 1
              }),
              _createVNode(_component_v_col, {
                cols: "12",
                md: "6"
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_v_text_field, {
                    modelValue: _ctx.localAiData.template.jobAd.StellenzielHeader,
                    "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.localAiData.template.jobAd.StellenzielHeader) = $event)),
                    label: "Stellenziel Header",
                    variant: "outlined",
                    class: "mb-4"
                  }, null, 8, ["modelValue"])
                ]),
                _: 1
              }),
              _createVNode(_component_v_col, { cols: "12" }, {
                default: _withCtx(() => [
                  _createVNode(_component_v_textarea, {
                    modelValue: _ctx.localAiData.template.jobAd.Stellenziel,
                    "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.localAiData.template.jobAd.Stellenziel) = $event)),
                    label: "Stellenziel",
                    variant: "outlined",
                    rows: "3",
                    class: "mb-4"
                  }, null, 8, ["modelValue"])
                ]),
                _: 1
              }),
              _createVNode(_component_v_col, {
                cols: "12",
                md: "6"
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_v_text_field, {
                    modelValue: _ctx.localAiData.template.jobAd.AufgabenHeader,
                    "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.localAiData.template.jobAd.AufgabenHeader) = $event)),
                    label: "Aufgaben Header",
                    variant: "outlined",
                    class: "mb-4"
                  }, null, 8, ["modelValue"])
                ]),
                _: 1
              }),
              _createVNode(_component_v_col, { cols: "12" }, {
                default: _withCtx(() => [
                  _createVNode(_component_v_textarea, {
                    modelValue: _ctx.localAiData.template.jobAd.Aufgaben,
                    "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.localAiData.template.jobAd.Aufgaben) = $event)),
                    label: "Aufgaben",
                    variant: "outlined",
                    rows: "3",
                    class: "mb-4"
                  }, null, 8, ["modelValue"])
                ]),
                _: 1
              }),
              _createVNode(_component_v_col, {
                cols: "12",
                md: "6"
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_v_text_field, {
                    modelValue: _ctx.localAiData.template.jobAd.PerspektivenHeader,
                    "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.localAiData.template.jobAd.PerspektivenHeader) = $event)),
                    label: "Perspektiven Header",
                    variant: "outlined",
                    class: "mb-4"
                  }, null, 8, ["modelValue"])
                ]),
                _: 1
              }),
              _createVNode(_component_v_col, { cols: "12" }, {
                default: _withCtx(() => [
                  _createVNode(_component_v_textarea, {
                    modelValue: _ctx.localAiData.template.jobAd.Perspektiven,
                    "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.localAiData.template.jobAd.Perspektiven) = $event)),
                    label: "Perspektiven",
                    variant: "outlined",
                    rows: "3",
                    class: "mb-4"
                  }, null, 8, ["modelValue"])
                ]),
                _: 1
              }),
              _createVNode(_component_v_col, {
                cols: "12",
                md: "6"
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_v_text_field, {
                    modelValue: _ctx.localAiData.template.jobAd.UnternehmensbedeutungHeader,
                    "onUpdate:modelValue": _cache[7] || (_cache[7] = ($event: any) => ((_ctx.localAiData.template.jobAd.UnternehmensbedeutungHeader) = $event)),
                    label: "Unternehmensbedeutung Header",
                    variant: "outlined",
                    class: "mb-4"
                  }, null, 8, ["modelValue"])
                ]),
                _: 1
              }),
              _createVNode(_component_v_col, { cols: "12" }, {
                default: _withCtx(() => [
                  _createVNode(_component_v_textarea, {
                    modelValue: _ctx.localAiData.template.jobAd.Unternehmensbedeutung,
                    "onUpdate:modelValue": _cache[8] || (_cache[8] = ($event: any) => ((_ctx.localAiData.template.jobAd.Unternehmensbedeutung) = $event)),
                    label: "Unternehmensbedeutung",
                    variant: "outlined",
                    rows: "3",
                    class: "mb-4"
                  }, null, 8, ["modelValue"])
                ]),
                _: 1
              }),
              _createVNode(_component_v_col, {
                cols: "12",
                md: "6"
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_v_text_field, {
                    modelValue: _ctx.localAiData.template.jobAd.FachlicheAnforderungenHeader,
                    "onUpdate:modelValue": _cache[9] || (_cache[9] = ($event: any) => ((_ctx.localAiData.template.jobAd.FachlicheAnforderungenHeader) = $event)),
                    label: "Fachliche Anforderungen Header",
                    variant: "outlined",
                    class: "mb-4"
                  }, null, 8, ["modelValue"])
                ]),
                _: 1
              }),
              _createVNode(_component_v_col, { cols: "12" }, {
                default: _withCtx(() => [
                  _createVNode(_component_v_textarea, {
                    modelValue: _ctx.localAiData.template.jobAd.FachlicheAnforderungen,
                    "onUpdate:modelValue": _cache[10] || (_cache[10] = ($event: any) => ((_ctx.localAiData.template.jobAd.FachlicheAnforderungen) = $event)),
                    label: "Fachliche Anforderungen",
                    variant: "outlined",
                    rows: "3",
                    class: "mb-4"
                  }, null, 8, ["modelValue"])
                ]),
                _: 1
              }),
              _createVNode(_component_v_col, {
                cols: "12",
                md: "6"
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_v_text_field, {
                    modelValue: _ctx.localAiData.template.jobAd.PersoenlicheAnforderungenHeader,
                    "onUpdate:modelValue": _cache[11] || (_cache[11] = ($event: any) => ((_ctx.localAiData.template.jobAd.PersoenlicheAnforderungenHeader) = $event)),
                    label: "Persönliche Anforderungen Header",
                    variant: "outlined",
                    class: "mb-4"
                  }, null, 8, ["modelValue"])
                ]),
                _: 1
              }),
              _createVNode(_component_v_col, { cols: "12" }, {
                default: _withCtx(() => [
                  _createVNode(_component_v_textarea, {
                    modelValue: _ctx.localAiData.template.jobAd.PersoenlicheAnforderungen,
                    "onUpdate:modelValue": _cache[12] || (_cache[12] = ($event: any) => ((_ctx.localAiData.template.jobAd.PersoenlicheAnforderungen) = $event)),
                    label: "Persönliche Anforderungen",
                    variant: "outlined",
                    rows: "3",
                    class: "mb-4"
                  }, null, 8, ["modelValue"])
                ]),
                _: 1
              }),
              _createVNode(_component_v_col, {
                cols: "12",
                md: "6"
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_v_text_field, {
                    modelValue: _ctx.localAiData.template.jobAd.ArbeitgeberleistungHeader,
                    "onUpdate:modelValue": _cache[13] || (_cache[13] = ($event: any) => ((_ctx.localAiData.template.jobAd.ArbeitgeberleistungHeader) = $event)),
                    label: "Arbeitgeberleistung Header",
                    variant: "outlined",
                    class: "mb-4"
                  }, null, 8, ["modelValue"])
                ]),
                _: 1
              }),
              _createVNode(_component_v_col, { cols: "12" }, {
                default: _withCtx(() => [
                  _createVNode(_component_v_textarea, {
                    modelValue: _ctx.localAiData.template.jobAd.Arbeitgeberleistung,
                    "onUpdate:modelValue": _cache[14] || (_cache[14] = ($event: any) => ((_ctx.localAiData.template.jobAd.Arbeitgeberleistung) = $event)),
                    label: "Arbeitgeberleistung",
                    variant: "outlined",
                    rows: "3",
                    class: "mb-4"
                  }, null, 8, ["modelValue"])
                ]),
                _: 1
              })
            ]),
            _: 1
          }),
          _createVNode(_component_v_btn, {
            class: "mt-10",
            type: "submit",
            color: "green-darken-3"
          }, {
            default: _withCtx(() => [
              _createTextVNode("Speichern")
            ]),
            _: 1
          })
        ]),
        _: 1
      }, 8, ["onSubmit"])
    ]),
    _: 1
  }))
}