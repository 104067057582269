<!--src/components/disposition/CandidateItem.vue-->
<template>
  <v-container v-if="!isHided" class="ma-0 pa-0">
    <v-hover>
      <template #default="{ isHovering, props }">
        <v-container
          @contextmenu.prevent="openContextMenu($event)"
          @dragstart="handleDragStart"
          @longpress="openContextMenu($event)"
          draggable="true"
          class="ma-0 pa-0 item longpress"
          v-if="isFullyCollapsed"
          :color="isHovering ? 'var(--color-item-hover)' : 'var(--color-item)'"
        >
          <v-card-title class="d-flex justify-space-between pa-0">
            <v-btn
              @click="loadCandidate"
              variant="tonal"
              density="compact"
              size="small"
              v-if="
                !candidate.firstName &&
                !candidate.lastName &&
                !candidateData?.firstName &&
                !candidateData?.lastName
              "
            >
              <v-icon class="mr-2" size="small"
                >fa-solid fa-cloud-arrow-down</v-icon
              >
              #{{ candidate.applicationId }}
              <v-tooltip activator="parent" location="top left"
                >Kandidatendaten vollständig aus dem ATS laden ...</v-tooltip
              >
            </v-btn>
            <span class="pa-0 text-body-2">
              {{
                candidateData?.firstName
                  ? candidateData.firstName
                  : candidate.firstName
              }}
              {{
                candidateData?.lastName
                  ? candidateData.lastName
                  : candidate.lastName
              }}
              <span v-if="candidateAge > 0"> ({{ candidateAge }})</span>
              {{
                candidateData?.shiftIcon
                  ? candidateData.shiftIcon
                  : candidate.shiftIcon
              }}
              <span
                style="color: black"
                v-if="
                  (candidateData && candidateData.mobilityRadius > 0) ||
                  (candidate &&
                    candidate?.mobilityRadius &&
                    candidate?.mobilityRadius > 0)
                "
              >
                ({{
                  candidateData?.mobilityRadius
                    ? candidateData.mobilityRadius
                    : candidate.mobilityRadius
                }}
                km
                <v-icon size="xsmall">{{
                  candidateData?.mobilityIcon
                    ? candidateData.mobilityIcon
                    : candidate.mobilityIcon
                }}</v-icon>
                )
              </span>
            </span>
            <span
              class="mx-1 text-caption font-weight-light text-medium-emphasis"
            >
              {{
                candidateData?.addressCity
                  ? candidateData.addressCity
                  : candidate.addressCity
              }}
            </span>
            <div
              class="status-list"
              :style="{ backgroundColor: statusColor }"
              :class="{ blinking: needToContact || prioNeedToContact }"
            ></div>
          </v-card-title>
          <v-divider class="my-1"></v-divider>
        </v-container>
        <v-card
          v-if="!isFullyCollapsed"
          class="item border-sm pa-2 mb-2 longpress"
          :elevation="2"
          rounded-lg
          v-bind="props"
          :color="isHovering ? 'var(--color-item-hover)' : 'var(--color-item)'"
          :class="{
            'fully-collapsed': isFullyCollapsed,
            expanded: isExpanded,
            'fully-expanded': isFullyExpanded,
          }"
          @dblclick="toggleFullExpansion"
          @dragstart="handleDragStart"
          @contextmenu.prevent="openContextMenu($event)"
          @longpress="openContextMenu($event)"
          draggable="true"
        >
          <div
            class="status"
            :style="{ backgroundColor: statusColor }"
            :class="{ blinking: needToContact || prioNeedToContact }"
          ></div>
          <div class="status-title">
            <div class="item-collapsed">
              <div>
                <v-card-title class="d-flex pa-0 text-body-2">
                  <v-btn
                    @click="loadCandidate(ExternalSoftware.atsRecruit)"
                    variant="tonal"
                    v-if="
                      !candidate.firstName &&
                      !candidate.lastName &&
                      !candidateData?.firstName &&
                      !candidateData?.lastName
                    "
                  >
                    <v-icon class="mr-2" size="small"
                      >fa-solid fa-cloud-arrow-down</v-icon
                    >
                    #{{ candidate.applicationId }}
                    <v-tooltip activator="parent" location="top left"
                      >Kandidatendaten vollständig aus dem ATS laden
                      ...</v-tooltip
                    >
                  </v-btn>
                  <v-btn
                    icon
                    variant="text"
                    density="compact"
                    v-if="isAtsStatusUnknown && !isAtsStatusChangedFromUnknown"
                    @click="updateComponent"
                  >
                    <v-icon size="small">fa-regular fa-question-circle</v-icon>
                    <v-tooltip activator="parent" location="bottom"
                      >ATS Status ist unbekannt! Kandidat aktualisieren und
                      neuen Status abrufen?</v-tooltip
                    >
                  </v-btn>

                  {{
                    candidateData?.firstName
                      ? candidateData.firstName
                      : candidate.firstName
                  }}
                  {{
                    candidateData?.lastName
                      ? candidateData.lastName
                      : candidate.lastName
                  }}
                  <span class="mx-1" v-if="candidateAge > 0">
                    ({{ candidateAge }})</span
                  >
                  {{
                    candidateData?.shiftIcon
                      ? candidateData.shiftIcon
                      : candidate.shiftIcon
                  }}
                  <span
                    class="mx-1 text-caption text-medium-emphasis"
                    v-if="isAtsStatusChangedFromUnknown"
                  >
                    (verschoben nach {{ matchedApplication.ats?.status }})
                  </span>
                </v-card-title>
                <v-card-subtitle class="pl-0">
                  {{
                    candidateData?.addressPostalCode
                      ? candidateData.addressPostalCode
                      : candidate.addressPostalCode
                  }}
                  {{
                    candidateData?.addressCity
                      ? candidateData.addressCity
                      : candidate.addressCity
                  }}
                  <span
                    style="color: black"
                    v-if="
                      (candidateData && candidateData.mobilityRadius > 0) ||
                      (candidate &&
                        candidate?.mobilityRadius &&
                        candidate?.mobilityRadius > 0)
                    "
                  >
                    ({{
                      candidateData?.mobilityRadius
                        ? candidateData.mobilityRadius
                        : candidate.mobilityRadius
                    }}
                    km
                    <v-icon size="xsmall">{{
                      candidateData?.mobilityIcon
                        ? candidateData.mobilityIcon
                        : candidate.mobilityIcon
                    }}</v-icon>
                    )
                  </span>
                </v-card-subtitle>
              </div>
            </div>
            <button @click="toggleExpansion" class="toggle-button">
              {{ isExpanded ? "▲" : "▼" }}
            </button>
          </div>
          <div class="hide-on-inactive-column" v-if="isActive">
            <span style="font-size: 0.7em"
              ><b
                >#{{ candidate?.applicationId }}
                {{
                  matchedApplication?.appliedAs
                    ? matchedApplication.appliedAs
                    : candidate.appliedAs
                }}</b
              ></span
            ><br />
            <span style="font-size: 0.8em">{{ matchedMandantName }}</span>
          </div>
          <v-divider></v-divider>
          <div v-if="isExpanded" class="status-communication-container">
            <div class="communication-icons-container">
              <div
                v-if="candidateData && candidateData._id"
                class="appointment-icon"
                @click="createNewMandantAppointmentOnDependencies()"
              >
                <i class="fa-solid fa-building">
                  <v-tooltip activator="parent" location="bottom"
                    >Termine mit Niederlassung</v-tooltip
                  ></i
                >
              </div>
              <v-select
                :items="statusOptionsRecruit"
                item-title="text"
                item-value="value"
                density="compact"
                variant="outlined"
                v-model="selectedStatus"
                @update:model-value="changeStatus(selectedStatus)"
              >
              </v-select>
            </div>
            <div class="communication-icons-container">
              <zvooveRecruitLinkMenu
                :zvooveRecruitMitarbeiterUuid="candidate?.candidateUuid"
                @getRecruitData="loadCandidate(ExternalSoftware.atsRecruit)"
              ></zvooveRecruitLinkMenu>
              <v-btn
                v-if="!isStatusInterview()"
                icon
                class="application-form-icon"
                variant="text"
                density="compact"
                @click="openApplicationFormDispatcherMode()"
              >
                <v-icon size="xsmall">fa-solid fa-id-card</v-icon>
                <v-tooltip activator="parent" location="bottom"
                  >Zum Bewerberbogen</v-tooltip
                >
              </v-btn>
              <PhoneClient
                ref="phoneClientComponent"
                :candidate="candidate"
                :candidateName="`${candidateData?.firstName} ${candidateData?.lastName}`"
                :phoneNumbers="filteredPhoneNumbers"
                @addFollowUpEvent="addFollowUpOnDependencies"
                @collapseParentItem="collapseItem"
                @isDialingNumber="phoneClientIsDialing"
                @setAppointmentEvent="setAppointmentOnDependencies"
              ></PhoneClient>
              <WhatsAppClient
                :AiMessageType="'whatsAppCandidate'"
                :candidate="candidate"
                :candidateName="`${candidateData?.firstName} ${candidateData?.lastName}`"
                :linkingStatuses="linkingStatuses"
                :message="message.body"
                :phoneNumbers="filteredPhoneNumbers"
                :salutation="salutationText"
                :signature="signatureWhatsApp"
                @collapseParentItem="collapseItem"
              ></WhatsAppClient>
              <MailClient
                ref="mailClientComponent"
                :AiMessageType="'mailCandidate'"
                :candidate="candidate"
                :candidateName="`${candidateData?.firstName} ${candidateData?.lastName}`"
                :emailAddresses="filteredEmail"
                :emailObject="message"
                :linkingStatuses="linkingStatuses"
                :signature="signatureMail"
                @collapseParentItem="collapseItem"
              ></MailClient>
            </div>
          </div>
          <DialogApplicationForm
            v-if="isExpanded"
            ref="dialogDialogApplicationFormComponent"
            :candidate="candidateData"
            :isStatusInterview="isStatusInterview()"
            @submit="loadCandidate()"
          ></DialogApplicationForm>
          <v-divider></v-divider>
          <Checklist
            :checklist="candidateData?.checklist || []"
            @updateChecklist="updateChecklist"
          ></Checklist>
          <v-divider></v-divider>
          <div v-if="isExpanded" class="tags-container">
            <v-chip
              v-for="(tag, index) in candidateData?.tags"
              :key="index"
              class="candidate-tag"
              color="var(--color-primary)"
              text-color="var(--color-font-light)"
              @click:close="removeTag(index)"
            >
              {{ tag }}
              <v-icon
                small
                @click.stop="removeTag(index)"
                class="fa fa-times"
              ></v-icon>
            </v-chip>
            <v-text-field
              variant="outlined"
              class="mt-2"
              label="Neuer Tag"
              style="max-width: 10rem"
              rounded="lg"
              density="compact"
              v-model="newTag"
              @keyup.enter="addTag"
            >
              <v-tooltip activator="parent" location="bottom"
                >mit "Enter" neuen Tag hinzufügen</v-tooltip
              >
            </v-text-field>
          </div>
          <v-divider></v-divider>
          <Dependencies
            v-if="
              candidate &&
              (hasLinking(candidateData?._id) || isDependenciesActive)
            "
            ref="dependenciesComponent"
            :mandant="[matchedMandantUuid || '']"
            :candidate_id="candidate.parentObjectid"
            :linkingDescription="linkingDescription"
            @updateCandidateStatus="handleCandidateStatusUpdate"
            @candidateToMandantLinked="setCandidateToMandantFromDependencies"
            @needToContact="prioNeedToContact = true"
            @notNeedToContact="prioNeedToContact = false"
            @openPhoneClient="openPhoneClient()"
          />
          <v-divider></v-divider>
          <div v-if="isExpanded">
            <v-textarea
              class="mt-2 pr-1"
              v-model="interviewResultEdit"
              label="Zusammenfassung Vorstellungsgespräch:"
              variant="solo"
              rows="4"
            ></v-textarea>
            <v-container class="ma-0 pa-0 d-flex">
              <v-checkbox
                v-if="
                  candidateData &&
                  softwareIntegration.wordPressPlugin &&
                  isCandidateActiveForPlacement()
                "
                density="compact"
                v-model="candidateData.isPublishedOnWebsite"
                :label="`Kandidat auf die Webseite stellen (WordPress Plugin)`"
                @change="handlePublishedOnWebseiteChange"
              ></v-checkbox>
              <v-spacer></v-spacer>
              <v-icon
                class="save-interview-result"
                @click="saveInterviewResult(interviewResultEdit)"
                >fa-solid fa-cloud-arrow-up</v-icon
              >
              <v-tooltip activator="parent" location="top left"
                >Zusammenfassung in den Kandidatendaten abspeichern</v-tooltip
              >
            </v-container>
            <p v-if="getCareerLevelDescription()">
              {{ getCareerLevelDescription() }}
            </p>
            <v-divider></v-divider>
            <SendCandidateToWp
              v-if="softwareIntegration.wordPressPlugin"
              ref="sendCandidateToWpComponent"
              @aborted="sendCandidateToWpAborted()"
            ></SendCandidateToWp>

            <Timeline
              :candidate="candidate"
              :candidateName="`${candidateData?.firstName} ${candidateData?.lastName}`"
              :mandant="matchedMandantUuid || ''"
            ></Timeline>
          </div>
        </v-card>
        <Teleport to="body">
          <JobAdLeadsCandidate
            v-if="
              isExpanded &&
              candidateData?.jobAdLeads &&
              isCandidateActiveForPlacement()
            "
            ref="jobAdListComponent"
            :jobAds="candidateData?.jobAdLeads as IndexJobAd[] || []"
            :indexCareerLevel="candidateData?.careerLevel"
            :mandant="matchedMandantUuid || ''"
            @generateAiSearchterm="fetchJobAdLeads(true)"
            @manualAnzeigendatenSearch="openAnzeigendatenManualSearch()"
            @updateCareerLevels="updateCareerLevels"
          />
          <DialogInterviewCompleted
            v-if="isDialogInterviewCompletedActive"
            ref="dialogInterviewCompletedComponent"
            :zvooveRecruitMitarbeiterUuid="candidate.candidateUuid"
            @saveResumeInterview="handleSaveResumeInterview"
            @fetchRecruitData="loadCandidate(ExternalSoftware.atsRecruit)"
            @openEditCandidateDialog="openEditCandidateModal"
          ></DialogInterviewCompleted>
          <DialogManualAnzeigendatenSearch
            v-if="isDialogManualAnzeigendatenSearchActive"
            :dataForQuery="prepareDataForJobAdLeadsQuery()"
            ref="dialogManualAnzeigendatenSearchComponent"
            @searchAndReplace="searchAnzeigendatenAndReplace"
            @searchAndAdd="searchAnzeigendatenAndAdd"
          ></DialogManualAnzeigendatenSearch>
          <DialogEditCandidate
            v-if="isDialogEditCandidateActive"
            ref="dialogEditCandidateComponent"
            :candidate="candidateData"
            @submit="loadCandidate()"
            @fetchJobAdLeads="fetchJobAdLeads()"
            @generateAiSearchterm="fetchJobAdLeads(true)"
            @manualAnzeigendatenSearch="openAnzeigendatenManualSearch()"
          ></DialogEditCandidate>
          <div
            v-if="showContextMenu"
            :style="{
              top: contextMenuPosition.y + 'px',
              left: contextMenuPosition.x + 'px',
            }"
            class="context-menu"
            ref="ContextMenu"
            @click.stop
          >
            <v-list dense>
              <v-list-item @click="openEditCandidateModal()">
                <v-icon class="text-medium-emphasis mr-1" size="xs">
                  fa-solid fa-pen-to-square </v-icon
                >bearbeiten</v-list-item
              >
              <v-list-item @click="askCustomerItemsForMatch()">
                <v-icon class="text-medium-emphasis mr-1" size="xs">
                  fa-solid fa-heart </v-icon
                >Matching</v-list-item
              >
              <v-list-item
                v-if="$store.state.isActiveCandidateMatching"
                @click="clearMatchingOject()"
              >
                <v-icon class="text-medium-emphasis mr-1" size="xs">
                  fa-solid fa-heart-circle-xmark </v-icon
                >Matches entfernen</v-list-item
              >
              <v-list-item @click="deleteCandidate()">
                <v-icon class="text-medium-emphasis mr-1" size="xs">
                  fa-solid fa-trash-can </v-icon
                >Kandidat in Disposition:er löschen</v-list-item
              >
              <v-list-item @click="emitToggleItemsMinimized()">
                <v-icon class="text-medium-emphasis mr-1" size="xs">
                  {{
                    isFullyCollapsed
                      ? "fa-solid fa-chevron-up"
                      : "fa-solid fa-chevron-down"
                  }}
                </v-icon>
                {{ isFullyCollapsed ? "Karten normal" : "Karten minimieren" }}
              </v-list-item>
            </v-list>
          </div>
        </Teleport>
      </template>
    </v-hover>
  </v-container>
</template>

<script lang="ts">
import { AnzeigedatenService } from "@/services/api/anzeigendaten.service";
import { AtsRecruitService } from "@/services/api/api-integration-one.service";
import { CandidateService } from "@/services/api/candidate.service";
import { CheckList } from "@/models/checklist.model";
import { LinkingStatus } from "@/enums/dependency.enum";
import { CareerLevel, IaCareerLevel } from "@/enums/anzeigendaten.enum";
import {
  Candidate,
  CareerStep,
  CommunicationMeans,
  Skill,
} from "@/models/candidate.model";
import { defineComponent, PropType } from "vue";
import { Salutation, getSalutationText } from "@/enums/salutation.enum";
import { Gender } from "@/enums/gender.enum";
import {
  generateCandidateTags,
  generateJobAdLeadsQuery,
} from "@/services/ai.service";
import {
  DataForJobAdLeadsQuery,
  IndexJobAd,
  JodAdLeadsQuery,
} from "@/models/external/index-anzeigendaten.model";
import { InterComponentMessage } from "@/enums/inter-component-messagin.enum";
import { Mandant } from "@/models/mandant.model";
import { mapGetters, mapMutations } from "vuex";
import { MutationType } from "@/enums/vuex-mutationtype.enum";
import { RecruitStatusOption } from "@/models/status-candidate.model";
import { SpinnerService } from "@/services/spinner.service";
import { ZipCodeDistance } from "@/models/zipcode.model";
import { ZipCodeService } from "@/services/api/zip-code.service";
import { ZorstService } from "@/services/zorst.service";
import Checklist from "./elements/Checklist.vue";
import Dependencies from "@/components/disposition/elements/Dependencies.vue";
import DialogApplicationForm from "./elements/DialogApplicationForm.vue";
import DialogEditCandidate from "@/components/disposition/elements/DialogEditCandidate.vue";
import DialogInterviewCompleted from "@/components/disposition/elements/DialogInterviewCompleted.vue";
import DialogService from "@/services/dialog.service";
import JobAdLeadsCandidate from "@/components/disposition/elements/JobAdLeadsCandidate.vue";
import MailClient from "./elements/MailClient.vue";
import moment from "moment";
import PhoneClient from "./elements/PhoneClient.vue";
import DialogManualAnzeigendatenSearch from "./elements/DialogManualAnzeigendatenSearch.vue";
import SendCandidateToWp from "./elements/SendCandidateToWp.vue";
import Timeline from "./elements/Timeline.vue";
import ToastService from "@/services/toast.service";
import WhatsAppClient from "./elements/WhatsAppClient.vue";
import zvooveRecruitLinkMenu from "./elements/ExternalSoftwareLinksMenu.vue";
import { SoftwareIntegration } from "@/models/company-config.model";
import { User } from "@/models/user.model";
import { CommunicationType } from "@/enums/communication-types.enum";
import { CandidateList } from "@/models/candidate-list.model";
import { LinkingService } from "@/services/api/linking.service";
import { StatusResponse } from "@/enums/dialog-action.enum";
import { MessageContent } from "@/enums/empty-message.enum";
import { ExternalSoftware } from "@/enums/external/external-software.enum";
import {
  AllLinkingsStatusObject,
  LinkingDescription,
  LinkingEvent,
} from "@/models/linking.model";

export default defineComponent({
  name: "CandidatItem",
  emits: ["updatePostcode", "loadCandidates", "toggleItemsMinimized"],
  components: {
    Checklist,
    Dependencies,
    DialogApplicationForm,
    DialogEditCandidate,
    DialogInterviewCompleted,
    JobAdLeadsCandidate,
    MailClient,
    PhoneClient,
    DialogManualAnzeigendatenSearch,
    SendCandidateToWp,
    Timeline,
    WhatsAppClient,
    zvooveRecruitLinkMenu,
  },
  props: {
    candidate: {
      type: Object as PropType<CandidateList>,
      required: true,
    },
    interComponentMessage: {
      type: Object as PropType<any>,
      required: true,
    },
    isActive: {
      type: Boolean,
      required: true,
    },
    isFullyCollapsed: {
      type: Boolean,
      required: true,
    },
    lastUpdateTimeline: {
      type: String,
      required: true,
    },
    softwareIntegration: {
      type: Object as PropType<SoftwareIntegration>,
      required: true,
    },
    user: {
      type: Object as PropType<User>,
      required: true,
    },
  },
  computed: {
    ...mapGetters({
      hasLinking: "hasLinkingForCandidate",
      mandants: "mandants",
      mailTemplates: "mailTemplates",
      statusOptionsRecruit: "statusOptionsRecruit",
    }),
    isAtsStatusUnknown() {
      return this.candidate.atsStatus === StatusResponse.unknown;
    },
    matchedApplication() {
      if (!this.candidateData || Object.keys(this.candidateData).length === 0) {
        return {
          applicationDate: "",
          appliedAs: "",
          ats: { status: "" },
          mandantUuid: "",
          operationalRadius: null,
          status: "",
        };
      }
      if (
        this.candidateData.applications &&
        this.candidateData.applications.length > 0
      ) {
        const matchedApplication = this.candidateData.applications.find(
          (application) => application.uuid === this.candidate.applicationUuid
        );

        if (matchedApplication) {
          const extractedData = {
            applicationDate: matchedApplication.applicationDate,
            appliedAs: matchedApplication.appliedAs,
            ats: { status: matchedApplication.ats.status },
            mandantUuid: matchedApplication.mandantUuid,
            operationalRadius: matchedApplication.operationalRadius,
            status: matchedApplication.status
              ? matchedApplication.status
              : null,
          };

          return extractedData;
        } else {
          return {
            applicationDate: "",
            appliedAs: "",
            ats: { status: "" },
            mandantUuid: "",
            operationalRadius: null,
            status: "",
          };
        }
      } else {
        return {
          applicationDate: "",
          appliedAs: "",
          mandantUuid: "",
          operationalRadius: null,
          status: "",
        };
      }
    },
    matchedMandant() {
      const matchedMandant = this.mandants.find(
        (mandant: Mandant) => mandant.uuid === this.candidate?.mandants[0]
      );
      return matchedMandant || { name: "", uuid: "" };
    },
    matchedMandantName() {
      return this.matchedMandant.name;
    },
    matchedMandantUuid() {
      return this.matchedMandant.uuid;
    },
    zvooveRecruitLink() {
      if (
        this.softwareIntegration.zvooveRecruit &&
        this.candidate?.candidateUuid
      ) {
        return `${this.softwareIntegration.zvooveRecruitLink}/bewerber/${this.candidate.candidateUuid}/dashboard`;
      }
      return "#";
    },
    salutationText() {
      if (
        this.candidateData &&
        this.candidateData.salutationCatalogId !== undefined
      ) {
        const salutation = getSalutationText(
          this.candidateData.salutationCatalogId
        );
        return `Guten Tag ${salutation} ${this.candidateData.firstName} ${this.candidateData.lastName},`;
      }
      return "";
    },
    signatureMail() {
      const user = this.user;
      return `freundliche Grüße<br>${Salutation[user.salutation]} ${
        user.forename
      } ${user.lastname}<br>${this.$store.state.company.name}`;
    },
    signatureWhatsApp() {
      const user = this.user;
      return `Viele Grüße\n${Salutation[user.salutation]} ${user.forename} ${
        user.lastname
      }\n${this.$store.state.company.name}`;
    },
  },
  data() {
    return {
      anzeigedatenService: AnzeigedatenService.getInstance(),
      atsRecruitService: new AtsRecruitService(),
      candidateAge: 0,
      candidateData: {} as Candidate | undefined,
      candidateService: new CandidateService(),
      careerLevel: [] as Array<keyof typeof IaCareerLevel>,
      contextMenuPosition: { x: 0, y: 0 },
      ExternalSoftware: ExternalSoftware,
      filteredEmail: [] as any,
      filteredPhoneNumbers: [] as any,
      interviewResultEdit: "",
      isDependenciesActive: false,
      isDialogInterviewCompletedActive: false,
      isDialogManualAnzeigendatenSearchActive: false,
      isDialogEditCandidateActive: false,
      isAtsStatusChangedFromUnknown: false,
      isExpanded: false,
      isFullyExpanded: false,
      isHided: false,
      licences: "",
      linkingDescription: {} as LinkingDescription,
      linkingStatuses: [] as LinkingEvent[],
      message: {
        subject: "Betreff",
        body: MessageContent.empty as string,
      },
      needToContact: false,
      newTag: "",
      prioNeedToContact: false,
      selectedStatus: this.candidate?.status,
      showContextMenu: false,
      skillsForJobAdLeads: [] as string[],
      statusColor: "",
      zipCodeService: new ZipCodeService(),
    };
  },
  watch: {
    lastUpdateTimeline() {
      if (this.$refs.dependenciesComponent) {
        (this.$refs.dependenciesComponent as any).loadLinkingsFromStore();
        (this.$refs.dependenciesComponent as any).checkForReminders();
      }
    },
    interComponentMessage: {
      handler(newVal) {
        switch (newVal.message) {
          case InterComponentMessage.matchMeFromGlobalSearch:
            this.filterCandidate(newVal.payload.searchTerm);
            break;
          default:
            break;
        }
      },
      deep: true,
    },
  },
  mounted() {
    this.initializeComponent();
    document.addEventListener("click", this.handleClickOutside);
  },
  beforeUnmount() {
    document.removeEventListener("click", this.handleClickOutside);
  },
  methods: {
    ...mapMutations({
      setICM: MutationType.setICM,
    }),
    actionsFromStatusChange(statusValue: string) {
      const candidateStatus = this.$store.getters.getStatusByValue(
        statusValue
      ) as string[];
      candidateStatus.forEach((status) => {
        switch (status) {
          case LinkingStatus.rejectedCandidate:
            this.deleteCandidateFromWpPlugin();
            break;
          case LinkingStatus.rejected:
            this.deleteCandidateFromWpPlugin();
            break;
          case LinkingStatus.hired:
            this.deleteCandidateFromWpPlugin();
            break;
          default:
            break;
        }
      });
    },
    addFollowUpOnDependencies() {
      if (this.candidateData && this.$refs.dependenciesComponent) {
        (this.$refs.dependenciesComponent as any).linkFollowUp(
          this.candidateData._id,
          null
        );
      }
    },
    addTag() {
      const trimmedTag = this.newTag.trim();
      if (this.candidateData && trimmedTag !== "") {
        if (!this.candidateData.tags) {
          this.candidateData.tags = [];
        }

        this.candidateData.tags.push(trimmedTag);
        this.newTag = "";

        this.updateCandidateData().then(() => {
          ToastService.show("Tag hinzugefügt!");
        });
      }
    },
    async askCustomerItemsForMatch() {
      if (!this.candidateData || !this.candidateData.tags) {
        return;
      }

      const customersInRange = await this.getCustomerPostcodesInMobilityRange();
      this.setICM({
        message: InterComponentMessage.matchMeFromCandidate,
        payload: {
          postcodes: customersInRange,
          tags: this.candidateData.tags ?? "",
        },
      });
      this.$store.state.isActiveCandidateMatching = true;
      this.closeContextMenu();
    },
    async autoCheckForJobAdLeads() {
      if (!this.candidateData || Object.keys(this.candidateData).length === 0) {
        return;
      }
      if (
        this.candidateData.tags &&
        this.candidateData.tags.length > 0 &&
        this.candidateData.careerSteps &&
        this.candidateData.careerSteps.length > 0 &&
        this.softwareIntegration.indexAnzeigendaten
      ) {
        let isFetchAds = false;
        if (this.candidateData.jobAdLeads?.length === 0) {
          isFetchAds = true;
        } else {
          const firstAdLead: IndexJobAd | undefined =
            this.candidateData.jobAdLeads?.[0];
          if (firstAdLead && "AD-RETRIEVAL-DATE" in firstAdLead) {
            const adRetrievalDate = moment(
              firstAdLead["AD-RETRIEVAL-DATE"],
              "DD.MM.YYYY"
            );
            const currentDate = moment();
            const daysSinceLastRetrieval = currentDate.diff(
              adRetrievalDate,
              "days"
            );
            isFetchAds =
              daysSinceLastRetrieval >
              this.softwareIntegration.indexJobAdRefreshInterval;
          } else {
            isFetchAds = true;
          }
        }

        if (isFetchAds) {
          try {
            const needsAiFetching = await this.fetchJobAdLeads();

            if (needsAiFetching) {
              this.fetchJobAdLeads(true).then(() =>
                ToastService.show(
                  `Job Leads von ${this.candidateData?.firstName} ${this.candidateData?.lastName}abgerufen!`
                )
              );
            }
          } catch (error) {
            console.error("Fehler beim Abrufen neuer Job-Leads:", error);
          }
        }
      }
    },
    calculateAge(birthdayISO: string) {
      const birthday = moment(birthdayISO);
      const today = moment();
      const age = today.diff(birthday, "years");

      this.candidateAge = age;
    },
    async changeAtsStatus(targetStatus: string): Promise<string | null> {
      const candidateLink = this.zvooveRecruitLink;
      if (!this.softwareIntegration.zorst || !this.$store.state.zorstAlive) {
        this.changeAtsStatusManualWithLink();
        return null;
      }
      return ZorstService.getInstance()
        .ZorstChangeRecruitCandidateStatus(candidateLink, targetStatus)
        .then((result) => {
          return result;
        })
        .catch((error) => {
          ToastService.showError(
            "Fehler bei der ATS Recruit Statusänderung:" + error
          );
          this.changeAtsStatusManualWithLink();
          return null;
        });
    },
    async changeAtsStatusManualWithLink() {
      const candidateLink = this.zvooveRecruitLink;
      const confirmed = await DialogService.confirm(
        `Statuswechsel für #${this.candidate.applicationId}: ${this.candidateData?.firstName} ${this.candidateData?.lastName} manuell durchführen?`,
        "Nein",
        "Kandidat in ATS öffnen"
      );
      if (confirmed) {
        window.open(candidateLink, "_blank", "width=950,height=900"); //Width 950 supress zvoove Recruits Side Menu and TODO: use responsive dimensions from service
      }
    },
    changeMatchedApplicationStatus(
      newStatus: string,
      atsStatusUuid?: string,
      atsNewStatus?: string,
      atsInternalStatusSystemName?: string
    ) {
      const application = this.candidateData?.applications.find(
        (application) => application.uuid === this.candidate.applicationUuid
      );
      if (application) {
        application.status = newStatus;
        if (atsNewStatus) application.ats.status = atsNewStatus;
        if (atsStatusUuid) application.ats.statusUuid = atsStatusUuid;
        if (atsInternalStatusSystemName)
          application.ats.internalStatusSystemName =
            atsInternalStatusSystemName;
        this.updateCandidateData();
      }
    },
    changeStatus(targetStatus: string) {
      if (this.candidate.status === targetStatus) {
        ToastService.show("Status ist bereits: " + targetStatus);
        return;
      }
      if (
        this.softwareIntegration.zvooveRecruit &&
        this.softwareIntegration.atsDeterminesStatus
      ) {
        this.changeAtsStatus(targetStatus).then((result) => {
          if (result) {
            this.changeMatchedApplicationStatus(targetStatus);
            this.actionsFromStatusChange(targetStatus);
          } else {
            ToastService.showError(
              "Statuswechsel in ATS Recruit hat nicht geklappt..."
            );
          }
        });
        return;
      } else if (this.softwareIntegration.zvooveRecruit) {
        this.changeAtsStatus(targetStatus);
      }
      this.changeMatchedApplicationStatus(targetStatus);
      this.actionsFromStatusChange(targetStatus);
    },
    async checkAtsStatus() {
      const atsStatus = this.matchedApplication.ats?.status;
      const status = this.candidate.status;
      let externalAtsStatus = {
        statusUuid: "",
        status: "",
        internalStatusSystemName: "",
      };

      // get new ats status if atsStatus is unknown
      if (this.softwareIntegration.zvooveRecruit) {
        if (this.softwareIntegration.zvooveRecruit && atsStatus === "unknown") {
          ToastService.show(
            `ATS Recruit Status von ${this.candidate.firstName} ${this.candidate.lastName} passt nicht zum gespeicherten Status - rufe neue Daten ab!`
          );
          const atsCandidate = await this.atsRecruitService.getById(
            this.candidate?.candidateUuid,
            this.candidate?.applicationId,
            this.candidate?.applicationUuid
          );
          const matchedApplication = atsCandidate.applications.find(
            (application) => application.uuid === this.candidate.applicationUuid
          );

          if (matchedApplication) {
            externalAtsStatus = matchedApplication.ats;
          }
        }
      }
      if (
        this.softwareIntegration.atsDeterminesStatus &&
        externalAtsStatus.status
      ) {
        ToastService.showReminder(
          `Ändere Status '${this.candidate.status}' von ${this.candidate.firstName} ${this.candidate.lastName} auf den ATS Recruit Status '${externalAtsStatus.status}'`
        );
        this.isAtsStatusChangedFromUnknown = true;
        this.changeMatchedApplicationStatus(
          externalAtsStatus.status,
          externalAtsStatus.statusUuid,
          externalAtsStatus.status, //to chance .ats.status too
          externalAtsStatus.internalStatusSystemName
        );
        return;
      } else if (externalAtsStatus.status) {
        this.changeMatchedApplicationStatus(
          status,
          externalAtsStatus.statusUuid,
          externalAtsStatus.status,
          externalAtsStatus.internalStatusSystemName
        );
        return;
      }
      if (
        this.softwareIntegration.atsDeterminesStatus &&
        status !== atsStatus &&
        atsStatus
      ) {
        this.changeMatchedApplicationStatus(atsStatus);
      } else if (status !== atsStatus && atsStatus) {
        const confirmed = await DialogService.confirm(
          `Der Kandidat #${this.candidate.applicationId}: ${this.candidateData?.firstName} ${this.candidateData?.lastName} hat im ATS System der status ${atsStatus} statt ${status}.`,
          "Beibehalten",
          "Status in ATS ändern!"
        );
        if (confirmed) this.changeAtsStatus(status);
      }
    },
    async clearMatchingOject() {
      this.setICM({
        message: InterComponentMessage.matchMeFromCandidate,
        payload: {
          postcodes: "",
          tags: "",
        },
      });
      this.$store.state.isActiveCandidateMatching = false;
      this.closeContextMenu();
    },
    collapseItem() {
      this.isFullyExpanded = false;
      this.isExpanded = false;
    },
    createNewMandantAppointmentOnDependencies() {
      this.isDependenciesActive = true;
      const mandantName = this.$store.getters.getMandantNameByUuid(
        this.matchedApplication.mandantUuid
      );
      this.linkingDescription = {
        firstName: this.candidateData?.firstName,
        lastName: this.candidateData?.lastName,
        postCode: this.candidateData?.addressPostalCode,
        city: this.candidateData?.addressCity,
        customerName: mandantName,
        uuid: this.candidateData?.uuid,
      } as LinkingDescription;
      this.$nextTick(() => {
        if (this.$refs.dependenciesComponent) {
          (this.$refs.dependenciesComponent as any).createNewAppointment();
        }
      });
    },
    closeContextMenu() {
      this.showContextMenu = false;
    },
    deactivateSubcomponents() {
      this.isDialogEditCandidateActive = false;
      this.isDialogInterviewCompletedActive = false;
      this.isDialogManualAnzeigendatenSearchActive = false;
    },
    async deleteCandidate() {
      let id = this.candidate.parentObjectid as string;
      if (this.candidateData && this.candidateData._id)
        id = this.candidateData._id;
      const confirmed = await DialogService.confirm(
        `Den Kandidat #${this.candidate.applicationId}: ${this.candidateData?.firstName} ${this.candidateData?.lastName} wirklich löschen?`,
        "Abbrechen",
        "Löschen"
      );
      if (confirmed && this.candidateData && this.candidateData._id) {
        const linkingService = new LinkingService();
        const responseLinking = await linkingService.deleteLinkingsByTypeAndId(
          "candidate",
          id
        );
        if (responseLinking.error) {
          ToastService.showError(
            "Fehler beim Löschen des Kunden: Verlinkungen konnten nicht entfernt werden!" +
              responseLinking.error
          );
          return;
        }
        this.candidateService
          .removeCandidate(this.candidateData._id)
          .then(() => {
            this.$emit("loadCandidates");
          });
      }
    },
    deleteCandidateFromWpPlugin() {
      if (!this.softwareIntegration.wordPressPlugin) {
        return;
      }
      if (this.$refs.sendCandidateToWpComponent) {
        (this.$refs.sendCandidateToWpComponent as any).deleteCandidateFromWP(
          this.candidate.applicationId
        );
      }
      if (this.candidateData && this.candidateData.isPublishedOnWebsite) {
        this.candidateData.isPublishedOnWebsite = false;
      }
      this.updateCandidateData;
    },
    emitToggleItemsMinimized() {
      this.$emit("toggleItemsMinimized");
      this.closeContextMenu();
    },
    async fetchJobAdLeads(ai?: boolean) {
      if (!this.candidateData || !this.softwareIntegration.indexAnzeigendaten) {
        return;
      }
      let mobilityRadius = this.candidate.mobilityRadius ?? 0;
      if (this.candidateData && this.candidateData.mobilityRadius)
        mobilityRadius = this.candidateData.mobilityRadius;
      const query = {
        POSTAL_CODE: this.candidateData.addressPostalCode,
      } as JodAdLeadsQuery;
      if (mobilityRadius > 0) {
        query.SURROUNDING_REGION = mobilityRadius.toString();
      }

      if (this.careerLevel && this.careerLevel.length) {
        query.CAREER_LEVEL = this.careerLevel.join(",");
      }

      if (this.candidateData && ai) {
        SpinnerService.showSpinner();
        const anonymizedResume = this.prepareAnonymizedResume(
          this.candidateData
        );
        const response = await generateJobAdLeadsQuery(anonymizedResume);
        query.JOB_TITLE = response.jobTitle;
        query.JOB_POSTING = response.searchstrings;
      } else {
        if (this.skillsForJobAdLeads.length > 5) {
          query.JOB_POSTING = this.skillsForJobAdLeads.join(" OR ");
        }

        if (
          this.candidateData &&
          this.candidateData.tags &&
          this.candidateData.tags.length
        ) {
          query.JOB_TITLE = this.candidateData.tags.join(" OR ");
        }
      }

      try {
        const leads = await this.anzeigedatenService.getLeads(query);
        if (this.candidateData && leads) {
          this.candidateData.jobAdLeads = leads;
        } else if (this.candidateData) {
          const retrievalDate = moment().format("DD.MM.YYYY");
          this.candidateData.jobAdLeads = [
            {
              "AD-ID": "not found",
              "JOB-ID": "not found",
              "AD-RETRIEVAL-DATE": retrievalDate,
              "COMPANY-CITY": "",
              "COMPANY-POSTAL-CODE": "",
              POSITION: "keine Ergebnisse",
            },
          ];
        }
        this.updateCandidateData();
        SpinnerService.removeSpinner();
        if (
          this.candidateData &&
          this.candidateData.jobAdLeads &&
          this.candidateData.jobAdLeads[0]["AD-ID"] === "not found" &&
          !ai
        ) {
          return true;
        } else {
          return false;
        }
      } catch (error) {
        console.error("Error fetching job leads:", error);
        SpinnerService.removeSpinner();
      }
    },
    async fetchJobAdLeadsManual(query: DataForJobAdLeadsQuery) {
      SpinnerService.showSpinner();
      const anzeigendatenQuery = {
        JOB_TITLE: query.tags.join(" OR "),
        POSTAL_CODE: query.postcode,
        SURROUNDING_REGION: query.radius,
        JOB_POSTING: query.skills.join(" OR "),
        CAREER_LEVEL: query.careerLevel ? query.careerLevel.join(",") : "",
      } as unknown as JodAdLeadsQuery;
      const differentPeriod = query.period;

      try {
        const response = await this.anzeigedatenService.getLeads(
          anzeigendatenQuery,
          differentPeriod
        );
        return response;
      } catch (error) {
        ToastService.showError("Fehler beim abrufen der Anzeigendaten");
        console.error("Error fetching Index anzeigendaten:", error);
      } finally {
        SpinnerService.removeSpinner();
      }
    },
    filterCandidate(searchTerm: string) {
      if (searchTerm === "") {
        this.showCandidate();
        return;
      }
      const terms = searchTerm.toLowerCase().split(" ");
      const candidate = this.candidateData;
      if (candidate) {
        const matches = terms.every(
          (term) =>
            this.searchCandidateObject(candidate, term) ||
            this.searchCandidateAllFields(this.candidate, term)
        );

        if (!matches) {
          this.hideCandidate();
        }
      }
    },
    //helping methods
    searchCandidateAllFields(candidate: CandidateList, term: string) {
      return Object.values(candidate).some(
        (value) =>
          typeof value === "string" && value.toLowerCase().includes(term)
      );
    },
    searchCandidateObject(candidate: Candidate, term: string) {
      return (
        this.searchField(candidate.addressHouseNumber, term) ||
        (candidate.additionalAddresses &&
          candidate.additionalAddresses.some(
            (address) =>
              this.searchField(address.houseNumber, term) ||
              this.searchField(address.city, term) ||
              this.searchField(address.postalCode, term) ||
              this.searchField(address.street, term)
          )) ||
        this.searchField(candidate.addressCity, term) ||
        this.searchField(candidate.addressPostalCode, term) ||
        this.searchField(candidate.addressStreet, term) ||
        (candidate.communicationMeans &&
          candidate.communicationMeans.some(
            (communicationMean) =>
              this.searchField(communicationMean.description, term) ||
              this.searchField(communicationMean.value, term)
          )) ||
        this.searchField(candidate.lastName, term) ||
        (candidate.skills &&
          candidate.skills.some((skill) =>
            this.searchField(skill.description, term)
          )) ||
        this.searchField(candidate.firstName, term) ||
        (candidate.careerSteps &&
          candidate.careerSteps.some(
            (careerStep) =>
              this.searchField(careerStep.at, term) ||
              this.searchField(careerStep.details, term) ||
              this.searchField(careerStep.title, term)
          )) ||
        (candidate.tags &&
          candidate.tags.some((tag) => this.searchField(tag, term)))
      );
    },
    searchField(field: any, term: string) {
      return field && field.toLowerCase().includes(term);
    },
    findStatusOptionByStatus(
      statusToFind: string
    ): RecruitStatusOption | undefined {
      return this.statusOptionsRecruit.find((option: RecruitStatusOption) =>
        Array.isArray(option.status)
          ? option.status.includes(statusToFind)
          : option.status === statusToFind
      );
    },
    async generateAndSetCandidateTags() {
      if (this.candidateData) {
        const anonymizedResume = this.prepareAnonymizedResume(
          this.candidateData
        );
        try {
          const tags = await generateCandidateTags(anonymizedResume);
          this.candidateData.tags = tags;
          await this.updateCandidateData();
        } catch (error) {
          console.error("Error generating candidate tags:", error);
        }
      }
    },
    getCareerLevelDescription() {
      if (!this.candidateData || Object.keys(this.candidateData).length === 0) {
        return;
      }
      if (
        !this.candidateData.careerLevel ||
        this.candidateData.careerLevel.length === 0
      ) {
        return null;
      }

      const firstLevelIndex = this.candidateData.careerLevel[0];
      const lastLevelIndex =
        this.candidateData.careerLevel[
          this.candidateData.careerLevel.length - 1
        ];

      const firstLevel = CareerLevel[firstLevelIndex];
      const lastLevel = CareerLevel[lastLevelIndex];

      if (firstLevelIndex === lastLevelIndex) {
        return `${firstLevel}`;
      } else {
        return `Karrierestufen von ${firstLevel} bis ${lastLevel}`;
      }
    },
    async getCustomerPostcodesInMobilityRange() {
      if (!this.candidateData || !this.candidateData.addressPostalCode) {
        console.error("AdressePlz ist nicht definiert.");
        return [];
      }

      const baseZipcode = this.candidateData.addressPostalCode;
      const targetZipcodes = this.$store.state.postcodesCustomers;

      try {
        const distances: ZipCodeDistance[] =
          await this.zipCodeService.getDistances(baseZipcode, targetZipcodes);
        let mobilityRadius = this.candidate.mobilityRadius ?? 0;
        if (this.candidateData && this.candidateData.mobilityRadius)
          mobilityRadius = this.candidateData.mobilityRadius;
        const filteredZipcodes = distances
          .filter(
            (distance: ZipCodeDistance) => distance.distance <= mobilityRadius
          )
          .map((distance: ZipCodeDistance) => distance.targetZipcode);

        const uniqueZipcodes = [...new Set(filteredZipcodes)];
        return uniqueZipcodes;
      } catch (error) {
        console.error("Fehler beim Abrufen der Distanzen: ", error);
        return [];
      }
    },
    handleClickOutside(event: MouseEvent) {
      const ContextMenu = this.$refs.ContextMenu as HTMLElement;
      if (
        ContextMenu &&
        !ContextMenu.contains(event.target as Node) &&
        this.showContextMenu
      ) {
        this.closeContextMenu();
      }
    },
    async handleDragStart(event: DragEvent) {
      if (!this.candidateData?._id) {
        event.preventDefault();
        await this.loadCandidate();
        ToastService.showReminder(
          `Vollständige Kandidatendaten wurden jetzt geladen! Die Karte von ${this.candidate.firstName} ${this.candidate.lastName} kann jetzt in die Kunden und Mitarbeiterspalte gezogen werden!`
        );
        return;
      }
      if (event.dataTransfer && this.candidateData) {
        event.dataTransfer.setData(
          "application/candidate_id",
          this.candidateData._id as string
        );
        let resultInterview =
          "Keine Zusammenfassung vom Vorstellungsgespräch vorhanden";
        if (
          this.candidateData.notes &&
          this.candidateData.notes.interviewResult
        ) {
          resultInterview = this.candidateData.notes.interviewResult;
        }
        const salutation = Salutation[this.candidateData.salutationCatalogId];

        const draggingData = {
          age: this.candidateAge.toString(),
          anonymizedResume: this.prepareAnonymizedResume(this.candidateData),
          appliedAs: this.matchedApplication.appliedAs,
          candidateData: this.candidateData as Candidate,
          licences: this.licences,
          mobility: `${this.candidateData.mobility}<br>${this.candidateData.mobilityRadius}km`,
          notes: resultInterview,
          profileNumber: this.candidate.applicationId,
          salutation: salutation,
          shiftReadiness: this.candidateData.shiftPreference,
          candidateMandantUuid: this.matchedApplication.mandantUuid,
        };

        this.$store.commit("SET_DRAGGING_ITEM", {
          type: "candidate",
          data: draggingData,
        });
      }
    },
    handleCandidateStatusUpdate(statusObject: AllLinkingsStatusObject) {
      this.linkingStatuses = statusObject.lastEvents;
    },
    handlePublishedOnWebseiteChange() {
      if (!this.softwareIntegration.wordPressPlugin) {
        return;
      }
      if (
        this.candidateData &&
        this.candidateData.salutationCatalogId !== undefined
      ) {
        const gender = Gender[this.candidateData.salutationCatalogId] || "";
        let qualification = "";
        if (
          this.candidateData.careerLevel &&
          Object.keys(this.candidateData.careerLevel).length > 0
        ) {
          qualification = this.getCareerLevelDescription() ?? "";
        }
        if (this.candidateData.isPublishedOnWebsite) {
          let mobilityRadius = this.candidate.mobilityRadius ?? 0;
          if (this.candidateData && this.candidateData.mobilityRadius)
            mobilityRadius = this.candidateData.mobilityRadius;
          const wpCandidateData = {
            title: this.matchedApplication.appliedAs,
            profileId: this.candidate.applicationId,
            gender: gender,
            age: this.candidateAge.toString(),
            residence: this.candidateData.addressCity,
            mobility: `${this.candidateData.mobility}: ${mobilityRadius} km`,
            shift: this.candidateData.shiftPreference,
            qualification: qualification,
            licenses: this.licences.replace(/<br>/g, ", "),
            experience: this.candidateData.tags.join(", "),
            anonymizedResume: this.prepareAnonymizedResume(this.candidateData),
          };

          if (this.$refs.sendCandidateToWpComponent) {
            (this.$refs.sendCandidateToWpComponent as any).openModal(
              wpCandidateData
            );
          }
          this.candidateData.isPublishedOnWebsite = true;
          this.updateCandidateData();
        } else if (this.candidateData.isPublishedOnWebsite === false) {
          this.deleteCandidateFromWpPlugin();
        }
      }
    },
    async handleSaveResumeInterview(resumeText: string) {
      if (!this.candidateData) return;
      await this.saveInterviewResult(resumeText);
      await this.loadCandidate();
      this.candidateData.isPublishedOnWebsite = true;
      await this.updateCandidateData();
      this.handlePublishedOnWebseiteChange();
    },
    hideCandidate() {
      this.isHided = true;
    },
    async initializeComponent() {
      this.setDefaultStatus();
      if (this.$refs.dependenciesComponent) {
        (this.$refs.dependenciesComponent as any).loadLinkingsFromStore();
        (this.$refs.dependenciesComponent as any).checkForReminders();
      }
      this.autoCheckForJobAdLeads();
      if (
        this.candidateData &&
        this.candidateData.notes &&
        this.candidateData.notes.interviewResult
      ) {
        this.interviewResultEdit = this.candidateData.notes.interviewResult;
      } else {
        this.interviewResultEdit = "";
      }
      if (this.candidate.birthDate) this.calculateAge(this.candidate.birthDate);
    },
    isCandidateActiveForPlacement(): boolean {
      if (!this.candidateData) {
        return false;
      }

      if (this.candidateData.isPublishedOnWebsite === undefined) {
        this.candidateData.isPublishedOnWebsite = false;
      }

      if (
        this.candidate.status === this.statusOptionsRecruit[0].value ||
        this.candidate.status === this.statusOptionsRecruit[1].value
      ) {
        return false;
      }

      return true;
    },
    async getCandidateDataFromATS() {
      if (this.softwareIntegration.zvooveRecruit) {
        try {
          const atsCandidate = await this.atsRecruitService.getById(
            this.candidate?.candidateUuid,
            this.candidate?.applicationId,
            this.candidate?.applicationUuid
          );

          const response = await this.candidateService.postCandidate(
            atsCandidate
          );

          return response;
        } catch (error) {
          console.error(
            `failed fetching candidate #${this.candidate?.applicationId} from ATS`,
            error
          );
          throw error;
        }
      } else {
        ToastService.showError("Zvoove Recruit ist nicht aktiviert!");
      }
    },
    async getCandidateData() {
      try {
        const response = await this.candidateService.getCandidateByUuid(
          this.candidate.candidateUuid
        );
        return response as Candidate;
      } catch (error) {
        console.error(
          `failed loading candidate #${this.candidate?.applicationId}`,
          error
        );
        throw error;
      }
    },
    isStatusInterview() {
      const statusArray = this.$store.getters.getStatusByValue(
        this.selectedStatus
      );
      return statusArray.includes(LinkingStatus.interview);
    },
    async loadCandidate(sourceCandidate?: string) {
      try {
        SpinnerService.showSpinner();
        let data = {} as Candidate | undefined;
        if (sourceCandidate === ExternalSoftware.atsRecruit) {
          data = await this.getCandidateDataFromATS();
          ToastService.showSuccess("Recruit Daten aktualisiert!");
        } else {
          data = await this.getCandidateData();
          if (!data?._id) {
            data = await this.getCandidateDataFromATS();
          }
        }
        if (data) this.prepareFilteredPhoneNumbers(data);
        if (data) this.prepareFilteredEmail(data);
        if (data) this.prepareLicensesAndSkillForJobLeads(data);
        if (data && data.birthDate) {
          this.calculateAge(data.birthDate);
        }
        if (data) this.$emit("updatePostcode", data.addressPostalCode);
        this.candidateData = data;
        if (data && data.notes?.interviewResult)
          this.interviewResultEdit = data.notes.interviewResult;
      } catch (error: any) {
        console.error("Fehler beim Abrufen der Bewerberdaten:", error);
      } finally {
        SpinnerService.removeSpinner();
      }
    },
    openAnzeigendatenManualSearch() {
      this.isDialogManualAnzeigendatenSearchActive = true;
      this.$nextTick().then(() => {
        if (this.$refs.dialogManualAnzeigendatenSearchComponent) {
          (
            this.$refs.dialogManualAnzeigendatenSearchComponent as any
          ).openModal();
        }
      });
    },
    openApplicationFormDispatcherMode() {
      if (this.$refs.dialogDialogApplicationFormComponent) {
        (this.$refs.dialogDialogApplicationFormComponent as any).openModal(
          true
        );
      }
    },
    openContextMenu(event: MouseEvent) {
      if (!this.candidateData?._id) this.loadCandidate();
      event.preventDefault();
      this.showContextMenu = true;
      this.contextMenuPosition = { x: event.clientX, y: event.clientY };
    },
    openEditCandidateModal() {
      this.isDialogEditCandidateActive = true;
      this.$nextTick().then(() => {
        if (this.$refs.dialogEditCandidateComponent) {
          (this.$refs.dialogEditCandidateComponent as any).openModal();
        }
      });
      this.closeContextMenu();
    },
    openPhoneClient() {
      this.isExpanded = true;
      this.updateComponent().then(() => {
        if (this.$refs.phoneClientComponent) {
          (this.$refs.phoneClientComponent as any).handleOutgoingCall();
        }
      });
    },
    phoneClientIsDialing() {
      if (this.$refs.dependenciesComponent) {
        (this.$refs.dependenciesComponent as any).waitForPhoneClientDialing();
      }
    },
    prepareAnonymizedResume(data: Candidate) {
      const salutation = Salutation[data.salutationCatalogId];

      const anonymizedResume = {
        tags: data.tags || [],
        addressCity: data.addressCity,
        addressPostalCode: data.addressPostalCode,
        addressStreet: data.addressStreet,
        maritalStatus: data.maritalStatus ? data.maritalStatus : "",
        birthDate: data.birthDate,
        birthPlace: data.birthPlace,
        nationality: data.nationality ? data.nationality : "",
        salutation: salutation,
        skills: data.skills.map((skill: Skill) => skill.description),
        careerSteps: data.careerSteps.map((careerStep: CareerStep) => ({
          degree: careerStep.degree,
          at: careerStep.at,
          until: careerStep.until,
          details: careerStep.details,
          title: careerStep.title,
          type: careerStep.type ? careerStep.type.description : "",
        })),
      };
      return JSON.stringify(anonymizedResume);
    },
    prepareDataForJobAdLeadsQuery(): DataForJobAdLeadsQuery {
      if (!this.candidateData || !this.candidateData.skills) {
        return {
          tags: [],
          postcode: "",
          radius: 0,
          period: this.softwareIntegration.indexJobLeadsAgeLimitDays ?? 30,
          skills: [],
          careerLevel: [],
        };
      }
      const skills = this.candidateData.skills
        .filter(
          (skill: any) =>
            skill.groupDescription !== "Schichtbereitschaft" &&
            skill.groupDescription !== "Mobilitäten"
        )
        .map((skill: any) => skill.description);

      let mobilityRadius = this.candidate.mobilityRadius ?? 0;
      if (this.candidateData && this.candidateData.mobilityRadius)
        mobilityRadius = this.candidateData.mobilityRadius;

      const dataForJobAdLeadsQuery = {
        tags: this.candidateData.tags ?? [],
        postcode: this.candidateData.addressPostalCode ?? "",
        radius: mobilityRadius,
        period:
          this.$store.state.company.softwareIntegration
            .indexJobLeadsAgeLimitDays ?? 30,
        skills: skills,
        careerLevel: this.candidateData.careerLevel ?? [],
      } as DataForJobAdLeadsQuery;

      return dataForJobAdLeadsQuery;
    },
    prepareFilteredEmail(data: Candidate) {
      this.filteredEmail =
        data?.communicationMeans
          ?.filter(
            (c: CommunicationMeans) => c.type === CommunicationType.Email
          )
          .map((c: CommunicationMeans) => {
            const salutationText = getSalutationText(data.salutationCatalogId);
            return {
              mailaddress: c.value,
              salutation: `Guten Tag ${salutationText} ${data.firstName} ${data.lastName},`,
            };
          }) || [];
    },
    prepareFilteredPhoneNumbers(data: Candidate) {
      this.filteredPhoneNumbers =
        data?.communicationMeans
          ?.filter(
            (c: CommunicationMeans) =>
              c.type === CommunicationType.Phone ||
              c.type === CommunicationType.Mobile ||
              c.type === CommunicationType.WorkMobile ||
              c.type === CommunicationType.WhatsApp
          )
          .map((c: CommunicationMeans) => ({
            label: c.description,
            value: c.value.replace(/ /g, ""), // delete "space" between the numbers
          })) || [];
    },
    prepareLicensesAndSkillForJobLeads(data: Candidate) {
      this.licences = "";
      if (data?.skills) {
        for (let skill of data.skills) {
          if (skill.groupDescription === "Führerscheine") {
            if (this.licences.length > 0) {
              this.licences += "<br>";
            }
            this.licences += skill.description;
          } else {
            this.skillsForJobAdLeads.push(skill.description);
          }
        }
      }
    },
    async removeTag(index: number) {
      if (!this.candidateData) return;
      this.candidateData.tags.splice(index, 1);
      await this.updateCandidateData();
    },
    async saveInterviewResult(resumeText: string) {
      if (!this.candidateData) return;
      if (!this.candidateData.notes) {
        this.candidateData.notes = {
          interviewResult: "",
          information: "",
        };
      }
      this.candidateData.notes.interviewResult = resumeText;
      await this.updateCandidateData();
      ToastService.show("Ergebnis Vorstellungstermin gespeichert!");
    },
    async searchAnzeigendatenAndAdd(query: DataForJobAdLeadsQuery) {
      if (!this.candidateData) return;
      try {
        const response = await this.fetchJobAdLeadsManual(query);
        if (!this.candidateData.jobAdLeads) {
          this.candidateData.jobAdLeads = [];
        }
        const existingAdIds = new Set(
          this.candidateData.jobAdLeads.map((ad: any) => ad["AD-ID"])
        );
        const existingJobIds = new Set(
          this.candidateData.jobAdLeads.map((ad: any) => ad["JOB-ID"])
        );
        const newJobAds = (response || []).filter((ad: any) => {
          return (
            !existingAdIds.has(ad["AD-ID"]) && !existingJobIds.has(ad["JOB-ID"])
          );
        });

        this.candidateData.jobAdLeads = [
          ...(this.candidateData.jobAdLeads || []),
          ...newJobAds,
        ];

        await this.updateCandidateData();

        ToastService.showSuccess("neue JOB Leads hinzugefügt!");
      } catch (error) {
        console.error("Error adding job ads:", error);
      }
    },
    async searchAnzeigendatenAndReplace(query: DataForJobAdLeadsQuery) {
      if (this.candidateData) {
        const response = await this.fetchJobAdLeadsManual(query);
        this.candidateData.jobAdLeads = response;
        await this.updateCandidateData();
      }
    },
    sendCandidateToWpAborted() {
      if (this.candidateData) {
        this.candidateData.isPublishedOnWebsite = false;
      }
    },
    setAppointmentOnDependencies() {
      this.isDependenciesActive = true;
      this.$nextTick(() => {
        if (this.$refs.dependenciesComponent) {
          (this.$refs.dependenciesComponent as any).openAppointmentModal();
        }
      });
    },
    setCandidateToMandantFromDependencies(data: {
      status: string;
      dates?: string[];
    }): void {
      const statusMatch = this.findStatusOptionByStatus(data.status);
      let statusDates = "";
      if (data.dates && data.dates.length > 0) {
        statusDates = data.dates.join(", ");
      }
      this.message.body = `${status} ${statusDates}`;

      if (statusMatch) {
        this.changeStatus(statusMatch.value);
        const formattedAppointments = data.dates
          ? data.dates
              .map((date) => moment(date).format("DD.MM.YYYY [um] HH:mm [Uhr]"))
              .join(", ")
          : "";
        this.setMessageTemplate(data.status, formattedAppointments);
        if (statusMatch.status[0] === LinkingStatus.interviewCompleted) {
          this.loadCandidate();
          (this.isDialogInterviewCompletedActive = true),
            this.$nextTick().then(() => {
              // We do that explicit NOT in this.actionsFromStatusChange() it has to start ONLY in this case
              if (this.$refs.dialogInterviewCompletedComponent) {
                (
                  this.$refs.dialogInterviewCompletedComponent as any
                ).openSkillsModal();
              }
            });
        }
      } else {
        ToastService.showError("Kein entsprechender Status gefunden.");
      }
    },
    setDefaultStatus() {
      const defaultStatus = LinkingStatus.noneProfile;
      this.updateStatusAndColor(defaultStatus);
    },
    setMessageTemplate(candidateStatus: string, appointments?: string) {
      let subjectTemplate = "";
      let messageTemplate = "";

      const templates = this.mailTemplates;
      const matchingTemplate = templates.find(
        (template: {
          candidateStatus: string;
          subject: string;
          text1: string;
          text2: string;
        }) => template.candidateStatus === candidateStatus
      );

      if (matchingTemplate) {
        const mandant = this.$store.getters.getMandantByUuid(
          this.matchedMandantUuid
        );
        const mandantAddress = mandant
          ? mandant.contact.replace(/<br>/g, "\n")
          : "Adresse nicht verfügbar";
        if (
          matchingTemplate.candidateStatus === LinkingStatus.interview ||
          matchingTemplate.candidateStatus === LinkingStatus.contractDate
        ) {
          subjectTemplate = `${matchingTemplate.subject} am ${appointments}`;
          messageTemplate = `\n${matchingTemplate.text1}\n\nam ${appointments}\n\n${mandant.name}\n${mandantAddress}\n\n${matchingTemplate.text2}`;
        } else {
          subjectTemplate = matchingTemplate.subject;
          messageTemplate = `\n${matchingTemplate.text1}\n${matchingTemplate.text2}`;
        }
      } else {
        console.error(
          "Keine passende E-Mail Vorlage gefunden für den Status: ",
          candidateStatus
        );
      }
      this.message = {
        subject: subjectTemplate,
        body: messageTemplate,
      };

      if (this.$refs.mailClientComponent) {
        (this.$refs.mailClientComponent as any).openModal();
      }

      ToastService.show(
        `Eine Nachricht ist im Messenger für Versand vorbereitet: Betreff: ${subjectTemplate}`
      );
    },
    showCandidate() {
      this.isHided = false;
    },
    async toggleExpansion() {
      this.isExpanded = !this.isExpanded;
      if (!this.isExpanded) {
        this.isFullyExpanded = false;
      }
      if (this.isExpanded) {
        this.updateComponent();
      } else {
        this.deactivateSubcomponents();
      }
    },
    async toggleFullExpansion() {
      if (this.isExpanded) {
        this.isFullyExpanded = !this.isFullyExpanded;
      }
    },
    updateCareerLevels(data: IaCareerLevel[]) {
      if (this.candidateData) {
        this.candidateData.careerLevel = data;
        this.updateCandidateData();
      }
    },
    async updateComponent() {
      SpinnerService.showSpinner();
      try {
        this.loadCandidate().then(() => {
          this.checkAtsStatus().then(() => {
            if (
              this.candidateData &&
              (!this.candidateData.tags ||
                this.candidateData.tags.length === 0 ||
                this.candidateData.careerSteps.length === 0)
            ) {
              if (
                this.candidateData.careerSteps &&
                this.candidateData.careerSteps.length > 0
              ) {
                this.generateAndSetCandidateTags();
              }
            }
            if (this.$refs.dependenciesComponent) {
              (this.$refs.dependenciesComponent as any).loadLinkingsFromStore();
            }
            this.autoCheckForJobAdLeads();
          });
        });
      } catch (error) {
        console.error("Error updating CandidateItem:", error);
      } finally {
        SpinnerService.removeSpinner();
      }
    },
    updateStatusAndColor(status: LinkingStatus) {
      const statusColors = this.$store.state.company.candidateStatusColor;
      let color = "";

      switch (status) {
        case LinkingStatus.interviewSuggestionExternal:
          this.needToContact = true;
          color = statusColors[LinkingStatus.interviewExternal];
          break;
        case LinkingStatus.trailWorkSuggestionExternal:
          this.needToContact = true;
          color = statusColors[LinkingStatus.trailWorkExternal];
          break;
        case LinkingStatus.jobofferExternal:
          this.needToContact = true;
          color = statusColors[status];
          break;
        default:
          this.needToContact = false;
          color = statusColors[status] || "#244578";
      }

      this.statusColor = color;
    },
    async updateCandidateData() {
      if (this.candidateData) {
        await this.candidateService.updateCandidateByUuid(
          this.candidate.candidateUuid,
          this.candidateData
        );
      }
    },
    updateChecklist(checklist: CheckList[]) {
      if (!this.candidateData) return;
      this.candidateData.checklist = checklist;
      this.updateCandidateData();
    },
  },
});
</script>

<style scoped>
.status {
  position: absolute;
  top: 0;
  right: 0;
  width: 5px;
  height: -webkit-fill-available;
  z-index: 10;
}
.status-list {
  position: absolute;
  top: 0;
  right: 0;
  width: 3px;
  height: 60%;
  z-index: 10;
}
@keyframes blink {
  0% {
    opacity: 1;
  }
  50% {
    background-color: var(--color-primary);
    opacity: 0.5;
  }
  100% {
    opacity: 1;
  }
}

.blinking {
  animation: blink 1.5s infinite;
}
.appointment-icon {
  margin-right: 1rem;
  font-size: 1.5rem;
  cursor: pointer;
  transition: all 0.3s ease;
}
.appointment-icon:hover {
  color: var(--color-secondary);
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
}
.application-form-icon {
  margin-right: 1rem;
  font-size: 1.5rem;
  cursor: pointer;
  transition: all 0.3s ease;
}
.application-form-icon:hover {
  color: var(--color-secondary);
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
}
.candidate-tag {
  margin-right: 1rem;
  margin-top: 0.5rem;
  border-radius: 5px;
}

.save-interview-result {
  font-size: 3rem;
  cursor: pointer;
  top: -2.4rem;
  right: 1rem;
  color: var(--color-primary);
  transition: all 0.3s ease;
}

.save-interview-result:hover {
  color: var(--color-secondary);
}

.zvoove-recuit-icon {
  margin-right: 1rem;
  cursor: pointer;
  transition: all 0.5s ease;
  width: 1.8rem;
  height: 1.8rem;
}
.zvoove-recuit-icon:hover {
  scale: var(--medium-scale-up);
}

.context-menu {
  position: fixed;
  z-index: 1000;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
  min-width: 10rem;
}

.context-menu .v-list-item:not(:last-child) {
  border-bottom: 0.1rem solid var(--table-hover);
}

.context-menu .v-list-item--active {
  background-color: var(--table-hover);
}

.context-menu-icons {
  width: 1rem;
  height: 1rem;
  margin-right: 0.5rem;
}
</style>
