//src/enums/company-config-components.enum.ts
export enum CompanyConfigComponentTypes {
  AiConfigCompanyPrompts = "AiConfigCompanyPrompts",
  AiConfigEmailTemplates = "AiConfigEmailTemplates",
  AiConfigJobAdTemplate = "AiConfigJobAdTemplate",
  AiConfigUserPrompts = "AiConfigUserPrompts",
  ConfigEmail = "ConfigEmail",
  ConfigIndexAnzeigendaten = "ConfigIndexAnzeigendaten",
  ConfigPayFlow = "ConfigPayFlow",
  ConfigPdHub = "ConfigPdHub",
  ConfigReminderSettings = "ConfigReminderSettings",
  ConfigStatusColor = "ConfigStatusColor",
  ConfigTimelineEntries = "ConfigTimelineEntries",
  ConfigWhatsApp = "ConfigWhatsApp",
  ConfigWordPressPlugin = "ConfigWordPressPlugin",
  ConfigZvooveOne = "ConfigZvooveOne",
  ConfigZvooveRecruit = "ConfigZvooveRecruit",
  ConfigProfileTemplate = "ConfigProfileTemplate",
  ConfigZorst = "ConfigZorst",
}
//these are component names
