<template>
  <div class="header-with-button">
    <h2>4. Farben</h2>
    <button
      class="save-button"
      @click="updateConfig({ Colors: this.ColorCopy })"
    >
      Änderungen speichern
    </button>
  </div>
  <div class="color-option">
    Farbe A:<input v-model="colorsCopy.Farbe1" type="color" id="Farbe1" />
  </div>
  <div class="color-option">
    Farbe B:<input v-model="colorsCopy.Farbe2" type="color" id="Farbe2" />
  </div>
  <div class="color-option">
    Schrift Hell:<input
      v-model="colorsCopy.SchriftHell"
      type="color"
      id="SchriftHell"
    />
  </div>
  <div class="color-option">
    Hintergrund Seite:<input
      v-model="colorsCopy.Hintergrund"
      type="color"
      id="Hintergrund"
    />
  </div>
  <div class="color-option">
    Hintergrund Fragen:<input
      v-model="colorsCopy.HintergrundQuestionForm"
      type="color"
      id="HintergrundQuestionForm"
    />
  </div>
  <div class="color-option">
    Farbe Fehler:<input
      v-model="colorsCopy.ErrorColor"
      type="color"
      id="ErrorColor"
    />
  </div>
  <div class="color-option">
    Hintergrund Fehler:<input
      v-model="colorsCopy.ErrorBackgroundColor"
      type="color"
      id="ErrorBackgroundColor"
    />
  </div>
  <div class="color-option">
    Hintergrund Textfeld:<input
      v-model="colorsCopy.TextFieldBackground"
      type="color"
      id="TextFieldBackground"
    />
  </div>
  <div class="color-option">
    Hintergrund Fortschrittsbalken:<input
      v-model="colorsCopy.ProgressBarBackground"
      type="color"
      id="ProgressBarBackground"
    />
  </div>
  1
</template>
<script lang="ts">
import { PropType, defineComponent, ref, watchEffect } from "vue";
import { ConfigService } from "@/services/config.service";
import { Colors, Config } from "@/models/funnel-config.model";

export default defineComponent({
  name: "FunnelColors",
  data() {
    return {
      configService: new ConfigService(),
    };
  },
  props: {
    colors: Object as PropType<Colors>,
  },
  setup(props) {
    let colorsCopy = ref<Colors>({
      Farbe1: "",
      Farbe2: "",
      SchriftHell: "",
      Hintergrund: "",
      HintergrundQuestionForm: "",
      ErrorColor: "",
      ErrorBackgroundColor: "",
      TextFieldBackground: "",
      ProgressBarBackground: "",
    });

    watchEffect(() => {
      const newVal = props.colors;
      if (newVal) {
        colorsCopy.value = newVal;
      }
    });

    return { colorsCopy };
  },
  methods: {
    updateConfig(config: Config) {
      this.configService.updateConfig(config); // Rufen Sie die gemeinsame Methode auf
    },
  },
});
</script>
