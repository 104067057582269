// src/services/timeline.service.ts
import {
  Customer,
  L1CustomerTimelineEntry,
  L1EmployeeTimelineEntry,
  Participant,
  Timeline,
  TimelineTypes,
  ZvooveOneTimelineEntry,
} from "@/models/timeline.model";
import { ZorstService } from "@/services/zorst.service";
import { CustomerService } from "@/services/api/customer.service";
import {
  ZvooveOneCustomer,
  ZvooveOneEmployee,
} from "@/services/api/api-integration-one.service";
import store from "../store/store";
import { TimelineService } from "./api/timeline.service";
import { PdHubService } from "./api/pd-hub.service";

interface TimeLineObject {
  note: string;
  entryType?: string;
  mandant?: string;
  customer?: Partial<Customer>;
  participant?: Partial<Participant>;
}

// we need that service to add every poop in the timeline
export class TimelineHelperService {
  private timelineService = new TimelineService();

  async timelineAutoDocu(
    {
      note,
      entryType = "SYSTEM",
      mandant = "",
      customer = { name: "", contact: "", customerId: "" },
      participant = { name: "", uuid: "", employeeId: "" },
    }: TimeLineObject,
    employeeNumber?: string
  ): Promise<void> {
    const newEntryTimeline: Timeline = {
      user: `${store.state.company.loggedInUser.forename} ${store.state.company.loggedInUser.lastname}`,
      mandant: mandant,
      entryDate: "",
      entryType,
      customer: {
        name: customer.name ?? "",
        contact: customer.contact ?? "",
        customerId: customer.customerId ?? "",
      },
      participant: {
        name: participant.name ?? "",
        uuid: participant.uuid ?? "",
        employeeId: participant.employeeId ?? "",
      },
      note,
    };

    try {
      await this.timelineService.postTimeline(newEntryTimeline);
    } catch (error) {
      console.error("Fehler beim Speichern des Timeline-Eintrags:", error);
    }
    // Autodocu for zvoove One
    if (
      store.state.company.softwareIntegration.erpAutoDocu &&
      (store.state.company.softwareIntegration.zvooveOne ||
        store.state.company.softwareIntegration.pdHub)
    ) {
      const artZvoove = (description: string): string => {
        const timelineTypes = store.state.company
          .timelineEntryTypes as TimelineTypes;

        for (const key of Object.keys(timelineTypes)) {
          if (timelineTypes[key].description === description) {
            return timelineTypes[key].artZvoove;
          }
        }
        return "NA";
      };

      const art = artZvoove(entryType);

      const zvooveOneTimelineEntry: ZvooveOneTimelineEntry = {
        beschreibung: entryType,
        art: art,
        disponentId: "",
        text: note,
        userIds: [store.state.company.loggedInUser.config.zvooveOneUuid],
        erledigtForLoggedInUser: true,
      };

      //AutoDocu zvoove One: Company
      if (customer.customerId) {
        const customerService = new CustomerService();
        const response = await customerService.getById(customer.customerId);
        const customerNumber = response.customerNumber;
        if (!isNaN(parseFloat(customerNumber))) {
          if (store.state.company.softwareIntegration.zvooveOne) {
            const zvooveOneCustomer = new ZvooveOneCustomer();
            await zvooveOneCustomer.createTimelineEntry(
              customerNumber,
              zvooveOneTimelineEntry
            );
          } else if (store.state.company.softwareIntegration.pdHub) {
            const l1CustomerTimelineEntry: L1CustomerTimelineEntry = {
              KundenNr: parseInt(customerNumber),
              AnsprechpartnerNr: 0,
              Art: art,
              Thema: entryType,
              Anlagebediener: "SYS",
              Wiedervorlagebediener: "SYS",
              Text: note,
            };
            const pdHubService = PdHubService.getInstance();
            await pdHubService.createTimelineEntryCustomer(
              l1CustomerTimelineEntry
            );
          }
        } else {
          console.error(
            "Keine zvoove Autodoku, da keine ERP Kundennumer:",
            customerNumber
          );
        }
      }
      //AutoDocu in ERP: Employee
      if (participant.employeeId) {
        // TODO geht employee Number (zvoove) from backend when we save timeline employee with mongo _id
        const employeeId = participant.employeeId;
        if (!isNaN(parseFloat(employeeId))) {
          if (store.state.company.softwareIntegration.zvooveOne) {
            const zvooveOneEmployee = new ZvooveOneEmployee();
            await zvooveOneEmployee.createTimelineEntry(
              employeeId,
              zvooveOneTimelineEntry
            );
          } else if (store.state.company.softwareIntegration.pdHub) {
            const l1EmployeeTimelineEntry: L1EmployeeTimelineEntry = {
              Personalnr: employeeNumber ?? "",
              Art: art,
              Beschreibung: entryType,
              Anlagebediener: "SYS",
              Wiedervorlagebediener: "SYS",
              Infotext: note,
            };

            const pdHubService = PdHubService.getInstance();
            await pdHubService.createTimelineEntryEmployee(
              l1EmployeeTimelineEntry
            );
          }
        } else {
          console.error(
            "Keine zvoove Autodoku, da keine zvoove One Mitarbeiternummer:",
            employeeId
          );
        }
      }
      //AutoDocu zvoove Recruit: Candidate with ZORST
    }

    if (
      participant.uuid &&
      store.state.company.softwareIntegration.atsAutoDocu
    ) {
      const zvooveRecruitLink = `${store.state.company.softwareIntegration.zvooveRecruitLink}/bewerber/${participant.uuid}/dashboard`;
      let docuType = "note";

      const timelineTypes = store.state.company
        .timelineEntryTypes as TimelineTypes;

      const contactDescriptions = [
        timelineTypes.phoneCallCandidate.description,
        timelineTypes.phoneCallCustomer.description,
        timelineTypes.emailCandidate.description,
        timelineTypes.eMailCustomer.description,
        timelineTypes.whatsAppCandidate.description,
        timelineTypes.whatsAppEmployee.description,
      ];

      if (contactDescriptions.includes(entryType)) {
        docuType = "contact";
      }

      const payload = {
        type: "ZORST_ZVOOVE_AD_ENTRY", //= ZORST - ORDER
        zvooveRecruitLink: zvooveRecruitLink,
        docuType: docuType,
        subject: entryType,
        text: note,
      };
      const zorstService = ZorstService.getInstance();
      zorstService.ZorstAddZvooveRecruitDocu(payload);
    }
  }
}
