import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_card_title = _resolveComponent("v-card-title")!
  const _component_v_text_field = _resolveComponent("v-text-field")!
  const _component_v_form = _resolveComponent("v-form")!
  const _component_v_card_text = _resolveComponent("v-card-text")!
  const _component_v_btn = _resolveComponent("v-btn")!
  const _component_v_spacer = _resolveComponent("v-spacer")!
  const _component_v_icon = _resolveComponent("v-icon")!
  const _component_v_tooltip = _resolveComponent("v-tooltip")!
  const _component_v_card_actions = _resolveComponent("v-card-actions")!
  const _component_v_card = _resolveComponent("v-card")!
  const _component_v_dialog = _resolveComponent("v-dialog")!
  const _component_v_container = _resolveComponent("v-container")!

  return (_openBlock(), _createBlock(_component_v_container, null, {
    default: _withCtx(() => [
      _createVNode(_component_v_dialog, {
        modelValue: _ctx.dialog,
        "onUpdate:modelValue": _cache[11] || (_cache[11] = ($event: any) => ((_ctx.dialog) = $event)),
        persistent: "",
        "max-width": _ctx.smAndDown ? 'var(--d-w-sd)' : 'var(--d-w-m)'
      }, {
        default: _withCtx(() => [
          _createVNode(_component_v_card, null, {
            default: _withCtx(() => [
              _createVNode(_component_v_card_title, null, {
                default: _withCtx(() => [
                  _createTextVNode(" Kandidat auf die Homepage stellen? ")
                ]),
                _: 1
              }),
              _createVNode(_component_v_card_text, null, {
                default: _withCtx(() => [
                  _createVNode(_component_v_form, null, {
                    default: _withCtx(() => [
                      _createVNode(_component_v_text_field, {
                        modelValue: _ctx.wpCandidateData.title,
                        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.wpCandidateData.title) = $event)),
                        label: "Titel",
                        density: "compact",
                        required: ""
                      }, null, 8, ["modelValue"]),
                      _createVNode(_component_v_text_field, {
                        modelValue: _ctx.wpCandidateData.profileId,
                        "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.wpCandidateData.profileId) = $event)),
                        label: "Profil ID",
                        density: "compact",
                        required: ""
                      }, null, 8, ["modelValue"]),
                      _createVNode(_component_v_text_field, {
                        modelValue: _ctx.wpCandidateData.gender,
                        "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.wpCandidateData.gender) = $event)),
                        label: "Geschlecht",
                        density: "compact"
                      }, null, 8, ["modelValue"]),
                      _createVNode(_component_v_text_field, {
                        modelValue: _ctx.wpCandidateData.age,
                        "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.wpCandidateData.age) = $event)),
                        label: "Alter",
                        density: "compact"
                      }, null, 8, ["modelValue"]),
                      _createVNode(_component_v_text_field, {
                        modelValue: _ctx.wpCandidateData.residence,
                        "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.wpCandidateData.residence) = $event)),
                        label: "Wohnort",
                        density: "compact"
                      }, null, 8, ["modelValue"]),
                      _createVNode(_component_v_text_field, {
                        modelValue: _ctx.wpCandidateData.mobility,
                        "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.wpCandidateData.mobility) = $event)),
                        label: "Mobilität",
                        density: "compact"
                      }, null, 8, ["modelValue"]),
                      _createVNode(_component_v_text_field, {
                        modelValue: _ctx.wpCandidateData.shift,
                        "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.wpCandidateData.shift) = $event)),
                        label: "Schicht",
                        density: "compact"
                      }, null, 8, ["modelValue"]),
                      _createVNode(_component_v_text_field, {
                        modelValue: _ctx.wpCandidateData.qualification,
                        "onUpdate:modelValue": _cache[7] || (_cache[7] = ($event: any) => ((_ctx.wpCandidateData.qualification) = $event)),
                        label: "Qualifikation",
                        density: "compact"
                      }, null, 8, ["modelValue"]),
                      _createVNode(_component_v_text_field, {
                        modelValue: _ctx.wpCandidateData.licenses,
                        "onUpdate:modelValue": _cache[8] || (_cache[8] = ($event: any) => ((_ctx.wpCandidateData.licenses) = $event)),
                        label: "Lizenzen",
                        density: "compact"
                      }, null, 8, ["modelValue"]),
                      _createVNode(_component_v_text_field, {
                        modelValue: _ctx.wpCandidateData.experience,
                        "onUpdate:modelValue": _cache[9] || (_cache[9] = ($event: any) => ((_ctx.wpCandidateData.experience) = $event)),
                        label: "Erfahrung",
                        density: "compact"
                      }, null, 8, ["modelValue"])
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              }),
              _createVNode(_component_v_card_actions, null, {
                default: _withCtx(() => [
                  _createVNode(_component_v_btn, {
                    color: "red-darken-4",
                    onClick: _cache[10] || (_cache[10] = ($event: any) => (_ctx.closeModal(true)))
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode("nicht Veröffentlichen")
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_v_spacer),
                  _createVNode(_component_v_icon, {
                    elevation: 5,
                    class: "ai-icon",
                    onClick: _ctx.generateAiProfile
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode(" fa-solid fa-rocket ")
                    ]),
                    _: 1
                  }, 8, ["onClick"]),
                  _createVNode(_component_v_tooltip, {
                    activator: "parent",
                    location: "bottom"
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode("Kandidateninformationen für die Webseite mit AI zusammstellen")
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_v_spacer),
                  _createVNode(_component_v_btn, {
                    color: "green-darken-3",
                    onClick: _ctx.postCandidateToWP
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode("auf Webseite stellen")
                    ]),
                    _: 1
                  }, 8, ["onClick"])
                ]),
                _: 1
              })
            ]),
            _: 1
          })
        ]),
        _: 1
      }, 8, ["modelValue", "max-width"])
    ]),
    _: 1
  }))
}