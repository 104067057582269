<!--src/components/disposition/elements/JobAdListCustomer-->
<template>
  <v-card
    v-if="isVisible"
    class="job-ad-list px-0 mx-0"
    @mouseenter="cancelClose"
    @mouseleave="scheduleClose"
    :style="windowPosition"
  >
    <div class="d-flex">
      <div>
        <v-btn @click="allAdsPostJobList" variant="text">
          auf JobList setzen&nbsp;
          <v-icon class="mr-2">fas fa-rectangle-list</v-icon>
          <v-icon class="mr-1">fas fa-arrow-right</v-icon>
          <v-icon class="mr-1">fas fa-square-plus</v-icon>
          <v-icon class="mr-1">fas fa-rectangle-ad</v-icon>
          <v-tooltip activator="parent" location="bottom"
            >AI extrahiert die Aufgaben und Anforderungen aller Stellen und
            speichert es in Recruting - JobList</v-tooltip
          >
        </v-btn>
      </div>
      <v-spacer></v-spacer>
      <div v-if="company.softwareIntegration.indexAnzeigendaten">
        <v-btn @click="fetchIndexAnzeigendaten" variant="text">
          Anzeigen neu laden&nbsp;
          <img
            src="@/assets/icon-index-anzeigendaten.png"
            class="mr-1"
            style="width: 1rem; height: 1rem"
          />
          <v-icon>fas fa-arrows-rotate</v-icon>
          <v-tooltip activator="parent" location="bottom"
            >Stellenanzeigen von Anzeigendaten neu abrufen</v-tooltip
          >
        </v-btn>
      </div>
    </div>
    <v-divider class="my-3"></v-divider>
    <v-list class="ma-0 pa-0">
      <v-list-item
        class="ma-0 pa-0"
        v-for="jobAd in uniqueAndLatestJobAds"
        :key="jobAd['AD-ID']"
      >
        <div class="job-ad">
          <div class="d-flex">
            <a
              class="mx-2 text-subtitle-1 job-ad-title"
              :href="jobAd.LINK"
              target="_blank"
              >{{ jobAd.POSITION }}</a
            >
            <v-btn
              icon
              size="x-small"
              density="compact"
              variant="text"
              class="ma-1"
              v-if="company.softwareIntegration.indexAnzeigendaten"
              :href="jobAd['INTERN-LINK-PDF']"
              target="_blank"
            >
              <v-tooltip activator="parent" location="bottom"
                >PDF von Anzeigendaten öffnen</v-tooltip
              >
              <v-icon>fa-solid fa-file-pdf job-ad-icon</v-icon>
            </v-btn>
            <v-btn
              icon
              size="x-small"
              density="compact"
              variant="text"
              class="ma-1"
              v-if="company.softwareIntegration.indexAnzeigendaten"
              :href="jobAd['INTERN-LINK-TXT']"
              target="_blank"
            >
              <v-icon> fa-solid fa-file-lines job-ad-icon</v-icon>
              <v-tooltip activator="parent" location="bottom"
                >Textdatei von Anzeigendaten öffnen</v-tooltip
              >
            </v-btn>
            <v-spacer></v-spacer>
            <v-btn
              size="medium"
              variant="text"
              class="job-ad-icon mx-2"
              @click="
                adPostJobList(
                  jobAd.POSITION,
                  jobAd['COMPANY-POSTAL-CODE'],
                  jobAd['COMPANY-CITY'],
                  jobAd['BA-JOB-CLASSIFICATION']?.split(', ') ?? [],
                  jobAd['TEXT']
                )
              "
            >
              <i class="fa-solid fa-square-plus"></i
              ><i class="fa-solid fa-rectangle-ad"></i>
              <v-tooltip activator="parent" location="bottom"
                >Aufgaben & Anforderungen extrahieren und Stelle in Joblist
                speichern</v-tooltip
              >
            </v-btn>
          </div>
          <div class="d-flex">
            <v-card-subtitle class="mx-2 pa-0">
              {{ jobAd.DATE }}: {{ jobAd.LOCATION }}
              {{ jobAd["SOURCE-TEXT"] }} &nbsp;
              <span v-html="jobAd['LIST-PRICE']"></span>
            </v-card-subtitle>
          </div>
          <v-divider></v-divider>
        </div>
      </v-list-item>
    </v-list>
  </v-card>
</template>

<script lang="ts">
import { defineComponent, PropType } from "vue";
import { BfaService } from "@/services/api/bfa.service";
import { mapGetters } from "vuex";
import { IndexJobAd } from "@/models/external/index-anzeigendaten.model";
import { extractJobAdData } from "@/services/ai.service";
import Fuse from "fuse.js";
import { SpinnerService } from "@/services/spinner.service";
import { JobAdService } from "@/services/api/job-ad.service";
import ToastService from "@/services/toast.service";
import {
  JobAd,
  jobAdType,
  JobLocation,
  PostingStatus,
} from "@/models/job-ad-model";

export default defineComponent({
  name: "JobAdListCustomer",
  props: {
    jobAds: {
      type: Array as PropType<IndexJobAd[]>,
      required: true,
    },
    mandant: {
      type: String,
      default: "",
    },
    positionX: {
      type: Number,
      default: 0,
    },
    positionY: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      allCategoriesBfA: [] as string[],
      bfaService: new BfaService(),
      isVisible: false,
      closeTimeout: null as number | null,
      jobAdService: new JobAdService(),
    };
  },
  computed: {
    ...mapGetters(["company"]),
    windowPosition() {
      // Calculate the left position, ensuring it doesn't go beyond the left edge of the viewport
      const leftPosition = this.positionX - 650 < 0 ? 0 : this.positionX - 650;
      return {
        top: this.positionY + 25 + "px",
        left: leftPosition + "px",
      };
    },
    uniqueAndLatestJobAds() {
      const uniqueAds = [];
      const positions = new Set();

      for (const ad of this.jobAds) {
        if (!positions.has(ad.POSITION)) {
          uniqueAds.push(ad);
          positions.add(ad.POSITION);
        }
      }

      return uniqueAds;
    },
  },
  methods: {
    fetchIndexAnzeigendaten() {
      this.$emit("fetchIndexAnzeigendaten");
    },
    show() {
      this.isVisible = true;
    },
    hide() {
      this.isVisible = false;
    },
    scheduleClose() {
      this.closeTimeout = window.setTimeout(() => {
        this.isVisible = false;
      }, 500);
    },
    cancelClose() {
      if (this.closeTimeout !== null) {
        clearTimeout(this.closeTimeout);
        this.closeTimeout = null;
      }
    },

    async allAdsPostJobList() {
      SpinnerService.setHeaderSpinnerElement(1, "recurion0.gif");
      for (const ad of this.uniqueAndLatestJobAds) {
        const baJobClassification =
          ad["BA-JOB-CLASSIFICATION"]?.split(", ") ?? [];
        const title = ad.POSITION as string;
        const postalCode = ad["COMPANY-POSTAL-CODE"] as string;
        const city = ad["COMPANY-CITY"] as string;
        const jobAdText = ad.TEXT;

        await this.postJobList(
          title,
          postalCode,
          city,
          baJobClassification,
          jobAdText
        );
      }
      SpinnerService.clearHeaderSpinnerElement();
      ToastService.showSuccess("Alle Kundenstellen erfolgreich bearbeitet!");
    },

    adPostJobList(
      title: string,
      postalCode: string,
      city: string,
      categoriesBfA: string[],
      jobAdText?: string
    ) {
      SpinnerService.showSpinner();
      this.postJobList(title, postalCode, city, categoriesBfA, jobAdText).then(
        () => SpinnerService.removeSpinner()
      );
    },

    async postJobList(
      title: string,
      postalCode: string,
      city: string,
      categoriesBfA: string[],
      jobAdText?: string
    ) {
      const jobAd: JobAd = {
        categoriesBfA: categoriesBfA,
        locationId: "",
        mandants: [this.mandant],
        profile: "",
        socialMedia: false,
        supportedBfAJobAd: true,
        salaryFrom: null,
        salaryTo: null,
        salaryPeriod: "",
        status: PostingStatus.requested,
        tasks: "",
        title: title,
        type: jobAdType.joblist,
      };

      const Location: JobLocation = {
        city: city,
        postalCode: parseInt(postalCode),
        mandants: [this.mandant],
      };

      try {
        if (jobAdText) {
          const jobAdDetails = await extractJobAdData(jobAdText);

          jobAd.tasks = jobAdDetails.tasks;
          jobAd.profile = jobAdDetails.profile;

          if (!this.allCategoriesBfA.length) {
            this.allCategoriesBfA = await this.bfaService.getBfaJoblist();
          }

          const fuse = new Fuse(this.allCategoriesBfA, {
            includeScore: true,
            threshold: 0.3,
          });

          const matchedCategories = jobAdDetails.bfaCategories
            .map((category: string) => {
              const result = fuse.search(category);
              return result.length ? result[0].item : null;
            })
            .filter(
              (category: string): category is string => category !== null
            );

          while (matchedCategories.length < 3) {
            matchedCategories.push(
              this.allCategoriesBfA[matchedCategories.length]
            );
          }

          jobAd.categoriesBfA = matchedCategories.slice(0, 3);
        } else {
          ToastService.showError(
            "Kein Text in der Stellenanzeige gefunden, Standardeintrag wird hinzugefügt."
          );
        }

        await this.jobAdService.addNewJobAd(jobAd, Location);
        ToastService.show("Job erfolgreich zur Liste hinzugefügt");
      } catch (error) {
        ToastService.showError(
          "Fehler beim Hinzufügen des Jobs zur Liste: " + error
        );
      }
    },
  },
});
</script>

<style scoped>
.job-ad-list {
  position: absolute;
  overflow: auto;
  z-index: 500;
}
.job-ad:hover {
  background-color: var(--table-hover);
}
.job-ad-title {
  color: var(--color-primary);
  text-decoration: none;
}

.job-ad-title:hover {
  color: var(--color-secondary);
}

.job-ad-icon {
  cursor: pointer;
}
.job-ad-icon:hover {
  color: var(--color-secondary);
}
</style>
