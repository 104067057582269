<!-- src/components/modal/GetPdHubUserToken.vue -->
<template>
  <v-dialog
    v-model="showModal"
    persistent
    :max-width="smAndDown ? 'var(--d-w-sd)' : 'var(--d-w-xs)'"
  >
    <v-card>
      <v-card-title>
        <span class="headline">Get PDHub User Token</span>
      </v-card-title>
      <v-card-text>
        <v-form ref="form">
          <v-text-field
            v-model="username"
            label="Username"
            required
            variant="outlined"
          ></v-text-field>
          <v-text-field
            v-model="password"
            label="Password"
            type="password"
            required
            variant="outlined"
          ></v-text-field>
        </v-form>
      </v-card-text>
      <v-card-actions>
        <v-btn color="red-darken-4" variant="text" @click="closeModal"
          >Cancel</v-btn
        >
        <v-spacer></v-spacer>
        <v-btn color="green-darken-3" variant="text" @click="getPdHubUserToken"
          >Submit</v-btn
        >
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { useDisplay } from "vuetify";
import { PdHubService } from "@/services/api/pd-hub.service";
import { UserHelperService } from "@/services/user-helper.service";
import { UserService } from "@/services/api/user.service";
import { User } from "@/models/user.model";
import { SpinnerService } from "@/services/spinner.service";
import ToastService from "@/services/toast.service";

export default defineComponent({
  name: "GetPdHubUserToken",

  data() {
    const { smAndDown } = useDisplay();
    return {
      smAndDown,
      showModal: false,
      username: "",
      password: "",
      pdHubService: PdHubService.getInstance(),
      user: {} as User | any,
      userHelperService: UserHelperService.getInstance(),
      userService: new UserService(),
    };
  },
  created(): void {
    this.getUser();
  },
  methods: {
    closeModal() {
      this.showModal = false;
    },
    async getUser() {
      this.user = this.$store.getters.user;
    },

    async syncUser() {
      const defaultPdHub = this.$store.getters.user.config.pdHub;

      if (!this.user.config.pdHub) {
        this.user.config.pdHub = { ...defaultPdHub };
        await this.updateUserConfig(this.user);
      } else {
        const pdHubKeys: (keyof typeof defaultPdHub)[] = Object.keys(
          defaultPdHub
        ) as (keyof typeof defaultPdHub)[];
        const missingKeys = pdHubKeys.filter(
          (key) => !(key in this.user.config.pdHub)
        );

        if (missingKeys.length > 0) {
          missingKeys.forEach((key) => {
            if (!this.user.config.pdHub[key]) {
              this.user.config.pdHub[key] = defaultPdHub[key];
            }
          });
          await this.updateUserConfig(this.user);
        }
      }
    },
    async getPdHubUserToken() {
      SpinnerService.showSpinner();
      try {
        this.pdHubService
          .getUserToken(this.username, this.password)
          .then((userToken: any) => {
            if (!this.user.config.pdHub) {
              this.user.config = {
                pdHub: { Token: "", TokenValidTill: "" },
                ...this.user.config,
              };
            }
            this.user.config.pdHub.token = userToken.Token;
            this.user.config.pdHub.tokenValidTill = userToken.TokenValidTill;
            this.updateUserConfig(this.user);
            this.closeModal();
            ToastService.showSuccess("Authorisierung erfolgreich");
          });
      } catch (error) {
        ToastService.showError("Authorisierung fehlgeschlagen");
      }
      SpinnerService.removeSpinner();
    },
    openModal() {
      this.showModal = true;
    },
    updateUserConfig(user: User) {
      this.userService.updateUserConfig(user.config).then(async () => {
        ToastService.show("Benutzereinstellungen aktualisiert");
      });
    },
  },
});
</script>

<style scoped>
.headline {
  font-weight: bold;
}
</style>
