<template>
  <v-chip color="red" variant="flat" v-if="label">{{ label }}</v-chip>
</template>

<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
  name: "ErrorChip",
  props: {
    label: String,
  },
});
</script>
