import { Customer, CustomerList } from "@/models/customer.model";
import { Api } from "./api.class";
import { ApiResponse } from "@/models/api-response.model";

export class CustomerService {
  private static instance: CustomerService;
  private api = Api.getInstance();
  private urlPath = "/customer";
  private urlPathList = "/customer-list";

  public static getInstance(): CustomerService {
    if (!CustomerService.instance) {
      CustomerService.instance = new CustomerService();
    }
    return CustomerService.instance;
  }

  // Methods for customer-list
  async getList() {
    const response = await this.api.getBackendApi().get(`${this.urlPathList}`);
    return response.data.response;
  }

  async saveList(customers: CustomerList[]) {
    const response = await this.api
      .getBackendApi()
      .post(`${this.urlPathList}`, customers);
    return response.data;
  }

  async addCustomerReducedToList(customer: CustomerList) {
    const response = await this.api
      .getBackendApi()
      .post(`${this.urlPathList}/add`, customer);
    return response.data;
  }

  // Methods for customer
  async addCustomer(customer: Customer) {
    const response = await this.api
      .getBackendApi()
      .post(`${this.urlPath}`, customer);
    return response.data;
  }

  async addCustomers(customers: Customer[]) {
    const response = await this.api
      .getBackendApi()
      .post(`${this.urlPath}/customers`, customers);
    return response.data;
  }

  async editCustomer(customer: Customer) {
    const response = await this.api
      .getBackendApi()
      .put(`${this.urlPath}/${customer._id}`, customer);
    return response.data.response;
  }

  async deleteCustomer(id: string) {
    const response = await this.api
      .getBackendApi()
      .delete(`${this.urlPath}/${id}`);
    return response.data;
  }

  async getAll(): Promise<Customer[]> {
    const response = await this.api
      .getBackendApi()
      .get<ApiResponse>(`${this.urlPath}/all`);
    return response.data.response;
  }

  async getAllFiltered(
    mandants: string[],
    statuses: string[]
  ): Promise<Customer[]> {
    const response = await this.api
      .getBackendApi()
      .post<{ response: Customer[] }>(`${this.urlPath}/filtered`, {
        mandants,
        statuses,
      });
    return response.data.response;
  }

  async getByCustomerNumbers(customerNumbers: string[]): Promise<Customer[]> {
    const response = await this.api
      .getBackendApi()
      .post<{ response: Customer[] }>(`${this.urlPath}/customer-numbers`, {
        customerNumbers,
      });
    return response.data.response;
  }

  async getById(id: string): Promise<Customer> {
    const response = await this.api
      .getBackendApi()
      .get(`${this.urlPath}/${id}`);
    return response.data.response;
  }
}
