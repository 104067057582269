<!--I_JobFunnelItem.vue-->
<template>
  <div class="funnel-item">
    <div class="name">{{ name }}</div>
    <button @click="downloadCSV">Analyse</button>
    <button @click="emitEditEvent">Edit</button>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
  name: "I_JobFunnelItem",
  props: {
    name: String, //from JobFunnels.vue item name
    funnelId: String, //funnel id
  },
  methods: {
    downloadCSV() {
      const csvLink = `http://localhost:3001/tracking/${this.funnelId}`;
      window.open(csvLink, "_blank");
    },
    emitEditEvent() {
      this.$emit("edit-funnel", { funnelId: this.funnelId });
    },
  },
});
</script>

<style scoped>
.funnel-item {
  margin: 1rem 0.5rem;
  width: 150px;
  height: 150px;
  background-color: #e6e3de;
  margin-bottom: 10px;
  border-radius: 8px;
  padding: 10px;
  box-sizing: border-box;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.4);

  button {
    cursor: pointer;
    margin: 0.25rem 0;
    width: 100%;
  }

  .name {
    margin-bottom: 0.25rem;
    text-transform: capitalize;
  }
}
</style>
