// src/enums/dialog-action.enum.ts

export enum DialogResponse {
  aborted = "aborted",
  failure = "failure",
  result = "result",
  startMethod = "startMethod",
  submit = "submit",
  success = "success",
  info = "info",
}

export enum StatusResponse {
  unknown = "unknown",
}

export enum ModalMode {
  add = "add",
  customerStatus = "customerStatus",
  employeeStatus = "employeeStatus",
  delete = "delete",
  edit = "edit",
  googleSearch = "googleSearch",
  indexAnzeigendaten = "indexAnzeigendaten",
  mandant = "mandant",
  pdHub = "pdHub",
  removePosted = "removePosted",
  removeAllPosted = "removeAllPosted",
  scrapeContactsFromSite = "scrapeContactsFromSite",
  zvooveOne = "zvooveOne",
}

export enum JobMatrixMenu {
  jobCategory = "jobCategory",
  jobLocation = "jobLocation",
  jobAd = "jobAd",
}
