//II. Dispo View

import { ZorstApiData } from "@/models/zorst-api-request.model";
import store from "@/store/store";
import { ZorstService } from "../zorst.service";

// Zvoove Recruit API
export class ApiIntegrationNextLevel {
  //Don't use!!!
  async getBfaJoblistFromZorst(
    nextLevelApiKey: string
  ): Promise<string[] | undefined> {
    const apiData: ZorstApiData = {
      openApiSiteLink: `${store.state.company.apiKeys.baseURLZvooveRecruit}/dashboard`,
      apiEndpointLink: `${store.state.company.apiKeys.baseURLZvooveRecruit}/api/nextlevel/v1/Stammdaten/GetQualifikationen`,
      apiMethod: "GET",
      apiKey: nextLevelApiKey,
    };
    console.log(
      "// TODO WORKAROUND fetching bfaJobData over ZORST. Dran denken: BACKEND!!!"
    );

    const zorstService = ZorstService.getInstance();
    try {
      const response = await zorstService.ZorstOneApiCall(apiData);
      if (response && Array.isArray(response)) {
        const qualifikationen = response.map(
          (item: { Bezeichnung: string }) => {
            return item.Bezeichnung.substring(0, 60);
          }
        );
        return qualifikationen;
      } else {
        console.error("Unexpected API response format:", response);
        throw new Error("Unexpected API response format");
      }
    } catch (error) {
      console.error("Error retrieving qualifications data:", error);
    }
  }
}
