//src/helper/enum.helper.ts

type EnumType = { [key: string]: number | string };

export const getEnumOptions = <T extends EnumType>(enumType: T) => {
  return Object.keys(enumType)
    .filter((key) => isNaN(Number(key)))
    .map((key) => ({
      label: enumType[key as keyof T],
      value: key,
    }));
};
