import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_MainHeader = _resolveComponent("MainHeader")!
  const _component_DispatcherBoard = _resolveComponent("DispatcherBoard")!
  const _component_Calendar = _resolveComponent("Calendar")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(_component_MainHeader, {
      onTabSelected: _ctx.handleTabSelection,
      showDispositionTabs: true
    }, null, 8, ["onTabSelected"]),
    (_ctx.currentTab === _ctx.HeaderTab.DispatcherBoard)
      ? (_openBlock(), _createBlock(_component_DispatcherBoard, { key: 0 }))
      : _createCommentVNode("", true),
    (_ctx.currentTab === _ctx.HeaderTab.Calendar)
      ? (_openBlock(), _createBlock(_component_Calendar, { key: 1 }))
      : _createCommentVNode("", true)
  ]))
}