import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, normalizeClass as _normalizeClass, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_card_title = _resolveComponent("v-card-title")!
  const _component_v_card_subtitle = _resolveComponent("v-card-subtitle")!
  const _component_v_btn = _resolveComponent("v-btn")!
  const _component_v_btn_toggle = _resolveComponent("v-btn-toggle")!
  const _component_PriceSelection = _resolveComponent("PriceSelection")!
  const _component_v_card_text = _resolveComponent("v-card-text")!
  const _component_v_card = _resolveComponent("v-card")!
  const _component_v_col = _resolveComponent("v-col")!
  const _component_FeatureOverview = _resolveComponent("FeatureOverview")!
  const _component_v_row = _resolveComponent("v-row")!
  const _component_v_container = _resolveComponent("v-container")!

  return (_openBlock(), _createBlock(_component_v_container, { class: "pa-1 ma-1" }, {
    default: _withCtx(() => [
      _createVNode(_component_v_card, { flat: "" }, {
        default: _withCtx(() => [
          _createVNode(_component_v_card_title, null, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.appname) + " Abo Auswahl: Nutze jetzt die Chance, Teil unserer Alpha-Version zu werden!", 1)
            ]),
            _: 1
          }),
          _createVNode(_component_v_card_subtitle, null, {
            default: _withCtx(() => [
              _createTextVNode(" Die App bietet die volle Power der künstlichen Intelligenz, ohne dein Budget zusätzlich zu belasten und entspricht dem DSGVO Standard ")
            ]),
            _: 1
          }),
          _createVNode(_component_v_row, { class: "pa-2 mt-5" }, {
            default: _withCtx(() => [
              _createVNode(_component_v_col, {
                cols: "12",
                md: "3"
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_v_card, null, {
                    default: _withCtx(() => [
                      _createVNode(_component_v_btn_toggle, {
                        modelValue: _ctx.billingCycle,
                        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.billingCycle) = $event)),
                        class: "d-flex"
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_component_v_btn, {
                            value: "monthly",
                            class: _normalizeClass({ active: _ctx.monthly }),
                            style: {"width":"50%"}
                          }, {
                            default: _withCtx(() => [
                              _createTextVNode(" Monatlich ")
                            ]),
                            _: 1
                          }, 8, ["class"]),
                          _createVNode(_component_v_btn, {
                            value: "yearly",
                            class: _normalizeClass({ active: _ctx.yearly }),
                            style: {"width":"50%"}
                          }, {
                            default: _withCtx(() => [
                              _createTextVNode(" Jährlich ")
                            ]),
                            _: 1
                          }, 8, ["class"])
                        ]),
                        _: 1
                      }, 8, ["modelValue"]),
                      _createVNode(_component_v_card_text, null, {
                        default: _withCtx(() => [
                          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.subscriptionPackages, (subscriptionPackage, index) => {
                            return (_openBlock(), _createBlock(_component_PriceSelection, {
                              key: index,
                              appname: _ctx.appname,
                              monthly: _ctx.monthly,
                              subscription: subscriptionPackage,
                              class: _normalizeClass(subscriptionPackage.selected ? 'selected' : ''),
                              onClick: ($event: any) => (_ctx.select(subscriptionPackage))
                            }, null, 8, ["appname", "monthly", "subscription", "class", "onClick"]))
                          }), 128))
                        ]),
                        _: 1
                      })
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_v_card, { class: "my-5" }, {
                    default: _withCtx(() => [
                      _createVNode(_component_v_card_title, null, {
                        default: _withCtx(() => [
                          _createTextVNode(" Laufzeit: " + _toDisplayString(_ctx.monthly ? "1 Monat" : "12 Monate"), 1)
                        ]),
                        _: 1
                      })
                    ]),
                    _: 1
                  }),
                  (_ctx.selected)
                    ? (_openBlock(), _createBlock(_component_v_btn, {
                        key: 0,
                        color: "green-darken-3",
                        onClick: _ctx.goToPayment
                      }, {
                        default: _withCtx(() => [
                          _createTextVNode(" auf Warteliste setzten ")
                        ]),
                        _: 1
                      }, 8, ["onClick"]))
                    : _createCommentVNode("", true)
                ]),
                _: 1
              }),
              _createVNode(_component_v_col, {
                cols: "12",
                md: "1"
              }),
              _createVNode(_component_v_col, {
                cols: "12",
                md: "8"
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_FeatureOverview, {
                    features: _ctx.features,
                    subscriptions: _ctx.subscriptions,
                    descriptions: _ctx.descriptions,
                    selected: _ctx.selected
                  }, null, 8, ["features", "subscriptions", "descriptions", "selected"])
                ]),
                _: 1
              })
            ]),
            _: 1
          })
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}