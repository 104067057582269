<!-- views/dispostion-view.vue -->
<template>
  <div>
    <MainHeader
      @tab-selected="handleTabSelection"
      :showDispositionTabs="true"
    ></MainHeader>
    <DispatcherBoard
      v-if="currentTab === HeaderTab.DispatcherBoard"
    ></DispatcherBoard>
    <Calendar v-if="currentTab === HeaderTab.Calendar"></Calendar>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import Calendar from "@/components/disposition/AppointmentCalendar.vue";
import DispatcherBoard from "@/components/disposition/DispatcherBoard.vue";
import MainHeader from "@/components/top-header/Header.vue";
import { ZvooveOneMultiFetch } from "@/services/api/api-integration-one.service";
import { HeaderTab } from "@/enums/header-tabs.enum";

export default defineComponent({
  name: "DispositionView",
  components: {
    MainHeader,
    DispatcherBoard,
    Calendar,
  },
  data() {
    return {
      currentTab: HeaderTab.DispatcherBoard as string,
      HeaderTab: HeaderTab,
    };
  },
  methods: {
    async fetchZvooveOneInitData() {
      if (
        !this.$store.getters.softwareIntegration.zvooveOne ||
        this.$store.getters.customerList.length > 0
      ) {
        return;
      }
      try {
        const zvooveOneMultiFetch = new ZvooveOneMultiFetch();
        await zvooveOneMultiFetch.fetchZvooveOneInitData(
          this.$store.getters.allZvooveOneGeschaeftsstelleIds,
          this.$store.getters.getLoggedInMandantBranchNumbers
        );
      } catch (error) {
        console.error("Fehler beim Abrufen der Initialdaten", error);
      }
    },
    handleTabSelection(selectedTab: string) {
      this.currentTab = selectedTab;
    },
  },
});
</script>

<style scoped>
/* Stile hier */
</style>
