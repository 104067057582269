<template>
  <h2>1. Banner und Logo</h2>
  <h4>Funnelbanner:</h4>
  <div class="uploadable-image" @click="openFilePicker('banner')">
    <img v-if="imageUrlBanner" :src="imageUrlBanner" alt="Banner" />
    <!-- Displaying the current banner image. 'timeStamp' is used to prevent caching. -->
    <i class="fa-solid fa-cloud-arrow-up icon"></i>
    <!-- Hidden - only shown if hover -->
    <input
      type="file"
      id="file-input-banner"
      @change="handleFileChange($event, 'banner')"
      style="display: none"
    />
  </div>
  <br />
  <h4>Logo für Übergangseffekte:</h4>
  <div class="uploadable-image" @click="openFilePicker('logo')">
    <img v-if="imageUrlLogo" :src="imageUrlLogo" alt="Logo" />
    <i class="fa-solid fa-cloud-arrow-up icon"></i>
    <input
      type="file"
      id="file-input-logo"
      @change="handleFileChange($event, 'logo')"
      style="display: none"
    />
  </div>
</template>
<script lang="ts">
import { defineComponent, ref, watchEffect } from "vue";
import { UploadService } from "@/services/upload.service";

export default defineComponent({
  name: "FunnelBanner",
  data() {
    return {
      imageUrlBanner: `http://localhost:3001/${this.companyNameCopy}/${this.funnelId}/banner.png`,
      imageUrlLogo: `http://localhost:3001/${this.companyNameCopy}/${this.funnelId}/logo.png`,
      uploadService: new UploadService(),
    };
  },
  props: {
    companyName: String,
    funnelId: String,
  },
  setup(props) {
    let companyNameCopy = ref<string>("");

    watchEffect(() => {
      const newVal = props.companyName;
      if (newVal) {
        companyNameCopy.value = newVal;
      }
    });

    return { companyNameCopy };
  },
  methods: {
    openFilePicker(inputIdSuffix: any) {
      this.uploadService.openFilePicker(inputIdSuffix);
    },
    uploadImage(fileType: any) {
      this.uploadService.uploadImage(fileType);
    },
    handleFileChange(event: any, filename: string) {
      const file = event.target.files[0];

      if (file) {
        const reader = new FileReader();

        reader.onload = (e: any) => {
          switch (filename) {
            case "banner":
              this.imageUrlBanner = e.target.result;
              break;
            case "logo":
              this.imageUrlLogo = e.target.result;
              break;
            default:
          }
        };

        reader.readAsDataURL(file);
      }
      this.uploadImage(filename);
    },
  },
});
</script>
