import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = (n: any) => (_pushScopeId("data-v-e4796ee0"),n=n(),_popScopeId(),n)
const _hoisted_1 = { key: 2 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_card_title = _resolveComponent("v-card-title")!
  const _component_v_text_field = _resolveComponent("v-text-field")!
  const _component_v_label = _resolveComponent("v-label")!
  const _component_v_list_item_title = _resolveComponent("v-list-item-title")!
  const _component_v_list_item_subtitle = _resolveComponent("v-list-item-subtitle")!
  const _component_v_list_item = _resolveComponent("v-list-item")!
  const _component_v_list = _resolveComponent("v-list")!
  const _component_v_card_text = _resolveComponent("v-card-text")!
  const _component_v_spacer = _resolveComponent("v-spacer")!
  const _component_v_btn = _resolveComponent("v-btn")!
  const _component_v_card_actions = _resolveComponent("v-card-actions")!
  const _component_v_card = _resolveComponent("v-card")!
  const _component_v_dialog = _resolveComponent("v-dialog")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(_component_v_dialog, {
      modelValue: _ctx.showModal,
      "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.showModal) = $event)),
      persistent: "",
      "max-width": _ctx.smAndDown ? 'var(--d-w-sd)' : 'var(--d-w-s)'
    }, {
      default: _withCtx(() => [
        _createVNode(_component_v_card, null, {
          default: _withCtx(() => [
            _createVNode(_component_v_card_title, null, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.modalTitle), 1)
              ]),
              _: 1
            }),
            _createVNode(_component_v_card_text, {
              class: _normalizeClass({
            'is-expanded': _ctx.filteredResults.length > 0,
            'is-contracted': _ctx.filteredResults.length === 0,
          })
            }, {
              default: _withCtx(() => [
                (_ctx.addCustomerModalLabel1)
                  ? (_openBlock(), _createBlock(_component_v_text_field, {
                      key: 0,
                      modelValue: _ctx.localInput1,
                      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.localInput1) = $event)),
                      label: _ctx.addCustomerModalLabel1,
                      variant: "outlined"
                    }, null, 8, ["modelValue", "label"]))
                  : _createCommentVNode("", true),
                (_ctx.addCustomerModalLabel2)
                  ? (_openBlock(), _createBlock(_component_v_text_field, {
                      key: 1,
                      modelValue: _ctx.localInput2,
                      "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.localInput2) = $event)),
                      label: _ctx.addCustomerModalLabel2,
                      variant: "outlined"
                    }, null, 8, ["modelValue", "label"]))
                  : _createCommentVNode("", true),
                (_ctx.showSearchResultsFromErp)
                  ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
                      (_ctx.filteredResults.length > 0)
                        ? (_openBlock(), _createBlock(_component_v_label, { key: 0 }, {
                            default: _withCtx(() => [
                              _createTextVNode("In Zvoove gefunden:")
                            ]),
                            _: 1
                          }))
                        : _createCommentVNode("", true),
                      _createVNode(_component_v_list, {
                        dense: "",
                        class: "scrollable-list"
                      }, {
                        default: _withCtx(() => [
                          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.filteredResults, (result, index) => {
                            return (_openBlock(), _createBlock(_component_v_list_item, {
                              key: index,
                              class: "clickable",
                              onClick: ($event: any) => (_ctx.displayCustomerNumber(result.customerNumber))
                            }, {
                              default: _withCtx(() => [
                                _createElementVNode("div", null, [
                                  _createVNode(_component_v_list_item_title, null, {
                                    default: _withCtx(() => [
                                      _createTextVNode(_toDisplayString(result.name), 1)
                                    ]),
                                    _: 2
                                  }, 1024),
                                  _createVNode(_component_v_list_item_subtitle, null, {
                                    default: _withCtx(() => [
                                      _createTextVNode(_toDisplayString(result.address), 1)
                                    ]),
                                    _: 2
                                  }, 1024)
                                ])
                              ]),
                              _: 2
                            }, 1032, ["onClick"]))
                          }), 128))
                        ]),
                        _: 1
                      })
                    ]))
                  : _createCommentVNode("", true)
              ]),
              _: 1
            }, 8, ["class"]),
            _createVNode(_component_v_card_actions, null, {
              default: _withCtx(() => [
                _createVNode(_component_v_spacer),
                _createVNode(_component_v_btn, {
                  color: "red-darken-4",
                  onClick: _ctx.closeModal
                }, {
                  default: _withCtx(() => [
                    _createTextVNode("Abbrechen")
                  ]),
                  _: 1
                }, 8, ["onClick"]),
                _createVNode(_component_v_btn, {
                  color: "green-darken-3",
                  onClick: _ctx.submitModal
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_ctx.modalActionButtonText), 1)
                  ]),
                  _: 1
                }, 8, ["onClick"])
              ]),
              _: 1
            })
          ]),
          _: 1
        })
      ]),
      _: 1
    }, 8, ["modelValue", "max-width"]),
    _createVNode(_component_v_dialog, {
      modelValue: _ctx.resultsModal,
      "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.resultsModal) = $event)),
      persistent: "",
      "max-width": _ctx.smAndDown ? 'var(--d-w-sd)' : 'var(--d-w-m)'
    }, {
      default: _withCtx(() => [
        _createVNode(_component_v_card, null, {
          default: _withCtx(() => [
            _createVNode(_component_v_card_title, null, {
              default: _withCtx(() => [
                _createTextVNode("Ergebnisse auswählen")
              ]),
              _: 1
            }),
            _createVNode(_component_v_card_text, null, {
              default: _withCtx(() => [
                _createVNode(_component_v_list, null, {
                  default: _withCtx(() => [
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.results, (result, index) => {
                      return (_openBlock(), _createBlock(_component_v_list_item, {
                        key: index,
                        onClick: ($event: any) => (_ctx.selectResult(result))
                      }, {
                        default: _withCtx(() => [
                          _createElementVNode("div", null, [
                            _createVNode(_component_v_list_item_title, null, {
                              default: _withCtx(() => [
                                _createTextVNode(_toDisplayString(result.generalData.name), 1)
                              ]),
                              _: 2
                            }, 1024),
                            _createVNode(_component_v_list_item_subtitle, null, {
                              default: _withCtx(() => [
                                _createTextVNode(_toDisplayString(result.addressAndCommunication.street) + ", " + _toDisplayString(result.addressAndCommunication.postalCode) + ", " + _toDisplayString(result.addressAndCommunication.city), 1)
                              ]),
                              _: 2
                            }, 1024)
                          ])
                        ]),
                        _: 2
                      }, 1032, ["onClick"]))
                    }), 128))
                  ]),
                  _: 1
                })
              ]),
              _: 1
            }),
            _createVNode(_component_v_card_actions, null, {
              default: _withCtx(() => [
                _createVNode(_component_v_spacer),
                _createVNode(_component_v_btn, {
                  color: "red-darken-4",
                  onClick: _ctx.closeResultsModal
                }, {
                  default: _withCtx(() => [
                    _createTextVNode("Schließen")
                  ]),
                  _: 1
                }, 8, ["onClick"])
              ]),
              _: 1
            })
          ]),
          _: 1
        })
      ]),
      _: 1
    }, 8, ["modelValue", "max-width"])
  ]))
}