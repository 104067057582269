<template>
  <v-container class="fill-height d-flex justify-center align-center">
    <v-row align="center" justify="center">
      <v-col cols="12" md="8" lg="3">
        <v-card
          color="var(--color-item)"
          class="my-10"
          border="sm"
          rounded="lg"
          elevation="4"
        >
          <v-card-item align="center" justify="center">
            <img
              src="@/assets/dispositioner-logo-light.svg"
              alt="Logo"
              width="100%"
            />
          </v-card-item>
          <v-form class="pa-5" @submit.prevent="authUser">
            <v-divider></v-divider>
            <v-card-title>Login</v-card-title>
            <v-text-field
              variant="outlined"
              v-model="username"
              label="Email"
              type="email"
              required
            ></v-text-field>
            <v-text-field
              variant="outlined"
              v-model="password"
              label="Passwort"
              type="password"
              required
            ></v-text-field>
            <v-row class="d-flex justify-space-between">
              <v-col>
                <a href="#" class="password-forgot">Passwort vergessen?</a>
              </v-col>
              <v-col class="text-right">
                <a href="/registration" class="register">Registrieren</a>
              </v-col>
            </v-row>
            <v-btn
              type="submit"
              color="var(--color-primary)"
              style="color: var(--color-font-light)"
              block
              class="mt-4 mb-6"
            >
              Einloggen
            </v-btn>
          </v-form>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { LoginService } from "@/services/api/login.service";
import ToastService from "@/services/toast.service";
import DialogService from "@/services/dialog.service";

export default defineComponent({
  name: "LoginView",
  data() {
    return {
      loginService: new LoginService(),
      username: "",
      password: "",
      currentRoute: "",
    };
  },
  created() {
    localStorage.removeItem("accessToken");
    this.currentRoute = this.$router.currentRoute.value.path;
  },
  mounted() {
    sessionStorage.removeItem("vuexState");
  },
  methods: {
    async authUser() {
      try {
        await this.loginService
          .login({ username: this.username, password: this.password })
          .then(() => {
            this.$store.dispatch("loadStoreInitialData");
            this.navigateToDashboard();
          });
      } catch (error: any) {
        switch (error.code) {
          case "ERR_BAD_REQUEST":
            ToastService.show("Name oder Passwort fehlerhaft");
            break;
          default:
        }
      }
    },
    navigateToDashboard() {
      this.$router.push("/dashboard");
    },
    showError(errorText: string) {
      DialogService.alert(errorText);
    },
  },
});
</script>

<style scoped lang="scss">
@import "./scss/styles.scss";

.fill-height {
  height: 100vh;
}
.password-forgot,
.register {
  color: var(--color-primary);
}

.password-forgot:hover,
.register:hover {
  color: var(--color-secondary);
}
</style>
