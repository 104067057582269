<!--src/components/disposition/elements/Checklist.vue-->
<template>
  <v-btn
    v-if="showOpenTasksReminderIcon"
    icon
    color="white"
    density="compact"
    variant="tonal"
    class="open-tasks-icon-btn"
    @mouseenter="showTooltip"
    @mouseleave="hideTooltip"
  >
    <v-icon class="open-tasks-icon" size="xs">fa-solid fa-list-ol</v-icon>
  </v-btn>
  <div class="d-flex justify-space-between">
    <v-btn
      v-if="hasCompletedTasks"
      icon
      density="compact"
      variant="text"
      class="my-2 text-medium-emphasis"
      @click="toggleShowCompletedTasks()"
    >
      <v-icon>
        {{
          showCompletedTasks ? "fa-solid fa-list-check" : "fa-solid fa-list"
        }}</v-icon
      >
      <v-tooltip activator="parent" location="bottom">{{
        showCompletedTasks
          ? "umstellen auf: nur offene Aufgaben anzeigen"
          : "umstellen auf: alle Aufgaben anzeigen"
      }}</v-tooltip>
    </v-btn>
    <div v-if="!hasCompletedTasks"><!--spacer--></div>
    <v-btn
      v-if="!showNewTaskInput"
      icon
      density="compact"
      variant="text"
      class="my-2 mr-3 text-medium-emphasis"
      @click="showNewTaskInput = true"
    >
      <v-icon> fas fa-circle-plus</v-icon>
      <v-tooltip activator="parent" location="bottom"
        >neue Aufgabe(n) zur Checkliste hinzufügen!</v-tooltip
      >
    </v-btn>
  </div>
  <v-list density="compact">
    <v-list-item v-for="(task, index) in filteredChecklist" :key="index">
      <div class="d-flex justify-space-between">
        <div>
          <div class="d-flex">
            <template v-if="editingTaskIndex === index">
              <v-text-field
                v-model="editableTaskEntry"
                density="compact"
                variant="plain"
                @keydown.enter="saveTaskEdit(index)"
              ></v-text-field>
            </template>
            <template v-else>
              <v-list-item-title :class="{ completed: task.completed }">
                {{ task.entry }}
              </v-list-item-title>
            </template>
            <v-btn
              icon
              class="mx-2 text-medium-emphasis"
              size="xsmall"
              density="compact"
              variant="text"
              v-if="editingTaskIndex === index"
              @click="saveTaskEdit(index)"
            >
              <v-icon size="xsmall">fas fa-check</v-icon>
            </v-btn>
            <v-btn
              icon
              class="mx-2 text-medium-emphasis"
              size="xsmall"
              density="compact"
              variant="text"
              v-if="
                !editingTaskIndex &&
                editingTaskIndex !== index &&
                canEditTask(task)
              "
              @click="editTask(index)"
            >
              <v-icon size="xsmall">fas fa-pencil</v-icon>
            </v-btn>
            <v-btn
              icon
              class="mr-5 text-medium-emphasis"
              size="xsmall"
              density="compact"
              variant="text"
              v-if="editingTaskIndex === index && canDeleteTask(task)"
              @click="deleteTask(index)"
            >
              <v-icon size="xsmall">fas fa-trash-can</v-icon>
            </v-btn>
          </div>
          <v-list-item-subtitle>
            erstellt: {{ task.createdBy }} {{ formatDate(task.created) }}
            {{ task.completedBy ? " | bearbeitet: " : "" }}
            {{ task.completedBy }}
          </v-list-item-subtitle>
        </div>
        <v-checkbox
          density="compact"
          class="mt-0 pa-0"
          v-model="task.completed"
          @update:model-value="setCompletedBy(task, index)"
        />
      </div>
    </v-list-item>
  </v-list>
  <v-text-field
    v-if="showNewTaskInput"
    v-model="newTask"
    class="mx-3"
    density="compact"
    variant="outlined"
    label="Neue Aufgabe zur Checkliste"
    @keydown.enter="addTask(newTask)"
  ></v-text-field>
</template>

<script lang="ts">
import { CheckList } from "@/models/checklist.model";
import { TooltipService } from "@/services/tooltip.service";
import moment from "moment";
import { defineComponent, PropType } from "vue";
import { mapGetters } from "vuex";

export default defineComponent({
  name: "CheckList",
  emits: ["updateChecklist"],
  props: {
    checklist: {
      type: Array as PropType<CheckList[]>,
      required: false,
      default: () => [],
    },
  },
  data() {
    return {
      localChecklist: this.checklist,
      newTask: "",
      showCompletedTasks: false,
      showNewTaskInput: false,
      editingTaskIndex: null as number | null,
      editableTaskEntry: "",
    };
  },
  watch: {
    checklist(newValue: CheckList[]) {
      this.localChecklist = Array.isArray(newValue) ? newValue : [];
    },
  },
  computed: {
    ...mapGetters(["user"]),
    filteredChecklist(): CheckList[] {
      if (this.showCompletedTasks) {
        return this.localChecklist;
      } else {
        return this.localChecklist.filter((task) => !task.completed);
      }
    },
    hasCompletedTasks(): boolean {
      return this.localChecklist.some((task) => task.completed);
    },
    openTasks(): string[] {
      return this.localChecklist
        .filter((task) => !task.completed)
        .map((task) => task.entry);
    },
    showOpenTasksReminderIcon(): boolean {
      return this.localChecklist.some((task) => !task.completed);
    },
  },
  methods: {
    addTask(task: string) {
      const newTask = this.createNewTask(task);
      this.localChecklist.push(newTask);
      this.newTask = "";
      this.updateChecklist();
    },
    createNewTask(task: string) {
      const newTask = {
        completed: false,
        completedBy: "",
        created: moment().format("YYYY-MM-DD HH:mm"),
        createdBy: `${this.user.forename} ${this.user.lastname}`,
        entry: task,
      };
      return newTask;
    },
    formatDate(date: string) {
      return moment(date).format("DD.MM.YYYY [um] HH:mm");
    },
    hideTooltip() {
      const existingTooltip = document.getElementById("custom-tooltip");
      if (existingTooltip) {
        existingTooltip.remove();
      }
    },
    showTooltip(event: any) {
      const htmlContent = `<h3>Checkliste</h3><br>● ${this.openTasks.join(
        "<br>● "
      )}`;
      TooltipService.showExpiredEvents(event, htmlContent);
    },
    toggleShowCompletedTasks() {
      this.showCompletedTasks = !this.showCompletedTasks;
    },
    setCompletedBy(task: CheckList, index: number) {
      const updatedTask = {
        ...task,
        completedBy: `${this.user.forename} ${this.user.lastname}`,
      };
      this.localChecklist[index] = updatedTask;
      this.updateChecklist();
    },
    canDeleteTask(task: CheckList): boolean {
      return !task.completedBy || this.user.role < 10;
    },
    canEditTask(task: CheckList): boolean {
      return this.user.role < 11 || !task.completedBy;
    },
    deleteTask(index: number) {
      this.localChecklist.splice(index, 1);
      this.updateChecklist();
    },
    editTask(index: number) {
      this.editingTaskIndex = index;
      this.editableTaskEntry = this.localChecklist[index].entry;
    },
    saveTaskEdit(index: number) {
      this.localChecklist[index].entry = this.editableTaskEntry;
      this.editingTaskIndex = null;
      this.updateChecklist();
    },
    updateChecklist() {
      this.$emit("updateChecklist", this.localChecklist);
    },
  },
});
</script>

<style scoped>
.open-tasks-icon-btn {
  position: absolute;
  bottom: -0.6rem;
  left: -0.4rem;
}
.open-tasks-icon {
  font-size: 0.6rem;
  animation: pulseAnimation 1s infinite, redBlinkAnimation 1s infinite,
    shakeAnimation 0.3s infinite;
  cursor: pointer;
}
</style>
