<template>
  <header v-if="isLoggedIn">
    <button
      v-if="getUserRole() === 0"
      @click="gimmeStore()"
      class="gimme-store-button"
    >
      GIMME STORE DATA
    </button>
    <v-spacer></v-spacer>
    <ZorstStatus
      v-if="$store.state.company.softwareIntegration.zorst"
    ></ZorstStatus>
    Theme:&nbsp;
    <v-btn density="compact" @click="setTheme()">{{
      themeLight ? "Light" : "Dark"
    }}</v-btn>
    &nbsp;

    <v-icon color="white" class="menu-icon" @click="toggleMenu"
      >fa-solid fa-bars</v-icon
    >
    <v-card rounded class="menu" :class="{ 'show-menu': showMenu }">
      <v-list
        style="
          background-color: var(--color-primary-dark);
          color: var(--color-accent);
        "
      >
        <v-list-item
          v-for="item in filteredItems"
          :key="item.text"
          class="d-flex menu-item"
          @click="toggleMenu(item.route)"
        >
          <template v-slot:prepend>
            <v-icon size="xs">{{ item.icon }}</v-icon>
          </template>
          <v-list-item-title>{{ item.text }}</v-list-item-title>
        </v-list-item>
      </v-list>
    </v-card>
  </header>
</template>

<script lang="ts">
import { useTheme } from "vuetify";
import { defineComponent } from "vue";
import { useRouter } from "vue-router";
import { UserRole } from "@/enums/user-role.enum";
import { UserHelperService } from "@/services/user-helper.service";
import { mapActions, mapGetters } from "vuex";
import ToastService from "@/services/toast.service";
import ZorstStatus from "./ZorstStatus.vue";

export default defineComponent({
  name: "TopHeader",
  components: {
    ZorstStatus,
  },
  props: {
    isLoggedIn: Boolean,
    showVersion: Boolean,
  },
  data() {
    return {
      showMenu: false,
      themeLight: true,
      menuItems: [
        {
          text: "Dashboard",
          route: "/dashboard",
          icon: "fa-solid fa-tachometer-alt",
        },
        {
          accessRoles: [UserRole.UberAdmin],
          text: "Firmenkunden",
          route: "/config/company",
          icon: "fa-solid fa-building",
        },
        { text: "Benutzer", route: "/config/user", icon: "fa-solid fa-user" },
        {
          text: "Niederlassungen",
          route: "/config/mandant",
          icon: "fa-solid fa-map-marker-alt",
        },
        {
          text: "Software & Schnittstellen",
          route: "/config/software-integration",
          icon: "fa-solid fa-plug",
        },
        {
          text: "Recurion.ai Einstellungen",
          route: "/config/ai",
          icon: "fa-solid fa-rocket",
        },
        { text: "Shop", route: "/shop", icon: "fa-solid fa-shopping-cart" },
        {
          text: "Version",
          route: "/version",
          icon: "fa-solid fa-info-circle",
          condition: "showVersion",
        },
        {
          text: "Ausloggen",
          route: "/logout",
          icon: "fa-solid fa-sign-out-alt",
        },
      ],
      userRole: UserHelperService.getInstance().getUserRole(),
    };
  },
  computed: {
    ...mapGetters(["storeHasToInit"]),
    filteredItems() {
      return this.menuItems.filter((item) => {
        if (!item.accessRoles) {
          return true;
        }
        return item.accessRoles.includes(this.userRole);
      });
    },
  },
  setup() {
    const router = useRouter();
    const theme = useTheme();

    return { router, theme };
  },
  created() {
    this.checkIfStoreHasToInit();
  },
  mounted() {
    document.addEventListener("click", this.handleClickOutside);
  },
  beforeUnmount() {
    document.removeEventListener("click", this.handleClickOutside);
  },
  methods: {
    ...mapActions(["loadStoreInitialData"]),
    async checkIfStoreHasToInit() {
      if (this.storeHasToInit) {
        await this.loadStoreInitialData().then(() => {
          ToastService.show(
            `Sichere Verbindung zu ${this.$store.state.company.name} hergestellt ...`
          );
        });
      }
    },
    getUserRole() {
      const userService = UserHelperService.getInstance();
      const userRole = userService.getUserRole();
      return userRole;
    },
    gimmeStore() {
      console.log(this.$store.state);
    },
    setTheme() {
      this.themeLight = !this.themeLight;
      this.theme.global.name.value = this.themeLight ? "light" : "dark";
    },
    toggleMenu(route: string) {
      this.showMenu = !this.showMenu;
      if (route) {
        this.router.push(route);
      }
    },
    handleClickOutside(event: any) {
      if (!this.$el.contains(event.target)) {
        this.showMenu = false;
      }
    },
  },
});
</script>

<style scoped>
header {
  align-items: center;
  background-color: var(--color-primary-dark);
  color: var(--color-accent);
  display: flex;
  justify-content: flex-end;
  height: var(--height-top-header);
  padding: 0.25rem 1rem;
  position: relative;
}

.menu-icon {
  color: var(--color-accent);
  cursor: pointer;
  font-size: 1.5rem;
}
.menu-icon:hover {
  scale: var(--small-scale-up);
  color: var(--color-font-light);
}

.menu {
  position: absolute;
  top: 0;
  right: 0;
  z-index: 100;
  width: 17rem;
  border-left: 2px solid var(--color-accent);
  border-bottom: 2px solid var(--color-accent);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
  transform: translateY(-100%);
  opacity: 0;
  visibility: hidden;
  transition: transform 0.3s ease-in-out, opacity 0.3s, visibility 0.3s;
}

.menu.show-menu {
  transform: translateY(0);
  opacity: 1;
  visibility: visible;
  transition-delay: 0s;
}

.menu-item:hover {
  color: var(--color-font-light);
  transform: scale(1.05);
  transition: transform 0.5s ease-in-out, color 0.3s ease-in-out;
}

.gimme-store-button {
  color: white;
}

@media only screen and (max-width: 1200px) {
  .gimme-store-button {
    display: none;
  }
}
</style>
