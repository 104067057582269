// services/openAi.service API Service for ChatGPT API
import axios from "axios";
import store from "../store/store"; // Importieren des Vuex Stores

// Initialisiere Axios-Instanzen ohne spezifische Konfiguration
const apiClientOpenAI = axios.create({
  headers: { "Content-Type": "application/json" },
});

// Funktion, um die API-Konfigurationen zu aktualisieren
export const updateOpenAiConfig = () => {
  const config = store.state.company.apiKeys;
  apiClientOpenAI.defaults.baseURL = config.baseURLOpenAI;
  apiClientOpenAI.defaults.headers.common[
    "Authorization"
  ] = `Bearer ${config.openAi}`;
};

// openAi API Funktion
export const ChatGPT = async ({
  prompt,
  model,
  temperature,
  role,
}: {
  prompt: string;
  model: string;
  temperature: number;
  role: string;
}) => {
  //(prompt, model, temperature, maxTokens, topP, frequencyPenalty, presencePenalty) => {
  /* das zeug schauen wir mal ob wir überhaupt brauchen:
  max_tokens: Dieser Parameter bestimmt die maximale Anzahl von Tokens (Wörtern oder Wortteilen), die das Modell generieren kann, wenn es auf eine Eingabeaufforderung (Prompt) antwortet. Ein höherer Wert ermöglicht längere Antworten, erfordert aber mehr Rechenzeit und Ressourcen.
  top_p (auch bekannt als "nucleus sampling"): Dieser Parameter steuert die Zufälligkeit der Antwort. Bei einem Wert von 1,0 wählt das Modell aus dem gesamten Satz möglicher nächster Wörter aus, was zu sehr kreativen, aber manchmal weniger kohärenten Antworten führen kann. Ein niedrigerer Wert wie 0,5 bedeutet, dass das Modell nur die wahrscheinlichsten nächsten Wörter berücksichtigt, was in der Regel zu kohärenteren, aber weniger kreativen Antworten führt.
  frequency_penalty: Dieser Parameter verringert die Wahrscheinlichkeit, dass das Modell Wörter wiederholt. Ein höherer Wert für diesen Parameter hilft, Redundanzen im Text zu vermeiden, kann aber auch zu weniger natürlichen oder flüssigen Antworten führen.
  presence_penalty: Dieser Parameter verringert die Wahrscheinlichkeit, dass das Modell Themen oder Begriffe wiederholt, die bereits im Text vorhanden sind. Ein höherer Wert fördert die Vielfalt und Kreativität in den Antworten, kann aber auch zu weniger relevanten oder zusammenhängenden Texten führen.
  */
  updateOpenAiConfig();
  const data = {
    model: model,
    messages: [{ role: role, content: prompt }],
    temperature: temperature,
    /*max_tokens: maxTokens,
      top_p: topP,
      frequency_penalty: frequencyPenalty,
      presence_penalty: presencePenalty*/
  };

  const response = await apiClientOpenAI.post("/v1/chat/completions", data);
  return response.data;
};
