<template>
  <div>
    <MainHeader
      @tab-selected="handleTabSelection"
      :showAdministrationTabs="true"
    ></MainHeader>
    <Documentation
      v-if="currentTab === HeaderTab.Documentation"
    ></Documentation>
    <PayFlowOverview
      v-if="currentTab === HeaderTab.PayFlowOverview"
    ></PayFlowOverview>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import Documentation from "@/components/documentation/Doku.vue";
import PayFlowOverview from "@/components/payflow/PayFlowOverview.vue";
import MainHeader from "@/components/top-header/Header.vue";
import { HeaderTab } from "@/enums/header-tabs.enum";

export default defineComponent({
  name: "DocumentationView",
  components: {
    MainHeader,
    Documentation,
    PayFlowOverview,
  },
  data() {
    return {
      currentTab: HeaderTab.Documentation as string,
      HeaderTab: HeaderTab,
    };
  },
  methods: {
    handleTabSelection(selectedTab: string) {
      this.currentTab = selectedTab;
    },
  },
});
</script>
