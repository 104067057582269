import { User } from "@/models/user.model";
import { UserService } from "./api/user.service";

type UserChangeListener = (newValue: boolean) => void;

export class UserHelperService {
  private static instance: UserHelperService;
  isLoggedIn = false;
  listeners: UserChangeListener[] = [];
  decodedToken: User | undefined = undefined;
  user: User | undefined = undefined;

  constructor() {
    this.updateIsLoggedIn();
  }

  public static getInstance(): UserHelperService {
    if (!UserHelperService.instance) {
      UserHelperService.instance = new UserHelperService();
    }
    return UserHelperService.instance;
  }

  updateIsLoggedIn(): any {
    this.isLoggedIn = !!localStorage.getItem("accessToken");
    this.notifyListeners();
  }

  decodeToken(token: string): User | null {
    if (this.decodedToken) return this.decodedToken;
    try {
      const base64Url = token.split(".")[1];
      const base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
      const jsonPayload = decodeURIComponent(
        atob(base64)
          .split("")
          .map(function (c) {
            return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
          })
          .join("")
      );
      this.decodedToken = JSON.parse(jsonPayload);
      return this.decodedToken as User;
    } catch (error) {
      console.error("Error decoding token:", error);
      return null;
    }
  }

  async getUser(): Promise<User> {
    if (!this.user) {
      const userService = new UserService();
      this.user = await userService.getUser();
    }
    return Promise.resolve(this.user as User);
  }

  setUser(user: User) {
    if (this.user?.username === user.username) {
      this.user = user;
    }
  }

  getUserRole(): number {
    if (this.user) {
      return this.user.role as number;
    }
    const accessToken = localStorage.getItem("accessToken");
    if (accessToken) {
      const user = this.decodeToken(accessToken);
      if (user) {
        return user.role as number;
      } else {
        console.error("Decoded user is null");
        return 100;
      }
    }
    console.error("Access token not found");
    return 100;
  }

  private notifyListeners(): void {
    this.listeners.forEach((listener) => listener(this.isLoggedIn));
  }

  onChange(callback: UserChangeListener) {
    this.listeners.push(callback);
  }

  removeFromListener(callback: UserChangeListener) {
    this.listeners.slice(this.listeners.indexOf(callback));
  }
}
