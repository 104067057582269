<template>
  <div>
    <v-dialog
      v-model="showModal"
      persistent
      :max-width="smAndDown ? 'var(--d-w-sd)' : 'var(--d-w-xxl)'"
      class="fill-height"
    >
      <v-card style="height: var(--d-h-full)" class="pa-0">
        <v-card-title>
          <v-tabs
            v-model="activeTab"
            bg-color="var(--color-primary)"
            style="color: var(--color-font-light)"
            slider-color="var(--color-primary)"
          >
            <v-tab v-for="(tab, index) in tabs" :key="index" :value="index">
              {{ tab }}
            </v-tab>
            <v-spacer></v-spacer>
            <v-tab @click="closeModal()"
              ><v-icon size="x-large">fa-solid fa-circle-xmark</v-icon></v-tab
            >
          </v-tabs>
        </v-card-title>
        <v-card-text>
          <v-tabs-window v-model="activeTab">
            <v-tabs-window-item
              v-for="(tab, index) in tabs"
              :key="index"
              :value="index"
            >
              <!--Tab general data + address-->
              <v-row v-if="index === 0">
                <v-card-title>Überschrift des Kandidaten</v-card-title>
                <v-divider class="mb-3"></v-divider>
                <v-col cols="12" md="6">
                  <v-text-field
                    v-if="
                      localCandidate.applications &&
                      localCandidate.applications.length > 0
                    "
                    v-model="
                      localCandidate.applications[
                        localCandidate.applications.length - 1
                      ].appliedAs
                    "
                    label="Beworben als"
                    variant="outlined"
                  />
                </v-col>
                <v-col cols="12" md="6"> </v-col>
                <v-card-title>Allgemeine Daten</v-card-title>
                <v-divider class="mb-3"></v-divider>
                <v-col cols="12" md="2">
                  <v-select
                    v-model="localCandidate.salutationCatalogId"
                    :items="salutations"
                    item-title="value"
                    item-value="label"
                    label="Anrede"
                    variant="outlined"
                  />
                </v-col>
                <v-col cols="12" md="5">
                  <v-text-field
                    v-model="localCandidate.firstName"
                    label="Vorname"
                    variant="outlined"
                  />
                </v-col>
                <v-col cols="12" md="5">
                  <v-text-field
                    v-model="localCandidate.lastName"
                    label="Nachname"
                    variant="outlined"
                  />
                </v-col>
                <v-col cols="12" md="4">
                  <v-text-field
                    v-model="localCandidate.addressStreet"
                    label="Straße"
                    variant="outlined"
                  />
                </v-col>
                <v-col cols="12" md="1">
                  <v-text-field
                    v-model="localCandidate.addressHouseNumber"
                    label="Hausnummer"
                    variant="outlined"
                  />
                </v-col>
                <v-col cols="12" md="3">
                  <v-text-field
                    v-model="localCandidate.addressPostalCode"
                    label="PLZ"
                    variant="outlined"
                  />
                </v-col>
                <v-col cols="12" md="4">
                  <v-text-field
                    v-model="localCandidate.addressCity"
                    label="Ort"
                    variant="outlined"
                  />
                </v-col>
                <v-col cols="12" md="3">
                  <v-text-field
                    label="Geburtsdatum"
                    v-model="formattedBirthDate"
                    variant="outlined"
                    type="date"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" md="3">
                  <v-text-field
                    v-model="localCandidate.birthPlace"
                    label="Geburtsort"
                    variant="outlined"
                  />
                </v-col>
                <v-col cols="12" md="3">
                  <v-text-field
                    v-model="localCandidate.birthName"
                    label="Geburtsname"
                    variant="outlined"
                  />
                </v-col>
                <v-col cols="12" md="3">
                  <v-select
                    v-model="localCandidate.maritalStatus"
                    :items="maritalStatus"
                    item-title="value"
                    item-value="label"
                    label="Familienstand"
                    variant="outlined"
                  />
                </v-col>
                <v-card-title>Kommunikationsmittel</v-card-title>
                <v-divider class="mb-3"></v-divider>

                <v-col
                  cols="12"
                  md="3"
                  v-for="(item, index) in localCandidate.communicationMeans"
                  :key="item._id"
                >
                  <v-card class="mb-4" variant="outlined">
                    <v-card-title class="d-flex">
                      <v-spacer></v-spacer>
                      <v-btn
                        variant="text"
                        icon
                        @click="removeCommunicationMethod(index)"
                      >
                        <v-icon>fa-solid fa-trash-can</v-icon>
                      </v-btn>
                    </v-card-title>
                    <v-card-text>
                      <v-select
                        v-model="item.type"
                        :items="communicationTypes"
                        item-title="label"
                        item-value="value"
                        label="Typ"
                        variant="outlined"
                      />
                      <v-text-field
                        v-model="item.description"
                        label="Beschreibung"
                        variant="outlined"
                      />
                      <v-text-field
                        v-model="item.value"
                        label="Wert"
                        variant="outlined"
                      />
                    </v-card-text>
                  </v-card>
                </v-col>

                <v-col cols="12" md="3">
                  <v-card class="mb-4" variant="outlined" border="dashed">
                    <v-card-title>Hinzufügen</v-card-title>
                    <v-card-text>
                      <v-select
                        v-model="newCommunicationMethod.type"
                        :items="communicationTypes"
                        item-title="label"
                        item-value="value"
                        label="Typ"
                        variant="outlined"
                      />
                      <v-text-field
                        v-model="newCommunicationMethod.description"
                        label="Beschreibung"
                        variant="outlined"
                      />
                      <v-text-field
                        v-model="newCommunicationMethod.value"
                        label="Wert"
                        variant="outlined"
                      />
                      <v-btn
                        color="green-darken-3"
                        @click="addCommunicationMethod"
                      >
                        Hinzufügen
                      </v-btn>
                    </v-card-text>
                  </v-card>
                </v-col>
              </v-row>
              <!--Tab Mobilität & Schichtbereitschaft-->
              <v-row v-else-if="index === 1">
                <v-card-title>Mobilität & Schichtbereitschaft</v-card-title>
                <v-divider class="mb-3"></v-divider>

                <v-col cols="12">
                  <v-card class="mb-4" variant="outlined">
                    <v-card-title> Mobilität </v-card-title>
                    <v-card-text>
                      {{ localCandidate.mobilityRadius }} km
                      <v-slider
                        class="mt-10"
                        v-model="localCandidate.mobilityRadius"
                        :max="100"
                        :min="0"
                        :step="0.5"
                        thumb-label="always"
                      ></v-slider>
                      <v-select
                        v-model="localCandidate.mobility"
                        :items="mobilities"
                        variant="outlined"
                        label="Beförderungsmittel"
                      ></v-select>
                    </v-card-text>
                  </v-card>
                </v-col>
                <v-col cols="12">
                  <v-card class="mb-4" variant="outlined">
                    <v-card-title> Schichtbereitschaft </v-card-title>
                    <v-card-text class="mt-5">
                      <v-select
                        v-model="localCandidate.shiftPreference"
                        :items="shiftPreferencesOption"
                        item-title="label"
                        item-value="label"
                        variant="outlined"
                        :label="localCandidate.shiftIcon"
                      ></v-select>
                    </v-card-text>
                  </v-card>
                </v-col>
              </v-row>
              <!--Tab Skills-->
              <v-row v-else-if="index === 2">
                <v-card-title>Skills</v-card-title>
                <v-divider class="mb-3"></v-divider>

                <v-col
                  cols="12"
                  v-for="group in skillGroups"
                  :key="group.groupDescription"
                >
                  <v-card class="mb-4" variant="outlined">
                    <v-card-title class="d-flex">
                      {{ group.groupDescription }}
                      <v-spacer></v-spacer>
                      <v-btn
                        icon
                        variant="text"
                        @click="removeSkillGroup(group)"
                      >
                        <v-icon>fa-solid fa-trash-can</v-icon>
                      </v-btn>
                    </v-card-title>
                    <v-card-text>
                      <v-row>
                        <v-col cols="12">
                          <v-chip-group
                            v-model="group.selectedSkills"
                            multiple
                            column
                          >
                            <v-chip
                              v-for="(skill, index) in group.skills"
                              :key="index"
                              color="var(--color-primary)"
                              :model-value="true"
                              close-icon="fa-solid fa-xmark"
                              :prepend-icon="
                                getAuspraegungIcon(skill.level, skill.type)
                              "
                              closable
                              @click:close="removeSkill(group, index)"
                              @click="toggleAuspraegung(skill)"
                            >
                              {{ skill.description }}
                            </v-chip>
                            <v-text-field
                              label="Neuer Skill"
                              rounded="pill"
                              variant="outlined"
                              density="compact"
                              v-model="group.newSkill"
                              style="max-width: 12rem"
                              @keyup.enter="addSkill(group)"
                            ></v-text-field>
                          </v-chip-group>
                        </v-col>
                      </v-row>
                    </v-card-text>
                  </v-card>
                </v-col>

                <v-col cols="12">
                  <v-card border="dashed" variant="outlined">
                    <v-card-title>
                      Neue Skill-Gruppe
                      <v-spacer></v-spacer>
                    </v-card-title>
                    <v-card-text>
                      <v-row>
                        <v-col cols="5">
                          <v-text-field
                            label="Skill-Gruppenbezeichnung"
                            variant="outlined"
                            v-model="newSkillGroup.groupDescription"
                            :error="!newSkillGroup.groupDescription"
                            error-messages="Erforderlich"
                          ></v-text-field>
                        </v-col>
                        <v-col cols="5">
                          <v-text-field
                            label="Erster Skill"
                            variant="outlined"
                            v-model="newSkillGroup.newSkill"
                            :error="!newSkillGroup.newSkill"
                            error-messages="Erforderlich"
                          ></v-text-field>
                        </v-col>
                        <v-col cols="2">
                          <v-btn
                            block
                            color="green-darken-3"
                            @click="addSkillGroup"
                            :disabled="
                              !newSkillGroup.groupDescription ||
                              !newSkillGroup.newSkill
                            "
                          >
                            <v-icon>fa-solid fa-plus</v-icon>
                            Hinzufügen
                          </v-btn>
                        </v-col>
                      </v-row>
                    </v-card-text>
                  </v-card>
                </v-col>
              </v-row>
              <!--Tab Lebenslauf-->
              <v-row v-else-if="index === 3">
                <v-card-title>Lebenslauf</v-card-title>
                <v-divider class="mb-3"></v-divider>
                <v-col cols="12">
                  <v-container class="d-flex ma-0 pa-0">
                    <v-btn @click="sortCvAsc" icon size="small" variant="text">
                      <v-icon> fa-solid fa-arrow-up </v-icon>
                    </v-btn>
                    <v-btn @click="sortCvDesc" icon size="small" variant="text">
                      <v-icon> fa-solid fa-arrow-down </v-icon>
                    </v-btn>
                    <v-spacer></v-spacer>
                    <v-btn @click="reloadCv" icon size="small" variant="text">
                      <v-icon> fa-solid fa-rotate-left </v-icon>
                    </v-btn>
                    <v-btn @click="aiFormatCv" icon size="small" variant="text">
                      <v-icon> fa-solid fa-rocket </v-icon>
                      <v-tooltip activator="parent" location="bottom"
                        >Lebenslauf mit AI vervollständigen</v-tooltip
                      >
                    </v-btn>
                  </v-container>
                </v-col>
                <v-col
                  cols="12"
                  v-for="(entry, index) in localCandidate.careerSteps"
                  :key="entry._id"
                >
                  <v-card class="mb-4" variant="outlined">
                    <v-card-title class="d-flex">
                      {{ entry.title }}
                      <v-spacer></v-spacer>
                      <v-btn icon variant="text" @click="removeEntry(index)">
                        <v-icon>fa-solid fa-trash-can</v-icon>
                      </v-btn>
                    </v-card-title>
                    <v-card-text>
                      <v-row>
                        <v-col cols="12" md="8">
                          <v-text-field
                            v-model="entry.title"
                            label="Titel"
                            variant="outlined"
                            density="compact"
                            class="my-0"
                          />
                        </v-col>
                        <v-col cols="12" md="4">
                          <v-select
                            v-model="entry.type"
                            :items="employmentTypes"
                            item-title="description"
                            item-value="systemName"
                            label="Typ"
                            variant="outlined"
                            density="compact"
                            class="my-0"
                          />
                        </v-col>
                        <v-col cols="12" md="6">
                          <v-text-field
                            v-model="entry.at"
                            label="Bei"
                            variant="outlined"
                            density="compact"
                            class="my-0"
                          />
                        </v-col>
                        <v-col cols="12" md="3">
                          <v-text-field
                            :value="
                              entry.from ? formatDateDisplay(entry.from) : ''
                            "
                            label="Von"
                            variant="outlined"
                            type="date"
                            density="compact"
                            class="my-0"
                            @change="
                              (event: any) =>
                                updateVonDate(entry, event.target.value)
                            "
                          />
                        </v-col>
                        <v-col cols="12" md="3">
                          <v-text-field
                            :value="
                              entry.until ? formatDateDisplay(entry.until) : ''
                            "
                            label="Bis"
                            variant="outlined"
                            type="date"
                            density="compact"
                            class="my-0"
                            @change="
                              (event: any) =>
                                updateBisDate(entry, event.target.value)
                            "
                          />
                        </v-col>
                        <v-col cols="12">
                          <v-textarea
                            v-model="entry.details"
                            label="Details"
                            variant="outlined"
                            rows="5"
                            density="compact"
                            class="my-0"
                          />
                        </v-col>
                      </v-row>
                    </v-card-text>
                    <v-card-actions>
                      <v-btn color="green-darken-3" @click="submitWithoutClose"
                        >Speichern</v-btn
                      >
                    </v-card-actions>
                  </v-card>
                </v-col>

                <v-col cols="12">
                  <v-card border="dashed" variant="outlined">
                    <v-card-title>
                      Neuer Eintrag
                      <v-spacer></v-spacer>
                    </v-card-title>
                    <v-card-text>
                      <v-row>
                        <v-col cols="12" md="8">
                          <v-text-field
                            v-model="newEntry.title"
                            label="Titel"
                            variant="outlined"
                            density="compact"
                            class="my-0"
                          />
                        </v-col>
                        <v-col cols="12" md="4">
                          <v-select
                            v-model="newEntry.type"
                            :items="employmentTypes"
                            item-title="description"
                            item-value="systemName"
                            label="Typ"
                            variant="outlined"
                            density="compact"
                            class="my-0"
                          />
                        </v-col>
                        <v-col cols="12" md="6">
                          <v-text-field
                            v-model="newEntry.at"
                            label="Bei"
                            variant="outlined"
                            density="compact"
                            class="my-0"
                          />
                        </v-col>
                        <v-col cols="12" md="3">
                          <v-text-field
                            v-model="newEntry.from"
                            label="Von"
                            variant="outlined"
                            type="date"
                            density="compact"
                            class="my-0"
                          />
                        </v-col>
                        <v-col cols="12" md="3">
                          <v-text-field
                            v-model="newEntry.until"
                            label="Bis"
                            variant="outlined"
                            type="date"
                            density="compact"
                            class="my-0"
                          />
                        </v-col>
                        <v-col cols="12">
                          <v-textarea
                            v-model="newEntry.details"
                            label="Details"
                            variant="outlined"
                            rows="5"
                            density="compact"
                            class="my-0"
                          />
                        </v-col>
                      </v-row>
                      <v-btn block color="green-darken-3" @click="addEntry">
                        <v-icon>fa-solid fa-calendar-plus</v-icon>
                        Neuer Eintrag hinzufügen
                      </v-btn>
                    </v-card-text>
                  </v-card>
                </v-col>
              </v-row>
              <!--Tab JOB Leads-->
              <v-row v-else-if="index === 4">
                <v-card-title class="mb-0">
                  <h2>
                    <i class="fa-solid fa-users-viewfinder"></i> JOB Leads
                  </h2>
                </v-card-title>
                <v-col cols="12" md="12">
                  <v-card-text class="d-flex">
                    <v-card-title
                      >Stellenanzeigen aus Anzeigendaten</v-card-title
                    >
                    <v-spacer></v-spacer>
                    <v-btn icon variant="text" @click="fetchJobAdLeads">
                      <img
                        src="@/assets/icon-index-anzeigendaten.png"
                        class="button-icon"
                      />
                      <v-tooltip activator="parent" location="bottom"
                        >neu von Anzeigendaten laden</v-tooltip
                      >
                    </v-btn>
                    <v-btn
                      icon
                      variant="text"
                      @click="$emit('generateAiSearchterm')"
                    >
                      <v-tooltip activator="parent" location="bottom"
                        >AI Suche starten</v-tooltip
                      >
                      <v-icon>fa-solid fa-rocket</v-icon>
                    </v-btn>
                    <v-btn
                      icon
                      variant="text"
                      @click="$emit('manualAnzeigendatenSearch')"
                    >
                      <v-tooltip activator="parent" location="bottom"
                        >manuelle Suche</v-tooltip
                      >
                      <v-icon>fa-solid fa-magnifying-glass</v-icon>
                    </v-btn>
                  </v-card-text>
                  <v-divider class="mb-3"></v-divider>
                  <v-list>
                    <v-list-item
                      v-for="(jobAd, index) in localCandidate.jobAdLeads"
                      :key="jobAd['AD-ID']"
                      class="job-ad ma-0 pa-0"
                      @click="openLink(jobAd.LINK ?? '')"
                    >
                      <v-container
                        class="ma-0 pa-0 d-flex justify-space-between align-center"
                      >
                        <v-container class="ma-0 pa-0">
                          <v-list-item-title>
                            {{ jobAd.POSITION }}
                          </v-list-item-title>
                          <v-list-item-subtitle>
                            {{ jobAd.DATE }}: {{ jobAd.LOCATION }} -
                            {{ jobAd["SOURCE-TEXT"] }}
                            <span v-html="jobAd['LIST-PRICE']"></span>
                          </v-list-item-subtitle>
                        </v-container>
                        <v-btn
                          icon
                          variant="text"
                          @click.stop="removeJobAd(index)"
                        >
                          <v-icon>fa-solid fa-trash-can</v-icon>
                        </v-btn>
                      </v-container>
                    </v-list-item>
                  </v-list>
                </v-col>
              </v-row>
            </v-tabs-window-item>
          </v-tabs-window>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="red-darken-4" @click="closeModal">Abbrechen</v-btn>
          <v-btn color="green-darken-3" @click="submitModal">Speichern</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script lang="ts">
import {
  Candidate,
  CareerStep,
  CommunicationMeans,
  Skill,
} from "@/models/candidate.model";
import { CandidateService } from "@/services/api/candidate.service";
import { CommunicationType } from "@/enums/communication-types.enum";
import { defineComponent, PropType } from "vue";
import { EmploymentType } from "@/enums/employment-types.enum";
import { formatCv } from "@/services/ai.service";
import { Mobility } from "@/enums/mobilities.enum";
import { Salutation } from "@/enums/salutation.enum";
import {
  ShiftPreferences,
  ShiftPreferencesIcon,
} from "@/enums/shift-preferences.enum";
import { SpinnerService } from "@/services/spinner.service";
import { useDisplay } from "vuetify";
import moment from "moment";
import ToastService from "@/services/toast.service";
import { MaritalStatus } from "@/enums/marital-status.enum";
import { getEnumOptions } from "@/helper/enum.helper";

export default defineComponent({
  name: "EditCandidateModal",
  emits: [
    "submit",
    "fetchJobAdLeads",
    "generateAiSearchterm",
    "manualAnzeigendatenSearch",
  ],
  props: {
    candidate: Object as PropType<Candidate>,
  },
  data() {
    const { smAndDown } = useDisplay();

    const employmentTypes = [
      {
        description: "Anstellung",
        systemName: EmploymentType.employed,
        sortOrder: 1,
      },
      {
        description: "Berufsausbildung",
        systemName: EmploymentType.professional_training,
        sortOrder: 2,
      },
      {
        description: "Weiterbildung",
        systemName: EmploymentType.further_training,
        sortOrder: 3,
      },
      {
        description: "Studium",
        systemName: EmploymentType.study,
        sortOrder: 4,
      },
      {
        description: "Wehr-/Zivildienst",
        systemName: EmploymentType.public_service,
        sortOrder: 5,
      },
      {
        description: "Schulbildung",
        systemName: EmploymentType.schooling,
        sortOrder: 6,
      },
      {
        description: "Sonstiges",
        systemName: EmploymentType.miscellaneous,
        sortOrder: 7,
      },
      {
        description: "Kurs",
        systemName: EmploymentType.course,
        sortOrder: 8,
      },
    ];

    return {
      smAndDown,
      activeTab: 0,
      showModal: false,
      tabs: [
        "Allgemein",
        "Mobilität & Schichtbereitschaft",
        "Skills",
        "Lebenslauf",
        "JOB Leads",
      ],
      localCandidate: {} as Candidate,
      employmentTypes,
      newEntry: {
        title: "",
        at: "",
        type: employmentTypes[0],
        from: "",
        until: "",
        details: "",
      },
      newSkillGroup: {
        groupDescription: "",
        newSkill: "",
      } as any,
      newCommunicationMethod: {
        type: null,
        description: "",
        value: "",
      },
      mobilityDistance: 0,
      shiftPreferences: [
        ShiftPreferences.TwoShift,
        ShiftPreferences.ThreeShift,
        ShiftPreferences.FourShift,
        ShiftPreferences.FiveShift,
        ShiftPreferences.Morning,
        ShiftPreferences.Afternoon,
        ShiftPreferences.DayShift,
        ShiftPreferences.PermanentNightShift,
      ],
      mobilities: [Mobility.OwnCar, Mobility.PublicTransport] as string[],
    };
  },
  computed: {
    formattedBirthDate: {
      get() {
        return this.localCandidate.birthDate
          ? moment(this.localCandidate.birthDate).format("YYYY-MM-DD")
          : "";
      },
      set(newValue: any) {
        this.localCandidate.birthDate = newValue;
      },
    },
    maritalStatus() {
      return getEnumOptions(MaritalStatus);
    },
    salutations() {
      return getEnumOptions(Salutation);
    },
    shiftPreferencesOption() {
      return getEnumOptions(ShiftPreferences);
    },
    communicationTypes() {
      const labels: { [key in CommunicationType]: string } = {
        [CommunicationType.Phone]: "Telefon",
        [CommunicationType.Mobile]: "Mobiltelefon",
        [CommunicationType.Fax]: "Fax",
        [CommunicationType.Email]: "E-Mail",
        [CommunicationType.Facebook]: "Facebook",
        [CommunicationType.LinkedIn]: "LinkedIn",
        [CommunicationType.Twitter]: "Twitter",
        [CommunicationType.Xing]: "Xing",
        [CommunicationType.Website]: "Webseite",
        [CommunicationType.Skype]: "Skype",
        [CommunicationType.WorkMobile]: "Diensthandy",
        [CommunicationType.WhatsApp]: "WhatsApp",
        [CommunicationType.EmergencyContact]: "Notfallkontakt",
      };

      return Object.keys(CommunicationType)
        .filter((key) => !isNaN(Number(key)))
        .map((key) => ({
          label: labels[key as unknown as CommunicationType],
          value: Number(key),
        }));
    },
    skillGroups() {
      const groups = this.localCandidate.skills.reduce(
        (acc: any, skill: Skill) => {
          if (
            skill.groupDescription !== "Mobilitäten" &&
            skill.groupDescription !== "Schichtbereitschaft"
          ) {
            const group = acc.find(
              (g: any) => g.groupDescription === skill.groupDescription
            );
            if (group) {
              group.skills.push(skill);
            } else {
              acc.push({
                groupDescription: skill.groupDescription,
                skills: [skill],
                newSkill: "",
              });
            }
          }
          return acc;
        },
        []
      );
      return groups;
    },
  },
  watch: {
    candidate: {
      handler(newVal) {
        this.localCandidate = { ...newVal } as Candidate;
        if (
          !this.localCandidate.careerSteps ||
          this.localCandidate.careerSteps.length === 0
        )
          return;
        this.localCandidate.careerSteps.forEach((entry) => {
          entry.from = entry.from
            ? moment(entry.from).format("YYYY-MM-DD")
            : "";
          entry.until = entry.until
            ? moment(entry.until).format("YYYY-MM-DD")
            : "";
        });
      },
      immediate: true,
      deep: true,
    },
    "localCandidate.shiftPreference": {
      handler(newVal) {
        this.localCandidate.shiftIcon = this.getShiftIcon(newVal);
      },
      immediate: true,
    },
  },
  methods: {
    addCommunicationMethod() {
      if (
        this.newCommunicationMethod.type !== null &&
        this.newCommunicationMethod.description &&
        this.newCommunicationMethod.value
      ) {
        this.localCandidate.communicationMeans.push({
          description: this.newCommunicationMethod.description,
          type: this.newCommunicationMethod.type as CommunicationType,
          value: this.newCommunicationMethod.value,
        } as CommunicationMeans);
        this.newCommunicationMethod = {
          type: null,
          description: "",
          value: "",
        };
      }
    },
    addEntry() {
      if (this.newEntry.title && this.newEntry.at) {
        const selectedType = this.newEntry.type;

        this.localCandidate.careerSteps.push({
          degree: "",
          title: this.newEntry.title,
          at: this.newEntry.at,
          type: {
            description: selectedType.description,
            systemName: this.getEnumKeyByEnumValue(
              EmploymentType,
              selectedType.systemName
            ),
            sortOrder: selectedType.sortOrder,
          },
          from: moment(this.newEntry.from).format(
            "YYYY-MM-DDTHH:mm:ss.SSSSSSS[Z]"
          ),
          until: moment(this.newEntry.until).format(
            "YYYY-MM-DDTHH:mm:ss.SSSSSSS[Z]"
          ),
          details: this.newEntry.details,
        } as CareerStep);

        this.newEntry = {
          title: "",
          at: "",
          type: this.employmentTypes[0],
          from: "",
          until: "",
          details: "",
        };
      }
    },
    addNewSkill(group: any, groupDescription: string, skillName: string) {
      this.newSkillGroup.groupDescription = groupDescription;
      this.newSkillGroup.newSkill = skillName;
      this.addSkill(group);
    },
    addSkill(group: any) {
      if (group.newSkill) {
        const newSkill: Skill = {
          type: 2,
          level: 5,
          description: group.newSkill,
          groupDescription: group.groupDescription,
        };
        this.localCandidate.skills.push(newSkill);
        group.skills.push(newSkill);
        group.newSkill = "";
      }
    },
    addSkillGroup() {
      if (this.newSkillGroup.groupDescription && this.newSkillGroup.newSkill) {
        const newGroup = {
          groupDescription: this.newSkillGroup.groupDescription,
          skills: [
            {
              type: 2,
              level: 5,
              description: this.newSkillGroup.newSkill,
              groupDescription: this.newSkillGroup.groupDescription,
            },
          ],
          newSkill: "",
        };
        this.skillGroups.push(newGroup);
        this.localCandidate.skills.push({
          type: 2,
          level: 5,
          description: this.newSkillGroup.newSkill,
          groupDescription: this.newSkillGroup.groupDescription,
        });
        this.newSkillGroup = {
          groupDescription: "",
          newSkill: "",
        };
      }
    },
    async aiFormatCv() {
      SpinnerService.showSpinner();
      try {
        const birthdate = this.localCandidate.birthDate;
        const cvData = this.localCandidate.careerSteps;
        const newCvData = await formatCv(birthdate, cvData);
        this.localCandidate.careerSteps = newCvData;
      } catch (error) {
        ToastService.showError("KI Verarbeitung hat nicht geklappt");
      } finally {
        SpinnerService.removeSpinner();
      }
    },
    closeModal() {
      this.showModal = false;
    },
    fetchJobAdLeads() {
      this.$emit("fetchJobAdLeads");
    },
    formatDateDisplay(date: string): string {
      return date ? moment(date).format("YYYY-MM-DD") : "";
    },
    getAuspraegungIcon(auspraegung: number | null, art: number | null): string {
      if (art === 1) {
        if (auspraegung === 1) return "fa-solid fa-user-xmark";
        if (auspraegung === 2) return "fa-solid fa-user-check";
        if (auspraegung === 3) return "fa-solid fa-user-graduate";
      } else if (art === 2) {
        if (auspraegung === 4) return "fa-solid fa-xmark";
        if (auspraegung === 5) return "fa-solid fa-check";
      }
      return "";
    },
    getEnumKeyByEnumValue(myEnum: any, enumValue: number | string): string {
      let keys = Object.keys(myEnum).filter((x) => myEnum[x] === enumValue);
      return keys.length > 0 ? keys[0] : "";
    },
    getShiftIcon(shiftPreference: ShiftPreferences) {
      switch (shiftPreference) {
        case ShiftPreferences.TwoShift:
          return ShiftPreferencesIcon.TwoShift;
        case ShiftPreferences.ThreeShift:
          return ShiftPreferencesIcon.ThreeShift;
        case ShiftPreferences.FourShift:
          return ShiftPreferencesIcon.FourShift;
        case ShiftPreferences.FiveShift:
          return ShiftPreferencesIcon.FiveShift;
        case ShiftPreferences.Morning:
          return ShiftPreferencesIcon.Morning;
        case ShiftPreferences.Afternoon:
          return ShiftPreferencesIcon.Afternoon;
        case ShiftPreferences.DayShift:
          return ShiftPreferencesIcon.DayShift;
        case ShiftPreferences.PermanentNightShift:
          return ShiftPreferencesIcon.PermanentNightShift;
        default:
          return ShiftPreferencesIcon.None;
      }
    },
    openLink(link: string) {
      if (link) {
        window.open(link, "_blank", "width=950,height=900");
      }
    },
    openModal() {
      this.showModal = true;
    },
    reloadCv() {
      if (this.candidate && this.candidate?.careerSteps)
        this.localCandidate.careerSteps = this.candidate?.careerSteps;
    },
    removeCommunicationMethod(index: number) {
      this.localCandidate.communicationMeans.splice(index, 1);
    },
    removeEntry(index: number) {
      this.localCandidate.careerSteps.splice(index, 1);
    },
    removeJobAd(index: number) {
      if (this.localCandidate.jobAdLeads) {
        this.localCandidate.jobAdLeads.splice(index, 1);
      }
    },
    removeSkill(group: any, index: number) {
      this.localCandidate.skills = this.localCandidate.skills.filter(
        (skill: Skill) => skill.description !== group.skills[index].description
      );
      group.skills.splice(index, 1);
    },
    removeSkillGroup(group: any) {
      this.localCandidate.skills = this.localCandidate.skills.filter(
        (skill: Skill) => skill.groupDescription !== group.groupDescription
      );
      this.skillGroups = this.skillGroups.filter(
        (g: any) => g.groupDescription !== group.groupDescription
      );
    },
    async saveCandidate() {
      const apiService = new CandidateService();
      if (this.localCandidate) {
        await apiService.updateCandidateByUuid(
          this.localCandidate.uuid,
          this.localCandidate
        );
      }
    },
    sortCvAsc() {
      this.localCandidate.careerSteps.sort((a, b) => {
        const dateA = a.from ? new Date(a.from).getTime() : Infinity;
        const dateB = b.from ? new Date(b.from).getTime() : Infinity;
        return dateA - dateB;
      });
    },
    sortCvDesc() {
      this.localCandidate.careerSteps.sort((a, b) => {
        const dateA = a.from ? new Date(a.from).getTime() : -Infinity;
        const dateB = b.from ? new Date(b.from).getTime() : -Infinity;
        return dateB - dateA;
      });
    },
    submitWithoutClose() {
      this.saveCandidate().then(() => {
        this.$emit("submit");
      });
    },
    async submitModal() {
      this.saveCandidate().then(() => {
        this.$emit("submit");
        this.closeModal();
      });
    },
    toggleAuspraegung(skill: any) {
      if (skill.level === undefined || skill.level === null) {
        skill.level = 1;
        skill.type = 1;
      } else {
        skill.level++;
        if (skill.level > 5) {
          skill.level = 1;
        }
        if (skill.level >= 1 && skill.level <= 3) {
          skill.type = 1;
        } else if (skill.level >= 4 && skill.level <= 5) {
          skill.type = 2;
        }
      }
    },
    updateBisDate(entry: any, date: string) {
      entry.until = date
        ? moment(date).format("YYYY-MM-DDTHH:mm:ss.SSSSSSS[Z]")
        : "";
    },
    updateVonDate(entry: any, date: string) {
      entry.from = date
        ? moment(date).format("YYYY-MM-DDTHH:mm:ss.SSSSSSS[Z]")
        : "";
    },
  },
});
</script>

<style scoped>
.job-ad {
  cursor: pointer;
}

.job-ad:hover {
  background-color: var(--v-list-item-hover-background-color);
}

.button-icon {
  height: 1.5rem;
  width: 1.5rem;
  margin-left: 0.5rem;
  margin-right: 0.5rem;
}
</style>
