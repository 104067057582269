<template>
  <v-form>
    <v-row>
      <v-col>
        <v-text-field
          label="server"
          v-model="emailConfigCopy.server"
          variant="outlined"
        ></v-text-field>
        <v-text-field
          label="port"
          v-model="emailConfigCopy.port"
          type="number"
          variant="outlined"
        ></v-text-field>
        <v-text-field
          label="username"
          v-model="emailConfigCopy.username"
          variant="outlined"
        ></v-text-field>
        <v-text-field
          label="from"
          v-model="emailConfigCopy.from"
          variant="outlined"
        ></v-text-field>
        <v-text-field
          label="password"
          v-model="emailConfigCopy.password"
          variant="outlined"
        ></v-text-field>
      </v-col>
    </v-row>
  </v-form>
</template>

<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
  name: "EmailServerConfigForm",
  props: {
    modelValue: Object,
  },
  data() {
    const emailConfigCopy = this.modelValue || {};

    return {
      emailConfigCopy,
    };
  },
  emits: ["update:modelValue"],
  watch: {
    modelValue: {
      handler(model: any) {
        this.updateModel(model);
      },
      deep: true,
    },
  },
  methods: {
    updateModel(model: any) {
      this.emailConfigCopy = model;
    },
    toDisplayName(camelCaseString: string): string {
      // Teilt den String an den Stellen, wo ein Großbuchstabe steht, mit Ausnahme des ersten Zeichens
      const words: string[] = [];
      let startIndexOfWord = 0;

      for (let i = 1; i < camelCaseString.length; i++) {
        if (camelCaseString[i].toUpperCase() === camelCaseString[i]) {
          words.push(camelCaseString.substring(startIndexOfWord, i));
          startIndexOfWord = i;
        }
      }

      // Fügt das letzte Wort hinzu
      words.push(camelCaseString.substring(startIndexOfWord));

      // Kombiniert die Wörter mit Leerzeichen und macht das erste Zeichen jedes Wortes groß
      const displayName = words
        .map((word) => word[0].toUpperCase() + word.substring(1))
        .join(" ");

      return displayName;
    },
  },
});
</script>
