import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, mergeProps as _mergeProps, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = (n: any) => (_pushScopeId("data-v-5d63a738"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "pa-0 text-body-2" }
const _hoisted_2 = { class: "ml-1 font-weight-light text-caption text-medium-emphasis" }
const _hoisted_3 = { class: "mx-1 font-weight-light text-caption text-medium-emphasis" }
const _hoisted_4 = { class: "item-collapsed" }
const _hoisted_5 = {
  key: 0,
  class: "hide-on-inactive-column"
}
const _hoisted_6 = {
  key: 1,
  class: "communication-icons-container"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_card_title = _resolveComponent("v-card-title")!
  const _component_v_divider = _resolveComponent("v-divider")!
  const _component_v_container = _resolveComponent("v-container")!
  const _component_v_card_subtitle = _resolveComponent("v-card-subtitle")!
  const _component_v_spacer = _resolveComponent("v-spacer")!
  const _component_zvooveOneLinkMenu = _resolveComponent("zvooveOneLinkMenu")!
  const _component_PhoneClient = _resolveComponent("PhoneClient")!
  const _component_WhatsAppClient = _resolveComponent("WhatsAppClient")!
  const _component_MailClient = _resolveComponent("MailClient")!
  const _component_Assignments = _resolveComponent("Assignments")!
  const _component_Checklist = _resolveComponent("Checklist")!
  const _component_PayFlowEmployee = _resolveComponent("PayFlowEmployee")!
  const _component_Timeline = _resolveComponent("Timeline")!
  const _component_SelectionPicker = _resolveComponent("SelectionPicker")!
  const _component_v_card = _resolveComponent("v-card")!
  const _component_v_hover = _resolveComponent("v-hover")!

  return (_openBlock(), _createBlock(_component_v_container, { class: "ma-0 pa-0" }, {
    default: _withCtx(() => [
      _createVNode(_component_v_hover, null, {
        default: _withCtx(({ isHovering, props }) => [
          (_ctx.isFullyCollapsed)
            ? (_openBlock(), _createBlock(_component_v_container, {
                key: 0,
                class: "ma-0 pa-0 item",
                color: isHovering ? 'var(--color-item-hover)' : 'var(--color-item)'
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_v_card_title, { class: "d-flex justify-space-between pa-0" }, {
                    default: _withCtx(() => [
                      _createElementVNode("span", _hoisted_1, [
                        _createElementVNode("span", _hoisted_2, _toDisplayString(_ctx.employee.employeeNumber), 1),
                        _createTextVNode(" " + _toDisplayString(_ctx.employee.firstName) + " " + _toDisplayString(_ctx.employee.lastName), 1)
                      ]),
                      _createElementVNode("span", _hoisted_3, _toDisplayString(_ctx.employee.address.city), 1)
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_v_divider, { class: "my-1" })
                ]),
                _: 2
              }, 1032, ["color"]))
            : _createCommentVNode("", true),
          (!_ctx.isFullyCollapsed)
            ? (_openBlock(), _createBlock(_component_v_card, _mergeProps({
                key: 1,
                class: "item border-sm pa-2 mb-2",
                elevation: 2,
                "rounded-lg": ""
              }, props, {
                color: isHovering ? 'var(--color-item-hover)' : 'var(--color-item)',
                class: {
            'fully-collapsed': _ctx.isFullyCollapsed,
            expanded: _ctx.isExpanded,
            'fully-expanded': _ctx.isFullyExpanded,
          },
                onDblclick: _ctx.toggleFullExpansion
              }), {
                default: _withCtx(() => [
                  _createElementVNode("div", _hoisted_4, [
                    _createVNode(_component_v_card_title, { class: "pa-0 text-body-2" }, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(_ctx.employee.firstName) + " " + _toDisplayString(_ctx.employee.lastName), 1)
                      ]),
                      _: 1
                    }),
                    _createVNode(_component_v_card_subtitle, { class: "pl-0" }, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(_ctx.employee.address.postalCode) + " " + _toDisplayString(_ctx.employee.address.city) + " (" + _toDisplayString(_ctx.employee.employeeNumber) + ") ", 1)
                      ]),
                      _: 1
                    })
                  ]),
                  _createVNode(_component_v_divider),
                  _createElementVNode("button", {
                    onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.toggleExpansion && _ctx.toggleExpansion(...args))),
                    class: "toggle-button"
                  }, _toDisplayString(_ctx.isExpanded ? "▲" : "▼"), 1),
                  (_ctx.isActive)
                    ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
                        _createElementVNode("div", {
                          class: "employee-status",
                          onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.openPicker(_ctx.ModalMode.employeeStatus)))
                        }, _toDisplayString(_ctx.getEmployeeState(_ctx.employee.employeeStatus)), 1)
                      ]))
                    : _createCommentVNode("", true),
                  (_ctx.isExpanded)
                    ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
                        _createVNode(_component_v_spacer),
                        _createVNode(_component_zvooveOneLinkMenu, {
                          zvooveOneEmployeeNumber: _ctx.employee.employeeNumber,
                          onGetErpData: _cache[2] || (_cache[2] = ($event: any) => (_ctx.updateEmployeeFromErp())),
                          onPutErpData: _cache[3] || (_cache[3] = ($event: any) => (_ctx.updateErpFromEmployee()))
                        }, null, 8, ["zvooveOneEmployeeNumber"]),
                        _createVNode(_component_PhoneClient, {
                          employee: _ctx.employee,
                          phoneNumbers: _ctx.filteredPhoneNumbers,
                          onCollapseParentItem: _ctx.collapseItem
                        }, null, 8, ["employee", "phoneNumbers", "onCollapseParentItem"]),
                        _createVNode(_component_WhatsAppClient, {
                          employee: _ctx.employee,
                          phoneNumbers: _ctx.filteredPhoneNumbers,
                          AiMessageType: 'whatsAppCandidate',
                          salutation: _ctx.employee.letterSalutation,
                          message: _ctx.message.body,
                          signature: _ctx.signatureWhatsApp(),
                          onCollapseParentItem: _ctx.collapseItem
                        }, null, 8, ["employee", "phoneNumbers", "salutation", "message", "signature", "onCollapseParentItem"]),
                        _createVNode(_component_MailClient, {
                          employee: _ctx.employee,
                          emailAddresses: _ctx.filteredEmail,
                          AiMessageType: 'mailCandidate',
                          emailObject: _ctx.message,
                          signature: _ctx.signatureMail(),
                          onCollapseParentItem: _ctx.collapseItem
                        }, null, 8, ["employee", "emailAddresses", "emailObject", "signature", "onCollapseParentItem"])
                      ]))
                    : _createCommentVNode("", true),
                  _createVNode(_component_v_divider),
                  (_ctx.isExpanded)
                    ? (_openBlock(), _createBlock(_component_Assignments, {
                        key: 2,
                        isEmployee: true,
                        assignments: _ctx.employee?.assignments,
                        onUpdateAssignments: _cache[4] || (_cache[4] = ($event: any) => (_ctx.getPlacements()))
                      }, null, 8, ["assignments"]))
                    : _createCommentVNode("", true),
                  _createVNode(_component_Checklist, {
                    checklist: _ctx.employee.checklist || [],
                    onUpdateChecklist: _ctx.updateChecklist
                  }, null, 8, ["checklist", "onUpdateChecklist"]),
                  _createVNode(_component_v_divider),
                  _createVNode(_component_v_divider),
                  (
              _ctx.softwareIntegration.payFlow && (_ctx.isExpanded || _ctx.isFullyExpanded)
            )
                    ? (_openBlock(), _createBlock(_component_PayFlowEmployee, {
                        key: 3,
                        employeeNumber: _ctx.employee.employeeNumber,
                        firstName: _ctx.employee.firstName,
                        lastName: _ctx.employee.lastName,
                        mandants: _ctx.employee.mandants
                      }, null, 8, ["employeeNumber", "firstName", "lastName", "mandants"]))
                    : _createCommentVNode("", true),
                  (_ctx.isExpanded)
                    ? (_openBlock(), _createBlock(_component_Timeline, {
                        key: 4,
                        employee: _ctx.employee,
                        mandant: _ctx.matchedMandantUuid || ''
                      }, null, 8, ["employee", "mandant"]))
                    : _createCommentVNode("", true),
                  (_ctx.pickerDialog)
                    ? (_openBlock(), _createBlock(_component_SelectionPicker, {
                        key: 5,
                        pickerItems: _ctx.pickerItems,
                        showDialog: _ctx.pickerDialog,
                        dialogTitle: _ctx.pickerTitle,
                        "onUpdate:showDialog": _cache[5] || (_cache[5] = ($event: any) => (_ctx.pickerDialog = $event)),
                        onItemSelected: _ctx.saveSelection
                      }, null, 8, ["pickerItems", "showDialog", "dialogTitle", "onItemSelected"]))
                    : _createCommentVNode("", true)
                ]),
                _: 2
              }, 1040, ["color", "class", "onDblclick"]))
            : _createCommentVNode("", true)
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}