// main.ts

import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store/store";
import "vuetify/styles";
import { createVuetify } from "vuetify";
import { aliases, fa } from "vuetify/iconsets/fa-svg";
import * as components from "vuetify/components";
import * as directives from "vuetify/directives";
import "vue-cal/dist/vuecal.css";
import "vue-cal/dist/i18n/de.es";
import { mdi } from "vuetify/iconsets/mdi";
import { Api } from "./services/api/api.class";
import longpress from "./directives/longpress";
import CKEditor from "@ckeditor/ckeditor5-vue";

const vuetify = createVuetify({
  components,
  directives,
  icons: {
    defaultSet: "mdi",
    aliases,
    sets: {
      fa,
      mdi,
    },
  },
});

let app: any;
store
  .dispatch("loadConfig")
  .then(() => {
    init(app);
  })
  .catch((error) => {
    console.debug("Failed to load config:", error);
    init(app);
  });

function init(app: any) {
  const api = Api.getInstance();
  api.setInterceptor();
  app = createApp(App).use(vuetify).use(store).use(CKEditor).use(router);
  app.directive("longpress", longpress);
  app.mount("#app");
}
