<!--src/components/disposition/CustomerColumn.vue-->
<template>
  <div class="column">
    <div v-if="!isActive" class="spacer-dispo-column"></div>
    <div v-if="isActive" class="header-dispo-column">
      <div>
        <FilterMenu>
          <template #filter-menu-left-column>
            <div class="status-options">
              <v-checkbox
                density="compact"
                v-for="status in customerStates"
                :key="status"
                v-model="selectedStatuses"
                :label="status"
                :value="status"
              ></v-checkbox>
              <div class="d-flex">
                <v-text-field
                  v-model="searchTerm"
                  density="compact"
                  variant="outlined"
                  label="Suchbegriff"
                  @keydown.enter="filterSearchterm(searchTerm)"
                >
                  <v-tooltip activator="parent" location="top"
                    >Einen oder mehrere Suchbegriffe eingeben und mit Enter
                    bestätigen</v-tooltip
                  >
                </v-text-field>
                <v-btn
                  class="mx-2"
                  density="compact"
                  variant="text"
                  icon
                  @click="loadCustomers()"
                >
                  <v-icon> fa-solid fa-arrows-rotate </v-icon>
                  <v-tooltip activator="parent" location="top"
                    >alle Firmen wieder anzeigen</v-tooltip
                  >
                </v-btn>
              </div>
            </div>
          </template>
          <template #filter-menu-right-column>
            <div class="mandants-options">
              <v-checkbox
                density="compact"
                v-for="mandant in mandants"
                :key="mandant.uuid"
                v-model="selectedMandants"
                :label="mandant.name"
                :value="mandant.uuid"
              ></v-checkbox>
            </div>
          </template>
        </FilterMenu>
      </div>
      <v-range-slider
        v-model="sliderCustomerStatesValue"
        :step="1"
        :ticks="customerStatesLabels"
        max="3"
        min="0"
        show-ticks="always"
        tick-size="10"
        color="var(--color-primary)"
        @update:model-value="updateselectedStatuses(sliderCustomerStatesValue)"
      >
      </v-range-slider>
      <v-menu>
        <template v-slot:activator="{ props }">
          <v-btn icon variant="text" v-bind="props"
            ><v-icon size="large" class="filter-icon"
              >fa-solid fa-circle-plus</v-icon
            >
            <v-tooltip activator="parent" location="top left"
              >neuen Kunden anlegen</v-tooltip
            ></v-btn
          >
        </template>
        <v-card>
          <v-card-title class="">Kunde / Interessent</v-card-title>
          <v-card-subtitle class="mb-2"
            >aus Quelle importieren:</v-card-subtitle
          >
          <v-list dense>
            <v-list-item
              v-if="softwareIntegration.zvooveOne"
              @click="openAddCustomerModal(ModalMode.zvooveOne)"
            >
              <div>
                <img
                  src="@/assets/icon-zvoove-one.svg"
                  class="context-menu-icons"
                />
                ERP One
              </div>
            </v-list-item>
            <v-list-item
              v-if="softwareIntegration.pdHub"
              @click="openAddCustomerModal(ModalMode.pdHub)"
            >
              <div>
                <img
                  src="@/assets/icon-pd-hub.svg"
                  class="context-menu-icons"
                />
                PD-Hub
              </div>
            </v-list-item>
            <v-list-item
              v-if="softwareIntegration.indexAnzeigendaten"
              @click="openAddCustomerModal(ModalMode.indexAnzeigendaten)"
            >
              <div>
                <img
                  src="@/assets/icon-index-anzeigendaten.png"
                  class="context-menu-icons"
                />
                INDEX Anzeigendaten
              </div>
            </v-list-item>
            <v-list-item @click="openAddCustomerModal(ModalMode.googleSearch)">
              <div>
                <i class="fa-brands fa-google context-menu-icons"></i>
                Kunde anlegen
              </div>
            </v-list-item>
            <v-list-item
              class="mt-10"
              v-if="
                softwareIntegration.zvooveOne &&
                loggedInMandantUuids.length > 0 &&
                user.role <= 10
              "
              @click="getAllCustomerFromERP"
            >
              <div>
                <img
                  src="@/assets/icon-zvoove-one.svg"
                  class="context-menu-icons"
                />
                alle aus ERP One
              </div>
            </v-list-item>
            <v-list-item
              class="mt-10"
              v-if="
                softwareIntegration.pdHub &&
                loggedInMandantUuids.length > 0 &&
                user.role <= 10
              "
              @click="getAllCustomerFromERP"
            >
              <div>
                <img
                  src="@/assets/icon-pd-hub.svg"
                  class="context-menu-icons"
                />
                alle aus PD-Hub
              </div>
            </v-list-item>
          </v-list>
        </v-card>
      </v-menu>
    </div>
    <draggable
      :list="customers"
      group="customer"
      @start="dragStart"
      @end="dragEnd"
      item-key="id"
    >
      <template #item="{ element }">
        <CustomerItem
          class="longpress"
          :customer="element"
          :interComponentMessage="interComponentMessage"
          :isActive="isActive"
          :isFullyCollapsed="minimizeAllItems"
          :lastUpdateTimeline="lastUpdateTimeline"
          :loggedInMandantUuids="loggedInMandantUuids"
          :mandants="mandants"
          :softwareIntegration="softwareIntegration"
          :user="user"
          @contextmenu.prevent="openContextMenu($event, element)"
          @longpress="openContextMenu($event, element)"
          @createInErp="createExitstingCustomerInErp"
          @insertUpdatedCustomer="insertUpdatedCustomer"
        />
      </template>
    </draggable>
    <div
      v-if="showContextMenu"
      :style="{
        top: contextMenuPosition.y + 'px',
        left: contextMenuPosition.x + 'px',
      }"
      class="context-menu"
      ref="ContextMenu"
      @click.stop
    >
      <v-list dense>
        <v-list-item @click="openEditCustomerModal()"
          ><v-icon class="text-medium-emphasis mr-1" size="xs">
            fa-solid fa-pen-to-square
          </v-icon>
          bearbeiten</v-list-item
        >
        <v-list-item @click="confirmDeleteCustomer(customerIdForDelete)">
          <v-icon class="text-medium-emphasis mr-1" size="xs">
            fa-solid fa-trash-can
          </v-icon>
          löschen
        </v-list-item>
        <v-list-item @click="toggleItemsMinimized()">
          <v-icon class="text-medium-emphasis mr-1" size="xs">
            {{
              minimizeAllItems
                ? "fa-solid fa-chevron-up"
                : "fa-solid fa-chevron-down"
            }}
          </v-icon>
          {{ minimizeAllItems ? "Karten normal" : "Karten minimieren" }}
        </v-list-item>
      </v-list>
    </div>
    <ModalAddCustomer
      :showModal="showModal"
      @update:showModal="showModal = $event"
      :results="results"
      :resultsModal="resultsModal"
      @update:resultsModal="resultsModal = $event"
      :modalTitle="modalTitle"
      :modalType="modalType"
      :addCustomerModalInput1="addCustomerModalInput1"
      :addCustomerModalInput2="addCustomerModalInput2"
      :addCustomerModalLabel1="addCustomerModalLabel1"
      :addCustomerModalLabel2="addCustomerModalLabel2"
      :modalActionButtonText="modalActionButtonText"
      @submit="handleModalSubmit"
      @selectedResult="handleSelectedResult"
      @addFromZvooveModal="openAddCustomerModal(ModalMode.zvooveOne)"
      @addFromPdHubModal="openAddCustomerModal(ModalMode.pdHub)"
    />
    <DialogEditCustomer
      :customer="customer"
      ref="modalEditCustomerComponent"
      @insertUpdatedCustomer="insertUpdatedCustomer"
    />
  </div>
</template>

<script lang="ts">
import { defineComponent, PropType } from "vue";
import { mapGetters, mapMutations } from "vuex";
import FilterMenu from "./elements/FilterMenu.vue";
import CustomerItem from "@/components/disposition/CustomerItem.vue";
import DialogService from "@/services/dialog.service";
import draggable from "vuedraggable";
import ModalAddCustomer from "@/components/disposition/elements/DialogAddCustomer.vue";
import DialogEditCustomer from "@/components/disposition/elements/DialogEditCustomer.vue";
import { CustomerService } from "@/services/api/customer.service";
import { Customer, CustomerList } from "@/models/customer.model";
import { AnzeigedatenService } from "@/services/api/anzeigendaten.service";
import { CompanyWebSearchService } from "@/services/api/company-web-search.service";
import { ModalMode } from "@/enums/dialog-action.enum";
import ToastService from "@/services/toast.service";
import { SpinnerService } from "@/services/spinner.service";
import { ZvooveOneCustomer } from "@/services/api/api-integration-one.service";
import { Mandant } from "@/models/mandant.model";
import { InterComponentMessage } from "@/enums/inter-component-messagin.enum";
import { MutationType } from "@/enums/vuex-mutationtype.enum";
import { UserService } from "@/services/api/user.service";
import { PdHubService } from "@/services/api/pd-hub.service";
import { UserHelperService } from "@/services/user-helper.service";
import { SoftwareIntegration } from "@/models/company-config.model";
import { User } from "@/models/user.model";
import { LinkingService } from "@/services/api/linking.service";

export default defineComponent({
  name: "CustomerColumn",
  components: {
    FilterMenu,
    ModalAddCustomer,
    DialogEditCustomer,
    CustomerItem,
    draggable,
  },
  props: {
    isActive: {
      type: Boolean,
      required: true,
    },
    interComponentMessage: {
      type: Object as PropType<any>,
      required: true,
    },
    lastUpdateTimeline: {
      type: String,
      required: true,
    },
    loggedInMandantUuids: {
      type: Object as PropType<string[]>,
      required: true,
    },
    mandants: {
      type: Object as PropType<Mandant[]>,
      required: true,
    },
    softwareIntegration: {
      type: Object as PropType<SoftwareIntegration>,
      required: true,
    },
    user: {
      type: Object as PropType<User>,
      required: true,
    },
  },
  data() {
    return {
      addCustomerModalInput1: "",
      addCustomerModalInput2: "",
      addCustomerModalLabel1: "",
      addCustomerModalLabel2: "",
      anzeigedatenService: AnzeigedatenService.getInstance(),
      companyWebSearchService: new CompanyWebSearchService(),
      contextMenuPosition: { x: 0, y: 0 },
      customers: [] as Customer[],
      customer: {} as Customer,
      customerStatesLabels: {
        0: "Interessent",
        1: "Kunde",
        2: "ehemaliger Kunde",
        3: "erledigt",
      },
      customerIdForDelete: "",
      customerService: new CustomerService(),
      drag: false,
      isLoadingCustomers: false,
      minimizeAllItems: false,
      modalActionButtonText: "aus ERP importieren",
      modalTitle: "",
      ModalMode: ModalMode,
      modalType: "",
      results: [] as Customer[],
      resultsModal: false,
      searchTerm: "",
      selectedMandants: [] as string[],
      selectedStatuses: [] as string[],
      showContextMenu: false,
      showFilters: false,
      showModal: false,
      sliderCustomerStatesValue: [1, 1],
      userService: new UserService(),
      userHelperService: UserHelperService.getInstance(),
    };
  },
  computed: {
    ...mapGetters({
      customerStates: "customerStates",
      zvooveCustomers: "zvooveCustomers",
    }),
  },
  created() {
    this.selectedStatuses =
      this.user.config.dispatcherBoard.columnCustomer.filterStatus;
    this.selectedMandants =
      this.user.config.dispatcherBoard.columnCustomer.filterMandants;
    this.loadCustomers();
  },
  mounted() {
    document.addEventListener("click", this.handleClickOutside, true);
    this.setSelectedMandantsFromLoggedInMandants();
  },
  beforeUnmount() {
    document.removeEventListener("click", this.handleClickOutside, true);
  },
  watch: {
    selectedStatuses: {
      handler(newValue, oldValue) {
        if (JSON.stringify(newValue) !== JSON.stringify(oldValue)) {
          this.loadCustomers();
          this.$store
            .dispatch("updateDispatcherBoardFilter", {
              columnName: "columnCustomer",
              property: "filterStatus",
              value: newValue,
            })
            .then(() => {
              this.userService.updateUserConfig(
                this.$store.state.company.loggedInUser.config
              );
            });
        }
      },
      deep: true,
    },
    selectedMandants: {
      handler(newValue, oldValue) {
        if (JSON.stringify(newValue) !== JSON.stringify(oldValue)) {
          this.loadCustomers();
          this.$store
            .dispatch("updateDispatcherBoardFilter", {
              columnName: "columnCustomer",
              property: "filterMandants",
              value: newValue,
            })
            .then(() => {
              this.userService.updateUserConfig(
                this.$store.state.company.loggedInUser.config
              );
            });
        }
      },
      deep: true,
    },
    interComponentMessage: {
      handler(newVal) {
        switch (newVal.message) {
          case InterComponentMessage.filterCustomers:
            this.handleFilterCustomers(newVal.payload.customerNumbers);
            break;

          case InterComponentMessage.openCustomer:
            this.handleOpenCustomer(newVal.payload.customerName);
            break;

          case InterComponentMessage.addCustomerFromZvoove:
            this.handleAddCustomerFromZvoove(newVal.payload.customerNumber);
            break;

          case InterComponentMessage.addCustomerFromIndex:
            this.handleAddCustomerFromIndex(
              newVal.payload.name,
              newVal.payload.postalCode
            );
            break;
          case InterComponentMessage.matchMeFromGlobalSearch:
            this.filterSearchterm(newVal.payload.searchTerm);
            break;
          default:
            break;
        }
      },
      deep: true,
    },
  },
  methods: {
    ...mapMutations({
      clearICM: MutationType.clearICM,
      setPostcodeList: MutationType.setPostcodeCustomers,
    }),
    closeModal() {
      this.showModal = false;
    },
    closeContextMenu() {
      this.showContextMenu = false;
    },
    async confirmDeleteCustomer(id: string) {
      if (this.customerIdForDelete) {
        const confirmed = await DialogService.confirm(
          "Möchten Sie diesen Kunden wirklich löschen?",
          "Abbrechen",
          "Löschen"
        );

        if (confirmed) {
          this.deleteCustomer(id);
        }
      }
    },

    async createCustomerInErp(selectedCustomer: Customer) {
      if (this.softwareIntegration.zvooveOne) {
        const confirmed = await DialogService.confirm(
          "Den Kunden auch in ERP One anlegen?",
          "Nein",
          "Kunde in zvoove anlegen!"
        );

        if (confirmed && this.user.config.loggedInMandants) {
          if (
            this.user?.config?.loggedInMandants?.length === 0 ||
            !selectedCustomer.mandants[0]
          ) {
            ToastService.showError(
              "Ohne eingeloggte Niederlassung kann der Kunden nichtangelegt werden!"
            );
            return null;
          }
          const newCustomer = selectedCustomer;
          const zvooveOneCustomer = new ZvooveOneCustomer();
          const response = await zvooveOneCustomer.createCustomer(newCustomer);
          newCustomer.customerNumber = response.id;
          newCustomer.generalData.costCenter = response.geschaeftsstelleId;
          return newCustomer;
        }
        return null;
      } else if (this.softwareIntegration.pdHub) {
        const confirmed = await DialogService.confirm(
          "Den Kunden auch in PD-Hub anlegen?",
          "Abbrechen",
          "Kunde in PD-Hub anlegen!"
        );

        if (confirmed) {
          const newCustomer = selectedCustomer;
          const pdHubService = PdHubService.getInstance();
          const response = await pdHubService.createCustomer(newCustomer);
          if (!response) {
            ToastService.showError("Kunde konnte nicht angelegt werden");
            return null;
          }
          newCustomer.customerNumber = response[0].KundenNr.toString();
          newCustomer.generalData.costCenter = response[0].Kostentraeger;
          return newCustomer;
        }
        return null;
      } else {
        return null;
      }
    },

    async createExitstingCustomerInErp(customer: Customer) {
      if (this.softwareIntegration.zvooveOne) {
        const confirmed = await DialogService.confirm(
          "Den Kunden jetzt in ERP One anlegen?",
          "Nein",
          "Kunde in zvoove anlegen!"
        );
        if (confirmed && this.user.config.loggedInMandants) {
          if (
            this.user?.config?.loggedInMandants?.length === 0 ||
            !customer.mandants[0]
          ) {
            ToastService.showError(
              "Ohne eingeloggte Niederlassung kann der Kunden nicht angelegt werden!"
            );
            return;
          }
          const zvooveOneCustomer = new ZvooveOneCustomer();
          const response = await zvooveOneCustomer.createCustomer(customer);
          customer.customerNumber = response.id;
          customer.generalData.costCenter = response.geschaeftsstelleId;
          const apiService = new CustomerService();
          if (customer) {
            const responseEdit = await apiService.editCustomer(customer);
            if (responseEdit.customerNumber) {
              ToastService.showSuccess(
                `${responseEdit.generalData?.name} erfolgreich im ERP One mit der Nummer ${responseEdit.customerNumber} angelegt ...`
              );
            }
          }
        }
        return;
      } else if (this.softwareIntegration.pdHub) {
        const confirmed = await DialogService.confirm(
          "Den Kunden auch in PD-Hub anlegen?",
          "Abbrechen",
          "Kunde in PD-Hub anlegen!"
        );

        if (confirmed) {
          const pdHubService = PdHubService.getInstance();
          const response = await pdHubService.createCustomer(customer);
          if (!response) {
            ToastService.showError("Kunde konnte nicht angelegt werden");
            return;
          }
          customer.customerNumber = response[0].KundenNr.toString();
          customer.generalData.costCenter = response[0].Kostentraeger;
          const apiService = new CustomerService();
          if (customer) {
            const responseEdit = await apiService.editCustomer(customer);
            if (responseEdit.customerNumber) {
              ToastService.showSuccess(
                `${responseEdit.generalData?.name} erfolgreich in PD-Hub mit der Nummer ${responseEdit.customerNumber} angelegt ...`
              );
            }
          }
        }
        return;
      } else {
        return;
      }
    },
    async deleteCustomer(id: string) {
      const customerService = new CustomerService();
      const linkingService = new LinkingService();
      try {
        const responseLinking = await linkingService.deleteLinkingsByTypeAndId(
          "customer",
          id
        );
        if (responseLinking.error) {
          ToastService.showError(
            "Fehler beim Löschen des Kunden: Verlinkungen konnten nicht entfernt werden!" +
              responseLinking.error
          );
          return;
        }
        const response = await customerService.deleteCustomer(id);
        if (response.error) {
          ToastService.showError(
            "Fehler beim Löschen des Kunden: " + response.error
          );
        } else {
          this.loadCustomers();
          this.customerIdForDelete = "";
        }
      } catch (error) {
        ToastService.showError("Fehler beim Löschen des Kunden: " + error);
      }
    },
    dragStart() {
      this.drag = true;
    },
    dragEnd() {
      this.drag = false;
    },
    extractAndSetPostcodeList() {
      const customers = this.customers;
      if (customers.length > 0) {
        const postcodes = customers
          .map((customer) => customer.addressAndCommunication?.postalCode)
          .filter(
            (postalCode) => postalCode !== undefined && postalCode !== ""
          );

        this.setPostcodeList(postcodes);
      }
    },
    async filterSearchterm(searchTerm: string) {
      if (searchTerm === "") {
        this.loadCustomers();
        return;
      }
      const terms = searchTerm.toLowerCase().split(" ");

      const filteredCustomers = this.customers.filter((customer) => {
        const {
          customerNumber,
          tags,
          generalData: { name, name2, name3, searchTerm: generalSearchTerm },
          addressAndCommunication: {
            street,
            postalCode,
            city,
            phone1,
            phone2,
            email,
            website,
          },
          additionalInfo: { indexCompanyId },
          contacts,
          jobAds,
          assignments,
        } = customer;

        const contactMatches =
          contacts &&
          contacts.some((contact) =>
            terms.every(
              (term) =>
                (contact.firstName &&
                  contact.firstName.toLowerCase().includes(term)) ||
                (contact.lastName &&
                  contact.lastName.toLowerCase().includes(term)) ||
                (contact.phone && contact.phone.toLowerCase().includes(term)) ||
                (contact.email && contact.email.toLowerCase().includes(term))
            )
          );

        const jobAdMatches =
          jobAds &&
          jobAds.some((jobAd) =>
            terms.every(
              (term) =>
                (Array.isArray(jobAd["JOB-CATEGORY"]) &&
                  jobAd["JOB-CATEGORY"].some((category: string) =>
                    category.toLowerCase().includes(term)
                  )) ||
                (jobAd.POSITION &&
                  jobAd.POSITION.toLowerCase().includes(term)) ||
                (jobAd["CAREER-LEVEL"] &&
                  jobAd["CAREER-LEVEL"].toLowerCase().includes(term)) ||
                (jobAd["AD-CONTACT-PERSON-PHONE"] &&
                  jobAd["AD-CONTACT-PERSON-PHONE"]
                    .toLowerCase()
                    .includes(term)) ||
                (jobAd.TEXT && jobAd.TEXT.toLowerCase().includes(term))
            )
          );

        const assignmentMatches =
          assignments &&
          assignments.some((assignment) =>
            terms.every((term) => {
              const fullName = `${assignment.firstName} ${assignment.lastName}`;
              return (
                this.lowerCaseIncludes(fullName, term) ||
                this.lowerCaseIncludes(assignment.jobTitle, term)
              );
            })
          );

        const matches = terms.every(
          (term) =>
            this.lowerCaseIncludes(customerNumber, term) ||
            (tags && tags.some((tag) => this.lowerCaseIncludes(tag, term))) ||
            this.lowerCaseIncludes(name, term) ||
            this.lowerCaseIncludes(name2, term) ||
            this.lowerCaseIncludes(name3, term) ||
            this.lowerCaseIncludes(generalSearchTerm, term) ||
            this.lowerCaseIncludes(street, term) ||
            this.lowerCaseIncludes(postalCode, term) ||
            this.lowerCaseIncludes(city, term) ||
            this.lowerCaseIncludes(phone1, term) ||
            this.lowerCaseIncludes(phone2, term) ||
            this.lowerCaseIncludes(email, term) ||
            this.lowerCaseIncludes(website, term) ||
            this.lowerCaseIncludes(indexCompanyId, term)
        );

        return matches || contactMatches || jobAdMatches || assignmentMatches;
      });

      this.customers = filteredCustomers;
    },
    lowerCaseIncludes(name: string | undefined, term: string) {
      return name && name.toLowerCase().includes(term);
    },
    async getAllCustomerFromERP() {
      if (this.loggedInMandantUuids.length === 0) {
        ToastService.showError(
          "Bitte in mindestens eine Niederlassung einloggen um alle Kunden abzurufen!"
        );
        return;
      }
      if (this.softwareIntegration.zvooveOne) {
        const branchIds = this.$store.getters.getLoggedInMandantBranchNumbers;
        const zvooveOneCustomer = new ZvooveOneCustomer();
        try {
          const response = (await zvooveOneCustomer.getAll(
            2,
            branchIds
          )) as Customer[];
          if (response) this.initAllCustomerData(response);
        } catch (error) {
          console.error("Error getting customers from ERP: ", error);
        }
      } else if (this.softwareIntegration.pdHub) {
        const pdHubService = new PdHubService();
        try {
          const response =
            (await pdHubService.fetchCustomerData()) as Customer[];
          if (response) this.initAllCustomerData(response);
        } catch (error) {
          console.error("Error getting customers from PD-Hub: ", error);
        }
      }
    },
    handleClickOutside(event: MouseEvent) {
      const ContextMenu = this.$refs.ContextMenu as HTMLElement;

      if (
        ContextMenu &&
        !ContextMenu.contains(event.target as Node) &&
        this.showContextMenu
      ) {
        this.closeContextMenu();
        this.customerIdForDelete = "";
      }
    },
    handleFilterCustomers(customerNumbers: string[]) {
      this.loadCustomers("", customerNumbers);
      this.clearICM;
    },
    handleOpenCustomer(customerName: string) {
      const customerNumber =
        this.$store.getters.getCustomerNumberByNameFromCustomerList(
          customerName
        );
      this.loadCustomers(undefined, [customerNumber]);
      this.clearICM;
    },
    async handleAddCustomerFromZvoove(customerNumber: string) {
      this.modalType = ModalMode.zvooveOne;
      this.addCustomerModalInput1 = customerNumber;
      this.submitModal();
      this.clearICM;
    },
    async handleAddCustomerFromPdHub(customerNumber: string) {
      this.modalType = ModalMode.pdHub;
      this.addCustomerModalInput1 = customerNumber;
      this.submitModal();
      this.clearICM;
    },
    handleAddCustomerFromIndex(name: string, postalCode: string) {
      this.modalType = ModalMode.indexAnzeigendaten;
      this.addCustomerModalInput1 = postalCode;
      this.addCustomerModalInput2 = name;
      this.submitModal();
      this.clearICM;
    },
    async handleModalSubmit({
      input1,
      input2,
    }: {
      input1: string;
      input2: string;
    }) {
      this.addCustomerModalInput1 = input1;
      this.addCustomerModalInput2 = input2;
      await this.submitModal();
    },
    handleSelectedResult(selectedResult: Customer) {
      SpinnerService.showSpinner();
      this.selectResult(selectedResult).then(() =>
        SpinnerService.removeSpinner()
      );
    },
    async initAllCustomerData(customersFromErp?: Customer[]) {
      let customerAll = this.zvooveCustomers;
      if (customersFromErp) customerAll = customersFromErp;

      try {
        const response = await this.customerService.addCustomers(customerAll);
        const { added, updated, skipped } = response;

        let successSummary = `${added} Kunde(n) erfolgreich hinzugefügt.`;
        if (updated > 0) {
          successSummary += `\n${updated} Kunde(n) erfolgreich aktualisiert.`;
        }
        if (skipped > 0) {
          successSummary += `\n${skipped} Kunde(n) wurden übersprungen, da sie bereits vorhanden waren.`;
        }
        ToastService.showReminder(successSummary);
      } catch (error) {
        ToastService.showError(
          "Fehler beim Hinzufügen oder Aktualisieren der Kunden."
        );
      }

      this.loadCustomers();
    },
    insertUpdatedCustomer(updatedCustomer: Customer) {
      const index = this.customers.findIndex(
        (customer) => customer._id === updatedCustomer._id
      );

      if (index !== -1) {
        this.customers[index] = updatedCustomer;
      }
    },
    setSelectedMandantsFromLoggedInMandants() {
      if (this.mandants && this.loggedInMandantUuids) {
        if (
          this.user.config.dispatcherBoard.columnCustomer.filterMandants
            .length > 0
        ) {
          //Do not set if other filters are setted
          return;
        }
        this.selectedMandants = this.mandants
          .filter((mandant: Mandant) =>
            this.loggedInMandantUuids.includes(mandant.uuid)
          )
          .map((mandant: Mandant) => mandant.uuid);
      }
    },
    async loadCustomers(id?: string, customerNumbers?: string[]) {
      if (this.isLoadingCustomers) return;
      try {
        SpinnerService.showSpinner();
        this.isLoadingCustomers = true;
        if (customerNumbers) {
          this.customers = await this.customerService.getByCustomerNumbers(
            customerNumbers
          );
          return;
        }
        if (id) {
          this.customers = [await this.customerService.getById(id)];
          return;
        }
        if (
          this.selectedMandants.length === 0 &&
          this.selectedStatuses.length === 0
        ) {
          this.customers = await this.customerService.getAll();
          return;
        }
        this.customers = await this.customerService.getAllFiltered(
          this.selectedMandants,
          this.selectedStatuses
        );
      } catch (error) {
        console.error("Error loading customers: ", error);
      } finally {
        this.extractAndSetPostcodeList();
        SpinnerService.removeSpinner();
        this.isLoadingCustomers = false;
      }
    },
    updateselectedStatuses(range: number[]) {
      this.selectedStatuses = [];
      for (let i = range[0]; i <= range[1]; i++) {
        switch (i) {
          case 0:
            this.selectedStatuses.push(this.customerStates[1]);
            break;
          case 1:
            this.selectedStatuses.push(this.customerStates[2]);
            break;
          case 2:
            this.selectedStatuses.push(this.customerStates[3]);
            break;
          case 3:
            this.selectedStatuses.push(this.customerStates[6]);
            break;
          default:
            break;
        }
      }
    },
    openContextMenu(event: MouseEvent, customer: Customer) {
      event.preventDefault();
      const customerId = customer._id;
      this.customer = customer;
      if (customerId) {
        this.customerIdForDelete = customerId;
      }
      this.showContextMenu = true;
      this.contextMenuPosition = { x: event.clientX, y: event.clientY };
    },
    openEditCustomerModal() {
      if (this.$refs.modalEditCustomerComponent) {
        (this.$refs.modalEditCustomerComponent as any).openModal();
      }
    },
    openAddCustomerModal(context: string) {
      switch (context) {
        case ModalMode.zvooveOne:
          this.modalTitle = "Aus zvoove One importieren";
          this.modalType = ModalMode.zvooveOne;
          this.addCustomerModalLabel1 = "zvoove Kundennummer";
          this.addCustomerModalInput1 = "";
          this.addCustomerModalLabel2 = "";
          this.modalActionButtonText = "in zvoove One suchen";
          break;
        case ModalMode.pdHub:
          this.modalTitle = "Aus PD-Hub importieren";
          this.modalType = ModalMode.pdHub;
          this.addCustomerModalLabel1 = "Kundennummer";
          this.addCustomerModalInput1 = "";
          this.addCustomerModalLabel2 = "";
          this.modalActionButtonText = "in PD-Hub suchen";
          break;
        case ModalMode.indexAnzeigendaten:
          this.modalTitle = "Aus INDEX Anzeigendaten importieren";
          this.modalType = ModalMode.indexAnzeigendaten;
          this.addCustomerModalLabel1 = "Postleitzahl";
          this.addCustomerModalInput1 = "";
          this.addCustomerModalLabel2 = "Firmenname / Suchbegriff";
          this.addCustomerModalInput2 = "";
          this.modalActionButtonText = "Importieren";
          break;
        case ModalMode.googleSearch:
          this.modalTitle = "Internet Suche nach dem Kunden";
          this.modalType = ModalMode.googleSearch;
          this.addCustomerModalLabel1 = "Suchbegriff";
          this.addCustomerModalInput1 = "";
          this.addCustomerModalLabel2 = "";
          this.modalActionButtonText = "Suchen";
          break;
      }
      this.showModal = true;
    },
    async selectResult(selectedCustomer: Customer) {
      try {
        if (
          isNaN(Number(selectedCustomer.customerNumber)) &&
          (this.softwareIntegration.zvooveOne || this.softwareIntegration.pdHub)
        ) {
          const zvooveOneCustomer = await this.createCustomerInErp(
            selectedCustomer
          );
          if (zvooveOneCustomer) {
            await this.customerService.addCustomer(zvooveOneCustomer);

            const customerReduced = {
              customerNumber: zvooveOneCustomer.customerNumber,
              name: zvooveOneCustomer.generalData.name,
              address: `${zvooveOneCustomer.addressAndCommunication.street} ${zvooveOneCustomer.addressAndCommunication.postalCode} ${zvooveOneCustomer.addressAndCommunication.city}`,
            } as CustomerList;
            this.$store.dispatch(
              "addCustomerReducedToCustomerList",
              customerReduced
            );
          } else {
            await this.customerService.addCustomer(selectedCustomer);
          }
        } else {
          await this.customerService.addCustomer(selectedCustomer);
        }
        this.loadCustomers();
        this.resultsModal = false;
      } catch (error) {
        console.error("Fehler beim Speichern des ausgewählten Kunden:", error);
      }
    },
    async submitModal() {
      this.showModal = false;

      try {
        let responseData = [];
        SpinnerService.showSpinner();
        if (this.modalType === ModalMode.zvooveOne) {
          const zvooveOneCustomer = new ZvooveOneCustomer();
          const customerData = await zvooveOneCustomer.getByCustomerNumber(
            this.addCustomerModalInput1
          );
          responseData.push(customerData);
          /*  scrape site with zorst: we maybe need that snippet for integration of other ERP system with srcaping
          import { ZorstService } from "@/services/zorst.service";
          import { extractCustomerData } from "@/services/ai.service";
          
          const zvooveOneCompanyLink = `${this.softwareIntegration.zvooveOneLink}/unternehmen/${this.addCustomerModalInput1}/uebersicht`;
          const companyData =
            await ZorstService.getInstance().ZorstOpenSiteAndScrapeText(
              zvooveOneCompanyLink
            );
          const extractedData = await extractCustomerData(companyData);
          console.log(extractedData);
          responseData.push(extractedData);*/
        } else if (this.modalType === ModalMode.pdHub) {
          const pdHubService = PdHubService.getInstance();
          const customerData = await pdHubService.getByCustomerNumber(
            this.addCustomerModalInput1
          );
          if (customerData === null) {
            SpinnerService.removeSpinner();
            DialogService.alert("keine Daten gefunden");
            return;
          }
          responseData.push(customerData);
        } else if (this.modalType === ModalMode.indexAnzeigendaten) {
          responseData = await this.anzeigedatenService.getCustomerFromIndex(
            this.addCustomerModalInput1,
            this.addCustomerModalInput2
          );
        } else if (this.modalType === ModalMode.googleSearch) {
          responseData = await this.companyWebSearchService.companyWebSearch(
            this.addCustomerModalInput1
          );
        }
        if (responseData.length > 0) {
          this.results = responseData;
          this.resultsModal = true;
        }
      } catch (error) {
        console.error("Fehler beim Abrufen der Daten: 2" + error);
      }
      SpinnerService.removeSpinner();
    },
    toggleItemsMinimized() {
      this.minimizeAllItems = !this.minimizeAllItems;
      this.closeContextMenu();
    },
  },
});
</script>
<style scoped>
.rotated-labels .v-slider__tick-label {
  transform: rotate(-45deg);
  transform-origin: center;
}

.context-menu {
  position: fixed;
  z-index: 1000;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
  min-width: 10rem;
}

.context-menu .v-list-item:not(:last-child) {
  border-bottom: 0.1rem solid var(--table-hover);
}

.context-menu .v-list-item--active {
  background-color: var(--table-hover);
}

.context-menu-icons {
  width: 1rem;
  height: 1rem;
  margin-right: 0.5rem;
}
</style>
