import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock, createVNode as _createVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_tab = _resolveComponent("v-tab")!
  const _component_v_tabs = _resolveComponent("v-tabs")!
  const _component_v_card = _resolveComponent("v-card")!

  return (_openBlock(), _createBlock(_component_v_card, null, {
    default: _withCtx(() => [
      _createVNode(_component_v_tabs, {
        modelValue: _ctx.activeTab,
        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.activeTab) = $event)),
        "align-tabs": "start",
        "bg-color": "var(--color-primary)",
        style: {"color":"var(--color-font-light)"},
        "slider-color": "var(--color-primary)"
      }, {
        default: _withCtx(() => [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.items, (item) => {
            return (_openBlock(), _createBlock(_component_v_tab, {
              key: item,
              value: item,
              onClick: ($event: any) => (_ctx.navigateTo(item))
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(item), 1)
              ]),
              _: 2
            }, 1032, ["value", "onClick"]))
          }), 128))
        ]),
        _: 1
      }, 8, ["modelValue"])
    ]),
    _: 1
  }))
}