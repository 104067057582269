<!--src/components/config/software-integration/ConfigPayFlow.vue-->
<template>
  <v-container>
    <h2 class="mb-5">
      <v-icon size="small" class="mr-2">fa-solid fa-coins</v-icon>PayFlow
      Einstellungen
    </h2>
    <v-form @submit.prevent="handleSubmit">
      <v-row>
        <v-col
          v-for="(salaryAdjustment, index) in localConfig.salaryAdjustments"
          :key="index"
          cols="12"
          md="4"
        >
          <v-card variant="outlined" class="mb-4 pa-4">
            <v-card-title class="mb-5 d-flex"
              >{{ salaryAdjustment.payrollInfoType || "Infotyp wählen" }}
              <v-spacer></v-spacer>
              <v-btn
                icon
                density="compact"
                variant="text"
                @click="removeSalaryAdjustment(index)"
              >
                <v-icon>fa-solid fa-trash-can</v-icon>
              </v-btn></v-card-title
            >
            <v-card-text>
              <v-select
                variant="outlined"
                label="Payroll Infotyp"
                v-model="salaryAdjustment.payrollInfoType"
                :items="payrollInfoTypeOptions"
                item-title="label"
                item-value="label"
              ></v-select>
              <v-text-field
                v-model="salaryAdjustment.description"
                label="Name in ERP"
                variant="outlined"
              ></v-text-field>
              <v-text-field
                v-model="salaryAdjustment.adjustmentTypeId"
                label="Abzugsart ID"
                variant="outlined"
              ></v-text-field>
              <v-select
                v-model="salaryAdjustment.type"
                :items="salaryAdjustmentType"
                item-title="value"
                item-value="label"
                label="Art"
                variant="outlined"
              ></v-select>
              <v-text-field
                v-model="salaryAdjustment.accountNumber"
                label="Sachkonto"
                variant="outlined"
                type="number"
              ></v-text-field>
            </v-card-text>
          </v-card>
        </v-col>
        <!-- Neue Einträge hinzufügen -->
        <v-col cols="12" md="4">
          <v-card variant="outlined" class="border-dashed mb-4 pa-4">
            <v-card-title>Neuer Eintrag</v-card-title>
            <v-card-text>
              <v-select
                v-model="newSalaryAdjustment.payrollInfoType"
                :items="payrollInfoTypeOptions"
                item-title="label"
                item-value="label"
                label="Payroll Infotyp"
                variant="outlined"
              ></v-select>
              <v-text-field
                v-model="newSalaryAdjustment.description"
                label="Name in ERP"
                variant="outlined"
              ></v-text-field>
              <v-text-field
                v-model="newSalaryAdjustment.adjustmentTypeId"
                label="Abzugsart ID"
                variant="outlined"
              ></v-text-field>
              <v-select
                v-model="newSalaryAdjustment.type"
                :items="salaryAdjustmentType"
                item-title="value"
                item-value="label"
                label="Art"
                variant="outlined"
              ></v-select>
              <v-text-field
                v-model="newSalaryAdjustment.accountNumber"
                label="Sachkonto"
                variant="outlined"
                type="number"
              ></v-text-field>
              <v-btn
                color="var(--color-primary)"
                variant="text"
                @click="addSalaryAdjustment"
              >
                Hinzufügen
              </v-btn>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
      <v-btn class="mt-10" type="submit" color="green-darken-3"
        >Speichern</v-btn
      >
    </v-form>
  </v-container>
</template>

<script lang="ts">
import { defineComponent, PropType } from "vue";
import { CompanyConfig, SalaryAdjustment } from "@/models/company-config.model";
import { getEnumOptions } from "@/helper/enum.helper";
import { PayrollInfoType, SalaryAdjustmentType } from "@/enums/payflow.enum";

export default defineComponent({
  name: "ConfigPayFlow",
  props: {
    modelValue: {
      type: Object as PropType<CompanyConfig>,
      required: true,
    },
  },
  data() {
    return {
      localConfig: { ...this.modelValue },
      payrollInfoTypeOptions: getEnumOptions(PayrollInfoType),
      salaryAdjustmentType: getEnumOptions(SalaryAdjustmentType),
      newSalaryAdjustment: {
        payrollInfoType: PayrollInfoType.advance,
        description: "",
        adjustmentTypeId: "",
        type: SalaryAdjustmentType.minus,
        accountNumber: 0,
      } as SalaryAdjustment,
    };
  },
  watch: {
    modelValue(newValue: CompanyConfig) {
      this.localConfig = { ...newValue };
    },
  },
  methods: {
    addSalaryAdjustment() {
      this.localConfig.salaryAdjustments.push({ ...this.newSalaryAdjustment });
      this.resetNewSalaryAdjustment();
    },
    removeSalaryAdjustment(index: number) {
      this.localConfig.salaryAdjustments.splice(index, 1);
    },
    resetNewSalaryAdjustment() {
      this.newSalaryAdjustment = {
        payrollInfoType: PayrollInfoType.advance,
        description: "",
        adjustmentTypeId: "",
        type: SalaryAdjustmentType.minus,
        accountNumber: 0,
      } as SalaryAdjustment;
    },
    handleSubmit() {
      this.$emit("update:modelValue", this.localConfig);
      this.$emit("save");
    },
  },
});
</script>
