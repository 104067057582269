<!--src/components/dispostion/AppointmentCalendar.vue-->
<template>
  <div class="calendar-header">TODO: calendar-header-menu</div>
  <vue-cal
    locale="de"
    :time-from="6 * 60"
    :time-to="20 * 60"
    :time-step="60"
    :events="appointments"
    view="week"
    hide-view-selector
    hide-weekends
    @event-click="eventClicked"
  >
  </vue-cal>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import VueCal from "vue-cal";
import moment from "moment";
import { LinkingService } from "@/services/api/linking.service";
import { CalendarObject } from "@/models/linking.model";
import { mapGetters } from "vuex";
import { LinkingStatus } from "@/enums/dependency.enum";
import { SpinnerService } from "@/services/spinner.service";

interface EventOption {
  eventName: string;
  candidateStatus?: LinkingStatus;
}

export default defineComponent({
  name: "AppointmentCalendar",
  components: {
    VueCal,
  },
  props: {
    selectedDatesTimes: {
      type: Array as () => Array<string>,
      default: () => [],
    },
  },
  data() {
    return {
      appointments: [] as CalendarObject[],
    };
  },
  computed: {
    ...mapGetters([
      "company",
      "candidateToCustomerEvents",
      "candidateToMandantEvents",
    ]),
    eventMap(): Record<string, LinkingStatus | undefined> {
      const allEvents: EventOption[] = [
        ...this.candidateToCustomerEvents,
        ...this.candidateToMandantEvents,
      ];
      const eventStatusMap: Record<string, LinkingStatus | undefined> = {};
      allEvents.forEach((event) => {
        if (event.candidateStatus) {
          eventStatusMap[event.eventName] = event.candidateStatus;
        }
      });
      return eventStatusMap;
    },
    displayStatuses(): Set<LinkingStatus> {
      return new Set([
        LinkingStatus.interviewSuggestionExternal,
        LinkingStatus.trailWorkSuggestionExternal,
        LinkingStatus.interviewExternal,
        LinkingStatus.trailWorkExternal,
        LinkingStatus.interview,
      ]);
    },
  },
  methods: {
    async fetchAppointments() {
      const linkingService = new LinkingService();
      const thirtyDaysAgo = moment().subtract(30, "days").format("YYYY-MM-DD");

      try {
        SpinnerService.showSpinner();
        const fetchedAppointments =
          await linkingService.getLinkingsNewerThanDateForMandant(
            thirtyDaysAgo,
            this.company.loggedInUser.config.loggedInMandants
          );

        this.appointments = fetchedAppointments
          .filter((appointment) => {
            const status = this.eventMap[appointment.class];
            return status && this.displayStatuses.has(status);
          })
          .map((appointment) => {
            const startDate = new Date(appointment.date);
            const endDate = new Date(startDate.getTime() + 60 * 60 * 1000);
            const candidateStatus = this.getStatusForClass(appointment.class);
            return {
              start: startDate,
              end: endDate,
              title: appointment.title,
              class: candidateStatus,
            };
          });
      } catch (error) {
        console.error("Error fetching appointments:", error);
      } finally {
        SpinnerService.removeSpinner();
      }
    },

    getStatusForClass(className: string) {
      const allEvents = [
        ...this.candidateToCustomerEvents,
        ...this.candidateToMandantEvents,
      ];
      const event = allEvents.find((event) => event.eventName === className);
      return event ? event.candidateStatus : undefined;
    },
    addSelectedDatesToCalendar() {
      this.appointments = this.appointments.filter(
        (appointment) => appointment.class !== "Placeholder"
      );

      this.selectedDatesTimes.forEach((dateTime) => {
        const startDate = new Date(dateTime);
        const endDate = new Date(startDate.getTime() + 60 * 60 * 1000);

        this.appointments.push({
          start: startDate,
          end: endDate,
          title: "Neuer Termin",
          class: "Placeholder",
        });
      });
    },
    eventClicked(event: any) {
      alert(JSON.stringify(event));
    },
  },
  watch: {
    selectedDatesTimes: {
      immediate: true,
      handler() {
        this.addSelectedDatesToCalendar();
      },
    },
  },
  mounted() {
    this.fetchAppointments();
  },
});
</script>

<style>
.vuecal__event.Placeholder {
  background-color: var(--border-light);
  border-radius: var(--border-radius-elements);
  border: 1px dotted var(--border-medium);
  color: var(--color-font-primary);
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.2);
}
.vuecal__event.interview {
  background-color: var(--calendar-interview);
  border-radius: var(--border-radius-elements);
  border: 1px solid var(--border-medium);
  color: var(--color-font-light);
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.2);
}
.vuecal__event.interviewSuggestionExternal {
  background-color: var(--calendar-interview-external-suggestion);
  border-radius: var(--border-radius-elements);
  border: 1px dotted var(--calendar-interview-external);
  color: var(--color-font-primary);
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.2);
}
.vuecal__event.interviewExternal {
  background-color: var(--calendar-interview-external);
  border-radius: var(--border-radius-elements);
  border: 1px solid var(--calendar-interview-external-suggestion);
  color: var(--color-font-primary);
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.2);
}
.vuecal__event.trailWorkSuggestionExternal {
  height: 95%;
  background-color: var(--calendar-trialwork-external-suggestion);
  border-radius: var(--border-radius-elements);
  border: 1px dotted var(--calendar-trialwork-external);
  color: var(--color-font-primary);
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.2);
}
.vuecal__event.trailWorkExternal {
  background-color: var(--calendar-trialwork-external);
  border-radius: var(--border-radius-elements);
  border: 1px solid var(--calendar-trialwork-external-suggestion);
  color: var(--color-font-light);
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.2);
}
.vuecal__event {
  cursor: pointer;
}
.calendar-header {
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
  background-color: var(--modal-background);
  border-top: 1px solid var(--border-light);
  border-left: 1px solid var(--border-light);
  border-right: 1px solid var(--border-light);
  padding-left: 0.5rem;
  padding-right: 0.5rem;
}
.vuecal {
  font-size: 0.8rem; /* Setzt die Schriftgröße auf 12px */
}
</style>
