// src/services/job-ad.service.ts
import { ApiResponse } from "@/models/api-response.model";
import { Api } from "./api.class";
import {
  JobAd,
  jobAdType,
  JobCategory,
  JobLocation,
} from "@/models/job-ad-model";

export class JobAdService {
  private api = Api.getInstance();
  private urlPath = "/jobads";

  async addJobLocation(location: JobLocation): Promise<JobLocation> {
    const response = await this.api
      .getBackendApi()
      .post(`${this.urlPath}/location`, location);
    return response.data.response;
  }

  async editJobLocation(location: JobLocation): Promise<JobLocation> {
    const response = await this.api
      .getBackendApi()
      .put(`${this.urlPath}/location/${location._id}`, location);
    return response.data.response;
  }

  async deleteJobLocation(id: string): Promise<void> {
    await this.api.getBackendApi().delete(`${this.urlPath}/location/${id}`);
  }

  async addJobCategory(category: JobCategory): Promise<JobCategory> {
    const response = await this.api
      .getBackendApi()
      .post(`${this.urlPath}/category`, category);
    return response.data.response;
  }

  async deleteJobCategory(id: string): Promise<void> {
    await this.api.getBackendApi().delete(`${this.urlPath}/category/${id}`);
  }

  async editJobCategory(category: JobCategory): Promise<JobCategory> {
    const response = await this.api
      .getBackendApi()
      .put(`${this.urlPath}/category/${category._id}`, category);
    return response.data.response;
  }

  async addJobAd(jobAd: JobAd): Promise<JobAd> {
    const response = await this.api.getBackendApi().post(this.urlPath, jobAd);
    return response.data.response;
  }

  async addNewJobAd(jobAd: JobAd, location: JobLocation): Promise<JobAd> {
    const response = await this.api
      .getBackendApi()
      .post(`${this.urlPath}/new`, { jobAd, location });
    return response.data.response;
  }

  async getJobAds(type: jobAdType, mandants?: string[]): Promise<JobAd[]> {
    const response = await this.api.getBackendApi().get(`${this.urlPath}/all`, {
      params: { type, mandants },
    });
    return response.data.response;
  }

  async deleteAllJobAds(
    type: jobAdType,
    mandants?: string[]
  ): Promise<ApiResponse> {
    const response = await this.api
      .getBackendApi()
      .delete(`${this.urlPath}/delete-all`, {
        params: { type, mandants },
      });
    return response.data.response;
  }

  async setAllPostedJobAdsToRequested(
    type: jobAdType,
    mandants?: string[]
  ): Promise<JobAd[]> {
    const response = await this.api
      .getBackendApi()
      .put(`${this.urlPath}/update-status`, null, {
        params: { type, mandants },
      });
    return response.data.response;
  }

  async getRequestedJobAds(
    type: number,
    mandants?: string[]
  ): Promise<JobAd[]> {
    const response = await this.api
      .getBackendApi()
      .get(`${this.urlPath}/requested`, {
        params: { type, mandants },
      });
    return response.data.response;
  }

  async updateJobAd(jobAd: JobAd): Promise<JobAd> {
    const response = await this.api
      .getBackendApi()
      .put(`${this.urlPath}/${jobAd._id}`, jobAd);
    return response.data.response;
  }

  async updateJobAdAndJobLocation(
    jobAd: JobAd,
    location: JobLocation
  ): Promise<JobAd> {
    const response = await this.api
      .getBackendApi()
      .put(`${this.urlPath}/updateWithLocation`, { jobAd, location });
    return response.data.response;
  }

  async deleteJobAd(id: string): Promise<any> {
    const result = await this.api
      .getBackendApi()
      .delete<ApiResponse>(`${this.urlPath}/${id}`);
    return result.data.response.response;
  }

  async getLocations(mandants?: string[]): Promise<JobLocation[]> {
    const response = await this.api
      .getBackendApi()
      .get(`${this.urlPath}/locations`, {
        params: { mandants },
      });
    return response.data.response;
  }

  async getCategories(): Promise<JobCategory[]> {
    const response = await this.api
      .getBackendApi()
      .get(`${this.urlPath}/categories`);
    return response.data.response;
  }
}
