import { QuestionObject } from "@/models/funnel-question.model";
import { Config } from "@/models/funnel-config.model";
import { FunnelService } from "./api/funnel.service";
import DialogService from "@/services/dialog.service";

export class ConfigService {
  private funnelId = "";
  private funnelService = new FunnelService();

  async updateConfig(config: Config) {
    const result = await this.funnelService.saveConfig(this.funnelId, config);
    if (result && result.ok === 1) {
      // Maybe we will not need the alert when everything works fine
      DialogService.alert("config.json im Funnelverzeichnis gespeichert");
    } else {
      alert("Fehler beim speichern der config.json im Funnelverzeichnis");
    }
  }

  async updateQuestions(questionObject: QuestionObject) {
    const result = await this.funnelService.saveQuestions(
      this.funnelId,
      questionObject
    );
    //TODO check response
    if (result) {
      // Same here
      DialogService.alert("questions.json im Funnelverzeichnis gespeichert");
    } else {
      DialogService.alert(
        "Fehler beim Speichern der questions.json im Funnelverzeichnis"
      );
    }
  }

  getFunnelId() {
    return this.funnelId;
  }

  setFunnelId(funnelId: string) {
    this.funnelId = funnelId;
  }
}
