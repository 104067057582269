//src/services/api/wordpress-plugin.service.de
import { WordPressCandidateData } from "@/models/wordpress-plugin.model";
import store from "@/store/store";
import axios from "axios";

export class WordPressPluginService {
  private apiClient;

  constructor() {
    this.apiClient = axios.create({
      baseURL: store.state.company.apiKeys.wpPluginBaseUrl,
      headers: {
        "Content-Type": "application/json",
        "X-API-KEY": store.state.company.apiKeys.wpPlugin,
      },
    });
  }

  async postCandidateToWordPressPlugin(
    candidateData: WordPressCandidateData
  ): Promise<any> {
    try {
      const response = await this.apiClient.post("", candidateData);
      return response.data;
    } catch (error) {
      console.error("Error posting candidate to WordPress:", error);
      throw error;
    }
  }

  async checkIfExistCandidateInWordPressPlugin(
    profileId: string
  ): Promise<boolean> {
    try {
      const response = await this.apiClient.get(`?checkProfilID=${profileId}`);
      return response.data.exists;
    } catch (error) {
      console.error("Error checking if candidate exists in WordPress:", error);
      throw error;
    }
  }

  async deleteCandidateFromWordPressPlugin(profileId: string): Promise<any> {
    try {
      const response = await this.apiClient.delete(`?ProfilID=${profileId}`);
      return response.data;
    } catch (error) {
      console.error("Error deleting candidate from WordPress:", error);
      throw error;
    }
  }
}
