<!-- views/recruiting-view.vue -->
<template>
  <div>
    <MainHeader
      @tab-selected="handleTabSelection"
      :showRecruitTabs="true"
    ></MainHeader>
    <JobMatrix v-if="currentTab === HeaderTab.JobMatrix"></JobMatrix>
    <JobList v-if="currentTab === HeaderTab.JobList"></JobList>
    <JobFunnels v-if="currentTab === HeaderTab.Funnels"></JobFunnels>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import JobFunnels from "@/components/recruiting/JobFunnels.vue";
import JobList from "@/components/recruiting/JobList.vue";
import JobMatrix from "@/components/recruiting/JobMatrix.vue";
import MainHeader from "@/components/top-header/Header.vue";
import { HeaderTab } from "@/enums/header-tabs.enum";

export default defineComponent({
  name: "RecruitingView",
  components: {
    MainHeader,
    JobMatrix,
    JobList,
    JobFunnels,
  },
  data() {
    return {
      currentTab: HeaderTab.JobList as string,
      HeaderTab: HeaderTab,
    };
  },
  methods: {
    handleTabSelection(selectedTab: string) {
      this.currentTab = selectedTab;
    },
  },
});
</script>

<style scoped>
/* Stile hier */
</style>
