<!--src/components/config/software-integration/ConfigWordPressPlugin.vue-->
<template>
  <v-container>
    <h2 class="mb-5">
      <v-icon size="small" class="mr-2">fa-solid fa-globe</v-icon>Endpunkt zu
      Alex's jobhub-online.de für Kandidaten + Stellenanzeigen
    </h2>
    <v-row>
      <v-col cols="12" md="6">
        <v-text-field
          v-model="localConfig.apiKeys.wpPluginBaseUrl"
          label="Base URL WordPress Plugin"
          variant="outlined"
        ></v-text-field>
      </v-col>
      <v-col cols="12" md="6">
        <v-text-field
          v-model="localConfig.apiKeys.wpPlugin"
          label="API Key"
          variant="outlined"
        ></v-text-field>
      </v-col>
    </v-row>
    <v-form @submit.prevent="handleSubmit">
      <v-btn class="mt-10" type="submit" color="green-darken-3"
        >Speichern</v-btn
      >
    </v-form>
  </v-container>
</template>

<script lang="ts">
import { defineComponent, PropType } from "vue";
import { CompanyConfig } from "@/models/company-config.model";

export default defineComponent({
  name: "ConfigWordPressPlugin",
  props: {
    modelValue: {
      type: Object as PropType<CompanyConfig>,
      required: true,
    },
  },
  data() {
    return {
      localConfig: { ...this.modelValue },
    };
  },
  watch: {
    modelValue(newValue: CompanyConfig) {
      this.localConfig = { ...newValue };
    },
  },
  methods: {
    handleSubmit() {
      this.$emit("update:modelValue", this.localConfig);
      this.$emit("save");
    },
  },
});
</script>
