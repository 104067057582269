<template>
  <v-container class="py-3">
    <v-card class="mt-15" variant="outlined">
      <div class="video-container">
        <div v-if="!videoEnded">
          <video
            ref="videoPlayer"
            :src="videoSrc"
            autoplay
            @ended="onVideoEnded"
            @error="onVideoError"
          >
            Your browser does not support the video tag.
          </video>
        </div>
        <div v-else>
          <img :src="logoSrc" class="logo" alt="Logo" />
        </div>
      </div>
    </v-card>
  </v-container>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { mapGetters } from "vuex";

export default defineComponent({
  name: "RecurionVideo",
  computed: {
    ...mapGetters(["getEnv"]),
  },
  data() {
    return {
      videoSrc: `${this.$store.getters.getEnv.VUE_APP_ASSETS_URL}/video/recurion.mp4`,
      logoSrc: "@/assets/dispositioner-logo-light.svg",
      videoEnded: false,
    };
  },

  methods: {
    onVideoEnded() {
      this.videoEnded = true;
    },
    onVideoError(event: any) {
      console.error("Error playing video", event);
    },
  },
});
</script>

<style scoped>
.video-container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  background-color: none;
}

video {
  max-width: 100%;
  max-height: 100%;
}

.logo {
  margin: 3rem;
  width: 100%;
}
</style>
