// src/enums/salutation.enum.ts
export enum Salutation {
  Leer = 0,
  Herr = 1,
  Frau = 2,
  Divers = 3,
  Unbekannt = 4,
  Firma = 5,
}

export const getSalutationText = (salutationId: number): string => {
  if (salutationId === 3 || salutationId === 4) return "";
  return Salutation[salutationId];
};
