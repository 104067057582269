import axios from "axios";
import store from "../store/store";

const apiClientMistral = axios.create({
  headers: { "Content-Type": "application/json" },
});

export const updateMistralAiConfig = () => {
  const config = store.state.company.apiKeys;
  apiClientMistral.defaults.baseURL = config.baseUrlMistralAi;
  apiClientMistral.defaults.headers.common[
    "Authorization"
  ] = `Bearer ${config.mistralAi}`;
};

export const MistralGDPR = async ({
  prompt,
  model,
  temperature,
  role,
}: {
  prompt: string;
  model: string;
  temperature: number;
  role: string;
}) => {
  updateMistralAiConfig();
  const data = {
    model: model,
    messages: [{ role: role, content: prompt }],
    temperature: temperature,
    // max_tokens, top_p, frequency_penalty, presence_penalty can be added
  };

  const response = await apiClientMistral.post("/v1/chat/completions", data);
  return response.data;
};
