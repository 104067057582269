<template>
  <v-container>
    <h2 class="mb-5">
      <v-icon size="small" class="mr-2">fa-solid fa-link</v-icon>Konfiguration
      für ATS System zvoove Recruit
    </h2>
    <v-form @submit.prevent="handleSubmit">
      <v-row>
        <v-col cols="12" md="6"></v-col>
        <v-col cols="12" md="3">
          <v-switch
            color="var(--color-primary)"
            v-model="localConfig.softwareIntegration.atsDeterminesStatus"
            label="ATS bestimmt Kandidatenstatus"
          ></v-switch>
        </v-col>
        <v-col cols="12" md="3">
          <v-switch
            color="var(--color-primary)"
            v-model="localConfig.softwareIntegration.atsAutoDocu"
            label="automatische Dokumentation"
          ></v-switch>
        </v-col>
        <v-col cols="12" md="6">
          <v-text-field
            v-model="localConfig.softwareIntegration.zvooveRecruitLink"
            label="zvoove Recruit Link"
            variant="outlined"
          ></v-text-field>
        </v-col>
        <v-col cols="12" md="6">
          <v-text-field
            v-model="localConfig.apiKeys.baseURLZvooveRecruit"
            label="Base URL API"
            variant="outlined"
          ></v-text-field>
        </v-col>
        <v-col cols="12" md="6">
          <v-text-field
            v-model="localConfig.apiKeys.zvooveOpenBewerber"
            label="API OpenBewerber"
            variant="outlined"
          ></v-text-field>
        </v-col>
        <v-col cols="12" md="6">
          <v-text-field
            v-model="localConfig.apiKeys.zvooveOpenStelle"
            label="API OpenStelle"
            variant="outlined"
          ></v-text-field>
        </v-col>
      </v-row>
      <v-card-title> Statusnamen im ATS System:</v-card-title>
      <v-divider class="mb-5"></v-divider>
      <v-row>
        <v-col
          cols="12"
          md="4"
          v-for="(statusOption, index) in localConfig.statusOptionsRecruit"
          :key="statusOption._id"
        >
          <v-card variant="outlined" class="mb-2">
            <v-card-title
              class="d-flex justify-content-between align-items-center mb-3"
            >
              {{ statusOption.text }}
              <v-spacer></v-spacer>
              <v-btn variant="text" @click="removeStatusOption(index)">
                <v-icon>fa-solid fa-trash-can</v-icon>
              </v-btn>
            </v-card-title>
            <v-card-text>
              <v-text-field
                v-model="statusOption.text"
                label="Anzeige in Dispositioner"
                variant="outlined"
                density="compact"
                class="mb-2"
              ></v-text-field>
              <v-text-field
                v-model="statusOption.value"
                label="Statusname Recruit"
                variant="outlined"
                density="compact"
                class="mb-2"
              ></v-text-field>
              <v-text-field
                v-model="statusOption.slider"
                label="Slider Position"
                variant="outlined"
                density="compact"
                class="mb-2"
              ></v-text-field>
              <v-select
                v-model="statusOption.status"
                :items="linkingStatusOptions"
                label="Status"
                variant="outlined"
                density="compact"
                multiple
              ></v-select>
            </v-card-text>
          </v-card>
        </v-col>
        <v-col cols="12" md="4">
          <v-card variant="outlined" class="border-dashed mb-2">
            <v-card-title
              class="d-flex justify-content-between align-items-center mb-3"
            >
              Neuer Status
            </v-card-title>
            <v-card-text>
              <v-text-field
                v-model="newStatusOption.text"
                label="Anzeige in Dispositioner"
                variant="outlined"
                density="compact"
                class="mb-2"
              ></v-text-field>
              <v-text-field
                v-model="newStatusOption.value"
                label="Statusname Recruit"
                variant="outlined"
                density="compact"
                class="mb-2"
              ></v-text-field>
              <v-text-field
                v-model="newStatusOption.slider"
                label="Slider Position"
                variant="outlined"
                density="compact"
                class="mb-2"
              ></v-text-field>
              <v-select
                v-model="newStatusOption.status"
                :items="linkingStatusOptions"
                label="Status"
                variant="outlined"
                density="compact"
                multiple
              ></v-select>
              <v-btn
                color="var(--color-primary)"
                variant="text"
                @click="addNewStatusOption"
                >Hinzufügen</v-btn
              >
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
      <v-btn class="mt-10" type="submit" color="green-darken-3"
        >Speichern</v-btn
      >
    </v-form>
  </v-container>
</template>

<script lang="ts">
import { defineComponent, PropType } from "vue";
import { CompanyConfig } from "@/models/company-config.model";
import { LinkingStatus } from "@/enums/dependency.enum";

export default defineComponent({
  name: "ConfigZvooveRecruit",
  props: {
    modelValue: {
      type: Object as PropType<CompanyConfig>,
      required: true,
    },
  },
  data() {
    return {
      localConfig: { ...this.modelValue },
      newStatusOption: {
        text: "",
        value: "",
        slider: 0,
        status: [] as string[],
      },
    };
  },
  computed: {
    linkingStatusOptions() {
      return Object.values(LinkingStatus);
    },
  },
  watch: {
    modelValue(newValue: CompanyConfig) {
      this.localConfig = { ...newValue };
    },
  },
  methods: {
    handleSubmit() {
      this.$emit("update:modelValue", this.localConfig);
      this.$emit("save");
    },
    removeStatusOption(index: number) {
      this.localConfig.statusOptionsRecruit.splice(index, 1);
    },
    addNewStatusOption() {
      if (this.newStatusOption.text && this.newStatusOption.value) {
        this.localConfig.statusOptionsRecruit.push({ ...this.newStatusOption });
        this.resetNewStatusOption();
        this.handleSubmit;
      }
    },
    resetNewStatusOption() {
      this.newStatusOption = {
        text: "",
        value: "",
        slider: 0,
        status: [] as string[],
      };
    },
  },
});
</script>

<style scoped>
/* Add any necessary styling here */
</style>
