<template>
  <v-container :fluid="true">
    <div class="title">
      <h1>Admin Management Board</h1>
      <h2>Firma {{ $store.state.company.name }}</h2>
    </div>
    <AdminMenu />
    <div class="admin-menu" />
    <v-row>
      <v-col cols="12" md="4">
        <v-img
          class="mb-2"
          :width="100"
          aspect-ratio="1/1"
          cover
          :src="assetsSettings"
        ></v-img>
        <v-card variant="outlined">
          <v-form
            class="pa-5"
            v-if="companyConfigModel.softwareIntegration && getUserRole() < 6"
            @submit.prevent="updateCompanyConfig"
          >
            <v-card-title>Unternehmensdaten</v-card-title>
            <v-text-field
              v-model="companyConfigModel.name"
              label="Unternehmensname"
              variant="outlined"
            ></v-text-field>
            <v-text-field
              v-model="companyConfigModel.domain"
              label="Domain"
              variant="outlined"
            ></v-text-field>
            <v-divider></v-divider>
            <v-card-title>Software Integration</v-card-title>
            <ClickableSwitch
              noLink
              :label="'AI-Kennzahlenanalyse auf dem Dashboard'"
              :status="
                companyConfigModel.softwareIntegration.dashboardAiKpiAnalysis
              "
              @handleChange="
                handleChange(SoftwareIntegrationType.dashboardAiKpiAnalysis)
              "
            ></ClickableSwitch>
            <ClickableSwitch
              :label="'ATS: zvoove Recruit'"
              :status="companyConfigModel.softwareIntegration.zvooveRecruit"
              @labelClick="
                handleLabelClick(SoftwareIntegrationType.zvooveRecruit)
              "
              @handleChange="
                handleChange(SoftwareIntegrationType.zvooveRecruit)
              "
            ></ClickableSwitch>
            <ClickableSwitch
              :label="'ERP: zvoove One'"
              :status="companyConfigModel.softwareIntegration.zvooveOne"
              @labelClick="handleLabelClick(SoftwareIntegrationType.zvooveOne)"
              @handleChange="handleChange(SoftwareIntegrationType.zvooveOne)"
            ></ClickableSwitch>
            <ClickableSwitch
              :label="'ERP: PD-Hub'"
              :status="companyConfigModel.softwareIntegration.pdHub"
              @labelClick="handleLabelClick(SoftwareIntegrationType.pdHub)"
              @handleChange="handleChange(SoftwareIntegrationType.pdHub)"
            ></ClickableSwitch>
            <ClickableSwitch
              :status="
                companyConfigModel.softwareIntegration.indexAnzeigendaten
              "
              :label="'INDEX Anzeigendaten'"
              @labelClick="
                handleLabelClick(SoftwareIntegrationType.indexAnzeigendaten)
              "
              @handleChange="
                handleChange(SoftwareIntegrationType.indexAnzeigendaten)
              "
            ></ClickableSwitch>
            <ClickableSwitch
              :status="companyConfigModel.softwareIntegration.payFlow"
              :label="'PayFlow'"
              @labelClick="handleLabelClick(SoftwareIntegrationType.payFlow)"
              @handleChange="handleChange(SoftwareIntegrationType.payFlow)"
            ></ClickableSwitch>
            <ClickableSwitch
              :label="'WhatsApp Integration'"
              :status="companyConfigModel.softwareIntegration.whatsApp"
              @labelClick="handleLabelClick(SoftwareIntegrationType.whatsApp)"
              @handleChange="handleChange(SoftwareIntegrationType.whatsApp)"
            ></ClickableSwitch>
            <ClickableSwitch
              :status="companyConfigModel.softwareIntegration.wordPressPlugin"
              :label="'WordPress Plugin auf Unternehmensseite'"
              @labelClick="handleLabelClick(SoftwareIntegrationType.wordPress)"
              @handleChange="handleChange(SoftwareIntegrationType.wordPress)"
            ></ClickableSwitch>
            <ClickableSwitch
              :status="companyConfigModel.softwareIntegration.zorst"
              :label="'ZORST Chrome Erweiterung'"
              @labelClick="handleLabelClick(SoftwareIntegrationType.zorst)"
              @handleChange="handleChange(SoftwareIntegrationType.zorst)"
            ></ClickableSwitch>
            <v-btn
              class="mt-10"
              type="submit"
              color="green-darken-3"
              :disabled="!isSubmitButtonActive"
              >Speichern</v-btn
            >
          </v-form>
        </v-card>
      </v-col>
      <v-col cols="12" md="8">
        <div class="d-flex">
          <v-spacer></v-spacer>
          <v-menu>
            <template v-slot:activator="{ props }">
              <v-btn color="white" v-bind="props"
                ><v-icon>fa-solid fa-bars</v-icon>Einstellungen
              </v-btn>
            </template>
            <v-list>
              <v-list-item
                v-for="(item, index) in filteredItems"
                :key="index"
                @click="setActiveComponent(item.component)"
              >
                <v-list-item-title>{{ item.title }}</v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
        </div>
        <component
          :is="activeConfigComponent"
          v-model="companyConfigModel"
          @save="updateCompanyConfig"
        />
      </v-col>
    </v-row>
  </v-container>
</template>

<script lang="ts">
import { CompanyConfig } from "@/models/company-config.model";
import { CompanyConfigComponentTypes } from "@/enums/company-config-components.enum";
import { CompanyConfigService } from "@/services/api/company-config.service";
import { defineComponent } from "vue";
import { SoftwareIntegrationType } from "@/enums/softwareintegration-type.enum";
import { UserHelperService } from "@/services/user-helper.service";
import AdminMenu from "@/components/menus/admin-menu.component.vue";
import ClickableSwitch from "./ClickableSwitch.vue";
import ConfigEmail from "./ConfigEmail.vue";
import ConfigIndexAnzeigendaten from "./ConfigIndexAnzeigendaten.vue";
import ConfigPayFlow from "./ConfigPayFlow.vue";
import ConfigPdHub from "./ConfigPdHub.vue";
import ConfigProfileTemplate from "./ConfigProfileTemplate.vue";
import ConfigReminderSettings from "./ConfigReminderSettings.vue";
import ConfigStatusColor from "./ConfigStatusColor.vue";
import ConfigTimelineEntries from "./ConfigTimelineEntries.vue";
import ConfigWhatsApp from "./ConfigWhatsApp.vue";
import ConfigWordPressPlugin from "./ConfigWordPressPlugin.vue";
import ConfigZorst from "./ConfigZorst.vue";
import ConfigZvooveOne from "./ConfigZvooveOne.vue";
import ConfigZvooveRecruit from "./ConfigZvooveRecruit.vue";
import ToastService from "@/services/toast.service";

export default defineComponent({
  name: "ConfigApi",
  components: {
    AdminMenu,
    ClickableSwitch,
    ConfigEmail,
    ConfigIndexAnzeigendaten,
    ConfigPdHub,
    ConfigPayFlow,
    ConfigProfileTemplate,
    ConfigReminderSettings,
    ConfigStatusColor,
    ConfigTimelineEntries,
    ConfigWhatsApp,
    ConfigWordPressPlugin,
    ConfigZorst,
    ConfigZvooveOne,
    ConfigZvooveRecruit,
  },
  data() {
    const userRole = this.getUserRole();

    return {
      apiService: new CompanyConfigService(),
      activeConfigComponent: CompanyConfigComponentTypes.ConfigEmail,
      assetsSettings: `${this.$store.getters.getEnv.VUE_APP_ASSETS_URL}/dispositioner-settings.svg`,
      companyConfigModel: {} as CompanyConfig,
      isSubmitButtonActive: false,
      items: [
        {
          title: "E-Mail Einstellungen",
          component: CompanyConfigComponentTypes.ConfigEmail,
          minRole: 5,
        },
        {
          title: "Profil Einstellungen",
          component: CompanyConfigComponentTypes.ConfigProfileTemplate,
          minRole: 5,
        },
        {
          title: "Erinnerungen/Deadlines",
          component: CompanyConfigComponentTypes.ConfigReminderSettings,
          minRole: 5,
        },
        {
          title: "Farben Kanbankarten",
          component: CompanyConfigComponentTypes.ConfigStatusColor,
          minRole: 5,
        },
        {
          title: "Kontaktarten",
          component: CompanyConfigComponentTypes.ConfigTimelineEntries,
          minRole: 5,
        },
      ],
      userRole: userRole,
      zvooverecruitstatus: [
        "neu",
        "terminiert",
        "pruefung",
        "vermittlung",
        "pool",
        "absage",
        "inaktiv",
      ],
      CompanyConfigComponentTypes: CompanyConfigComponentTypes,
      SoftwareIntegrationType,
    };
  },
  computed: {
    filteredItems() {
      return this.items.filter((item) => this.userRole <= item.minRole);
    },
  },
  created() {
    this.apiService.getConfig().then((companyConfig: CompanyConfig) => {
      this.companyConfigModel = companyConfig;
      this.syncSoftwareIntegration();
      this.syncApiKeys();
    });
  },
  methods: {
    handleChange(softwareIntegrationType: SoftwareIntegrationType) {
      this.isSubmitButtonActive = true;
      switch (softwareIntegrationType) {
        case SoftwareIntegrationType.dashboardAiKpiAnalysis:
          this.toggleStoreVariable(
            SoftwareIntegrationType.dashboardAiKpiAnalysis
          );
          break;
        case SoftwareIntegrationType.zvooveRecruit:
          this.toggleStoreVariable(SoftwareIntegrationType.zvooveRecruit);
          this.openComponent(
            this.companyConfigModel.softwareIntegration.zvooveRecruit,
            CompanyConfigComponentTypes.ConfigZvooveRecruit
          );
          break;
        case SoftwareIntegrationType.zvooveOne:
          this.toggleStoreVariable(SoftwareIntegrationType.zvooveOne);
          this.openComponent(
            this.companyConfigModel.softwareIntegration.zvooveOne,
            CompanyConfigComponentTypes.ConfigZvooveOne
          );
          this.toggleErp(SoftwareIntegrationType.zvooveOne);
          break;
        case SoftwareIntegrationType.payFlow:
          this.toggleStoreVariable(SoftwareIntegrationType.payFlow);
          this.openComponent(
            this.companyConfigModel.softwareIntegration.payFlow,
            CompanyConfigComponentTypes.ConfigPayFlow
          );
          break;
        case SoftwareIntegrationType.pdHub:
          this.toggleStoreVariable(SoftwareIntegrationType.pdHub);
          this.openComponent(
            this.companyConfigModel.softwareIntegration.pdHub,
            CompanyConfigComponentTypes.ConfigPdHub
          );
          this.toggleErp(SoftwareIntegrationType.pdHub);
          break;
        case SoftwareIntegrationType.indexAnzeigendaten:
          this.toggleStoreVariable(SoftwareIntegrationType.indexAnzeigendaten);
          this.openComponent(
            this.companyConfigModel.softwareIntegration.indexAnzeigendaten,
            CompanyConfigComponentTypes.ConfigIndexAnzeigendaten
          );
          break;
        case SoftwareIntegrationType.whatsApp:
          this.toggleStoreVariable(SoftwareIntegrationType.whatsApp);
          this.openComponent(
            this.companyConfigModel.softwareIntegration.whatsApp,
            CompanyConfigComponentTypes.ConfigWhatsApp
          );
          break;
        case SoftwareIntegrationType.wordPress:
          this.toggleStoreVariable(SoftwareIntegrationType.wordPress);
          this.openComponent(
            this.companyConfigModel.softwareIntegration.wordPressPlugin,
            CompanyConfigComponentTypes.ConfigWordPressPlugin
          );
          break;
        case SoftwareIntegrationType.zorst:
          this.toggleStoreVariable(SoftwareIntegrationType.zorst);
          this.openComponent(
            this.companyConfigModel.softwareIntegration.zvooveRecruit,
            CompanyConfigComponentTypes.ConfigZorst
          );
          break;
      }
    },
    handleLabelClick(softwareIntegrationType: SoftwareIntegrationType) {
      switch (softwareIntegrationType) {
        case SoftwareIntegrationType.indexAnzeigendaten:
          this.openComponent(
            this.companyConfigModel.softwareIntegration.indexAnzeigendaten,
            CompanyConfigComponentTypes.ConfigIndexAnzeigendaten
          );
          break;
        case SoftwareIntegrationType.payFlow:
          this.openComponent(
            this.companyConfigModel.softwareIntegration.payFlow,
            CompanyConfigComponentTypes.ConfigPayFlow
          );
          break;
        case SoftwareIntegrationType.pdHub:
          this.openComponent(
            this.companyConfigModel.softwareIntegration.pdHub,
            CompanyConfigComponentTypes.ConfigPdHub
          );
          break;
        case SoftwareIntegrationType.whatsApp:
          this.openComponent(
            this.companyConfigModel.softwareIntegration.whatsApp,
            CompanyConfigComponentTypes.ConfigWhatsApp
          );
          break;
        case SoftwareIntegrationType.wordPress:
          this.openComponent(
            this.companyConfigModel.softwareIntegration.wordPressPlugin,
            CompanyConfigComponentTypes.ConfigWordPressPlugin
          );
          break;
        case SoftwareIntegrationType.zorst:
          this.openComponent(
            this.companyConfigModel.softwareIntegration.zvooveRecruit,
            CompanyConfigComponentTypes.ConfigZorst
          );
          break;
        case SoftwareIntegrationType.zvooveRecruit:
          this.openComponent(
            this.companyConfigModel.softwareIntegration.zvooveRecruit,
            CompanyConfigComponentTypes.ConfigZvooveRecruit
          );
          break;
        case SoftwareIntegrationType.zvooveOne:
          this.openComponent(
            this.companyConfigModel.softwareIntegration.zvooveOne,
            CompanyConfigComponentTypes.ConfigZvooveOne
          );
          break;
      }
    },
    getUserRole() {
      const userService = UserHelperService.getInstance();
      const userRole = userService.getUserRole();
      return userRole;
    },

    setActiveComponent(component: CompanyConfigComponentTypes) {
      this.activeConfigComponent = component;
    },
    openComponent(isOpen: boolean, component: CompanyConfigComponentTypes) {
      if (isOpen) {
        this.setActiveComponent(component);
      }
    },
    updateCompanyConfig() {
      this.apiService
        .putCompanyConfig(this.companyConfigModel)
        .then(() => {
          // this.$store.dispatch("loadStoreInitialData");
          this.$store.commit("UPDATE_COMPANY_CONFIG", this.companyConfigModel);
          ToastService.showSuccess("Einstellungen erfolgreich gespeichert!");
          this.isSubmitButtonActive = false;
        })
        .catch((err) => {
          console.error(err);
          ToastService.showError("Fehler beim speichern der Einstellugen!");
        });
    },
    handleSave() {
      console.log("Save event triggered from", this.activeConfigComponent);
    },
    syncSoftwareIntegration() {
      const storeSoftwareIntegration =
        this.$store.state.company.softwareIntegration;
      const localSoftwareIntegration =
        this.companyConfigModel.softwareIntegration;
      const missingSoftwareIntegrations = Object.keys(
        storeSoftwareIntegration
      ).filter((key) => !(key in localSoftwareIntegration));

      if (missingSoftwareIntegrations.length > 0) {
        missingSoftwareIntegrations.forEach((key) => {
          (this.companyConfigModel.softwareIntegration as any)[key] =
            storeSoftwareIntegration[key];
        });
      }
    },
    syncApiKeys() {
      const storeApiKeys = this.$store.state.company.apiKeys;
      const localApiKeys = this.companyConfigModel.apiKeys;
      const missingApiKeys = Object.keys(storeApiKeys).filter(
        (key) => !(key in localApiKeys)
      );

      if (missingApiKeys.length > 0) {
        missingApiKeys.forEach((key) => {
          (this.companyConfigModel.apiKeys as any)[key] = storeApiKeys[key];
        });
      }
    },
    toggleErp(integration: string) {
      switch (integration) {
        case SoftwareIntegrationType.zvooveOne:
          if (this.companyConfigModel.softwareIntegration.zvooveOne) {
            this.companyConfigModel.softwareIntegration.pdHub = false;
          }
          break;
        case SoftwareIntegrationType.pdHub:
          if (this.companyConfigModel.softwareIntegration.pdHub) {
            this.companyConfigModel.softwareIntegration.zvooveOne = false;
          }
          break;
      }
    },
    toggleStoreVariable(variableName: string) {
      (this.companyConfigModel.softwareIntegration as any)[variableName] = !(
        this.companyConfigModel.softwareIntegration as any
      )[variableName];
    },
  },
});
</script>

<style scoped>
.v-row {
  padding: 2rem 1rem;
}

.title {
  display: flex;
  justify-content: space-between;
  color: var(--color-primary);
}
</style>
