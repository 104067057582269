<template>
  <div>
    <v-badge
      :color="$store.state.zorstAlive ? 'green-darken-3' : 'red-darken-4'"
      :content="$store.state.zorstAlive ? '✔' : '✘'"
      overlap
      offset-x="3"
      offset-y="9"
      class="mr-5"
    >
      <v-img class="mr-2" :src="zorstIcon" alt="Zorst Icon" width="24"></v-img>
    </v-badge>
    <v-tooltip activator="parent" location="end">
      {{
        $store.state.zorstAlive
          ? "Verbindung zu ZORST hergestellt"
          : "Keine Verbindung zu ZORST"
      }}
    </v-tooltip>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { mapGetters } from "vuex";
import ToastService from "@/services/toast.service";

export default defineComponent({
  name: "ZorstListener",
  computed: {
    ...mapGetters(["getEnv"]),
  },
  data() {
    return {
      zorstIcon: `${this.$store.getters.getEnv.VUE_APP_ASSETS_URL}/zorst_icon16.png`,
      checkInterval: null as number | null,
      retryCount: 0,
      maxRetries: 3,
    };
  },
  mounted() {
    window.addEventListener("message", this.handleZorstMessage);
    this.startCheckingZorst();
  },
  beforeUnmount() {
    window.removeEventListener("message", this.handleZorstMessage);
    if (this.checkInterval) {
      clearInterval(this.checkInterval);
    }
  },
  methods: {
    handleZorstMessage(event: any) {
      if (event.data && event.data.type === "ZORST_LOAD") {
        this.$store.state.zorstAlive = true;
        this.retryCount = 0; // Reset the retry count on successful connection
      }
    },
    startCheckingZorst() {
      this.checkInterval = setInterval(() => {
        if (!this.$store.state.zorstAlive) {
          this.retryCount++;
          window.postMessage(
            { type: "ZORST_CHECK", message: "Checking ZORST" },
            "*"
          );

          if (this.retryCount >= this.maxRetries && this.checkInterval) {
            ToastService.showReminder("Kein ZORST im Browser aktiviert");
            clearInterval(this.checkInterval);
          }
        }
      }, 5000);
    },
  },
});
</script>
