import { Api } from "./api.class";

export class GitLabService {
  private api = Api.getInstance();
  private urlPath = "/bug-report";

  async createBugReport(
    title: string,
    description: string,
    labels: string[],
    issueType: string
  ) {
    const response = await this.api.getBackendApi().post(`${this.urlPath}`, {
      title,
      description,
      labels,
      issueType,
    });
    return response;
  }
}
