import { Api } from "./api.class";
import { FunnelWithQuestions } from "@/models/funnel-with-questions.model";
import { QuestionObject } from "@/models/funnel-question.model";

export class FunnelService {
  private api = Api.getInstance();
  private urlPath = "/funnel-config";

  async fetchConfigData(funnelId: string): Promise<FunnelWithQuestions> {
    const response = await this.api
      .getBackendApi()
      .get<FunnelWithQuestions>(`${this.urlPath}/${funnelId}`);
    return response.data;
  }

  // Methode zum Speichern der Konfigurationsdaten
  async saveConfig(funnelId: string, config: any) {
    const response = await this.api
      .getBackendApi()
      .put<any>(`${this.urlPath}/_id/${funnelId}`, config);
    return response.data;
  }

  // Methode zum Speichern der Fragen-Daten
  async saveQuestions(funnelId: string, questions: QuestionObject) {
    const response = await this.api
      .getBackendApi()
      .put<any[]>(`/question/configId/${funnelId}`, questions);
    return response.data;
  }

  // Methode zum Abrufen der questions.json im Funnelverzeichnis
  async fetchQuestionsData(funnelId: string): Promise<QuestionObject[]> {
    const response = await this.api
      .getBackendApi()
      .get<QuestionObject[]>(`/question/${funnelId}`);
    return response.data;
  }

  async uploadImage(funnelId: string, imageFile: File, fileName: string) {
    try {
      const formData = new FormData();
      formData.append("image", imageFile);
      formData.append("filename", fileName);

      const config = {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      };

      const response = await this.api
        .getBackendApi()
        .post<any>(`/image/${funnelId}`, formData, config);
      return response.data;
    } catch (error) {
      console.error("Fehler beim Bild-Upload", error);
      throw new Error("Fehler beim Hochladen des Bildes");
    }
  }
}
