//src/enums/anzeigendaten.enum.ts
export enum IaApiType {
  auth = "auth",
  customers = "3.6",
  jodAds = "4.5",
}
export enum IaCareerLevel {
  "Ungelernte Arbeitskräfte" = 0,
  "Vorstände,Geschäftsführer" = 1,
  "Bereichsleiter,Hauptabteilungsleiter" = 2,
  "Abteilungsleiter,Gruppenleiter" = 3,
  "Projektleitung" = 4,
  "sonstige Fachkräfte" = 5,
  "gewerbliche Fachkräfte" = 6,
  "Ausbildung" = 7,
  "Praktika, Studenten, Schüler" = 8,
  "Young Professionals" = 9,
  "Fachkräfte mit akademischer Bildung" = 10,
  "Fachkräfte mit Berufsausbildung (mehrjährige Berufserfahrung oder Lehrberuf)" = 11,
  "Fachkräfte mit Berufsausbildung und speziellen Qualifikationen" = 12,
}
// strings has to be exaktly like this - comes from INDEX Anzeigendaten API

export const careerLevel = {
  0: IaCareerLevel["Praktika, Studenten, Schüler"],
  1: IaCareerLevel["Ausbildung"],
  2: IaCareerLevel["Ungelernte Arbeitskräfte"],
  3: IaCareerLevel["Young Professionals"],
  4: IaCareerLevel["sonstige Fachkräfte"],
  5: IaCareerLevel["gewerbliche Fachkräfte"],
  6: IaCareerLevel[
    "Fachkräfte mit Berufsausbildung (mehrjährige Berufserfahrung oder Lehrberuf)"
  ],
  7: IaCareerLevel[
    "Fachkräfte mit Berufsausbildung und speziellen Qualifikationen"
  ],
  8: IaCareerLevel["Fachkräfte mit akademischer Bildung"],
  9: IaCareerLevel["Projektleitung"],
  10: IaCareerLevel["Abteilungsleiter,Gruppenleiter"],
  11: IaCareerLevel["Bereichsleiter,Hauptabteilungsleiter"],
  12: IaCareerLevel["Vorstände,Geschäftsführer"],
};

export enum CareerLevel {
  "ungelernte Kraft" = 0,
  "Vorstände, Geschäftsführer" = 1,
  "Bereichsleiter, Hauptabteilungsleiter" = 2,
  "Abteilungsleiter, Gruppenleiter" = 3,
  "Projektleitung" = 4,
  "sonstige Fachkraft" = 5,
  "gewerbliche Fachkraft" = 6,
  "Ausbildung" = 7,
  "Praktika, Studenten, Schüler" = 8,
  "Young Professional" = 9,
  "Akademiker" = 10,
  "Fachkraft mit mehrjähriger Berufserfahrung" = 11,
  "Spezialist" = 12,
}
