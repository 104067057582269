<!--src/components/config/software-integration/ConfigZorst.vue-->
<template>
  <v-container>
    <h2 class="mb-5 d-flex">
      <v-img class="mr-3" :src="zorstIcon" alt="Zorst Icon" max-width="36">
      </v-img
      >Chrome Browsererweiterung ZORST
    </h2>
    <v-col cols="12" md="8">
      <v-card>
        <v-card-title>Download</v-card-title>
        <v-card-text>
          <h3>Schritt 1: Download der Datei</h3>
          Klicke auf diesen Button, um die Datei herunterzuladen:<br />
          <v-btn
            v-if="$store.state.company.softwareIntegration.zvooveOne"
            color="var(--color-primary)"
            style="color: var(--color-font-light)"
            href="https://zorst.dispositioner.de/download/zorst_full.zip"
            target="_blank"
          >
            ZORST 1.3 FULL
          </v-btn>
          <v-btn
            v-if="!$store.state.company.softwareIntegration.zvooveOne"
            color="var(--color-primary)"
            style="color: var(--color-font-light)"
            href="https://zorst.dispositioner.de/download/zorst_ats.zip"
            target="_blank"
          >
            ZORST 1.3 ATS
          </v-btn>
          <br /><br />
          <h3>Schritt 2: Entpacken der Datei</h3>
          1. Gehe zu dem Ordner, in dem du <code>zorst.zip</code> gespeichert
          hast.<br />
          2. Rechtsklicke auf die Datei und wähle "Alle extrahieren..." oder
          eine ähnliche Option.<br />
          3. Entpacke die Datei in einen Zielordner.<br /><br />
          <h3>Schritt 3: Installation der Erweiterung in Chrome</h3>
          1. Öffne Google Chrome.<br />
          2. Klicke auf das Dreipunkt-Menü (oben rechts) und wähle "Weitere
          Tools" > "Erweiterungen".<br />
          3. Aktiviere den Entwicklermodus (Schalter oben rechts).<br />
          4.Klicke auf "Entpackte Erweiterung laden".<br />
          5. Wähle den Ordner aus, in den du die Dateien entpackt hast.<br />
          6. Klicke auf "Ordner auswählen".<br /><br /><br />
          <p>Fertig! Die Erweiterung ist jetzt installiert.</p>
        </v-card-text>
      </v-card>
    </v-col>
  </v-container>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { mapGetters } from "vuex";

export default defineComponent({
  name: "ConfigZorst",
  computed: {
    ...mapGetters(["getEnv"]),
  },
  data() {
    return {
      zorstIcon: `${this.$store.getters.getEnv.VUE_APP_ASSETS_URL}/zorst_icon48.png`,
    };
  },
});
</script>
