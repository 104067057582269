//src/services/toast.service.ts
import { createApp } from "vue";

const ToastService = {
  show(message: string) {
    // Erstelle eine Wurzel-App-Instanz
    const app = createApp({
      template: `<div style="position: fixed; top: 1rem; left: 50%; transform: translateX(-50%); background-color: #333; color: #fff; padding: 0.5rem; border-radius: 5px; box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2); z-index: 9999;">{{ message }}</div>`,
      data() {
        return {
          message: "",
        };
      },
      created() {
        // Aktualisiere die Nachricht beim Erstellen der App
        this.message = message;

        // Starte den Timer für 5 Sekunden und schließe dann den Toast
        setTimeout(() => {
          app.unmount();
        }, 5000);
      },
    });

    // Montiere die App an ein temporäres Element
    const tempDiv = document.createElement("div");
    document.body.appendChild(tempDiv);
    app.mount(tempDiv);
  },
  showSuccess(message: string) {
    const app = createApp({
      template: `<div style="position: fixed; top: 50%; left: 50%; transform: translate(-50%, -50%); background-color: rgba(0, 128, 0, 0.7); color: #fff; padding: 1rem; border-radius: 0.5rem; box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2); border: 2px solid green; font-size: 2rem; z-index: 9999; display: flex; align-items: center;">
                <i class="fas fa-check-circle" style="margin-right: 1rem; margin-right: 2rem; font-size: 5rem;"></i>
                {{ message }}
               </div>`,
      data() {
        return {
          message: "",
        };
      },
      created() {
        this.message = message;

        setTimeout(() => {
          app.unmount();
        }, 3000);
      },
    });

    const tempDiv = document.createElement("div");
    document.body.appendChild(tempDiv);
    app.mount(tempDiv);
  },
  showReminder(message: string) {
    const app = createApp({
      template: `<div style="max-width: 80%; position: fixed; top: 80%; left: 50%; transform: translate(-50%, -50%); background-color: rgba(36, 69, 120, 0.7); color: #fff; padding: 1rem; border-radius: 0.5rem; box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2); border: 2px solid #244578; font-size: 1.5rem; z-index: 9999; display: flex; align-items: center;">
                <i class="fas fa-circle-info" style="margin-right: 1rem; margin-right: 2rem; font-size: 4rem;"></i>
                {{ message }}
               </div>`,
      data() {
        return {
          message: "",
        };
      },
      created() {
        this.message = message;

        setTimeout(() => {
          app.unmount();
        }, 7000);
      },
    });

    const tempDiv = document.createElement("div");
    document.body.appendChild(tempDiv);
    app.mount(tempDiv);
  },
  showError(message: string) {
    const app = createApp({
      template: `<div style="position: fixed; top: 50%; left: 50%; transform: translate(-50%, -50%); background-color: rgba(255, 0, 0, 0.7); color: #fff; padding: 1rem; border-radius: 0.5rem; box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2); border: 2px solid red; font-size: 2rem; z-index: 9999; display: flex; align-items: center; justify-content: center;">
            <i class="fas fa-exclamation-triangle" style="margin-right: 1rem; font-size: 3rem;"></i>
            {{ message }}
           </div>`,
      data() {
        return {
          message: "",
        };
      },
      created() {
        this.message = message;

        setTimeout(() => {
          app.unmount();
        }, 3000);
      },
    });

    const tempDiv = document.createElement("div");
    document.body.appendChild(tempDiv);
    app.mount(tempDiv);
  },
};

export default ToastService;
