//src/services/api/anzeigendaten.service.ts
import axios from "axios";
import store from "../../store/store";
import moment from "moment";
import {
  IndexJobAd,
  JobLeadsRequestBody,
  JodAdLeadsQuery,
} from "@/models/external/index-anzeigendaten.model";
import ToastService from "../toast.service";
import { CompanyConfigService } from "./company-config.service";
import { ApiConfig, CompanyConfig } from "@/models/company-config.model";
import { IaApiType } from "@/enums/anzeigendaten.enum";

export class AnzeigedatenService {
  companyConfigService = CompanyConfigService.getInstance();
  apiKeys = {} as ApiConfig;

  private static instance: AnzeigedatenService;

  constructor() {
    this.companyConfigService
      .getConfig()
      .then((companyConfig: CompanyConfig) => {
        this.apiKeys = companyConfig.apiKeys;
      });
  }

  public static getInstance(): AnzeigedatenService {
    if (!AnzeigedatenService.instance) {
      AnzeigedatenService.instance = new AnzeigedatenService();
    }
    return AnzeigedatenService.instance;
  }

  getBaseURL(apiVersion: string) {
    const apiKeys = this.apiKeys;
    if (apiVersion === IaApiType.auth) {
      return apiKeys.baseURLIndexAuth;
    } else if (apiVersion === IaApiType.customers) {
      return apiKeys.baseUrlIndexCustomers;
    } else {
      return apiKeys.baseURLIndexAds;
    }
  }

  //TODO: Add later searchlogic for mandant and user in the objects
  getToken(mandant?: string, user?: string) {
    const indexAnzeigendaten = this.apiKeys.indexAnzeigendaten;
    const tokenObject = indexAnzeigendaten.find((key) => {
      if (user && key.creditalsForUsersOnly.includes(user)) {
        return true;
      }
      if (mandant && key.creditalsForMandantsOnly.includes(mandant)) {
        return true;
      }
      return (
        key.creditalsForUsersOnly.includes("all") ||
        key.creditalsForMandantsOnly.includes("all")
      );
    });

    if (tokenObject) {
      return tokenObject.TOKEN;
    } else {
      throw new Error("No valid token found");
    }
  }

  async authAndGetIndexApiKey(login: string, password: string) {
    try {
      const response = await axios.post(this.getBaseURL(IaApiType.auth), [
        {
          login: login,
          password: password,
        },
      ]);

      if (response.data.includes("Not understood(LOGIN or PASSWORD).")) {
        ToastService.showError("Login oder Passwort fehlerhaft");
      } else if (response.status === 200) {
        return response.data;
      } else {
        throw new Error(`Error: Received status code ${response.status}`);
      }
    } catch (error) {
      console.error("Error fetching API key:", error);
      throw error;
    }
  }

  async getCustomerFromIndex(postalCode: string, customerName: string) {
    const baseURL = this.getBaseURL(IaApiType.customers); // Endpoint for customer API 3.6
    const requestBody = [
      {
        TOKEN: this.getToken(),
        COMPANY_NAME: customerName,
        COMPANY_POSTAL_CODE: postalCode,
      },
    ];

    try {
      const response = await axios.post(baseURL, requestBody, {
        headers: {
          "Content-Type": "application/json",
        },
      });
      if (response.data.includes("Not understood(TOKEN)")) {
        throw new Error("ungültiger Token");
      }

      // Transform in Customers Array
      const transformedData = response.data[0]?.EXPORT.RESULT.ITEM.map(
        (item: any, index: number) => {
          const uniqueNumber = `index_${Date.now()}_${index}`;
          let contacts = [];
          const contactPersons = Array.isArray(item["COMPANY-CONTACT-PERSON"])
            ? item["COMPANY-CONTACT-PERSON"]
            : [item["COMPANY-CONTACT-PERSON"]];
          contacts = contactPersons.map((person) => ({
            salutation: person["CONTACT-PERSON-SALUTATION"],
            title: person["CONTACT-PERSON-TITLE"],
            role: person["CONTACT-PERSON-FUNCTION"],
            firstName: person["CONTACT-PERSON-FIRSTNAME"],
            lastName: person["CONTACT-PERSON-LASTNAME"],
            phone: person["CONTACT-PERSON-PHONE"],
            email: person["CONTACT-PERSON-EMAIL"],
            status: "aus index",
          }));

          return {
            customerNumber: uniqueNumber,
            mandants: [
              store.state.company.loggedInUser.config.loggedInMandants[0],
            ],
            generalData: {
              name: item.COMPANY,
              name2: null,
              name3: null,
              searchTerm: customerName,
              costCenter: null,
              customerSince: null,
              accountsReceivable: null,
              createdOn: null,
              status: "Interessent",
            },
            addressAndCommunication: {
              street:
                item["COMPANY-ADDRESS"] +
                " " +
                (item["COMPANY-ADDRESS-NUMBER"] || ""),
              postalCode: item["COMPANY-POSTAL-CODE"],
              city: item["COMPANY-CITY"],
              country:
                item["COMPANY-COUNTRY"] === "D" ? "Deutschland" : "Unbekannt",
              state: null,
              phone1: item["COMPANY-PHONE"],
              phone2: null,
              email: item["COMPANY-EMAIL"],
              website: item["COMPANY-INTERNET"],
            },
            furtherInformation: {
              dispatcher: null,
              industries: item.BRANCHE.replace(/'/g, ""),
              WZ08: item.WZ08,
              Siret: item["COMPANY-SIRET"],
            },
            additionalInfo: {
              info1: null,
              info2: null,
              info3: null,
              indexLink: item["COMPANY-LINK"],
              indexCompanyId: item["COMPANY-ID"],
            },
            reporting: {
              jobs: item["COMPANY-REPORT-JOBS"],
              company: item["COMPANY-REPORT"],
              groupMedia: item["COMPANY-REPORT-GROUP-MEDIA"],
              groupFlexible: item["COMPANY-REPORT-GROUP-FLEXIBLE"],
              group: item["COMPANY-REPORT-GROUP"],
              media: item["COMPANY-REPORT-MEDIA"],
              flexible: item["COMPANY-REPORT-FLEXIBLE"],
            },
            contacts,
            jobAds: [],
          };
        }
      );
      return transformedData;
    } catch (error) {
      console.error("Error in getCustomerFromIndex:", error);
      throw error;
    }
  }

  async getJobAdsFromCustomer(indexCompanyId: string) {
    const baseURL = this.getBaseURL(IaApiType.jodAds);
    const retrievalDate = moment().format("DD.MM.YYYY");
    const requestBody = [
      {
        TOKEN: this.getToken(),
        COMPANY_ID: indexCompanyId,
        DATE_FROM: moment()
          .subtract(
            store.state.company.softwareIntegration.indexJobAdAgeLimitDays,
            "days"
          )
          .format("DD.MM.YYYY"),
      },
    ];

    try {
      const response = await axios.post(baseURL, requestBody, {
        headers: { "Content-Type": "application/json" },
      });

      if (response.data.includes("Not understood(TOKEN)")) {
        throw new Error("ungültiger Token");
      }

      const items = response.data[0].EXPORT.RESULT.ITEM;

      if (items && Array.isArray(items)) {
        const itemsWithRetrievalDate = items.map((item: IndexJobAd) => ({
          ...item,
          "AD-RETRIEVAL-DATE": retrievalDate,
        }));
        return itemsWithRetrievalDate;
      } else {
        return [];
      }
    } catch (error) {
      console.error("Error in getJobAdsFromCustomer:", error);
      throw error;
    }
  }

  async getLeads(query: JodAdLeadsQuery, differentPeriod?: number) {
    const baseURL = this.getBaseURL(IaApiType.jodAds);
    const retrievalDate = moment().format("DD.MM.YYYY");
    let indexJobLeadsAgeLimitDays =
      store.state.company.softwareIntegration.indexJobLeadsAgeLimitDays;
    if (differentPeriod) {
      indexJobLeadsAgeLimitDays = differentPeriod;
    }
    let requestBody: JobLeadsRequestBody = {
      TOKEN: this.getToken(),
      JOB_TITLE: query.JOB_TITLE,
      AI_SEARCH: "yes",
      POSTAL_CODE: query.POSTAL_CODE,
      SURROUNDING_REGION: query.SURROUNDING_REGION ?? "5",
      EXCLUDE_EMPLOYMENT_AGENCIES: "yes",
      DATE_FROM: moment()
        .subtract(indexJobLeadsAgeLimitDays, "days")
        .format("DD.MM.YYYY"),
      NUMBER_OF_ITEMS:
        store.state.company.softwareIntegration.indexJobLeadsMaxAds.toString(),
      JOB_POSTING: query.JOB_POSTING,
      CAREER_LEVEL: query.CAREER_LEVEL,
    };
    const attempts = [
      { remove: "CAREER_LEVEL" },
      { remove: "JOB_POSTING" },
      { replace: { SURROUNDING_REGION: "50" } },
      { final: true },
    ];

    for (const attempt of attempts) {
      try {
        const response = await axios.post(baseURL, [requestBody], {
          headers: { "Content-Type": "application/json" },
        });
        if (response.data.includes("Not understood(TOKEN)")) {
          throw new Error("ungültiger Token");
        }
        if (response.data[0].EXPORT.RESULT === "NOT FOUND" && !attempt.final) {
          if (attempt.remove) {
            delete requestBody[attempt.remove];
          }
          if (attempt.replace) {
            requestBody = { ...requestBody, ...attempt.replace };
          }
        } else if (
          response.data[0].EXPORT.RESULT === "NOT FOUND" &&
          attempt.final
        ) {
          console.error("Final attempt failed. No more retries.");
          return [
            {
              "AD-ID": "not found",
              "JOB-ID": "not found",
              "AD-RETRIEVAL-DATE": retrievalDate,
              "COMPANY-CITY": "",
              "COMPANY-POSTAL-CODE": "",
              POSITION: "keine Anzeigen gefunden",
            },
          ];
        } else {
          const items = response.data[0].EXPORT.RESULT.ITEM;
          if (items && Array.isArray(items)) {
            const itemsWithRetrievalDate = items.map((item) => ({
              ...item,
              "AD-RETRIEVAL-DATE": retrievalDate,
            }));
            return itemsWithRetrievalDate;
          } else {
            return [];
          }
        }
      } catch (error) {
        if (attempt.final) {
          throw error;
        }
      }
    }
  }
}
