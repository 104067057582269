<template>
  <h2>2. Funnelfragen</h2>
  <h4>Überschriften:</h4>
  <div class="Funnelheaders" v-if="headers">
    <!-- Input field for the Headers text -->
    Hauptfrage:
    <input v-model="headers.MainHeader" type="text" /> Subfragen:
    <input v-model="headers.SubHeader" type="text" /> Skills:
    <input v-model="headers.ChoicesHeader" type="text" />
  </div>
  <hr />
  <div>
    <h4>Fragenbaum:</h4>
    <!-- Logic for the Funnel-question-tree -->
    <div class="funnel-questions">
      <div class="main-questions" v-if="questions">
        <div
          v-for="question in questions as Question[]"
          :key="question.id"
          class="question-card"
        >
          <button class="remove-button" @click="removeQuestion(question.id)">
            <i class="fa-solid fa-square-minus"></i>
          </button>
          <div class="main-image uploadable-image">
            <img
              :src="`http://localhost:3001/${companyName}/${funnelId}/main${question.id}.png?time=${timeStamp}`"
              alt="MainImage"
              @click="openFilePicker('main' + question.id)"
            />
            <i class="fa-solid fa-cloud-arrow-up icon"></i>
            <input
              type="file"
              :id="`file-input-main${question.id}`"
              @change="uploadImage('main' + question.id)"
              style="display: none"
            />
          </div>
          Hauptfrage: {{ question.id }}
          <input
            class="funnel-textfield"
            v-model="question.question_text"
            type="text"
          />
          Exit: <input type="checkbox" v-model="question.exit" />
          <div class="sub-questions">
            <div
              v-for="subQuestion in question.sub_questions"
              :key="subQuestion.id"
              class="sub-question-card"
            >
              <button
                class="remove-button"
                @click="removeSubQuestion(question.id, subQuestion.id)"
              >
                <i class="fa-solid fa-square-minus"></i>
              </button>
              <div class="uploadable-image">
                <img
                  :src="`http://localhost:3001/${companyName}/${funnelId}/sub_${subQuestion.id}.png?time=${timeStamp}`"
                  alt="SubImage"
                  @click="openFilePicker('sub_' + subQuestion.id)"
                />
                <i class="fa-solid fa-cloud-arrow-up icon"></i>
                <input
                  type="file"
                  :id="`file-input-sub_${subQuestion.id}`"
                  @change="uploadImage('sub_' + subQuestion.id)"
                  style="display: none"
                />
              </div>
              <input
                class="funnel-textfield"
                v-model="subQuestion.question_text"
                type="text"
              /><br />
              <span style="font-size: 0.8em">Frage </span>{{ subQuestion.id }}
              <input type="checkbox" v-model="subQuestion.exit" /><span
                style="font-size: 0.8em"
                >Beendet Funnel</span
              >
              <hr />
              <div class="choices">
                <div
                  v-for="(choice, choiceIndex) in subQuestion.choices"
                  :key="choiceIndex"
                  class="choice-card"
                >
                  <input
                    class="funnel-textfield"
                    v-model="choice.choice_text"
                    type="text"
                  />
                  <button
                    class="remove-button"
                    @click="
                      removeChoice(question.id, subQuestion.id, choiceIndex)
                    "
                  >
                    <i class="fa-solid fa-circle-minus"></i>
                  </button>
                </div>
                <button
                  class="add-button"
                  @click="addChoice(question.id, subQuestion.id)"
                >
                  <i class="fa-solid fa-square-plus"></i>
                </button>
                <!-- Button zum Hinzufügen einer neuen Choice -->
              </div>
            </div>
            <button class="add-button" @click="addSubQuestion(question.id)">
              <i class="fa-solid fa-square-plus"></i>
            </button>
            <!-- Button zum Hinzufügen einer neuen Subfrage -->
          </div>
        </div>
        <button class="add-button" @click="addQuestion">
          <i class="fa-solid fa-square-plus"></i></button
        ><!-- Button zum Hinzufügen einer neuen Hauptfrage -->
      </div>
      <button class="save-button" @click="updateQuestions()">
        Änderungen speichern
      </button>
    </div>
  </div>
</template>

<script lang="ts">
/*import configService from "@/services/config.service";
import QuestionService from "@/services/question.service";
import { UploadService } from "@/services/upload.service";
*/
import {
  Headers,
  Question,
  QuestionObject,
} from "@/models/funnel-question.model";
import { FunnelService } from "@/services/api/funnel.service";
import { ConfigService } from "@/services/config.service";
import QuestionsService from "@/services/question.service";
import { UploadService } from "@/services/upload.service";
import { defineComponent } from "vue";

export default defineComponent({
  name: "FunnelQuestionsComponent",
  props: {
    questions: Array,
  },
  data() {
    return {
      configService: new ConfigService(),
      questionService: new QuestionsService(),
      uploadService: new UploadService(),
      funnelService: new FunnelService(),
      companyName: "",
      funnelId: "",
      headers: {} as Headers,
    };
  },
  methods: {
    updateQuestions() {
      const questionObject: QuestionObject = {
        company: {
          name: this.companyName,
        },
        headers: this.headers,
        configId: this.funnelId,
        questions: this.questions as Question[],
        template: "",
      };
      this.configService.updateQuestions(questionObject);
    },

    addQuestion() {
      this.questionService.addQuestion(this.questions as Question[]);
    },

    addSubQuestion(questionId: string) {
      this.questionService.addSubQuestion(
        this.questions as Question[],
        questionId
      );
    },

    addChoice(questionId: string, subQuestionId: string) {
      this.questionService.addChoice(
        this.questions as Question[],
        questionId,
        subQuestionId
      );
    },

    removeQuestion(questionId: string) {
      this.questionService.removeQuestion(
        this.questions as Question[],
        questionId
      );
    },

    removeSubQuestion(questionId: string, subQuestionId: string) {
      this.questionService.removeSubQuestion(
        this.questions as Question[],
        questionId,
        subQuestionId
      );
    },

    removeChoice(
      questionId: string,
      subQuestionId: string,
      choiceIndex: number
    ) {
      this.questionService.removeChoice(
        this.questions as Question[],
        questionId,
        subQuestionId,
        choiceIndex
      );
    },

    openFilePicker(inputIdSuffix: string) {
      this.uploadService.openFilePicker(inputIdSuffix);
    },

    uploadImage(fileType: any) {
      this.uploadService.uploadImage(fileType);
    },
  },
});
</script>
