<!-- src/components/whatsapp/QrCode.vue -->
<template>
  <v-dialog
    v-model="dialog"
    persistent
    :max-width="smAndDown ? 'var(--d-w-sd)' : 'var(--d-w-xs)'"
  >
    <v-card>
      <v-card-title class="headline"
        ><v-icon>fa-brands fa-whatsapp</v-icon
        ><v-icon size="x-small" class="mr-3">fa-solid fa-flask</v-icon>WhatsApp
        X-perimental Mode</v-card-title
      >
      <v-card-subtitle>Bitte Rufnummer verknüpfen</v-card-subtitle>
      <v-card-text class="d-flex justify-center">
        <v-tooltip activator="parent" location="end">
          <div v-html="tooltipContent"></div
        ></v-tooltip>
        <img v-if="qrCode" :src="qrCode" alt="Scan me with WhatsApp" />
        <p v-else>Warten auf QR-Code...</p>
      </v-card-text>
      <v-card-actions>
        <v-btn color="red-darken-4" @click="closeDialog">Abbrechen</v-btn>
        <v-spacer></v-spacer>
        <v-btn color="green-darken-3" @click="checkStatus">OK</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { useDisplay } from "vuetify";

export default defineComponent({
  name: "QrCode",
  emits: ["checkStatus"],
  data() {
    const { smAndDown } = useDisplay();
    return {
      smAndDown,
      qrCode: null as string | null,
      dialog: false,
      tooltipContent: `
       <div style="padding: 1rem">
        <ol>
          <li>Öffne WhatsApp auf deinem Telefon.</li>
          <li>
            Tippe auf einem Android-Gerät auf <strong>Menü</strong>
            bzw. auf einem iPhone auf <strong>Einstellungen</strong>.
          </li>
          <li>Tippe auf <strong>Verknüpfte Geräte</strong> und dann auf <strong>Gerät hinzufügen</strong>.</li>
          <li>Richte dein Telefon auf diesen Bildschirm, um den QR-Code zu scannen.</li>
        </ol>
       </div>
      `,
    };
  },
  methods: {
    async checkStatus() {
      this.$emit("checkStatus", true);
    },
    closeDialog() {
      this.dialog = false;
    },
    showQrCode(qr: string) {
      this.qrCode = qr;
      this.dialog = true;
    },
  },
});
</script>

<style>
img {
  width: 23rem;
  height: 23rem;
}
</style>
