<template>
  <div class="header-with-button">
    <h2>6. Leider nicht Seite</h2>
    <button
      class="save-button"
      @click="updateConfig({ NoWayPage: noWayPageCopy } as Config)"
    >
      Änderungen speichern
    </button>
  </div>
  <div class="config-option">
    Titel:<input v-model="noWayPageCopy.title" type="text" />
  </div>
  <div class="config-option">
    Subtitel:<input v-model="noWayPageCopy.subtitle" type="text" />
  </div>
  <div
    v-for="(step, index) in noWayPageCopy.steps"
    :key="index"
    class="config-option"
  >
    <label :for="'step-' + index">Punkt {{ index + 1 }}:</label>
    <input
      :id="'step-' + index"
      v-model="noWayPageCopy.steps[index]"
      type="text"
    />
  </div>
</template>
<script lang="ts">
import { ConfigService } from "@/services/config.service";
import { Config } from "@/models/funnel-config.model";
import { NoWayPage } from "@/models/funnel-config.model";
import { PropType, defineComponent, reactive, watchEffect } from "vue";

export default defineComponent({
  name: "FunnelNoWayPage",
  data() {
    return {
      configService: new ConfigService(),
    };
  },
  methods: {
    updateConfig(config: Config) {
      this.configService.updateConfig(config);
    },
  },
  props: {
    noWayPage: Object as PropType<NoWayPage>,
  },
  setup(props) {
    let noWayPageCopy = reactive<NoWayPage>({
      title: "",
      subtitle: "",
      steps: [],
    } as NoWayPage);

    watchEffect(() => {
      const newVal = props.noWayPage as NoWayPage;
      if (newVal) {
        noWayPageCopy = newVal;
      }
    });

    return { noWayPageCopy };
  },
});
</script>
