import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createBlock as _createBlock } from "vue"

const _hoisted_1 = ["innerHTML"]
const _hoisted_2 = ["src"]
const _hoisted_3 = { key: 1 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_icon = _resolveComponent("v-icon")!
  const _component_v_card_title = _resolveComponent("v-card-title")!
  const _component_v_card_subtitle = _resolveComponent("v-card-subtitle")!
  const _component_v_tooltip = _resolveComponent("v-tooltip")!
  const _component_v_card_text = _resolveComponent("v-card-text")!
  const _component_v_btn = _resolveComponent("v-btn")!
  const _component_v_spacer = _resolveComponent("v-spacer")!
  const _component_v_card_actions = _resolveComponent("v-card-actions")!
  const _component_v_card = _resolveComponent("v-card")!
  const _component_v_dialog = _resolveComponent("v-dialog")!

  return (_openBlock(), _createBlock(_component_v_dialog, {
    modelValue: _ctx.dialog,
    "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.dialog) = $event)),
    persistent: "",
    "max-width": _ctx.smAndDown ? 'var(--d-w-sd)' : 'var(--d-w-xs)'
  }, {
    default: _withCtx(() => [
      _createVNode(_component_v_card, null, {
        default: _withCtx(() => [
          _createVNode(_component_v_card_title, { class: "headline" }, {
            default: _withCtx(() => [
              _createVNode(_component_v_icon, null, {
                default: _withCtx(() => [
                  _createTextVNode("fa-brands fa-whatsapp")
                ]),
                _: 1
              }),
              _createVNode(_component_v_icon, {
                size: "x-small",
                class: "mr-3"
              }, {
                default: _withCtx(() => [
                  _createTextVNode("fa-solid fa-flask")
                ]),
                _: 1
              }),
              _createTextVNode("WhatsApp X-perimental Mode")
            ]),
            _: 1
          }),
          _createVNode(_component_v_card_subtitle, null, {
            default: _withCtx(() => [
              _createTextVNode("Bitte Rufnummer verknüpfen")
            ]),
            _: 1
          }),
          _createVNode(_component_v_card_text, { class: "d-flex justify-center" }, {
            default: _withCtx(() => [
              _createVNode(_component_v_tooltip, {
                activator: "parent",
                location: "end"
              }, {
                default: _withCtx(() => [
                  _createElementVNode("div", { innerHTML: _ctx.tooltipContent }, null, 8, _hoisted_1)
                ]),
                _: 1
              }),
              (_ctx.qrCode)
                ? (_openBlock(), _createElementBlock("img", {
                    key: 0,
                    src: _ctx.qrCode,
                    alt: "Scan me with WhatsApp"
                  }, null, 8, _hoisted_2))
                : (_openBlock(), _createElementBlock("p", _hoisted_3, "Warten auf QR-Code..."))
            ]),
            _: 1
          }),
          _createVNode(_component_v_card_actions, null, {
            default: _withCtx(() => [
              _createVNode(_component_v_btn, {
                color: "red-darken-4",
                onClick: _ctx.closeDialog
              }, {
                default: _withCtx(() => [
                  _createTextVNode("Abbrechen")
                ]),
                _: 1
              }, 8, ["onClick"]),
              _createVNode(_component_v_spacer),
              _createVNode(_component_v_btn, {
                color: "green-darken-3",
                onClick: _ctx.checkStatus
              }, {
                default: _withCtx(() => [
                  _createTextVNode("OK")
                ]),
                _: 1
              }, 8, ["onClick"])
            ]),
            _: 1
          })
        ]),
        _: 1
      })
    ]),
    _: 1
  }, 8, ["modelValue", "max-width"]))
}