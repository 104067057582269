<template>
  <GetPdHubUserToken ref="getPdHubUserTokenComponent"></GetPdHubUserToken>
  <v-container>
    <h2 class="mb-5">
      <v-icon size="small" class="mr-2">fa-solid fa-link</v-icon>Konfiguration
      für PD-Hub
    </h2>
    <v-form @submit.prevent="handleSubmit">
      <v-row>
        <v-col cols="12" md="9"></v-col>
        <v-col cols="12" md="3">
          <v-switch
            color="var(--color-primary)"
            v-model="localConfig.softwareIntegration.erpAutoDocu"
            label="automatische Dokumentation"
          ></v-switch>
        </v-col>
        <v-col cols="12" md="6">
          <v-text-field
            v-model="localConfig.apiKeys.pdHubBaseUrl"
            label="Base URL PD-Hub"
            variant="outlined"
          ></v-text-field>
        </v-col>
        <v-col cols="12" md="6">
          <v-text-field
            v-model="localConfig.apiKeys.pdHubCustomerId"
            label="CustomerId"
            variant="outlined"
          ></v-text-field>
        </v-col>
        <v-col cols="12" md="6">
          <v-text-field
            v-model="localConfig.apiKeys.pdHubClientId"
            label="Client Id"
            variant="outlined"
          ></v-text-field>
        </v-col>
        <v-col cols="12" md="6">
          <v-text-field
            v-model="localConfig.apiKeys.pdHubClientSecret"
            label="Client Secret"
            variant="outlined"
          ></v-text-field>
        </v-col>
        <v-col cols="12" md="6">
          <v-text-field
            v-model="localConfig.apiKeys.pdHubL1Mandant"
            label="L1 Mandant"
            variant="outlined"
          ></v-text-field>
        </v-col>
        <v-col cols="12" md="6">
          <v-card variant="outlined">
            <v-card-title>{{ cardTitle }}</v-card-title>
            <v-card-subtitle>{{ cardSubtitle }}</v-card-subtitle>
            <v-card-text>
              <v-btn variant="outlined" @click="openGetUserTokenModal">{{
                buttonText
              }}</v-btn>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
      <v-btn class="mt-10" type="submit" color="green-darken-3"
        >Speichern</v-btn
      >
    </v-form>
  </v-container>
</template>

<script lang="ts">
import { defineComponent, PropType } from "vue";
import moment from "moment";
import { CompanyConfig } from "@/models/company-config.model";
import GetPdHubUserToken from "@/components/modals/GetPdHubUserToken.vue";
import { User } from "@/models/user.model";
import { UserHelperService } from "@/services/user-helper.service";

export default defineComponent({
  name: "ConfigPdHub",
  emits: ["update:modelValue", "save"],
  components: {
    GetPdHubUserToken,
  },
  props: {
    modelValue: {
      type: Object as PropType<CompanyConfig>,
      required: true,
    },
  },
  data() {
    return {
      localConfig: { ...this.modelValue },
      user: {} as User | any,
      userHelperService: UserHelperService.getInstance(),
    };
  },
  computed: {
    cardTitle(): string {
      const token = this.user.config?.pdHub?.token;
      const tokenValidTill = this.user.config?.pdHub?.tokenValidTill;

      if (token && tokenValidTill) {
        if (moment().isBefore(moment(tokenValidTill))) {
          return "Aktuell bei PD-Hub angemeldet";
        } else {
          return "Token abgelaufen";
        }
      } else {
        return "Nicht angemeldet";
      }
    },
    cardSubtitle(): string {
      const tokenValidTill = this.user.config?.pdHub?.tokenValidTill;

      if (tokenValidTill) {
        if (moment().isBefore(moment(tokenValidTill))) {
          return `Gültig bis ${moment(tokenValidTill).format(
            "DD.MM.YYYY HH:mm"
          )}`;
        } else {
          return `Abgelaufen seit ${moment(tokenValidTill).format(
            "DD.MM.YYYY HH:mm"
          )}`;
        }
      } else {
        return "";
      }
    },
    buttonText(): string {
      const token = this.user.config?.pdHub?.token;
      const tokenValidTill = this.user.config?.pdHub?.tokenValidTill;

      if (token && tokenValidTill) {
        if (moment().isBefore(moment(tokenValidTill))) {
          return "Neuer Token";
        } else {
          return "Token erneuern";
        }
      } else {
        return "Anmelden";
      }
    },
  },
  watch: {
    modelValue(newValue: CompanyConfig) {
      this.localConfig = { ...newValue };
    },
  },
  created() {
    this.getUser();
  },
  methods: {
    async getUser() {
      this.user = this.$store.getters.user;
    },
    openGetUserTokenModal() {
      if (this.$refs.getPdHubUserTokenComponent) {
        (this.$refs.getPdHubUserTokenComponent as any).openModal();
      }
    },
    handleSubmit() {
      this.$emit("update:modelValue", this.localConfig);
      this.$emit("save");
    },
  },
});
</script>

<style scoped>
.headline {
  font-weight: bold;
}
</style>
