<!--/src/components/elements/ExternalSoftwareLinksMenu.vue-->
<template>
  <div
    v-if="
      zvooveOneCustomerNumber &&
      isNumeric(zvooveOneCustomerNumber) &&
      company.softwareIntegration.zvooveOne
    "
    @click.stop="openMenu"
  >
    <img
      src="@/assets/icon-zvoove-one.svg"
      class="zvoove-one-icon"
      alt="zvoove One Icon"
    />
    <v-tooltip activator="parent" location="bottom"
      >Unternehmen in ERP One öffnen</v-tooltip
    >
  </div>
  <div
    v-if="
      zvooveOneCustomerNumber &&
      isNumeric(zvooveOneCustomerNumber) &&
      company.softwareIntegration.pdHub
    "
    @click.stop="openMenu"
  >
    <img src="@/assets/icon-pd-hub.svg" class="pd-hub-icon" alt="PD-Hub Icon" />
    <v-tooltip activator="parent" location="bottom"
      >Optionen in PD-Hub</v-tooltip
    >
  </div>
  <div
    v-if="zvooveOneEmployeeNumber && company.softwareIntegration.zvooveOne"
    @click.stop="openMenu"
  >
    <img
      src="@/assets/icon-zvoove-one.svg"
      class="zvoove-one-icon"
      alt="zvoove One Icon"
    />
    <v-tooltip activator="parent" location="bottom"
      >Mitarbeiter in ERP One öffnen</v-tooltip
    >
  </div>
  <div
    v-if="zvooveOneEmployeeNumber && company.softwareIntegration.pdHub"
    @click.stop="openMenu"
  >
    <img src="@/assets/icon-pd-hub.svg" class="pd-hub-icon" alt="PD-Hub Icon" />
    <v-tooltip activator="parent" location="bottom"
      >Optionen in PD-Hub</v-tooltip
    >
  </div>
  <div
    v-if="
      zvooveRecruitMitarbeiterUuid && company.softwareIntegration.zvooveRecruit
    "
    @click.stop="openMenu"
  >
    <img
      src="@/assets/icon-ats-recruit.svg"
      class="zvoove-recruit-icon"
      alt="zvoove Recruit Icon"
    />
    <v-tooltip activator="parent" location="bottom"
      >Kandidat in ATS Recruit öffnen</v-tooltip
    >
  </div>
  <Teleport to="body">
    <v-container
      v-if="showMenu"
      :style="`position: fixed; top: ${y}px; left: ${x}px; max-width: 20rem; z-index: 3000;`"
    >
      <v-list dense>
        <v-list-item
          v-for="(item, index) in menuItems"
          :key="index"
          @click="item.action"
        >
          <div>
            {{ item.title }}
          </div>
        </v-list-item>
      </v-list>
    </v-container>
  </Teleport>
</template>

<script lang="ts">
import { defineComponent, PropType } from "vue";
import { mapGetters } from "vuex";

interface MenuItem {
  title: string;
  action: () => void;
}

export default defineComponent({
  emits: ["getRecruitData", "getErpData", "putErpData"],
  props: {
    zvooveOneCustomerNumber: String as PropType<string>,
    zvooveOneEmployeeNumber: String as PropType<string>,
    zvooveRecruitMitarbeiterUuid: String as PropType<string>,
    hideUpdateMenuPoint: Boolean as PropType<boolean>,
  },
  computed: {
    ...mapGetters(["company"]),
  },
  data() {
    return {
      showMenu: false,
      x: 0,
      y: 0,
      menuItems: [] as MenuItem[],
    };
  },
  mounted() {
    document.addEventListener("click", this.closeMenu);
  },
  beforeUnmount() {
    document.removeEventListener("click", this.closeMenu);
  },
  methods: {
    adjustMenuPosition() {
      const menuWidth = 320;
      const screenWidth = window.innerWidth;
      if (this.x + menuWidth > screenWidth) {
        this.x = screenWidth - menuWidth;
      }
    },
    isNumeric(value: string | null | undefined): boolean {
      return /^\d+$/.test(value ?? "");
    },
    closeMenu() {
      this.showMenu = false;
    },
    openExternalLink(target: string) {
      let externalLink = "";
      if (this.zvooveOneCustomerNumber) {
        externalLink = `${this.company.softwareIntegration.zvooveOneLink}/unternehmen/${this.zvooveOneCustomerNumber}/${target}`;
      } else if (this.zvooveOneEmployeeNumber) {
        externalLink = `${this.company.softwareIntegration.zvooveOneLink}/personal/${this.zvooveOneEmployeeNumber}/${target}`;
      } else if (this.zvooveRecruitMitarbeiterUuid) {
        externalLink = `${this.company.softwareIntegration.zvooveRecruitLink}/bewerber/${this.zvooveRecruitMitarbeiterUuid}/${target}`;
      }
      window.open(externalLink, "_blank", "width=950,height=900"); //Width 950 supress zvoove Recruits Side Menu and TODO: use responsive dimensions from service
    },
    getMenuItems() {
      let items = [];
      if (
        this.zvooveOneCustomerNumber &&
        this.company.softwareIntegration.zvooveOne &&
        !this.hideUpdateMenuPoint
      ) {
        items.push({
          title: "▲ Daten in ERP laden...",
          action: () => this.$emit("putErpData"),
        });
        items.push({
          title: "▼ Daten aus ERP aktualisieren...",
          action: () => this.$emit("getErpData"),
        });
      } else if (
        this.zvooveOneCustomerNumber &&
        this.company.softwareIntegration.pdHub &&
        !this.hideUpdateMenuPoint
      ) {
        items.push({
          title: "▲ Daten in PD-Hub laden...",
          action: () => this.$emit("putErpData"),
        });
        items.push({
          title: "▼ Daten aus PD-Hub aktualisieren...",
          action: () => this.$emit("getErpData"),
        });
      } else if (
        this.zvooveOneEmployeeNumber &&
        this.company.softwareIntegration.zvooveOne &&
        !this.hideUpdateMenuPoint
      ) {
        items.push({
          title: "▲ Daten in ERP laden...",
          action: () => this.$emit("putErpData"),
        });
        items.push({
          title: "▼ Daten aus ERP aktualisieren...",
          action: () => this.$emit("getErpData"),
        });
      } else if (
        this.zvooveOneEmployeeNumber &&
        this.company.softwareIntegration.pdHub &&
        !this.hideUpdateMenuPoint
      ) {
        items.push({
          title: "▲ Daten in PD-Hub laden...",
          action: () => this.$emit("putErpData"),
        });
        items.push({
          title: "▼ Daten aus PD-Hub aktualisieren...",
          action: () => this.$emit("getErpData"),
        });
      } else if (
        this.zvooveRecruitMitarbeiterUuid &&
        !this.hideUpdateMenuPoint
      ) {
        items.push({
          title: "▼ Daten aus ATS aktualisieren...",
          action: () => this.$emit("getRecruitData"),
        });
      }

      if (
        this.zvooveOneCustomerNumber &&
        this.company.softwareIntegration.zvooveOne
      ) {
        items.push({
          title: "Übersicht",
          action: () => this.openExternalLink("uebersicht"),
        });
        items.push({
          title: "Ansprechpartner",
          action: () => this.openExternalLink("ansprechpartner"),
        });
        items.push({
          title: "Aktivitäten",
          action: () => this.openExternalLink("aktivitaeten"),
        });
        items.push({
          title: "Aufträge",
          action: () => this.openExternalLink("auftraege"),
        });
        items.push({
          title: "Einsätze",
          action: () => this.openExternalLink("ueberlassungen"),
        });
        items.push({
          title: "Abrechnung",
          action: () => this.openExternalLink("abrechnung"),
        });
        items.push({
          title: "Berufsdetails",
          action: () => this.openExternalLink("berufsdetails"),
        });
        items.push({
          title: "Verbundene Entleiher",
          action: () => this.openExternalLink("verbundeneentleiher"),
        });
        items.push({
          title: "Belege",
          action: () => this.openExternalLink("belege"),
        });
      } else if (
        this.zvooveOneEmployeeNumber &&
        this.company.softwareIntegration.zvooveOne
      ) {
        items.push({
          title: "Übersicht",
          action: () => this.openExternalLink("uebersicht"),
        });
        items.push({
          title: "Abrechnung",
          action: () => this.openExternalLink("abrechnung"),
        });
        items.push({
          title: "Lohnbuchhaltung",
          action: () => this.openExternalLink("lohnbuchhaltung"),
        });
        items.push({
          title: "Berufsdetails",
          action: () => this.openExternalLink("berufsdetails"),
        });
        items.push({
          title: "Einsätze/Fehlzeiten",
          action: () => this.openExternalLink("ueberlassungen"),
        });
        items.push({
          title: "Aktivitäten",
          action: () => this.openExternalLink("aktivitaeten"),
        });
        items.push({
          title: "Tätigkeiten Vorarbeitgeber",
          action: () => this.openExternalLink("taetigkeitenvorarbeitgeber"),
        });
        items.push({
          title: "Arbeitszeiten",
          action: () => this.openExternalLink("arbeitszeiten"),
        });
        items.push({
          title: "Vorsorgeuntersuchungen",
          action: () => this.openExternalLink("vorsorgeuntersuchungen"),
        });
        items.push({
          title: "Sanktionsprüfungen",
          action: () => this.openExternalLink("sanktionspruefungen"),
        });
      } else if (this.zvooveRecruitMitarbeiterUuid) {
        items.push({
          title: "Historie",
          action: () => this.openExternalLink("dashboard"),
        });
        items.push({
          title: "Stammdaten",
          action: () => this.openExternalLink("stammdaten"),
        });
        items.push({
          title: "Werdegang",
          action: () => this.openExternalLink("vita"),
        });
        items.push({
          title: "Anhänge",
          action: () => this.openExternalLink("anhaenge"),
        });
        items.push({
          title: "Skills",
          action: () => this.openExternalLink("perfectmatch/skills"),
        });
        items.push({
          title: "Exposé",
          action: () => this.openExternalLink("profil"),
        });
      }
      return items;
    },
    openMenu(event: MouseEvent) {
      this.showMenu = true;
      this.x = event.clientX;
      this.y = event.clientY;
      this.adjustMenuPosition();
      this.menuItems = this.getMenuItems();
    },
  },
});
</script>
<style scoped>
.zvoove-one-icon,
.pd-hub-icon,
.zvoove-recruit-icon {
  margin-right: 1rem;
  cursor: pointer;
  transition: all 0.5s ease;
  width: 1.7rem;
  height: 1.7rem;
}
.zvoove-one-icon:hover,
.pd-hub-icon:hover,
.zvoove-recruit-icon:hover {
  scale: var(--medium-scale-up);
}
</style>
