// src/services/tooltip.service.ts

export const TooltipService = {
  showTooltip(event: MouseEvent, message: string) {
    const tooltip = document.createElement("div");
    tooltip.textContent = message;
    tooltip.style.position = "fixed";
    tooltip.style.left = `${event.clientX + 10}px`;
    tooltip.style.top = `${event.clientY + 10}px`;
    tooltip.style.backgroundColor = "black";
    tooltip.style.color = "white";
    tooltip.style.padding = "0.7rem";
    tooltip.style.borderRadius = "0.3rem";
    tooltip.style.zIndex = "1000";
    tooltip.style.pointerEvents = "none";
    tooltip.setAttribute("id", "custom-tooltip");

    document.body.appendChild(tooltip);

    setTimeout(() => {
      document.body.removeChild(tooltip);
    }, 3000);
  },
  showItemDragoverMessage(event: MouseEvent, message: string) {
    const tooltip = document.createElement("div");
    tooltip.textContent = message;
    tooltip.style.position = "fixed";
    tooltip.style.left = `${event.clientX + 10}px`;
    tooltip.style.top = `${event.clientY + 10}px`;
    tooltip.style.backgroundColor = "black";
    tooltip.style.color = "white";
    tooltip.style.padding = "0.7rem";
    tooltip.style.borderRadius = "0.3rem";
    tooltip.style.zIndex = "1000";
    tooltip.style.pointerEvents = "none";
    tooltip.setAttribute("id", "custom-tooltip");

    document.body.appendChild(tooltip);

    setTimeout(() => {
      document.body.removeChild(tooltip);
    }, 3000);
  },
  showExpiredEvents(event: MouseEvent, htmlContent: string) {
    const tooltip = document.createElement("div");
    tooltip.innerHTML = htmlContent;
    tooltip.style.position = "fixed";
    tooltip.style.visibility = "hidden";
    tooltip.style.backgroundColor = "white";
    tooltip.style.color = "black";
    tooltip.style.padding = "1rem";
    tooltip.style.borderRadius = "0.3rem";
    tooltip.style.border = "2px solid #244578";
    tooltip.style.zIndex = "1000";
    tooltip.style.pointerEvents = "none";
    tooltip.setAttribute("id", "custom-tooltip");

    document.body.appendChild(tooltip);

    const tooltipWidth = tooltip.offsetWidth;
    let leftPosition = event.clientX - tooltipWidth - 10;

    if (leftPosition < 0) {
      leftPosition = 10;
    }

    tooltip.style.left = `${leftPosition}px`;
    tooltip.style.top = `${event.clientY + 10}px`;
    tooltip.style.visibility = "visible";

    /* setTimeout(() => {
      document.body.removeChild(tooltip);
    }, 3000); */
  },
};
