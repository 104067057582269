import { Email } from "@/models/email.model";
import { Api } from "./api.class";
import { ApiResponse } from "@/models/api-response.model";
import { blobToBase64 } from "@/helper/pdf.helper";

export class EmailService {
  private api = Api.getInstance();
  private urlPath = "/email";

  async sendMail(message: Email): Promise<ApiResponse> {
    if (message.pdf && message.pdf.data) {
      message.pdf.data = await blobToBase64(message.pdf.data as Blob);
    }
    const response = await this.api
      .getBackendApi()
      .post<ApiResponse>(`${this.urlPath}`, message);
    return response.data;
  }

  async sendEmailValidation(id: number) {
    const response = await this.api
      .getBackendApi()
      .post(`${this.urlPath}/validation/${id}`);
    return response.data;
  }
}
