<!--src/components/disposition/CustomerItem.vue-->
<template>
  <v-container class="ma-0 pa-0">
    <v-hover>
      <template v-slot:default="{ isHovering, props }">
        <v-container
          @drop="handleDrop"
          @dragover="handleDragover"
          class="ma-0 pa-0 item"
          v-if="isFullyCollapsed"
        >
          <v-card-title
            :style="{
              backgroundColor: isHovering ? itemHoverColor : itemColor,
            }"
            class="d-flex justify-space-between pa-0"
          >
            <span class="pa-0 text-body-2">
              <span
                class="ml-1 font-weight-light text-caption text-medium-emphasis"
                >{{ customer?.customerNumber }}
              </span>
              {{ customer?.generalData?.name }}
            </span>
            <span
              class="mx-1 font-weight-light text-caption text-medium-emphasis"
            >
              <span
                v-if="customer?.jobAds && customer.jobAds.length > 0"
                @mouseenter="handleMouseEnter"
                @mouseleave="scheduleJobAdListClose"
              >
                <i class="fa-solid fa-rectangle-ad"></i>
              </span>
              {{ customer?.addressAndCommunication?.city }}
            </span>
          </v-card-title>
          <v-divider class="my-1"></v-divider>
        </v-container>
        <v-card
          v-if="!isFullyCollapsed"
          class="item pa-2 mb-2"
          :elevation="itemElevation"
          rounded-lg
          :border="itemBorder"
          v-bind="props"
          :color="isHovering ? itemHoverColor : itemColor"
          :class="{
            'fully-collapsed': isFullyCollapsed,
            expanded: isExpanded,
            'fully-expanded': isFullyExpanded,
          }"
          @dblclick="toggleFullExpansion"
          @drop="handleDrop"
          @dragover="handleDragover"
        >
          <v-icon class="match-icon" v-if="candidatesTagsMatching"
            >fa-solid fa-heart</v-icon
          >
          <div class="item-collapsed">
            <v-card-title class="pa-0 text-body-2">
              <img
                v-if="
                  customer?.additionalInfo && customer?.additionalInfo.logoLink
                "
                alt="Logo"
                :src="customer?.additionalInfo.logoLink"
                class="logo-img"
              />
              {{ customer?.generalData?.name }}
            </v-card-title>
            <v-card-subtitle class="pl-0">
              {{ customer?.addressAndCommunication?.postalCode }}
              {{ customer?.addressAndCommunication?.city }} ({{
                customer?.customerNumber
              }})
            </v-card-subtitle>
          </div>
          <button @click="toggleExpansion" class="toggle-button">
            {{ isExpanded ? "▲" : "▼" }}
          </button>
          <div
            v-if="customer?.jobAds && customer.jobAds.length > 0"
            class="job-ad-hover"
            @mouseenter="handleMouseEnter"
            @mouseleave="scheduleJobAdListClose"
          >
            <i class="fa-solid fa-rectangle-ad"></i>
          </div>
          <div class="hide-on-inactive-column" v-if="isActive">
            <div
              class="customer-mandant"
              @click="openPicker(ModalMode.mandant)"
            >
              {{ mandantName() }}
            </div>
            <div
              class="customer-status"
              @click="openPicker(ModalMode.customerStatus)"
            >
              {{ customer?.generalData?.status }}
            </div>
          </div>
          <v-divider></v-divider>
          <div class="status-communication-container">
            <div v-if="isExpanded" class="communication-icons-container">
              <div
                v-if="
                  (softwareIntegration as SoftwareIntegration).indexAnzeigendaten &&
                  (!customer?.additionalInfo?.indexLink ||
                    customer?.additionalInfo?.indexLink === '')
                "
              >
                <img
                  src="@/assets/icon-index-disconnect.png"
                  alt="Index Anzeigendaten disconnected"
                  class="index-erp-ats-icon"
                  @click="openUpdateCustomerModal(ModalMode.indexAnzeigendaten)"
                />
                <v-tooltip activator="parent" location="bottom"
                  >mit Anzeigendaten verknüpfen</v-tooltip
                >
              </div>
              <div
                v-if="
                  (softwareIntegration as SoftwareIntegration).zvooveOne &&
                  (!isNumeric(customer?.customerNumber) ||
                    customer?.generalData?.costCenter == null)
                "
              >
                <img
                  src="@/assets/icon-zvoove-one-disconnect.png"
                  alt="ERP One disconnected"
                  class="index-erp-ats-icon"
                  title="nicht mit ERP One verknüpft"
                  @click="openUpdateCustomerModal(ModalMode.zvooveOne)"
                />
                <v-tooltip activator="parent" location="bottom"
                  >mit bestehendem Kunden in ERP One verknüpfen</v-tooltip
                >
              </div>
              <div v-if="isNotZvooveCustomerNumber()">
                <img
                  src="@/assets/icon-zvoove-one-upload.svg"
                  alt="ERP One upload"
                  class="index-erp-ats-icon"
                  title="in ERP One anlegen"
                  @click="$emit('createInErp', customer)"
                />
              </div>
              <div
                v-if="
                  (softwareIntegration as SoftwareIntegration).pdHub &&
                  (!isNumeric(customer?.customerNumber) ||
                    customer?.generalData?.costCenter == null)
                "
              >
                <img
                  src="@/assets/icon-pd-hub-disconnect.svg"
                  alt="PD-Hub disconnected"
                  class="index-erp-ats-icon"
                  title="nicht mit PD-Hub verknüpft"
                  @click="openUpdateCustomerModal(ModalMode.pdHub)"
                />
                <v-tooltip activator="parent" location="bottom"
                  >mit bestehendem Kunden in PD-Hub verknüpfen</v-tooltip
                >
              </div>
              <div v-if="isNotPdHubCustomerNumber()">
                <img
                  src="@/assets/icon-pd-hub-upload.svg"
                  alt="pd hub upload"
                  class="index-erp-ats-icon"
                  title="in PD-Hub anlegen"
                  @click="$emit('createInErp', customer)"
                />
                <v-tooltip activator="parent" location="bottom"
                  >in PD-Hub anlegen</v-tooltip
                >
              </div>
              <div v-if="isNotPdHubCustomerNumber()">
                <img
                  src="@/assets/icon-pd-hub-upload.svg"
                  alt="pd hub upload"
                  class="index-erp-ats-icon"
                  title="in PD-Hub anlegen"
                  @click="$emit('createInErp', customer)"
                />
              </div>
              <div>
                <i
                  class="fa-solid fa-person-circle-plus get-contacts-icon"
                  @click="
                    openUpdateCustomerModal(ModalMode.scrapeContactsFromSite)
                  "
                >
                  <v-tooltip activator="parent" location="bottom"
                    >Ansprechpartner von Webseite ergänzen</v-tooltip
                  >
                </i>
              </div>
            </div>
            <div class="communication-icons-container">
              <zvooveOneLinkMenu
                v-if="isExpanded && customer?.generalData?.costCenter != null"
                :zvooveOneCustomerNumber="customer?.customerNumber"
                @getErpData="updateCustomerFromErp()"
                @putErpData="updateErpFromCustomer()"
              ></zvooveOneLinkMenu>
              <PhoneClient
                v-if="isExpanded"
                ref="phoneClientComponent"
                :customer="customer"
                :phoneNumbers="filteredPhoneNumbers"
                @addFollowUpEvent="addFollowUpOnDependencies"
                @collapseParentItem="collapseItem"
                @isDialingNumber="phoneClientIsDialing"
                @setAppointmentEvent="setAppointmentOnDependencies"
              ></PhoneClient>
              <MailClient
                v-if="isExpanded || isMailClientActive"
                ref="mailClientComponent"
                :AiMessageType="'mailCustomer'"
                :customer="customer"
                :emailAddresses="filteredEmail"
                :emailObject="email"
                :signature="signatureText"
                @collapseParentItem="collapseItem"
              ></MailClient>
            </div>
          </div>
          <v-divider></v-divider>
          <Checklist
            :checklist="customer?.checklist || []"
            @updateChecklist="updateChecklist"
          ></Checklist>
          <v-divider></v-divider>
          <div v-if="isExpanded" class="tags-container">
            <v-chip
              v-for="(tag, index) in customer?.tags"
              :key="index"
              class="customer-tag"
              color="var(--color-primary)"
              text-color="var(--color-font-light)"
              @click:close="removeTag(index)"
            >
              {{ tag }}
              <v-icon
                small
                @click.stop="removeTag(index)"
                class="fa fa-times"
              ></v-icon>
            </v-chip>
            <v-text-field
              variant="outlined"
              class="mt-2"
              label="Neuer Tag"
              style="max-width: 10rem"
              rounded="lg"
              density="compact"
              v-model="newTag"
              @keyup.enter="addTag"
            >
              <v-tooltip activator="parent" location="bottom"
                >mit "Enter" neuen Tag hinzufügen</v-tooltip
              >
            </v-text-field>
            <v-btn
              icon
              class="mt-3"
              variant="text"
              density="compact"
              @click="generateAndSetCustomerTags()"
            >
              <v-icon size="small">fas fa-arrows-rotate</v-icon>
              <v-tooltip activator="parent" location="bottom"
                >Tags anhand historischer Einsätze und ausgeschriebener Stellen
                neu generieren</v-tooltip
              >
            </v-btn>
          </div>
          <v-divider></v-divider>
          <Dependencies
            v-if="customer && hasLinking(customer?._id)"
            :mandant="[customer?.mandants[0] || '']"
            :customer_id="customer?._id"
            :linkingDescription="linkingDescription"
            ref="dependenciesComponent"
            @generateProfile="generateProfileFromDependencies()"
            @openPhoneClient="openPhoneClient()"
          />
          <v-divider></v-divider>
          <Assignments
            v-if="isExpanded"
            :isCustomer="true"
            :assignments="customer?.assignments"
            @updateAssignments="
              getAssignmentsFromErp(customer?.customerNumber ?? '')
            "
          ></Assignments>
          <Timeline
            contact="Test Ansprechpartner"
            :customer="customer"
            v-if="isExpanded"
          ></Timeline>
          <SelectionPicker
            v-if="pickerDialog"
            :pickerItems="pickerItems"
            :showDialog="pickerDialog"
            :dialogTitle="pickerTitle"
            @update:showDialog="pickerDialog = $event"
            @itemSelected="saveSelection"
          ></SelectionPicker>
          <DialogUpdateCustomer
            v-if="showModal || resultsModal"
            :showModal="showModal"
            :showSearchResultsFromErp="showSearchResultsFromErp"
            @update:showModal="showModal = $event"
            :results="results"
            :resultsModal="resultsModal"
            @update:resultsModal="resultsModal = $event"
            :modalTitle="modalTitle"
            :modalType="modalType"
            :addCustomerModalInput1="addCustomerModalInput1"
            :addCustomerModalInput2="addCustomerModalInput2"
            :addCustomerModalLabel1="addCustomerModalLabel1"
            :addCustomerModalLabel2="addCustomerModalLabel2"
            :modalActionButtonText="modalActionButtonText"
            @submit="handleModalSubmit"
            @selectedResult="handleSelectedResult"
          />
          <ProfileGenerator
            v-if="isProfileGeneratorActive"
            ref="profileGeneratorComponent"
            :profileSourceData="profileSourceData"
            @openMailClient="openMailClientFromProfileGenerator"
          />
        </v-card>
        <Teleport to="body">
          <JobAdListCustomer
            v-if="!isExpanded && customer?.jobAds"
            ref="jobAdListRef"
            @fetchIndexAnzeigendaten="autoFetchJobAds(true)"
            :jobAds="customer?.jobAds || []"
            :mandant="customer?.mandants[0] || ''"
            :positionX="jobAdListPosition.x"
            :positionY="jobAdListPosition.y"
          />
        </Teleport>
      </template>
    </v-hover>
  </v-container>
</template>

<script lang="ts">
import Assignments from "./elements/Assignments.vue";
import Checklist from "./elements/Checklist.vue";
import { CheckList } from "@/models/checklist.model";
import { Customer, CustomerContact } from "@/models/customer.model";
import { CustomerService } from "@/services/api/customer.service";
import { defineComponent, nextTick, PropType } from "vue";
import { DialogResponse } from "@/enums/dialog-action.enum";
import DialogService from "@/services/dialog.service";
import {
  extractCustomerContacts,
  generateCustomerTags,
} from "@/services/ai.service";
import { AnzeigedatenService } from "@/services/api/anzeigendaten.service";
import { LinkingService } from "@/services/api/linking.service";
import { Mandant } from "@/models/mandant.model";
import { mapGetters, mapMutations } from "vuex";
import { SpinnerService } from "@/services/spinner.service";
import { TooltipService } from "@/services/tooltip.service";
import { ZorstService } from "@/services/zorst.service";
import { ZvooveOneCustomer } from "@/services/api/api-integration-one.service";
import Dependencies from "@/components/disposition/elements/Dependencies.vue";
import JobAdListCustomer from "@/components/disposition/elements/JobAdListCustomer.vue";
import MailClient from "./elements/MailClient.vue";
import { ModalMode } from "@/enums/dialog-action.enum";
import DialogUpdateCustomer from "@/components/disposition/elements/DialogAddCustomer.vue";
import moment from "moment";
import PhoneClient from "./elements/PhoneClient.vue";
import ProfileGenerator from "./elements/ProfileGenerator.vue";
import SelectionPicker from "./elements/SelectionPicker.vue";
import Timeline from "./elements/Timeline.vue";
import ToastService from "@/services/toast.service";
import zvooveOneLinkMenu from "./elements/ExternalSoftwareLinksMenu.vue";
import { ProfileJobAd, ProfileSourceData } from "@/models/profile.model";
import { InterComponentMessage } from "@/enums/inter-component-messagin.enum";
import Fuse from "fuse.js";
import { MutationType } from "@/enums/vuex-mutationtype.enum";
import { SoftwareIntegration } from "@/models/company-config.model";
import { PdHubService } from "@/services/api/pd-hub.service";
import { Salutation } from "@/enums/salutation.model";
import { getCustomerStateValue } from "@/enums/customer-states.enum";
import { User } from "@/models/user.model";
import { Assignment } from "@/models/assignment.model";
import { Email } from "@/models/email.model";
import { LinkingDescription } from "@/models/linking.model";

export default defineComponent({
  name: "CustomerItem",
  emits: ["createInErp", "insertUpdatedCustomer"],
  components: {
    Assignments,
    Checklist,
    Dependencies,
    JobAdListCustomer,
    MailClient,
    DialogUpdateCustomer,
    PhoneClient,
    ProfileGenerator,
    SelectionPicker,
    Timeline,
    zvooveOneLinkMenu,
  },
  props: {
    customer: {
      type: Object as PropType<Customer>,
      required: false,
    },
    isActive: {
      type: Boolean,
      required: false,
    },
    isFullyCollapsed: {
      type: Boolean,
      required: false,
    },
    interComponentMessage: {
      type: Object as PropType<any>,
      required: true,
    },
    lastUpdateTimeline: {
      type: String,
      required: true,
    },
    loggedInMandantUuids: {
      type: Object as PropType<string[]>,
      required: true,
    },
    mandants: {
      type: Object as PropType<Mandant[]>,
      required: true,
    },
    softwareIntegration: {
      type: Object as PropType<SoftwareIntegration>,
      required: true,
    },
    user: {
      type: Object as PropType<User>,
      required: true,
    },
  },
  data() {
    return {
      addCustomerModalInput1: "",
      addCustomerModalInput2: "",
      addCustomerModalLabel1: "",
      addCustomerModalLabel2: "",
      apiCustomerService: new CustomerService(),
      anzeigedatenService: AnzeigedatenService.getInstance(),
      candidatesTagsMatching: false,
      email: {
        subject: "Betreff",
        body: "[Gibt Dein Text ein...]",
      } as Email,
      filteredEmail: [] as any,
      filteredPhoneNumbers: [] as any,
      isCheckingDependency: false,
      isExpanded: false,
      isFullyExpanded: false,
      isHovered: false,
      isMailClientActive: false,
      isProfileGeneratorActive: false,
      isTriedToFetchAssignments: false,
      isTriedToFetchContacts: false,
      itemBorder: "sm",
      itemColor: "var(--color-item)",
      itemElevation: 2,
      itemHoverColor: "var(--color-item-hover)",
      jobAdListPosition: { x: 0, y: 0 },
      linkingDescription: {} as LinkingDescription,
      linkingService: new LinkingService(),
      modalActionButtonText: "aus ERP importieren",
      modalTitle: "",
      modalType: "",
      mountDependencies: false,
      newTag: "",
      onItemSelect: null as ((uuid: string) => Promise<void>) | null,
      pdHubService: PdHubService.getInstance(),
      pickerDialog: false,
      pickerItems: [] as any,
      pickerTitle: "",
      profileSourceData: {} as ProfileSourceData,
      results: [] as Customer[],
      resultsModal: false,
      showModal: false,
      showSearchResultsFromErp: true,
      statusPickerDialog: false,
      zvooveOneCustomer: new ZvooveOneCustomer(),
    };
  },
  computed: {
    ...mapGetters({
      company: "company",
      customerStates: "customerStates",
      hasLinking: "hasLinkingForCustomer",
      zvooveCustomers: "zvooveCustomers",
    }),
    DialogResponse() {
      return DialogResponse;
    },
    ModalMode() {
      return ModalMode;
    },
    signatureText() {
      const user = this.company.loggedInUser;
      return `freundliche Grüße<br>${Salutation[user.salutation]} ${
        user.forename
      } ${user.lastname}<br>${this.company.name}`;
    },
  },
  mounted() {
    this.autoFetchJobAds();
    this.extractPhoneNumbers();
    this.extractEmailsAndSalutations();
  },
  watch: {
    interComponentMessage: {
      handler(newVal) {
        switch (newVal.message) {
          case InterComponentMessage.matchMeFromCandidate:
            this.handleCheckMatch(newVal.payload);
            break;
          default:
            this.itemElevation = 2;
            this.itemBorder = "sm";
            this.itemColor = "var(--color-item-secondary)";
            this.itemHoverColor = "var(--color-item-hover)";
            break;
        }
      },
      deep: true,
    },
    lastUpdateTimeline() {
      if (this.$refs.dependenciesComponent) {
        (this.$refs.dependenciesComponent as any).loadLinkingsFromStore();
        (this.$refs.dependenciesComponent as any).checkForReminders();
      }
    },
  },
  methods: {
    ...mapMutations({
      clearICM: MutationType.clearICM,
    }),
    addFollowUpOnDependencies() {
      if (this.$refs.dependenciesComponent) {
        (this.$refs.dependenciesComponent as any).linkFollowUp(
          null,
          this.customer?._id
        );
      }
    },
    addTag() {
      if (!this.customer) return;
      const customer = this.customer;
      if (
        this.newTag.trim() !== "" &&
        customer &&
        Array.isArray(customer.tags)
      ) {
        customer.tags.push(this.newTag.trim());
        this.newTag = "";
      }
      this.updateCustomer(customer);
    },
    async autoFetchJobAds(forceFetchIndexAnzeigendaten?: boolean) {
      if (!this.customer) return;
      const customer = this.customer;
      if (
        (this.softwareIntegration as SoftwareIntegration).indexAnzeigendaten &&
        customer.additionalInfo?.indexCompanyId
      ) {
        let shouldFetchAds = false;
        if (customer.jobAds.length === 0 || forceFetchIndexAnzeigendaten) {
          shouldFetchAds = true;
        } else {
          const adRetrievalDate = moment(
            customer.jobAds[0]["AD-RETRIEVAL-DATE"],
            "DD.MM.YYYY"
          );
          const currentDate = moment();
          const daysSinceLastRetrieval = currentDate.diff(
            adRetrievalDate,
            "days"
          );
          shouldFetchAds =
            daysSinceLastRetrieval >
            (this.softwareIntegration as SoftwareIntegration)
              .indexJobAdRefreshInterval;
        }

        if (shouldFetchAds) {
          try {
            const newJobAds =
              await this.anzeigedatenService.getJobAdsFromCustomer(
                customer.additionalInfo.indexCompanyId
              );
            ToastService.show(
              `Index Anzeigendaten von  ${customer.generalData.name} abgerufen!`
            );
            customer.jobAds = newJobAds;

            await this.updateCustomer(customer);
          } catch (error) {
            console.error("Fehler beim Abrufen neuer Stellenanzeigen:", error);
          }
        }
      }
    },
    checkTagsMatching(payloadTags: string[], customerTags: string[]) {
      const options = {
        includeScore: true,
        threshold: 0.7,
        isCaseSensitive: false,
        keys: ["tag"],
      };
      const fuse = new Fuse(
        customerTags.map((tag) => ({ tag })),
        options
      );
      return payloadTags.some((payloadTag) => {
        const results = fuse.search(payloadTag);
        return results.some((result: any) => {
          const matchTag = result.item.tag;
          return matchTag.includes(payloadTag) || payloadTag.includes(matchTag);
        });
      });
    },
    collapseItem() {
      this.isMailClientActive = false;
      this.isFullyExpanded = false;
      this.isExpanded = false;
    },
    async extractEmailsAndSalutations() {
      const emails = [];

      // Add central mailadresse and salutation for all
      if (this.customer?.addressAndCommunication?.email) {
        emails.push({
          mailaddress: this.customer.addressAndCommunication.email,
          salutation: "Sehr geehrte Damen und Herren",
        });
      }

      // Add contacts mailadresses
      this.customer?.contacts.forEach((contact) => {
        if (
          (contact.status === "aktiv" || contact.status.includes("index")) &&
          contact.email
        ) {
          const salutation = `Guten Tag ${contact.salutation} ${contact.lastName},`;
          emails.push({
            mailaddress: contact.email,
            salutation,
          });
        }
      });

      this.filteredEmail = emails;
    },
    extractPhoneNumbers() {
      const phoneNumbers = [];

      // Add central phone numbers
      if (this.customer?.addressAndCommunication?.phone1) {
        phoneNumbers.push({
          value: this.customer.addressAndCommunication.phone1,
          label: "Zentrale",
        });
      }
      if (this.customer?.addressAndCommunication?.phone2) {
        phoneNumbers.push({
          value: this.customer.addressAndCommunication.phone2,
          label: "Telefon 2",
        });
      }
      // Add phone numbers from contacts
      this.customer?.contacts.forEach((contact) => {
        if (
          (contact.status === "aktiv" || contact.status.includes("index")) &&
          contact.phone
        ) {
          const label = `${contact.salutation} ${contact.firstName} ${
            contact.lastName
          } ${contact.role || ""}`.trim();
          phoneNumbers.push({
            value: contact.phone,
            label,
          });
        }
      });

      this.filteredPhoneNumbers = phoneNumbers;
    },
    async generateAndSetCustomerTags() {
      if (this.customer) {
        const customerDataAndJobAdList = this.generateCustomerSummary();
        const customer = this.customer;

        try {
          let jobAdTags: string[] = [];
          let historicalAssignmentsTags: string[] = [];

          if (customer.jobAds && customer.jobAds.length > 0) {
            jobAdTags = await generateCustomerTags(customerDataAndJobAdList);
          }

          if (customer.assignments && customer.assignments.length > 0) {
            let historicalAssignments = [] as Assignment[];

            if (this.softwareIntegration.zvooveOne) {
              historicalAssignments =
                await this.zvooveOneCustomer.getPlacements(
                  customer.customerNumber,
                  true
                );
            } else if (this.softwareIntegration.pdHub) {
              historicalAssignments = await this.pdHubService.getPlacements(
                customer.customerNumber,
                true,
                true
              );
            }
            if (historicalAssignments.length > 0) {
              historicalAssignmentsTags = historicalAssignments.map(
                (assignment: Assignment) =>
                  assignment.jobTitle.replace(/\/in/g, "")
              );
            }
          }
          const tagsSet = new Set([...jobAdTags, ...historicalAssignmentsTags]);
          const tags = Array.from(tagsSet);
          customer.tags = tags;
          await this.updateCustomer(customer);
        } catch (error) {
          console.error("Error generating customer tags:", error);
        }
      }
    },
    generateCustomerSummary() {
      const industries = this.customer?.furtherInformation?.industries
        ? this.customer.furtherInformation.industries.join(", ")
        : "N/A";

      const employeeCount = this.customer?.jobAds[0]?.["COMPANY-EMPLOYEES"];
      const jobPositions = this.customer?.jobAds
        .map((ad) => ad.POSITION)
        .filter((value, index, self) => self.indexOf(value) === index) // remove duplicates
        .join(", ");

      return `
      Branche des Kunden: ${industries || "N/A"}
      Mitarbeiteranzahl: ${employeeCount || "N/A"}
      Aktuell ausgeschriebene Stellenanzeigen: ${jobPositions || "N/A"}
    `;
    },
    async generateProfileFromDependencies() {
      this.isProfileGeneratorActive = true;
      this.$nextTick().then(() => {
        if (this.$refs.profileGeneratorComponent) {
          (
            this.$refs.profileGeneratorComponent as any
          ).checkForExisitingProfiles();
        }
      });
    },
    async getAssignmentsFromErp(customerNumber: string) {
      if (!this.customer) return;
      const customer = this.customer;
      if (this.softwareIntegration.zvooveOne) {
        const assignments = await this.zvooveOneCustomer.getPlacements(
          customerNumber
        );
        customer.assignments = assignments;
        this.updateCustomer(customer).then(() => {
          this.updateCustomerItem();
        });
      } else if (this.softwareIntegration.pdHub) {
        const assignments = await this.pdHubService.getPlacements(
          customerNumber,
          true
        );
        customer.assignments = assignments;
        this.updateCustomer(customer).then(() => {
          this.updateCustomerItem();
        });
      }
    },
    async getContactsAndAssignmentsFromErp(customerNumber: string) {
      if (!this.customer) return;
      const customer = this.customer;
      if (this.softwareIntegration.zvooveOne) {
        const response = await this.zvooveOneCustomer.getContactsAndPlacements(
          customerNumber
        );
        const extractedContacts = response.contacts;

        extractedContacts.forEach((newContact: CustomerContact) => {
          const contactExists = customer?.contacts.some(
            (existingContact) =>
              existingContact.firstName === newContact.firstName &&
              existingContact.lastName === newContact.lastName
          );

          if (!contactExists) {
            customer.contacts.push(newContact);
          }
          customer.assignments = response.assignments;
        });
        this.updateCustomer(customer).then(() => {
          this.updateCustomerItem();
        });
      }
      // PDHUB gets contacts on init!
    },
    async getContactsFromErp(customerNumber: string) {
      if (!this.customer) return;
      const customer = this.customer;
      if (this.softwareIntegration.zvooveOne) {
        const extractedContacts =
          await this.zvooveOneCustomer.getContactsByCustomerNumber(
            customerNumber
          );
        extractedContacts.forEach((newContact: CustomerContact) => {
          const contactExists = customer?.contacts.some(
            (existingContact) =>
              existingContact.firstName === newContact.firstName &&
              existingContact.lastName === newContact.lastName
          );

          if (!contactExists) {
            customer?.contacts.push(newContact);
          }
        });
        await this.updateCustomer(customer);
        this.updateCustomerItem();
      }
      // PDHUB gets contacts on init!

      //TODO: Add timeline entry "SYSTEM"
      /* try {
        const zvooveOneContactsLink = `${this.softwareIntegration.zvooveOneLink}/unternehmen/${zvooveOneCustomerNumber}/ansprechpartner`;
        const companyContacts =
          await ZorstService.getInstance().ZorstOpenSiteAndScrapeText(
            zvooveOneContactsLink
          );
        const extractedContacts = await extractCustomerContacts(
          companyContacts as string
        );

        extractedContacts.forEach((newContact: CustomerContact) => {
          const contactExists = this.customer?.contacts.some(
            (existingContact) =>
              existingContact.firstName === newContact.firstName &&
              existingContact.lastName === newContact.lastName
          );

          if (!contactExists) {
            // FIXME
// eslint-disable-next-line vue/no-mutating-props
            this.customer?.contacts.push(newContact);
          }
        });
        await this.updateCustomer();
         this.updateCustomerItem();
        //TODO: Add Toast
      } catch (error) {
        console.error("Fehler beim Abrufen der Daten:", error);
        // TODO: Add Toast
      } */
    },
    handleCheckMatch(payload: any) {
      const customerPostalCode =
        this.customer?.addressAndCommunication?.postalCode;

      if (
        customerPostalCode &&
        payload.postcodes.includes(customerPostalCode)
      ) {
        const perfectmatch = (this.candidatesTagsMatching =
          this.checkTagsMatching(
            payload.tags || [],
            this.customer?.tags || []
          ));
        if (perfectmatch) {
          this.itemElevation = 5;
          this.itemBorder = "sucess lg";
          this.itemColor = "var(--color-item-perfect-match)";
          this.itemHoverColor = "var(--color-item-hover-perfect-match)";
        } else {
          this.itemElevation = 5;
          this.itemBorder = "md";
          this.itemColor = "var(--color-item-match)";
          this.itemHoverColor = "var(--color-item-hover-match)";
        }
      } else {
        this.itemElevation = 2;
        this.itemBorder = "sm";
        this.itemColor = "var(--color-item)";
        this.itemHoverColor = "var(--color-item-hover)";
        this.candidatesTagsMatching = false;
      }
    },
    async handleDragover(event: DragEvent) {
      event.preventDefault();
      if (this.isCheckingDependency) return;
      const candidate_id =
        this.$store.state.isDraggingItem.candidate.candidateData._id;
      if (candidate_id) {
        const customer_id = this.customer?._id;

        if (customer_id) {
          this.isCheckingDependency = true;
          try {
            const dependency: any =
              await this.linkingService.checkDependencyCandidateCustomer(
                candidate_id,
                customer_id
              );
            if (!dependency.error) {
              const formattedDates = dependency.eventDate
                .map((date: string) =>
                  moment(date).format("DD.MM.YYYY [um] HH:mm")
                )
                .join(", ");

              const message = `${dependency.eventType} am ${formattedDates}`;
              TooltipService.showItemDragoverMessage(event, message);
            }
          } catch (error) {
            console.error(
              "Fehler bei der Überprüfung der Abhängigkeit:",
              error
            );
          }
        }
      }
      setTimeout(() => {
        this.isCheckingDependency = false;
      }, 3000);
    },
    async handleDrop(event: DragEvent) {
      this.isExpanded = true;
      if (this.customer) {
        const positions = new Set<string>();

        const uniqueAndFilteredJobAds = (this.customer.jobAds || []).reduce(
          (uniqueAds: ProfileJobAd[], jobAd) => {
            if (!positions.has(jobAd.POSITION)) {
              uniqueAds.push({
                POSITION: jobAd.POSITION,
                "JOB-CATEGORY": Array.isArray(jobAd["JOB-CATEGORY"])
                  ? jobAd["JOB-CATEGORY"]
                  : [],
                TEXT: jobAd.TEXT || "",
              });
              positions.add(jobAd.POSITION);
            }
            return uniqueAds;
          },
          []
        );
        const draggingData = this.$store.state.isDraggingItem.candidate;
        this.linkingDescription = {
          firstName: draggingData.candidateData.firstName,
          lastName: draggingData.candidateData.lastName,
          postCode: draggingData.candidateData.addressPostalCode,
          city: draggingData.candidateData.addressCity,
          customerName: this.customer.generalData.name,
          customerPostCode: this.customer.addressAndCommunication.postalCode,
          customerCity: this.customer.addressAndCommunication.city,
          uuid: draggingData.candidateData.uuid,
        } as LinkingDescription;

        this.profileSourceData = {
          age: draggingData.age,
          anonymizedResume: draggingData.anonymizedResume,
          appliedAs: draggingData.appliedAs,
          candidateId: draggingData.candidateData._id,
          candidateUuid: draggingData.candidateData.uuid,
          careerSteps: draggingData.candidateData.careerSteps,
          city: draggingData.candidateData.addressCity,
          filteredEmail: this.filteredEmail,
          firstName: draggingData.candidateData.firstName,
          jobAds: uniqueAndFilteredJobAds,
          lastName: draggingData.candidateData.lastName,
          licences: draggingData.licences,
          mandant: this.customer.mandants[0],
          mobility: draggingData.mobility,
          profileNumber: draggingData.profileNumber,
          profiles: draggingData.candidateData.profiles,
          salutation: draggingData.salutation,
          shiftReadiness: draggingData.shiftReadiness,
          tags: this.customer?.tags || [],
        };
      }

      await nextTick();
      if (event.dataTransfer) {
        const candidateId = event.dataTransfer.getData(
          "application/candidate_id"
        );
        if (candidateId) {
          (
            this.$refs.dependenciesComponent as InstanceType<
              typeof Dependencies
            >
          ).linkCandidateToCustomer(candidateId);
        }
      }
      // this.$store.commit("CLEAR_DRAGGING_ITEM"); //Alway reset the this.$store
    },
    async handleModalSubmit({
      input1,
      input2,
    }: {
      input1: string;
      input2: string;
    }) {
      this.addCustomerModalInput1 = input1;
      this.addCustomerModalInput2 = input2;
      await this.submitModal();
    },
    handleMouseEnter(event: any) {
      const rect = event.target.getBoundingClientRect();
      this.jobAdListPosition = {
        x: rect.left + window.pageXOffset,
        y: rect.top + window.pageYOffset,
      };

      if (this.$refs.jobAdListRef) {
        (
          this.$refs.jobAdListRef as InstanceType<typeof JobAdListCustomer>
        ).show();
        (
          this.$refs.jobAdListRef as InstanceType<typeof JobAdListCustomer>
        ).cancelClose();
      }
    },
    handleSelectedResult(selectedResult: Customer) {
      SpinnerService.showSpinner();
      this.selectResult(selectedResult).then(() =>
        SpinnerService.removeSpinner()
      );
    },
    hasAssignments() {
      const hasAssignments =
        Array.isArray(this.customer?.assignments) &&
        this.customer.assignments.length > 0;
      return hasAssignments;
    },
    hasContactWithPhoneAndEmail() {
      return this.customer?.contacts.some(
        (contact) => contact.phone && contact.email
      );
    },
    async initAssignmentsAndContactsIfEmpty() {
      if (
        this.isNumeric(this.customer?.customerNumber) &&
        this.customer?.generalData?.costCenter != null
      ) {
        if (
          !this.hasContactWithPhoneAndEmail() &&
          !this.isTriedToFetchContacts &&
          !this.hasAssignments() &&
          !this.isTriedToFetchAssignments
        ) {
          this.isTriedToFetchAssignments = true;
          this.isTriedToFetchContacts = true;
          await this.getContactsAndAssignmentsFromErp(
            this.customer?.customerNumber || ""
          );
        } else if (
          !this.hasContactWithPhoneAndEmail() &&
          !this.isTriedToFetchContacts
        ) {
          this.isTriedToFetchContacts = true;
          await this.getContactsFromErp(this.customer?.customerNumber || "");
        } else if (!this.hasAssignments() && !this.isTriedToFetchAssignments) {
          this.isTriedToFetchAssignments = true;
          await this.getAssignmentsFromErp(this.customer?.customerNumber || "");
        }
      }
    },
    isNumeric(value: string | null | undefined): boolean {
      return /^\d+$/.test(value ?? "");
    },
    isNotPdHubCustomerNumber() {
      if (!this.softwareIntegration?.pdHub) return false;
      return (
        this.softwareIntegration.pdHub &&
        (!this.isNumeric(this.customer?.customerNumber) ||
          this.customer?.generalData?.costCenter == null)
      );
    },
    isNotZvooveCustomerNumber() {
      if (!this.softwareIntegration?.zvooveOne) return false;
      return (
        this.softwareIntegration.zvooveOne &&
        (!this.isNumeric(this.customer?.customerNumber) ||
          this.customer?.generalData?.costCenter == null)
      );
    },
    mandantName() {
      let mandantName = "Unbekannter Mandant";
      const mandantUuid = this.customer?.mandants[0];

      if (mandantUuid) {
        mandantName = this.$store.getters.getMandantNameByUuid(mandantUuid);
      }

      return mandantName;
    },
    openMailClientFromProfileGenerator(email: any, candidateTimelineData: any) {
      this.isProfileGeneratorActive = false;
      this.isMailClientActive = true;
      this.$nextTick().then(() => {
        this.email = email;
        this.email.subject = `Neues Profil #${this.profileSourceData.profileNumber}`;
        if (this.$refs.mailClientComponent) {
          (this.$refs.mailClientComponent as any).openModal(
            candidateTimelineData
          );
        }
      });
    },
    openPhoneClient() {
      this.isExpanded = true;
      if (this.$refs.phoneClientComponent) {
        (this.$refs.phoneClientComponent as any).handleOutgoingCall();
      }
    },
    openPicker(context: string) {
      this.pickerDialog = true;

      if (context === ModalMode.mandant) {
        this.pickerItems = this.mandants.map((m: Mandant) => ({
          name: m.name,
          uuid: m.uuid,
        }));
        this.pickerTitle = "Mandanten auswählen";
        this.onItemSelect = this.saveMandantSelection;
      } else if (context === ModalMode.customerStatus) {
        this.pickerItems = Object.entries(this.customerStates).map(
          ([uuid, name]) => ({
            name: name,
            uuid: uuid,
          })
        );
        this.pickerTitle = "Status auswählen";
        this.onItemSelect = this.saveStatusSelection;
      }
    },
    openUpdateCustomerModal(context: string) {
      //TODO: Add timeline entry "SYSTEM"
      switch (context) {
        case ModalMode.scrapeContactsFromSite:
          this.modalTitle = "Ansprechpartner von Internetseite holen";
          this.modalType = ModalMode.scrapeContactsFromSite;
          this.addCustomerModalLabel1 = "Link zur Ansprechpartnern";
          this.addCustomerModalInput1 = "";
          this.addCustomerModalLabel2 = "";
          this.modalActionButtonText = "Extrahieren";
          this.showSearchResultsFromErp = false;
          break;
        case ModalMode.zvooveOne:
          this.modalTitle = "Unternehmen in ERP One suchen";
          this.modalType = ModalMode.zvooveOne;
          this.addCustomerModalLabel1 = "Kundennummer";
          this.addCustomerModalInput1 = "";
          this.addCustomerModalLabel2 = "";
          this.modalActionButtonText = "in ERP One suchen";
          this.showSearchResultsFromErp = true;
          break;
        case ModalMode.pdHub:
          this.modalTitle = "Unternehmen in PD-Hub suchen";
          this.modalType = ModalMode.pdHub;
          this.addCustomerModalLabel1 = "Kundennummer";
          this.addCustomerModalInput1 = "";
          this.addCustomerModalLabel2 = "";
          this.modalActionButtonText = "im PD-Hub suchen";
          this.showSearchResultsFromErp = true;
          break;
        case ModalMode.indexAnzeigendaten:
          this.modalTitle = "Unternehmen in Index Anzeigendaten suchen";
          this.modalType = ModalMode.indexAnzeigendaten;
          this.addCustomerModalLabel1 = "Postleitzahl";
          this.addCustomerModalInput1 = this.customer?.addressAndCommunication
            .postalCode as string;
          this.addCustomerModalLabel2 = "Firmenname / Suchbegriff";
          this.addCustomerModalInput2 = this.customer?.generalData
            .name as string;
          this.modalActionButtonText = "in Index suchen";
          this.showSearchResultsFromErp = false;
          break;
      }
      this.showModal = true;
    },
    phoneClientIsDialing() {
      if (this.$refs.dependenciesComponent) {
        (this.$refs.dependenciesComponent as any).waitForPhoneClientDialing();
      }
    },
    removeTag(index: number) {
      if (!this.customer) return;
      const customer = this.customer;
      if (customer && Array.isArray(customer.tags)) {
        customer.tags.splice(index, 1);
      }
      this.updateCustomer(customer);
    },
    async saveMandantSelection(selectedMandantUuid: string): Promise<void> {
      if (!this.customer) return;
      const customer = this.customer;
      customer.mandants[0] = selectedMandantUuid;
      await this.updateCustomer(customer);
    },
    async saveStatusSelection(selectedStatusIndex: string): Promise<void> {
      if (!this.customer) return;
      const customer = this.customer;
      const index = parseInt(selectedStatusIndex, 10);
      const selectedStatus = this.customerStates[index];

      if (!selectedStatus) {
        console.error("Ungültiger Statusindex:", selectedStatusIndex);
        return;
      }

      customer.generalData.status = selectedStatus;
      await this.updateCustomer(customer);

      //Change status in ERP
      if (
        this.softwareIntegration?.zvooveOne &&
        this.customer.generalData.status
      ) {
        const status =
          getCustomerStateValue(this.customer.generalData.status) ?? 1;
        await this.zvooveOneCustomer.changeCustomerStatus(
          this.customer.customerNumber,
          status
        );
      }
      //TODO: ERP integration pdHub
    },
    saveSelection(uuid: string): void {
      if (typeof this.onItemSelect === "function") {
        this.onItemSelect(uuid);
        this.pickerDialog = false;
      }
    },
    scheduleJobAdListClose() {
      if (this.$refs.jobAdListRef) {
        (
          this.$refs.jobAdListRef as InstanceType<typeof JobAdListCustomer>
        ).scheduleClose();
      }
    },
    async selectResult(selectedCustomer: Customer) {
      try {
        if (!this.customer) {
          throw new Error("Kundenobjekt ist nicht initialisiert.");
        }
        const customer = this.customer;

        if (
          selectedCustomer.customerNumber &&
          (this.modalType === ModalMode.zvooveOne ||
            this.modalType === ModalMode.pdHub)
        ) {
          customer.customerNumber = selectedCustomer.customerNumber;
        }

        const updatedCustomer = await this.updateCustomerData(
          customer,
          selectedCustomer
        );
        this.updateCustomer(updatedCustomer);
        this.resultsModal = false;
      } catch (error) {
        console.error("Fehler beim Speichern des ausgewählten Kunden:", error);
      }
    },
    setAppointmentOnDependencies() {
      if (this.$refs.dependenciesComponent) {
        (this.$refs.dependenciesComponent as any).openAppointmentModal();
      }
    },
    async submitModal() {
      this.showModal = false;
      let customer = {} as Customer;
      if (this.customer) customer = this.customer;
      try {
        let responseData = [];
        if (this.modalType === ModalMode.zvooveOne) {
          const customerData = await this.zvooveOneCustomer.getByCustomerNumber(
            this.addCustomerModalInput1
          );
          responseData.push(customerData);
          /* /*  scrape site with zorst: we maybe need that snippet for integration of other ERP system with srcaping
          const zvooveOneCompanyLink = `${this.softwareIntegration.zvooveOneLink}/unternehmen/${this.addCustomerModalInput1}/uebersicht`;
          const companyData =
            await ZorstService.getInstance().ZorstOpenSiteAndScrapeText(
              zvooveOneCompanyLink
            );
          const extractedData = await extractCustomerData(companyData);
          responseData.push(extractedData); */
        } else if (this.modalType === ModalMode.pdHub) {
          const pdHubService = PdHubService.getInstance();
          const customerData = await pdHubService.getByCustomerNumber(
            this.addCustomerModalInput1
          );
          if (customerData === null) {
            SpinnerService.removeSpinner();
            DialogService.alert("keine Daten gefunden");
            return;
          }
          responseData.push(customerData);
        } else if (this.modalType === ModalMode.scrapeContactsFromSite) {
          try {
            const linkToScrapeContacts = this.addCustomerModalInput1;
            const companyContacts =
              await ZorstService.getInstance().ZorstOpenSiteAndScrapeText(
                linkToScrapeContacts
              );
            const extractedContacts = await extractCustomerContacts(
              companyContacts as string
            );

            // Prepare a map or set to check for existing contacts more efficiently
            const existingContactsSet = new Set(
              customer.contacts.map(
                (contact) => `${contact.firstName} ${contact.lastName}`
              )
            );

            // Filter and add only new contacts
            const newContacts = extractedContacts.filter((newContact) => {
              const newContactKey = `${newContact.firstName} ${newContact.lastName}`;
              return !existingContactsSet.has(newContactKey);
            });

            if (newContacts.length > 0) {
              customer.contacts.push(...newContacts);
              await this.updateCustomer(customer);
              ToastService.showSuccess("Ansprechpartner ergänzt");
            }

            this.updateCustomerItem();
          } catch (error) {
            // ToastService.showError("Fehler beim Abrufen der Daten: " + error);
          }
        } else if (this.modalType === ModalMode.indexAnzeigendaten) {
          responseData = await this.anzeigedatenService.getCustomerFromIndex(
            this.addCustomerModalInput1,
            this.addCustomerModalInput2
          );
        }
        if (responseData.length > 0) {
          this.results = responseData;
          this.resultsModal = true;
        }
      } catch (error) {
        ToastService.showError("Fehler beim Abrufen der Daten: " + error);
      }
    },
    toggleExpansion() {
      this.isExpanded = !this.isExpanded;
      if (!this.isExpanded) {
        this.isFullyExpanded = false;
      }
      if (this.isExpanded) {
        this.updateCustomerItem();
      }
    },
    toggleFullExpansion() {
      if (this.isExpanded) {
        this.isFullyExpanded = !this.isFullyExpanded;
      }
    },
    updateChecklist(checklist: CheckList[]) {
      if (!this.customer) return;
      const customer = this.customer;
      customer.checklist = checklist;
      this.updateCustomer(customer);
    },
    async updateCustomerFromErp() {
      if (this.customer) {
        const customer = this.customer;
        let customerData = {} as Customer | null;
        try {
          if ((this.softwareIntegration as SoftwareIntegration).zvooveOne) {
            customerData =
              await this.zvooveOneCustomer.getCustomerAndContactsAndPlacements(
                customer.customerNumber
              );
          } else if ((this.softwareIntegration as SoftwareIntegration).pdHub) {
            customerData = await this.pdHubService.getByCustomerNumber(
              customer.customerNumber
            );
            if (customerData) {
              customerData.assignments = await this.pdHubService.getPlacements(
                customer.customerNumber,
                true
              );
            }
          } else {
            return;
          }
          Object.assign(customer, customerData);
          await this.updateCustomer(customer);
        } catch (error) {
          console.error("Error updating customer from ERP:", error);
        }
      }
    },
    updateCustomerItem() {
      this.autoFetchJobAds();
      this.extractPhoneNumbers();
      this.extractEmailsAndSalutations();
      this.initAssignmentsAndContactsIfEmpty().then(() => {
        if (this.customer && this.customer.tags && !this.customer.tags[2]) {
          this.generateAndSetCustomerTags();
        }
      });
      if (this.$refs.dependenciesComponent) {
        (this.$refs.dependenciesComponent as any).loadLinkingsFromStore();
        (this.$refs.dependenciesComponent as any).checkForReminders();
      }
    },
    async updateCustomer(customer: Customer) {
      try {
        const apiService = new CustomerService();
        await apiService.editCustomer(customer).then(() => {
          this.$emit("insertUpdatedCustomer", customer);
        });
      } catch (error) {
        console.error("Error updating customer: ", error);
      }
    },
    updateCustomerData(
      existingCustomer: Customer,
      newCustomerData: Customer
    ): Customer {
      //TODO: Add timeline entry "SYSTEM"
      const sections: (keyof Customer)[] = [
        "generalData",
        "addressAndCommunication",
        "furtherInformation",
        "additionalInfo",
        "reporting",
      ];

      sections.forEach((section) => {
        if (section in newCustomerData && newCustomerData[section] !== null) {
          const existingSection = existingCustomer[section] as Record<
            string,
            any
          >;
          const newSection = newCustomerData[section] as Record<string, any>;

          Object.keys(newSection).forEach((key) => {
            if (
              !(key in existingSection) ||
              existingSection[key] === null ||
              existingSection[key] === ""
            ) {
              existingSection[key] = newSection[key];
            }
          });
        }
      });

      newCustomerData.contacts.forEach((newContact) => {
        const contactExists = existingCustomer.contacts.some(
          (existingContact) => existingContact.email === newContact.email
        );
        if (!contactExists) {
          existingCustomer.contacts.push(newContact);
        }
      });

      if (newCustomerData.reporting) {
        existingCustomer.reporting = newCustomerData.reporting;
      }
      return existingCustomer;
    },
    async updateErpFromCustomer(): Promise<any> {
      const customer = this.customer;

      if (!customer) {
        console.error("Customer is undefined");
        return;
      }
      if (this.softwareIntegration?.zvooveOne) {
        try {
          await this.zvooveOneCustomer.putCustomer(customer);
        } catch (error) {
          console.error("Error updating ERP from customer:", error);
          throw error;
        }
      } else if (this.softwareIntegration?.pdHub) {
        try {
          await this.pdHubService.patchCustomer(customer);
        } catch (error) {
          console.error("Error updating PD-Hub from customer:", error);
          throw error;
        }
      }
    },
  },
});
</script>

<style scoped>
.logo-img {
  max-height: 0.8rem;
  height: auto;
  width: auto;
  margin-right: auto;
}

.match-icon {
  position: absolute;
  font-size: 0.8rem;
  bottom: 1.3rem;
  right: 0.95rem;
  text-shadow: var(--text-shadow);
  animation: pulseAnimation 1s infinite, redBlinkAnimation 1s infinite;
}

.job-ad-hover {
  background: none;
  border: none;
  color: grey;
  cursor: pointer;
  padding: 0;
  position: absolute;
  right: 0.75rem;
  text-shadow: var(--text-shadow);
  bottom: 0;
  z-index: 10;
}
.customer-status {
  cursor: pointer;
}
.customer-mandant {
  cursor: pointer;
  font-size: 0.8rem;
}
.customer-tag {
  margin-right: 1rem;
  margin-top: 0.5rem;
  border-radius: 5px;
}
.index-erp-ats-icon {
  margin-right: 1rem;
  cursor: pointer;
  transition: all 0.5s ease;
  width: 1.7rem;
  height: 1.7rem;
}
.index-erp-ats-icon:hover {
  scale: var(--medium-scale-up);
}
.get-contacts-icon {
  color: grey;
  font-size: 1.5rem;
  cursor: pointer;
  transition: all 0.3s ease;
}
.get-contacts-icon:hover {
  color: var(--color-primary);
  font-size: 1.6rem;
}
</style>
@/services/api/anzeigendaten.service
