<!--src/components/disposition/EmployeeItem.vue-->
<template>
  <v-container class="ma-0 pa-0">
    <v-hover>
      <template v-slot:default="{ isHovering, props }">
        <v-container
          class="ma-0 pa-0 item"
          v-if="isFullyCollapsed"
          :color="isHovering ? 'var(--color-item-hover)' : 'var(--color-item)'"
        >
          <v-card-title class="d-flex justify-space-between pa-0">
            <span class="pa-0 text-body-2">
              <span
                class="ml-1 font-weight-light text-caption text-medium-emphasis"
              >
                {{ employee.employeeNumber }}</span
              >
              {{ employee.firstName }} {{ employee.lastName }}
            </span>
            <span
              class="mx-1 font-weight-light text-caption text-medium-emphasis"
            >
              {{ employee.address.city }}</span
            ></v-card-title
          >
          <v-divider class="my-1"></v-divider>
        </v-container>
        <v-card
          v-if="!isFullyCollapsed"
          class="item border-sm pa-2 mb-2"
          :elevation="2"
          rounded-lg
          v-bind="props"
          :color="isHovering ? 'var(--color-item-hover)' : 'var(--color-item)'"
          :class="{
            'fully-collapsed': isFullyCollapsed,
            expanded: isExpanded,
            'fully-expanded': isFullyExpanded,
          }"
          @dblclick="toggleFullExpansion"
        >
          <div class="item-collapsed">
            <v-card-title class="pa-0 text-body-2">
              {{ employee.firstName }} {{ employee.lastName }}</v-card-title
            >
            <v-card-subtitle class="pl-0">
              {{ employee.address.postalCode }}
              {{ employee.address.city }} ({{ employee.employeeNumber }})
            </v-card-subtitle>
          </div>
          <v-divider></v-divider>
          <button @click="toggleExpansion" class="toggle-button">
            {{ isExpanded ? "▲" : "▼" }}
          </button>
          <div class="hide-on-inactive-column" v-if="isActive">
            <div
              class="employee-status"
              @click="openPicker(ModalMode.employeeStatus)"
            >
              {{ getEmployeeState(employee.employeeStatus) }}
            </div>
          </div>
          <div v-if="isExpanded" class="communication-icons-container">
            <v-spacer></v-spacer>
            <zvooveOneLinkMenu
              :zvooveOneEmployeeNumber="employee.employeeNumber"
              @getErpData="updateEmployeeFromErp()"
              @putErpData="updateErpFromEmployee()"
            ></zvooveOneLinkMenu>
            <PhoneClient
              :employee="employee"
              :phoneNumbers="filteredPhoneNumbers"
              @collapseParentItem="collapseItem"
            ></PhoneClient>
            <WhatsAppClient
              :employee="employee"
              :phoneNumbers="filteredPhoneNumbers"
              :AiMessageType="'whatsAppCandidate'"
              :salutation="employee.letterSalutation"
              :message="message.body"
              :signature="signatureWhatsApp()"
              @collapseParentItem="collapseItem"
            ></WhatsAppClient>
            <MailClient
              :employee="employee"
              :emailAddresses="filteredEmail"
              :AiMessageType="'mailCandidate'"
              :emailObject="message"
              :signature="signatureMail()"
              @collapseParentItem="collapseItem"
            ></MailClient>
          </div>
          <v-divider></v-divider>
          <Assignments
            v-if="isExpanded"
            :isEmployee="true"
            :assignments="employee?.assignments"
            @updateAssignments="getPlacements()"
          ></Assignments>
          <Checklist
            :checklist="employee.checklist || []"
            @updateChecklist="updateChecklist"
          ></Checklist>
          <v-divider></v-divider>
          <v-divider></v-divider>
          <PayFlowEmployee
            v-if="
              softwareIntegration.payFlow && (isExpanded || isFullyExpanded)
            "
            :employeeNumber="employee.employeeNumber"
            :firstName="employee.firstName"
            :lastName="employee.lastName"
            :mandants="employee.mandants"
          />
          <Timeline
            :employee="employee"
            :mandant="matchedMandantUuid || ''"
            v-if="isExpanded"
          ></Timeline>
          <SelectionPicker
            v-if="pickerDialog"
            :pickerItems="pickerItems"
            :showDialog="pickerDialog"
            :dialogTitle="pickerTitle"
            @update:showDialog="pickerDialog = $event"
            @itemSelected="saveSelection"
          ></SelectionPicker>
        </v-card>
      </template>
    </v-hover>
  </v-container>
</template>

<script lang="ts">
import { Assignment } from "@/models/assignment.model";
import { CheckList } from "@/models/checklist.model";
import { defineComponent, PropType } from "vue";
import { Employee } from "@/models/employee.model";
import { EmployeeService } from "@/services/api/employee.service";
import { Mandant } from "@/models/mandant.model";
import { mapGetters } from "vuex";
import { ModalMode } from "@/enums/dialog-action.enum";
import { PdHubService } from "@/services/api/pd-hub.service";
import { Salutation } from "@/enums/salutation.model";
import { User } from "@/models/user.model";
import { ZvooveOneEmployee } from "@/services/api/api-integration-one.service";
import Assignments from "./elements/Assignments.vue";
import Checklist from "./elements/Checklist.vue";
import DialogService from "@/services/dialog.service";
import MailClient from "./elements/MailClient.vue";
import moment from "moment";
import PayFlowEmployee from "../payflow/PayFlowEmployee.vue";
import PhoneClient from "./elements/PhoneClient.vue";
import SelectionPicker from "./elements/SelectionPicker.vue";
import Timeline from "./elements/Timeline.vue";
import ToastService from "@/services/toast.service";
import WhatsAppClient from "./elements/WhatsAppClient.vue";
import zvooveOneLinkMenu from "./elements/ExternalSoftwareLinksMenu.vue";

export default defineComponent({
  name: "EmployeeItem",
  emits: ["insertUpdatedEmployee"],
  components: {
    Assignments,
    Checklist,
    MailClient,
    PayFlowEmployee,
    PhoneClient,
    SelectionPicker,
    Timeline,
    WhatsAppClient,
    zvooveOneLinkMenu,
  },
  props: {
    employee: {
      type: Object as PropType<Employee>,
      required: true,
    },
    isActive: {
      type: Boolean,
      required: false,
    },
    isFullyCollapsed: {
      type: Boolean,
      required: true,
    },
    user: {
      type: Object as PropType<User>,
      required: true,
    },
  },
  data() {
    return {
      isExpanded: false,
      isFullyExpanded: false,
      employeeService: new EmployeeService(),
      filteredEmail: [
        {
          mailaddress: this.employee.email,
          salutation: this.employee.letterSalutation,
        },
      ] as any,
      filteredPhoneNumbers: [
        { label: "Telefon1", value: this.employee.address.phone1 },
        { label: "Telefon2", value: this.employee.address.phone1 },
      ] as any,
      message: {
        subject: "Betreff",
        body: "[Gibt Dein Text ein...]",
      },
      onItemSelect: null as ((uuid: string) => Promise<void>) | null,
      pdHubService: PdHubService.getInstance(),
      pickerDialog: false,
      pickerItems: [] as any,
      pickerTitle: "",
      statusPickerDialog: false,
      zvooveOneEmployee: new ZvooveOneEmployee(),
    };
  },
  computed: {
    ...mapGetters(["employeeStates", "mandants", "softwareIntegration"]),
    //TODO: Remove when build Placements.vue
    currentPlacement() {
      const today = moment();
      const current = this.employee.assignments?.find(
        (placement) =>
          moment(placement.from).isSameOrBefore(today) &&
          moment(placement.to).isSameOrAfter(today)
      );
      return current
        ? `${current.clientName}: ${current.jobTitle} von ${current.from} bis ${current.to}`
        : "Kein Einsatz!";
    },
    matchedMandant() {
      const matchedMandant = this.mandants.find(
        (mandant: Mandant) =>
          mandant.branchNumber === this.employee?.branchOfficeId
      );
      return matchedMandant || { name: "", uuid: "" };
    },
    matchedMandantUuid() {
      return this.matchedMandant.uuid;
    },
    ModalMode() {
      return ModalMode;
    },
  },
  methods: {
    collapseItem() {
      this.isFullyExpanded = false;
      this.isExpanded = false;
    },
    checkEmployeeStatus() {
      const employeeStatus = this.employee.employeeStatus;
      const employeeNumber = this.employee.employeeNumber;
      const employeeListStatus =
        this.$store.getters.getStatusByEmployeeNumber(employeeNumber);
      if (employeeStatus !== employeeListStatus) {
        DialogService.confirm(
          `${this.employee.firstName} ${this.employee.lastName} (${employeeNumber}) hat in der Liste aus dem ERP System vom letzten Abruf den Status ${employeeListStatus}! Soll der Status angepasst werden?`,
          `Abbrechen`,
          `Ja auf Status ${employeeListStatus} setzen`
        ).then((confirmed) => {
          if (confirmed) {
            const employee = this.employee;
            employee.employeeStatus = employeeListStatus;
            this.updateEmployee(employee);
          }
        });
      }
    },
    getEmployeeState(id: number) {
      return this.employeeStates[id] || "Unbekannter Status";
    },
    async getPlacements() {
      if (
        this.softwareIntegration.zvooveOne ||
        this.softwareIntegration.pdHub
      ) {
        let placements = [] as Assignment[];
        const employee = this.employee;
        if (this.softwareIntegration.zvooveOne) {
          placements = await this.zvooveOneEmployee.getPlacements(
            employee.employeeNumber
          );
        } else if (this.softwareIntegration.pdHub) {
          placements = await this.pdHubService.getPlacements(
            employee.employeeNumber
          );
        }
        if (placements.length > 0) {
          employee.assignments = placements;
          await this.updateEmployee(employee);
        }
      }
    },
    openPicker(context: string) {
      this.pickerDialog = true;

      if (context === ModalMode.employeeStatus) {
        this.pickerItems = Object.entries(this.employeeStates).map(
          ([uuid, name]) => ({
            name: name,
            uuid: uuid,
          })
        );
        this.pickerTitle = "Status auswählen";
        this.onItemSelect = this.saveStatusSelection;
      }
    },
    saveSelection(uuid: string): void {
      if (typeof this.onItemSelect === "function") {
        this.onItemSelect(uuid);
        this.pickerDialog = false;
      }
    },
    async saveStatusSelection(selectedStatusIndex: string): Promise<void> {
      if (!this.employee) return;
      const employee = this.employee;
      employee.employeeStatus = parseInt(selectedStatusIndex);
      await this.updateEmployee(employee);
    },
    signatureMail() {
      const user = this.user;
      return `freundliche Grüße<br>${Salutation[user.salutation]} ${
        user.forename
      } ${user.lastname}<br>${this.$store.state.company.name}`;
    },
    signatureWhatsApp() {
      const user = this.user;
      return `Viele Grüße\n${Salutation[user.salutation]} ${user.forename} ${
        user.lastname
      }\n${this.$store.state.company.name}`;
    },
    toggleExpansion() {
      this.isExpanded = !this.isExpanded;
      if (!this.isExpanded) {
        this.isFullyExpanded = false;
      }
      if (this.isExpanded || this.isFullyExpanded) this.updateComponent();
    },
    toggleFullExpansion() {
      if (this.isExpanded) {
        this.isFullyExpanded = !this.isFullyExpanded;
      }
    },
    updateComponent() {
      if (!this.employee.assignments || this.employee.assignments.length === 0)
        this.getPlacements();
      this.checkEmployeeStatus();
    },
    async updateEmployee(employee: Employee) {
      this.employeeService.editEmployee(employee).then(() => {
        this.$emit("insertUpdatedEmployee", employee);
      });
    },
    updateChecklist(checklist: CheckList[]) {
      if (!this.employee) return;
      const employee = this.employee;
      employee.checklist = checklist;
      this.updateEmployee(employee);
    },
    async updateEmployeeFromErp() {
      if (this.employee) {
        const employee = this.employee;
        try {
          let employeeData = {} as Employee | null;
          if (this.softwareIntegration.zvooveOne) {
            employeeData = await this.zvooveOneEmployee.getEmployeeAndPlacement(
              employee.employeeNumber
            );
          } else if (this.softwareIntegration.pdHub) {
            employeeData = await this.pdHubService.getByEmployeeNumber(
              employee.employeeNumber
            );
            if (employeeData) {
              employeeData.assignments = await this.pdHubService.getPlacements(
                employee.employeeNumber
              );
            }
          } else {
            return;
          }
          Object.assign(employee, employeeData);
          await this.updateEmployee(employee);
        } catch (error) {
          console.error("Error updating employee from ERP:", error);
        }
      }
    },
    async updateErpFromEmployee() {
      if (this.softwareIntegration.zvooveOne) {
        await this.zvooveOneEmployee.putEmployee(this.employee);
      } else if (this.softwareIntegration.pdHub) {
        // await this.pdHubService.patchEmployee(this.employee);
        ToastService.showReminder(
          "Mitarbeiter Update derzeit in PD-Hub nicht vorgesehen ..."
        );
      }
    },
  },
});
</script>
<style scoped>
.employee-status {
  cursor: pointer;
  font-size: 0.8rem;
}
</style>
