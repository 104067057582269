// src/enum/shift-preferences.enum.ts
export enum ShiftPreferences {
  TwoShift = "2er Schicht",
  ThreeShift = "3er Schicht",
  FourShift = "4er Schicht",
  FiveShift = "5er Schicht",
  Morning = "Vormittags",
  Afternoon = "Nachmittags",
  DayShift = "Tagschicht",
  PermanentNightShift = "Dauernachtschicht",
}

export enum ShiftPreferencesIcon {
  None = "",
  TwoShift = "□■■",
  ThreeShift = "■■■",
  FourShift = "■■■■",
  FiveShift = "■■■■■",
  Morning = "■□",
  Afternoon = "□■",
  DayShift = "□■□",
  PermanentNightShift = "■□□",
}
