<template>
  <h3>DEBUG Info</h3>
  <br />
  <div>
    <ul>
      <li>Version {{ getVersion() }}</li>
      <li>Environment: {{ getEnvironment() }}</li>
      <li>Backend URL: {{ getBackendURI() }}</li>
    </ul>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { mapGetters } from "vuex";

export default defineComponent({
  name: "DebugInfo",
  computed: {
    ...mapGetters(["getEnv"]),
  },
  methods: {
    getBackendURI() {
      return this.$store.getters.getEnv.VUE_APP_BACKEND_URI;
    },
    getEnvironment() {
      return this.$store.getters.getEnv.NODE_ENV;
    },
    getVersion() {
      return this.$store.getters.getEnv.VUE_APP_VERSION;
    },
  },
});
</script>
