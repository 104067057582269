<!-- App.vue -->
<template>
  <div id="app">
    <HeartbeatService />
    <TopHeader
      :isLoggedIn="isLoggedIn"
      :showVersion="showVersion()"
    ></TopHeader>
    <JobPoster></JobPoster>
    <router-view />
    <!-- Spinner Overlay -->
    <div v-if="isSpinnerVisible" class="spinner-overlay">
      <SpinnerGame></SpinnerGame>
      <i class="fas fa-spinner fa-spin fa-3x"></i>
    </div>
  </div>
</template>

<script lang="ts">
import "@fortawesome/fontawesome-free/css/all.css";
import { defineComponent, ref } from "vue";
import { mapGetters } from "vuex";
import { onBeforeMount } from "vue";
import { onMounted, onUnmounted } from "vue";
import { UserHelperService } from "./services/user-helper.service";
import HeartbeatService from "@/helper/HeartbeatService.vue";
import JobPoster from "@/components/job-poster/job-poster.vue";
import TopHeader from "@/components/top-header/TopHeader.vue";
import SpinnerGame from "./components/spinner/SpinnerGame.vue";

export default defineComponent({
  components: {
    TopHeader,
    HeartbeatService,
    JobPoster,
    SpinnerGame,
  },
  computed: {
    ...mapGetters(["getEnv"]),
  },
  setup() {
    const isSpinnerVisible = ref(false);
    const isLoggedIn = ref(false);
    const userService = UserHelperService.getInstance();

    const handleShowSpinner = () => {
      isSpinnerVisible.value = true;
    };

    const handleHideSpinner = () => {
      isSpinnerVisible.value = false;
    };

    const updateUser = (newValue: boolean) => {
      isLoggedIn.value = newValue;
    };

    onBeforeMount(() => {
      userService.onChange(updateUser);
      isLoggedIn.value = userService.isLoggedIn;
    });

    onMounted(() => {
      document.addEventListener("show-spinner", handleShowSpinner);
      document.addEventListener("hide-spinner", handleHideSpinner);
    });

    onUnmounted(() => {
      userService.removeFromListener(updateUser);
      document.removeEventListener("show-spinner", handleShowSpinner);
      document.removeEventListener("hide-spinner", handleHideSpinner);
    });

    return { isLoggedIn, isSpinnerVisible };
  },
  methods: {
    showVersion(): boolean {
      return this.$store.getters.getEnv.NODE_ENV !== "production";
    },
  },
});
</script>

<style lang="scss">
@import "./scss/styles.scss";
</style>
