export enum Languages {
  Albanian = "Shqip",
  Arabic = "العربية",
  English = "English",
  French = "Français",
  German = "Deutsch",
  Italian = "Italiano",
  Romanian = "Română",
  Russian = "Русский",
  Spanish = "Español",
  Turkish = "Türkçe",
  Ukrainian = "Українська",
}
