import { WhatsApp } from "@/models/whatsapp.model";
import { Api } from "./api.class";

export class WhatsAppConfig {
  private api = Api.getInstance();
  private urlPath = "/wa-config";

  async get() {
    return (await this.api.getBackendApi().get(`${this.urlPath}`)).data
      .response as WhatsApp;
  }

  async update(whatsAppConfig: WhatsApp) {
    if (whatsAppConfig) {
      const response = await this.api
        .getBackendApi()
        .put(this.urlPath, whatsAppConfig);
      return response.data;
    }
    return undefined;
  }
}
