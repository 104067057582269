<!--src/components/disposition/elements/SelectionPicker.vue-->
<template>
  <v-dialog
    v-model="dialog"
    persistent
    :max-width="smAndDown ? 'var(--d-w-sd)' : 'var(--d-w-m)'"
  >
    <v-card>
      <v-card-title> {{ dialogTitle }} </v-card-title>
      <v-card-text>
        <v-radio-group v-model="selectedUuid">
          <v-radio
            v-for="pickerItem in pickerItems"
            :key="pickerItem.uuid"
            :label="pickerItem.name"
            :value="pickerItem.uuid"
          ></v-radio>
        </v-radio-group>
      </v-card-text>
      <v-card-actions>
        <v-btn color="green-darken-3" @click="saveSelection">OK</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script lang="ts">
import { defineComponent, ref, Ref, watch } from "vue";
import { useDisplay } from "vuetify";

interface Item {
  name: string;
  uuid: string;
}

export default defineComponent({
  name: "SelectionPicker",
  props: {
    pickerItems: {
      type: Array as () => Item[],
      required: true,
    },
    showDialog: {
      type: Boolean,
      required: true,
    },
    dialogTitle: {
      type: String,
      required: true,
    },
  },
  emits: ["update:showDialog", "itemSelected"],
  setup(props, { emit }) {
    const dialog: Ref<boolean> = ref(props.showDialog);
    const selectedUuid: Ref<string | null> = ref(null);
    const { smAndDown } = useDisplay();

    watch(
      () => props.showDialog,
      (newVal: boolean) => {
        dialog.value = newVal;
      }
    );

    const saveSelection = () => {
      emit("itemSelected", selectedUuid.value);
      dialog.value = false;
      emit("update:showDialog", false);
    };

    return { dialog, selectedUuid, saveSelection, smAndDown };
  },
});
</script>
