// auth.service.ts

import { Router } from "vue-router";

class AuthService {
  private static instance: AuthService;
  private router: Router | null = null;
  private isTokenRefreshSetup = false; // Neue Variable, um das Setup zu verfolgen

  private constructor(router: Router) {
    this.router = router;
    //this.setupTokenRefresh();
  }

  public static getInstance(router: Router): AuthService {
    if (!AuthService.instance) {
      AuthService.instance = new AuthService(router);
    }

    return AuthService.instance;
  }

  public setupTokenRefresh(): void {
    if (!this.router) {
      throw new Error("Router is not provided.");
    }

    // Prüfen, ob das Setup bereits durchgeführt wurde, um eine erneute Einrichtung zu verhindern
    if (this.isTokenRefreshSetup) {
      return;
    }

    this.router.beforeEach((to, from, next) => {
      const token = this.getToken();

      if (!token && to.name !== "Login") {
        // Benutzer ist nicht angemeldet und versucht eine andere Seite als die Login-Seite zu besuchen
        next({ name: "Login" });
      } else {
        // Überprüfen Sie, ob das Token abgelaufen ist
        const expirationDate = this.getTokenExpirationDate(token);

        if (expirationDate && expirationDate < new Date()) {
          // Token ist abgelaufen, leite den Benutzer zur Login-Seite weiter
          next({ name: "Login" });
        } else {
          // Token ist gültig, erlauben Sie die Navigation
          next();
        }
      }
    });

    // Setze die Variable, um anzuzeigen, dass das Setup abgeschlossen ist
    this.isTokenRefreshSetup = true;
  }

  private getToken(): string | null {
    return localStorage.getItem("accessToken");
  }

  public getTokenExpirationDate(token: string | null): Date | null {
    if (!token) {
      return null;
    }

    try {
      // Decodieren Sie den Payload des Tokens von Base64
      const payloadBase64 = token.split(".")[1];
      const payloadJson = atob(payloadBase64);

      // Parse JSON-Payload
      const payload = JSON.parse(payloadJson);

      // Überprüfen Sie, ob das Ablaufdatum (exp) im Payload vorhanden ist
      if (payload.exp) {
        // Erstellen Sie ein Date-Objekt basierend auf dem Unix-Zeitstempel
        const expirationDate = new Date(payload.exp * 1000);

        return expirationDate;
      }
    } catch (error) {
      console.error("Fehler beim Extrahieren des Ablaufdatums:", error);
    }

    return null;
  }

  public isExpired(): boolean {
    const expirationDate = this.getTokenExpirationDate(
      localStorage.getItem("accessToken")
    );

    if (expirationDate && expirationDate < new Date()) return true;
    else return false;
  }
}

export default AuthService;
