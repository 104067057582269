<template>
  <div>
    <h1>
      Hier können Benutzer, AI Credits, Funnelcredits, WhatsApp Bewerbung
      Funktion und Stellencredits für den Stellenausschreiber gekauft werden
    </h1>
    Zugang hat der Licence-Admin und mit eine Klick + Bestätigung muss gekauft
    <p>
      Preisleiste:<br />
      Benutzer (900 AI Credits inklusive pro Monat): 2x brav sein / Monat<br />
      Heavy User (2000 AI Credits inklusive): immer brav sein im Monat<br />

      <br />
      Pay by consumption<br />
      AI Credit Stück = 1 Erdnuss <br />
      Stellenausschreiber Credit Stück = 1 Hosenknopf <br />
    </p>
  </div>
</template>

<script lang="ts">
export default {
  name: "ShopView",
};
</script>
