import { Api } from "./api.class";

export class BfaService {
  private api = Api.getInstance();
  private urlPath = "/bfa-jobs";

  async getBfaJoblist() {
    const response = await this.api
      .getBackendApi()
      .get(`${this.urlPath}/titles`);
    return response.data.map((title: string) =>
      title.length > 75 ? title.substring(0, 75) : title
    );
  }
}
