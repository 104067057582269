<template>
  <div class="header-with-button">
    <h2>3. Seiten Konfiguration</h2>
    <button class="save-button" @click="updateConfig({ Funnel: Funnel })">
      Änderungen speichern
    </button>
  </div>
  <div class="config-option">
    Landingpage Titel:<input v-model="funnelCopy.title" type="text" />
  </div>
  <div class="config-option">
    Link zu allen Stellen:<input v-model="funnelCopy.ALL_JOBS" type="text" />
  </div>
  <div class="config-option">
    Copyright:<input v-model="funnelCopy.copyright" type="text" />
  </div>
  <div class="config-option">
    Text (Copyright Link):<input
      v-model="funnelCopy.copyrightLinkText"
      type="text"
    />
  </div>
  <div class="config-option">
    Ziel (Copyright Link):<input
      v-model="funnelCopy.copyrightLink"
      type="text"
    />
  </div>
  <div class="config-option">
    Datenschutz Link:<input v-model="funnelCopy.DatenschutzLink" type="text" />
  </div>
</template>
<script lang="ts">
import { PropType, defineComponent, ref, watchEffect } from "vue";
import { ConfigService } from "@/services/config.service";
import { Config, Funnel } from "@/models/funnel-config.model";

export default defineComponent({
  name: "FunnelConfiguration",
  data() {
    return {
      configService: new ConfigService(),
    };
  },
  methods: {
    updateConfig(config: Config) {
      this.configService.updateConfig(config); // Rufen Sie die gemeinsame Methode auf
    },
  },
  props: {
    funnel: Object as PropType<Funnel>,
  },
  setup(props) {
    let funnelCopy = ref<Funnel>({
      title: "",
      key: "",
      ALL_JOBS: "",
      copyright: "",
      copyrightLinkText: "",
      copyrightLink: "",
      DatenschutzLink: "",
    });

    watchEffect(() => {
      const newVal = props.funnel;
      if (newVal) {
        funnelCopy.value = newVal;
      }
    });

    return { funnelCopy };
  },
});
</script>
