//src/enums/vuex-mutationtyp.enum.ts
export enum MutationType {
  setZvooveCustomerList = "SET_ZVOOVE_CUSTOMER_LIST",
  setZvooveCustomers = "SET_ZVOOVE_CUSTOMERS",
  setZvooveEmployeeList = "SET_ZVOOVE_EMPLOYEE_LIST",
  setZvooveEmployees = "SET_ZVOOVE_EMPLOYEES",
  setZvooveDashboardData = "SET_ZVOOVE_DASHBOARD_DATA",
  addReminder = "ADD_REMINDER",
  clearReminderBySource = "CLEAR_REMINDER_BY_SOURCE",
  updateLoggedinMandants = "UPDATE_LOGGEDIN_MANDANTS",
  setHeaderSpinnerElement = "SET_HEADER_SPINNER_ELEMENT",
  clearHeaderSpinnerElement = "CLEAR_HEADER_SPINNER_ELEMENT",
  setStatus = "SET_STATUS",
  setJobAdPublishHomepage = "SET_JOB_AD_PUBLISH_HOMEPAGE",
  togglePostingIsPaused = "TOGGLE_POSTING_IS_PAUSED",
  togglePostingIsCancelled = "TOGGLE_POSTING_IS_CANCELLED",
  setTotalJobAds = "SET_TOTAL_JOB_ADS",
  incrementPostedJobAds = "INCREMENT_POSTED_JOB_ADS",
  resetPostedJobAds = "RESET_POSTED_JOB_ADS",
  setDraggingItem = "SET_DRAGGING_ITEM",
  clearDraggingItem = "CLEAR_DRAGGING_ITEM",
  setICM = "SET_INTER_COMPONENT_MESSAGE",
  clearICM = "CLEAR_INTER_COMPONENT_MESSAGE",
  addPostcodeCandidates = "ADD_POSTCODE_CANDIDATES",
  clearPostcodesCandidates = "CLEAR_POSTCODES_CANDIDATES",
  setPostcodeCustomers = "SET_POSTCODE_CUSTOMERS",
}
