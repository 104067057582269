import { convertColWidthsToMm } from "@/helper/pdf.helper";
import { Api } from "./api.class";

export class PdfService {
  private api = Api.getInstance();
  private urlPath = "/pdf";

  async getPdf(html: any) {
    const response = await this.api.getBackendApi().post(`${this.urlPath}`, {
      html: JSON.stringify(html),
    });
    return response.data;
  }

  async getPdf2(html: any) {
    const adjustedHtml = convertColWidthsToMm(html);
    const response = await this.api
      .getBackendApi()
      .post(`${this.urlPath}/pdf2`, {
        html: adjustedHtml,
      });
    return response.data;
  }
}
