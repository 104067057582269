import { resolveComponent as _resolveComponent, mergeProps as _mergeProps, createVNode as _createVNode, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "d-flex align-center" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_switch = _resolveComponent("v-switch")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_v_switch, _mergeProps({
      "model-value": _ctx.status,
      color: "var(--color-primary)",
      "hide-details": ""
    }, _ctx.$attrs, {
      onChange: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('handleChange')))
    }), null, 16, ["model-value"]),
    _createElementVNode("label", {
      onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.$emit('labelClick'))),
      class: _normalizeClass({ label: true, labelclickable: !_ctx.noLink && _ctx.status })
    }, _toDisplayString(_ctx.label), 3)
  ]))
}