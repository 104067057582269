import { Question, SubQuestion } from "@/models/funnel-question.model";

export default class QuestionsService {
  addQuestion(questions: Question[]) {
    const newId = questions.length + 1;
    const newQuestion: Question = {
      id: newId.toString(),
      question_text: "",
      exit: false,
      sub_questions: [],
    };
    questions.push(newQuestion);
  }

  addSubQuestion(questions: Question[], questionId: string) {
    const parentQuestion = questions.find((q: any) => q.id === questionId);
    if (parentQuestion) {
      const newSubId = `${questionId}_${
        parentQuestion.sub_questions.length + 1
      }`;
      const newSubQuestion = {
        id: newSubId,
        question_text: "",
        exit: false,
        choices: [],
      };
      parentQuestion.sub_questions.push(newSubQuestion);
    }
  }

  addChoice(questions: Question[], questionId: string, subQuestionId: string) {
    const parentQuestion = questions.find((q: Question) => q.id === questionId);
    const subQuestion = parentQuestion?.sub_questions.find(
      (sq: any) => sq.id === subQuestionId
    );
    if (subQuestion) {
      const newChoice = { choice_text: "" };
      subQuestion.choices.push(newChoice);
    }
  }

  removeQuestion(questions: Question[], questionId: any) {
    questions = questions.filter((q: Question) => q.id !== questionId);
  }

  removeSubQuestion(
    questions: Question[],
    questionId: string,
    subQuestionId: string
  ) {
    const question = questions.find((q: Question) => q.id === questionId);
    if (question) {
      question.sub_questions = question.sub_questions.filter(
        (sq: SubQuestion) => sq.id !== subQuestionId
      );
    }
  }

  removeChoice(
    questions: Question[],
    questionId: string,
    subQuestionId: string,
    choiceIndex: number
  ) {
    const question = questions.find((q: Question) => q.id === questionId);
    const subQuestion = question?.sub_questions.find(
      (sq: SubQuestion) => sq.id === subQuestionId
    );
    if (subQuestion && subQuestion.choices.length > choiceIndex) {
      subQuestion.choices.splice(choiceIndex, 1);
    }
  }
}
