// src/services/spinner.service.ts
import store from "@/store/store";
import { App } from "vue";

export class SpinnerService {
  private static app: App<Element> | null = null;

  static setHeaderSpinnerElement(size: number, gif: string) {
    store.commit("SET_HEADER_SPINNER_ELEMENT", { size, gif });
  }

  static clearHeaderSpinnerElement() {
    store.commit("CLEAR_HEADER_SPINNER_ELEMENT");
  }

  static showSpinner() {
    document.dispatchEvent(new CustomEvent("show-spinner"));
  }

  static removeSpinner() {
    document.dispatchEvent(new CustomEvent("hide-spinner"));
  }
}
