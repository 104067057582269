import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = (n: any) => (_pushScopeId("data-v-2e861e0d"),n=n(),_popScopeId(),n)
const _hoisted_1 = { key: 0 }
const _hoisted_2 = { key: 1 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_card_subtitle = _resolveComponent("v-card-subtitle")!
  const _component_v_progress_circular = _resolveComponent("v-progress-circular")!
  const _component_v_card_text = _resolveComponent("v-card-text")!
  const _component_v_list_item_title = _resolveComponent("v-list-item-title")!
  const _component_v_list_item_subtitle = _resolveComponent("v-list-item-subtitle")!
  const _component_v_card = _resolveComponent("v-card")!
  const _component_v_container = _resolveComponent("v-container")!

  return (_openBlock(), _createBlock(_component_v_container, {
    class: "mx-0 pa-0 mb-5 chat-messages",
    fluid: ""
  }, {
    default: _withCtx(() => [
      _createVNode(_component_v_card, { class: "ma-0 pa-0 border-md rounded-md chat-card" }, {
        default: _withCtx(() => [
          _createVNode(_component_v_card_subtitle, null, {
            default: _withCtx(() => [
              _createTextVNode(" Chat Nachrichten ")
            ]),
            _: 1
          }),
          (_ctx.messages.length === 0)
            ? (_openBlock(), _createBlock(_component_v_card_text, {
                key: 0,
                class: "spinner-container"
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_v_progress_circular, {
                    indeterminate: "",
                    color: "var(--color-primary)",
                    size: "300",
                    width: "16",
                    class: "slow-spinner"
                  })
                ]),
                _: 1
              }))
            : _createCommentVNode("", true),
          (_ctx.messages.length > 0)
            ? (_openBlock(), _createBlock(_component_v_card_text, { key: 1 }, {
                default: _withCtx(() => [
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.messages, (msg) => {
                    return (_openBlock(), _createElementBlock("div", {
                      key: msg.id,
                      class: "message-container"
                    }, [
                      _createVNode(_component_v_card, {
                        class: _normalizeClass({
              'message-from-me': msg.fromMe,
              'message-from-them': !msg.fromMe,
            })
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_component_v_list_item_title, null, {
                            default: _withCtx(() => [
                              (msg.fromMe)
                                ? (_openBlock(), _createElementBlock("strong", _hoisted_1, "Ich:"))
                                : (_openBlock(), _createElementBlock("strong", _hoisted_2, _toDisplayString(msg.sender.pushname) + ":", 1))
                            ]),
                            _: 2
                          }, 1024),
                          _createVNode(_component_v_list_item_subtitle, null, {
                            default: _withCtx(() => [
                              _createElementVNode("span", null, _toDisplayString(msg.body), 1)
                            ]),
                            _: 2
                          }, 1024),
                          _createVNode(_component_v_list_item_subtitle, { class: "text-caption text-grey--text" }, {
                            default: _withCtx(() => [
                              _createElementVNode("small", null, _toDisplayString(_ctx.formatTimestamp(msg.timestamp)), 1)
                            ]),
                            _: 2
                          }, 1024)
                        ]),
                        _: 2
                      }, 1032, ["class"])
                    ]))
                  }), 128))
                ]),
                _: 1
              }))
            : _createCommentVNode("", true)
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}