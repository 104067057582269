import { Api } from "./api.class";

export class WhatsAppExperimentalService {
  private api = Api.getInstance();
  private urlPath = "/wa-experimental";

  async sendMessage(sessionName: string, jid: string, message: string) {
    try {
      const payload = {
        sessionName,
        jid,
        message,
      };
      const response = await this.api
        .getBackendApi()
        .post(`${this.urlPath}/send-message`, payload);
      return response.data;
    } catch (error) {
      console.error("Error sending message:", error);
      return { data: null, error };
    }
  }

  async checkChat(sessionName: string, jid: string) {
    try {
      const payload = {
        sessionName,
        jid,
      };
      const response = await this.api
        .getBackendApi()
        .post(`${this.urlPath}/check-chat`, payload);
      return response.data;
    } catch (error) {
      console.error("Error checking chat:", error);
      return { data: null, error };
    }
  }

  async checkStatus(sessionName: string) {
    try {
      const payload = { sessionName };
      const response = await this.api
        .getBackendApi()
        .post(`${this.urlPath}/status`, payload);
      return response.data;
    } catch (error) {
      console.error("Error checking status:", error);
      return { data: null, error };
    }
  }
}
