export enum PayrollInfoType {
  advance = "Vorschuss",
  partialPayment = "Abschlag",
  contractualPenalty = "Vertragsstrafe",
  unpaidVacation = "unbezahlter Urlaub",
  unpaidTrailWork = "unbezahlte Probearbeit",
  noWageEntitlement = "kein Lohnanspruch",
  deductPPE = "PSA abziehen",
  reimbursePPE = "PSA erstatten",
  payOvertime = "Überstunden auszahlen",
  fuelVoucher = "Tankgutschein",
  goodsVoucher = "Warengutschein",
  employeeReferralBonus = "Prämie Mitarbeiter-Werben-Mitarbeiter",
  miscellaneous = "Sonstiges",
}

export enum SalaryAdjustmentType {
  plus = 0,
  minus = 1,
}

export enum PayFlowCheck {
  none = 0,
  accepted = 1,
  edited = 2,
  rejected = 3,
}

export enum PayFlowType {
  advanceList = "Vorschussliste",
  payrollInfo = "Lohnlaufinfo",
  all = "Alles",
}

export enum PayFlowRole {
  BranchManager = "Niederlassungsleitung",
  Accountant = "Buchhaltung",
  PayrollOfficer = "Lohnauszahlung",
}

export interface PayrollDeduction {
  adjustmentTypeId: string;
  type: number;
  payrollPeriodFrom: string;
  payrollPeriodTo: string;
  interval: string;
  amount: number;
  accountNumber: number;
  info: string;
}
