import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock, createElementVNode as _createElementVNode, createVNode as _createVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = (n: any) => (_pushScopeId("data-v-3fda4c3e"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "container" }
const _hoisted_2 = { class: "filter-menu-left-column" }
const _hoisted_3 = { class: "filter-menu-right-column" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_I_JobFunnelItem = _resolveComponent("I_JobFunnelItem")!
  const _component_I_JobFunnelEdit = _resolveComponent("I_JobFunnelEdit")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.company.funnels, (funnel) => {
        return (_openBlock(), _createBlock(_component_I_JobFunnelItem, {
          key: funnel.id,
          name: funnel.name,
          funnelId: funnel.id,
          onEditFunnel: _ctx.handleEditFunnel
        }, null, 8, ["name", "funnelId", "onEditFunnel"]))
      }), 128))
    ]),
    _createElementVNode("div", _hoisted_3, [
      _createVNode(_component_I_JobFunnelEdit, { funnelId: _ctx.funnelIdSelection }, null, 8, ["funnelId"])
    ])
  ]))
}