<!--I_JobFunnelEdit.vue-->
<template>
  <div v-if="active">
    <!-- Check if a source for the funnel is available. If so, render the content inside. -->
    <FunnelBanner
      :funnelId="funnelId"
      :companyName="funnelWithQuestions.company?.name"
    />
    <hr />
    <FunnelQuestions
      :headers="Headers"
      :questions="funnelWithQuestions.questions"
      :timestamp="timeStamp"
      :MainHeader="MainHeader"
      :SubHeader="SubHeader"
      :ChoicesHeader="ChoicesHeader"
      :funnelId="funnelId"
      :companyName="funnelWithQuestions.company?.name"
    />
    <hr />
    <FunnelConfiguration :funnel="funnelWithQuestions.Funnel" />
    <hr />
    <FunnelColors :colors="funnelWithQuestions.Colors" />
    <hr />
    <FunnelThankYou
      :thankYouPage="funnelWithQuestions.ThankYouPage"
      :funnelId="funnelId"
      :companyName="funnelWithQuestions.company?.name"
    />
    <hr />
    <FunnelNoWay :noWayPage="funnelWithQuestions.NoWayPage" />
    <br /><br />
    <hr />
  </div>
  <div v-else>Bitte wählen Sie eine Funnel-Quelle aus.</div>
</template>

<script lang="ts">
import { mapGetters } from "vuex"; //store.ts
import FunnelBanner from "@/components/recruiting/funnel-edit/funnel-banner.vue";
import FunnelColors from "@/components/recruiting/funnel-edit/funnel-colors.vue";
import FunnelConfiguration from "@/components/recruiting/funnel-edit/funnel-configuration.vue";
import FunnelQuestions from "@/components/recruiting/funnel-edit/funnel-questions.vue";
import FunnelThankYou from "@/components/recruiting/funnel-edit/funnel-thank-you.vue";
import FunnelNoWay from "@/components/recruiting/funnel-edit/funnel-no-way-page.vue";
import { FunnelService } from "@/services/api/funnel.service";
import { Config } from "@/models/funnel-config.model";
import { FunnelWithQuestions } from "@/models/funnel-with-questions.model";
import { defineComponent, ref, toRefs, watch } from "vue";

export default defineComponent({
  name: "I_JobFunnelEdit",
  props: {
    funnelId: String,
  },
  components: {
    FunnelBanner,
    FunnelColors,
    FunnelConfiguration,
    FunnelNoWay,
    FunnelQuestions,
    FunnelThankYou,
  },
  data() {
    return {
      timeStamp: new Date().getTime(), // We use this as a URL parameter for images to bypass caching.
      Headers: "",
      MainHeader: "",
      SubHeader: "",
      ChoicesHeader: "",
      config: {} as Config,
      funnelService: new FunnelService(),
    };
  },
  watch: {
    MainHeader() {
      // Using watchers, I store all the data back into the main objects 'questions' and 'config'. This workaround is necessary to avoid errors outside the v-for
      //this.headers.MainHeader = newVal;
    },
    SubHeader() {
      //this.questions.headers.ChoicesHeader = newVal;
    },
    Funnel: {
      deep: true,
      handler(newVal) {
        this.config.Funnel = newVal;
      },
    },
    Colors: {
      deep: true,
      handler(newVal) {
        this.config.Colors = newVal;
      },
    },
    "config.Colors": {
      deep: true,
      handler(newColors) {
        this.updateCSSVariables(newColors);
      },
    },
    ThankYouPage: {
      deep: true,
      handler(newVal) {
        this.config.ThankYouPage = newVal;
      },
    },
    NoWayPage: {
      deep: true,
      handler(newVal) {
        this.config.NoWayPage = newVal;
      },
    },
    ZvooveRecruit: {
      deep: true,
      handler(newVal) {
        this.config.ZvooveRecruit = newVal;
      },
    },
  },
  computed: {
    ...mapGetters(["company"]), //Get Funnel Data from vuex store.je
  },
  setup(props) {
    const { funnelId } = toRefs(props);
    let funnelWithQuestions = ref<FunnelWithQuestions>(
      {} as FunnelWithQuestions
    );
    let active = ref(false);
    funnelWithQuestions.value = {} as FunnelWithQuestions;

    watch(funnelId, async (newVal) => {
      if (newVal) {
        const funnelService = new FunnelService();
        funnelWithQuestions.value = await funnelService.fetchConfigData(newVal);
        console.log("Funnel: ", funnelWithQuestions.value);
        active.value = true;
      }
    });

    return {
      active,
      funnelWithQuestions,
    };
  },
  methods: {
    isObject(value: any) {
      return (
        value !== null && typeof value === "object" && !Array.isArray(value)
      );
    },
    updateCSSVariables(colors: any) {
      for (const key in colors) {
        if (colors[key]) {
          document.documentElement.style.setProperty(`--${key}`, colors[key]);
        }
      }
    },
    async fetchFunnelWithQuestions() {
      const response = await this.funnelService.fetchConfigData(
        this.funnelId as string
      );
      console.log("Response: ", response);
    },
  },
});
</script>

<style>
:root {
  /* Default values: The watchers dynamically update the design based on the colors from config.Colors, allowing the user to see how their changes affect the funnel's CSS styling. */
  --color-primary: #244578;
  --color-secondary: #eb8c2f;
  --SchriftHell: #ffffff;
  --Hintergrund: #ffffff;
  --HintergrundQuestionForm: #e6e3de;
  --ErrorColor: #ff0000;
  --ErrorBackgroundColor: #ffe6e6;
  --TextFieldBackground: #f9f9f9;
  --ProgressBarBackground: #f3f3f3;
}

.funnel-questions {
  background-color: var(--Hintergrund);
}

.main-questions,
.sub-questions {
  display: flex;
  flex-wrap: wrap;
  gap: 15px;
}

.main-image {
  background-color: var(--color-primary);
  border-radius: 8px;
  width: 165px;
}

.uploadable-image {
  cursor: pointer;
  position: relative;
}

/* Added an intense upload animation to make it more intuitive that images can be uploaded individually. */
.uploadable-image .icon {
  display: none;
  position: absolute;
  bottom: 0px;
  left: -15px;
  font-size: 3em;
  color: var(--color-primary);
  text-shadow: -3px -3px 0 var(--HintergrundQuestionForm),
    3px -3px 0 var(--HintergrundQuestionForm),
    -3px 3px 0 var(--HintergrundQuestionForm),
    3px 3px 0 var(--HintergrundQuestionForm);
  opacity: 0;
  transition: opacity 0.3s ease-in-out;
}

.uploadable-image:hover .icon {
  display: block;
  animation: slideUp 1s ease-in-out infinite;
  opacity: 1;
  /*visible on hover*/
}

@keyframes slideUp {
  0% {
    transform: translateY(20%);
    opacity: 0;
  }

  50% {
    opacity: 1;
  }

  100% {
    transform: translateY(-20%);
    opacity: 0;
  }
}

.question-card {
  position: relative;
  background-color: var(--HintergrundQuestionForm);
  width: 450px;
  border-radius: 8px;
  padding: 10px;
  margin-bottom: 10px;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.4);
}

.sub-question-card {
  position: relative;
  background-color: var(--color-primary);
  color: var(--SchriftHell);
  border-radius: 8px;
  padding: 10px;
  margin-bottom: 10px;
}

.choice-card {
  position: relative;
  background-color: var(--HintergrundQuestionForm);
  padding: 0px;
  margin-bottom: 5px;
  border-radius: 8px;
}

.funnel-textfield {
  background-color: var(--TextFieldBackground);
  border-color: var(--ProgressBarBackground);
}

.funnel-textfield:hover {
  border-color: var(--color-secondary);
}

.remove-button {
  position: absolute;
  top: -10px;
  right: -5px;
  background-color: transparent;
  color: var(--ErrorColor);
  font-size: 1em;
  border: none;
  border-radius: 50%;
  width: 25px;
  height: 25px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.remove-button:hover {
  scale: var(--medium-scale-up);
  background-color: var(--ErrorColor);
  color: var(--ErrorBackgroundColor);
}

.add-button {
  background-color: var(--color-primary);
  color: var(--SchriftHell);
}

.add-button:hover {
  background-color: var(--color-secondary);
  color: var(--SchriftHell);
}

.header-with-button {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.save-button {
  color: #e6e3de;
}

.config-option {
  display: grid;
  grid-template-columns: 200px auto;
  gap: 0px;
  align-items: center;
  margin-bottom: 10px;
  text-align: right;
}

.config-option img {
  display: block;
  margin-left: auto;
  margin-right: 0;
}

.config-option input {
  width: 90%;
}

.color-option {
  display: grid;
  grid-template-columns: 200px auto;
  gap: 0px;
  align-items: center;
  margin-bottom: 10px;
  text-align: right;
}

.color-option input {
  width: 40px;
  margin-left: 5px;
}
</style>
@/models/funnel-config.model
