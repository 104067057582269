// src/enums/employment-types.enum.ts
export enum EmploymentType {
  employed = 1,
  professional_training = 2,
  further_training = 3,
  study = 4,
  public_service = 5,
  schooling = 6,
  miscellaneous = 7,
  course = 8,
}
