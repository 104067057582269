<template>
  <v-container>
    <h2 class="mb-5">
      <v-icon size="small" class="mr-2">fa-brands fa-whatsapp</v-icon>WhatsApp
      Integration
    </h2>
    <v-form @submit.prevent="handleSubmit">
      <v-row>
        <v-col cols="12" md="6"></v-col>
        <v-col cols="12" md="6">
          <v-select
            v-model="whatsAppConfig.whatsAppMode"
            :items="whatsAppModeOptions"
            label="WhatsApp Modus"
            item-value="label"
            item-title="value"
            variant="outlined"
          ></v-select>
        </v-col>
      </v-row>
      <v-card-title>Accounts</v-card-title>
      <v-divider class="mb-5"></v-divider>
      <v-row>
        <v-col
          cols="12"
          md="4"
          v-for="(account, index) in whatsAppConfig.accounts"
          :key="account._id"
        >
          <v-card variant="outlined" class="mb-2">
            <v-card-title
              class="d-flex justify-content-between align-items-center mb-3"
            >
              {{ account.description }}
              <v-spacer></v-spacer>
              <v-btn variant="text" @click="removeAccount(index)">
                <v-icon>fa-solid fa-trash-can</v-icon>
              </v-btn>
            </v-card-title>
            <v-card-text>
              <v-text-field
                v-model="account.description"
                label="Name"
                variant="outlined"
                density="compact"
                class="mb-2"
              ></v-text-field>
              <v-select
                v-model="account.mandants"
                :items="mandants"
                item-title="name"
                item-value="uuid"
                label="Niederlassungen"
                variant="outlined"
                density="compact"
                class="mb-2"
                multiple
              ></v-select>
              <v-text-field
                v-model="account.phoneNumber"
                label="WhatsApp Nummer"
                variant="outlined"
                density="compact"
                class="mb-2"
              ></v-text-field>
              <v-text-field
                v-model="account.accountId"
                label="WA Business Account ID"
                variant="outlined"
                density="compact"
                class="mb-2"
              ></v-text-field>
              <v-text-field
                v-model="account.apiToken"
                label="WA Business API Token"
                variant="outlined"
                density="compact"
                class="mb-2"
              ></v-text-field>
              <v-text-field
                v-model="account.phoneId"
                label="WA Business Phone ID"
                variant="outlined"
                density="compact"
                class="mb-2"
              ></v-text-field>
              <v-select
                v-model="account.bot"
                :items="botOptions"
                label="Ai Bot"
                item-value="value"
                item-title="label"
                variant="outlined"
                density="compact"
              ></v-select>
            </v-card-text>
          </v-card>
        </v-col>
        <v-col cols="12" md="4">
          <v-card variant="outlined" class="border-dashed mb-2">
            <v-card-title
              class="d-flex justify-content-between align-items-center mb-3"
            >
              Neuer Status
            </v-card-title>
            <v-card-text>
              <v-text-field
                v-model="newAccount.description"
                label="Name"
                variant="outlined"
                density="compact"
                class="mb-2"
              ></v-text-field>
              <v-select
                v-model="newAccount.mandants"
                :items="mandants"
                item-title="name"
                item-value="uuid"
                label="Niederlassungen"
                variant="outlined"
                density="compact"
                class="mb-2"
                multiple
              ></v-select>
              <v-text-field
                v-model="newAccount.phoneNumber"
                label="WhatsApp Nummer"
                variant="outlined"
                density="compact"
                class="mb-2"
              ></v-text-field>
              <v-text-field
                v-model="newAccount.accountId"
                label="WA Business Account ID"
                variant="outlined"
                density="compact"
                class="mb-2"
              ></v-text-field>
              <v-text-field
                v-model="newAccount.apiToken"
                label="WA Business API Token"
                variant="outlined"
                density="compact"
                class="mb-2"
              ></v-text-field>
              <v-text-field
                v-model="newAccount.phoneId"
                label="WA Business Phone ID"
                variant="outlined"
                density="compact"
                class="mb-2"
              ></v-text-field>
              <v-select
                v-model="newAccount.bot"
                :items="botOptions"
                label="Ai Bot"
                item-value="value"
                item-title="label"
                variant="outlined"
                density="compact"
              ></v-select>
              <v-btn
                color="var(--color-primary)"
                variant="text"
                @click="addNewAccount"
                >Hinzufügen</v-btn
              >
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
      <v-btn class="mt-10" type="submit" color="green-darken-3"
        >Speichern</v-btn
      >
    </v-form>
  </v-container>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { WhatsAppConfig } from "@/services/api/whatsApp.service";
import { WhatsApp } from "@/models/whatsapp.model";
import { WhatsAppBot, WhatsAppMode } from "@/enums/whatsapp.enum";
import { getEnumOptions } from "@/helper/enum.helper";
import { mapGetters } from "vuex";
import ToastService from "@/services/toast.service";

export default defineComponent({
  name: "ConfigWhatsApp",
  data() {
    return {
      newAccount: {
        description: "",
        mandants: [] as string[],
        phoneNumber: "",
        accountId: "",
        apiToken: "",
        phoneId: "",
        bot: WhatsAppBot.noBot,
      },
      whatsAppConfigApi: new WhatsAppConfig(),
      whatsAppConfig: {} as WhatsApp,
    };
  },
  computed: {
    ...mapGetters({
      mandants: "reducedMandants",
    }),
    botOptions() {
      return getEnumOptions(WhatsAppBot);
    },
    whatsAppModeOptions() {
      return getEnumOptions(WhatsAppMode);
    },
  },
  mounted() {
    this.loadWhatsAppConfig();
  },
  methods: {
    addNewAccount() {
      if (this.newAccount.description && this.newAccount.phoneNumber) {
        this.whatsAppConfig.accounts.push({ ...this.newAccount });
        this.resetNewStatusOption();
      }
    },
    async handleSubmit() {
      this.saveWhatsAppConfig();
    },
    async loadWhatsAppConfig() {
      this.whatsAppConfig = await this.whatsAppConfigApi.get();
    },
    removeAccount(index: number) {
      this.whatsAppConfig.accounts.splice(index, 1);
    },
    resetNewStatusOption() {
      this.newAccount = {
        description: "",
        mandants: [] as string[],
        phoneNumber: "",
        accountId: "",
        apiToken: "",
        phoneId: "",
        bot: WhatsAppBot.noBot,
      };
    },
    async saveWhatsAppConfig() {
      try {
        const response = await this.whatsAppConfigApi.update(
          this.whatsAppConfig
        );
        if (response.error)
          ToastService.showError("Keine neue Daten gespeichert");
        if (response.response === "WhatsApp config successfully updated")
          ToastService.showSuccess("gespeichert ...");
      } catch (error) {
        console.error("Failed to save configuration:", error);
        throw error;
      }
    },
  },
});
</script>

<style scoped>
/* Add any necessary styling here */
</style>
