<!--src/components/top-header/Header.vue-->
<template>
  <header>
    <MainHeaderMenu></MainHeaderMenu>
    <div v-if="showRecruitTabs" class="tab-container">
      <div
        class="tab"
        @click="selectTab(HeaderTab.JobMatrix)"
        data-tid="recruit-link-jobmatrix"
      >
        <i class="fa-solid fa-table-cells tab-icon"></i>
        <p>Jobs Matrix</p>
      </div>
      <div
        class="tab"
        @click="selectTab(HeaderTab.JobList)"
        data-tid="recruit-link-joblist"
      >
        <i class="fa-solid fa-table-list tab-icon"></i>
        <p>Jobs Liste</p>
      </div>
      <!-- add when funnels are fixed
      <div
        class="tab"
        @click="selectTab(HeaderTab.Funnels)"
        data-tid="recruit-link-funnel"
      >
        <i class="fa-brands fa-facebook tab-icon"></i>
        <p>unnels</p>
      </div>
      -->
    </div>
    <div v-if="showDispositionTabs" class="tab-container">
      <div class="tab" @click="openZvooveRecruitLink()">
        <i class="fa-solid fa-envelope-open-text tab-icon"></i>
        <p>Bewerbereingang</p>
      </div>
      <div class="tab" @click="selectTab(HeaderTab.DispatcherBoard)">
        <i class="fa-solid fa-people-group tab-icon"></i>
        <p>Dispoboard</p>
      </div>
      <div class="tab" @click="selectTab(HeaderTab.Calendar)">
        <i class="fa-solid fa-calendar-days tab-icon"></i>
        <p>Kalender</p>
      </div>
    </div>
    <div v-if="showAdministrationTabs" class="tab-container">
      <div class="tab" @click="selectTab(HeaderTab.Documentation)">
        <i class="fa-solid fa-file-lines tab-icon"></i>
        <p>Dokumentation</p>
      </div>
      <div
        v-if="softwareIntegration.payFlow"
        class="tab"
        @click="selectTab(HeaderTab.PayFlowOverview)"
      >
        <i class="fa-solid fa-coins tab-icon"></i>
        <p>PayFlow Übersicht</p>
      </div>
    </div>
    <v-spacer>
      <div class="mt-3 ml-3 icon-container">
        <ReportBug></ReportBug>
        <UserProfile></UserProfile>
        <v-badge
          color="red-darken-4"
          :content="globalReminders.length"
          overlap
          offset-x="0"
        >
          <v-icon
            large
            size="small"
            color="var(--color-font-light)"
            @mouseover="showTooltip"
            @mouseleave="hideTooltip"
          >
            fa-solid fa-bell
          </v-icon>
        </v-badge>
      </div>
    </v-spacer>
    <SpinnerElementHeader></SpinnerElementHeader>
    <div class="pt-5 search-area">
      <v-text-field
        v-if="showDispositionTabs"
        v-model="searchTerm"
        class="ma-0 pa-0"
        density="compact"
        variant="solo"
        label="Suchbegriff"
        @keydown.enter="askColumnsForMatches()"
      >
        <v-tooltip activator="parent" location="bottom"
          >Einen oder mehrere Suchbegriffe eingeben und mit Enter
          bestätigen</v-tooltip
        >
      </v-text-field>
      <v-select
        v-model="selectedMandants"
        density="compact"
        variant="solo"
        :items="reducedMandants"
        item-title="branchInitials"
        item-value="uuid"
        label="Eingeloggte Niederlassungen"
        multiple
      >
      </v-select>
    </div>
  </header>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { mapGetters, mapMutations } from "vuex";
import { MutationType } from "@/enums/vuex-mutationtype.enum";
import { TooltipService } from "@/services/tooltip.service";
import MainHeaderMenu from "@/components/menus/main-header-menu.vue";
import ReportBug from "./ReportBug.vue";
import SpinnerElementHeader from "@/components/top-header/SpinnerElementHeader.vue";
import UserProfile from "@/components/config/user/UserProfile.vue";
import { UserHelperService } from "@/services/user-helper.service";
import { UserService } from "@/services/api/user.service";
import { InterComponentMessage } from "@/enums/inter-component-messagin.enum";
import { HeaderTab } from "@/enums/header-tabs.enum";

export default defineComponent({
  name: "MainHeader",
  components: {
    MainHeaderMenu,
    ReportBug,
    SpinnerElementHeader,
    UserProfile,
  },
  data() {
    return {
      menuOpen: false,
      currentMandantUuid: this.$store.state.company.loggedInUser.config
        .loggedInMandants
        ? this.$store.state.company.loggedInUser.config.loggedInMandants
        : [],
      HeaderTab: HeaderTab,
      tooltipTimeout: null,
      selectedMandants: [] as string[],
      searchTerm: "",
      userService: new UserService(),
      userHelperService: UserHelperService.getInstance(),
    };
  },
  computed: {
    ...mapGetters([
      "globalReminders",
      "softwareIntegration",
      "user",
      "reducedMandants",
    ]),
  },
  watch: {
    selectedMandants(newValues) {
      if (this.$store.state.storeHasToInit) return;
      this.updateLoggedInMandants(newValues);
      this.userService.updateUserConfig(
        this.$store.state.company.loggedInUser.config
      );
    },
    searchTerm(newValue) {
      if (newValue === "") this.askColumnsForMatches();
    },
  },
  async mounted() {
    this.checkForLoggedInMandants();
  },
  methods: {
    ...mapMutations({
      updateLoggedInMandants: MutationType.updateLoggedinMandants,
      setICM: MutationType.setICM,
    }),
    askColumnsForMatches() {
      this.setICM({
        message: InterComponentMessage.matchMeFromGlobalSearch,
        payload: {
          searchTerm: this.searchTerm,
        },
      });
    },
    async checkForLoggedInMandants() {
      let loggedInUuids =
        this.$store.state.company.loggedInUser.config.loggedInMandants;
      const user = this.$store.getters.user;
      loggedInUuids = user?.config?.loggedInMandants as string[];

      if (loggedInUuids) {
        this.selectedMandants = loggedInUuids;
      }
    },
    openZvooveRecruitLink() {
      const baseLink = this.softwareIntegration.zvooveRecruitLink;
      const fullLink = `${baseLink}/inbox/bw`;
      window.open(fullLink, "_blank", "width=800,height=800"); //Opens a small window that the user do not waste his time in another software
    },
    toggleMenu() {
      this.menuOpen = !this.menuOpen;
    },
    selectTab(tabName: any) {
      this.$emit("tab-selected", tabName);
    },
    showTooltip(event: MouseEvent) {
      let htmlContent = this.globalReminders
        .flatMap(
          (reminderObj: { sourceId: string; reminders: string[] }) =>
            reminderObj.reminders
        )
        .join("<hr>");
      if (htmlContent) {
        TooltipService.showExpiredEvents(event, htmlContent);
      }
    },
    hideTooltip() {
      const existingTooltip = document.getElementById("custom-tooltip");
      if (existingTooltip) {
        existingTooltip.remove();
      }
    },
  },
  props: {
    showRecruitTabs: Boolean,
    showDispositionTabs: Boolean,
    showAdministrationTabs: Boolean,
  },
});
</script>

<style scoped lang="scss">
@import "./scss/variables.scss";

header {
  align-items: end;
  background-color: var(--color-primary);
  border-bottom-left-radius: var(--border-radius-dialog);
  border-bottom-right-radius: var(--border-radius-dialog);
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.2);
  display: flex;
  height: var(--height-header);
  padding: 1rem 1rem 0rem 0rem;
}

.icon-container {
  color: var(--color-font-light);
  display: flex;
  justify-content: flex-start;
  position: absolute;
  right: 1.3rem;
  top: 2rem;
  width: fit-content;
}

.tab-container {
  display: flex;
  margin-bottom: 0;
  margin-left: 2rem;
}

.tab {
  background-color: transparent;
  display: flex;
  color: var(--color-font-light);
  margin: 0 2px;
  padding: 0.7rem 1rem;
  border-top: 2px solid var(--color-font-light);
  border-left: 2px solid var(--color-font-light);
  border-right: 2px solid var(--color-font-light);
  border-top-left-radius: var(--border-radius-dialog);
  border-top-right-radius: var(--border-radius-dialog);
  cursor: pointer;
  transition: background-color 0.5s, color 0.5s, transform 0.7s;
}

.tab-icon {
  margin-right: 0.2rem;
}

.tab:hover {
  background-color: var(--color-hover);
  border: none;
  color: black;
  transform: scale(1.05);
  box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
}

.search-area {
  display: flex;
  width: 20rem;
  flex-direction: column;
  justify-content: space-between;
  margin-left: 1rem;
}

input {
  background-color: #fff;
  border: 0;
  border-radius: var(--border-radius-dialog);
  height: 30px;
  padding: 1rem;
  width: 100%;
}

.select-mandants {
  background-color: var(--color-font-light);
  width: 100%;
  border-radius: var(--border-radius-dialog);
  margin-top: 1rem;
  margin-bottom: 0.5rem;
}
@media (max-width: $breakpointL) {
  header {
    flex-direction: row;
    align-items: center;
    padding: 0.5rem 0.1rem;
  }

  .search-area {
    flex-direction: column;
    justify-content: center;
    width: 100%;
    margin-left: 0.3rem;
  }

  .icon-container {
    position: absolute;
    top: -0.15rem;
    left: 0;
  }

  .tab {
    background-color: transparent;
    color: var(--color-font-light);
    height: 2.5rem;
    margin: 0 2px;
    padding: 0.2rem;
    border: 2px solid var(--color-font-light);
    border-radius: 0.5rem;
    cursor: pointer;
    transition: background-color 0.5s, color 0.5s, transform 0.7s;
  }

  .tab-container {
    flex-direction: column;
    align-items: center;
    width: 70%;
    margin-left: 1rem;
    margin-right: 1rem;
    padding: 0.5rem 0;
  }

  .tab {
    width: 6rem;
    height: 2.5rem;
    font-size: 1.5rem;
    text-align: center;
    justify-content: center;
    margin: 0.1rem 0;
  }
  .tab p {
    display: none;
  }
  .select-mandants {
    width: 100%;
    margin-top: 1rem;
  }

  input {
    width: 100%; /* Vollere Breitenausnutzung für das Suchfeld */
    margin: 0;
  }
}
</style>
../menus/MainHeaderMenu.vue
