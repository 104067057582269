import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, withKeys as _withKeys, normalizeClass as _normalizeClass, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = (n: any) => (_pushScopeId("data-v-e0420386"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "d-flex justify-space-between" }
const _hoisted_2 = { key: 1 }
const _hoisted_3 = { class: "d-flex justify-space-between" }
const _hoisted_4 = { class: "d-flex" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_icon = _resolveComponent("v-icon")!
  const _component_v_btn = _resolveComponent("v-btn")!
  const _component_v_tooltip = _resolveComponent("v-tooltip")!
  const _component_v_text_field = _resolveComponent("v-text-field")!
  const _component_v_list_item_title = _resolveComponent("v-list-item-title")!
  const _component_v_list_item_subtitle = _resolveComponent("v-list-item-subtitle")!
  const _component_v_checkbox = _resolveComponent("v-checkbox")!
  const _component_v_list_item = _resolveComponent("v-list-item")!
  const _component_v_list = _resolveComponent("v-list")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (_ctx.showOpenTasksReminderIcon)
      ? (_openBlock(), _createBlock(_component_v_btn, {
          key: 0,
          icon: "",
          color: "white",
          density: "compact",
          variant: "tonal",
          class: "open-tasks-icon-btn",
          onMouseenter: _ctx.showTooltip,
          onMouseleave: _ctx.hideTooltip
        }, {
          default: _withCtx(() => [
            _createVNode(_component_v_icon, {
              class: "open-tasks-icon",
              size: "xs"
            }, {
              default: _withCtx(() => [
                _createTextVNode("fa-solid fa-list-ol")
              ]),
              _: 1
            })
          ]),
          _: 1
        }, 8, ["onMouseenter", "onMouseleave"]))
      : _createCommentVNode("", true),
    _createElementVNode("div", _hoisted_1, [
      (_ctx.hasCompletedTasks)
        ? (_openBlock(), _createBlock(_component_v_btn, {
            key: 0,
            icon: "",
            density: "compact",
            variant: "text",
            class: "my-2 text-medium-emphasis",
            onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.toggleShowCompletedTasks()))
          }, {
            default: _withCtx(() => [
              _createVNode(_component_v_icon, null, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.showCompletedTasks ? "fa-solid fa-list-check" : "fa-solid fa-list"), 1)
                ]),
                _: 1
              }),
              _createVNode(_component_v_tooltip, {
                activator: "parent",
                location: "bottom"
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.showCompletedTasks
          ? "umstellen auf: nur offene Aufgaben anzeigen"
          : "umstellen auf: alle Aufgaben anzeigen"), 1)
                ]),
                _: 1
              })
            ]),
            _: 1
          }))
        : _createCommentVNode("", true),
      (!_ctx.hasCompletedTasks)
        ? (_openBlock(), _createElementBlock("div", _hoisted_2))
        : _createCommentVNode("", true),
      (!_ctx.showNewTaskInput)
        ? (_openBlock(), _createBlock(_component_v_btn, {
            key: 2,
            icon: "",
            density: "compact",
            variant: "text",
            class: "my-2 mr-3 text-medium-emphasis",
            onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.showNewTaskInput = true))
          }, {
            default: _withCtx(() => [
              _createVNode(_component_v_icon, null, {
                default: _withCtx(() => [
                  _createTextVNode(" fas fa-circle-plus")
                ]),
                _: 1
              }),
              _createVNode(_component_v_tooltip, {
                activator: "parent",
                location: "bottom"
              }, {
                default: _withCtx(() => [
                  _createTextVNode("neue Aufgabe(n) zur Checkliste hinzufügen!")
                ]),
                _: 1
              })
            ]),
            _: 1
          }))
        : _createCommentVNode("", true)
    ]),
    _createVNode(_component_v_list, { density: "compact" }, {
      default: _withCtx(() => [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.filteredChecklist, (task, index) => {
          return (_openBlock(), _createBlock(_component_v_list_item, { key: index }, {
            default: _withCtx(() => [
              _createElementVNode("div", _hoisted_3, [
                _createElementVNode("div", null, [
                  _createElementVNode("div", _hoisted_4, [
                    (_ctx.editingTaskIndex === index)
                      ? (_openBlock(), _createBlock(_component_v_text_field, {
                          key: 0,
                          modelValue: _ctx.editableTaskEntry,
                          "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.editableTaskEntry) = $event)),
                          density: "compact",
                          variant: "plain",
                          onKeydown: _withKeys(($event: any) => (_ctx.saveTaskEdit(index)), ["enter"])
                        }, null, 8, ["modelValue", "onKeydown"]))
                      : (_openBlock(), _createBlock(_component_v_list_item_title, {
                          key: 1,
                          class: _normalizeClass({ completed: task.completed })
                        }, {
                          default: _withCtx(() => [
                            _createTextVNode(_toDisplayString(task.entry), 1)
                          ]),
                          _: 2
                        }, 1032, ["class"])),
                    (_ctx.editingTaskIndex === index)
                      ? (_openBlock(), _createBlock(_component_v_btn, {
                          key: 2,
                          icon: "",
                          class: "mx-2 text-medium-emphasis",
                          size: "xsmall",
                          density: "compact",
                          variant: "text",
                          onClick: ($event: any) => (_ctx.saveTaskEdit(index))
                        }, {
                          default: _withCtx(() => [
                            _createVNode(_component_v_icon, { size: "xsmall" }, {
                              default: _withCtx(() => [
                                _createTextVNode("fas fa-check")
                              ]),
                              _: 1
                            })
                          ]),
                          _: 2
                        }, 1032, ["onClick"]))
                      : _createCommentVNode("", true),
                    (
                !_ctx.editingTaskIndex &&
                _ctx.editingTaskIndex !== index &&
                _ctx.canEditTask(task)
              )
                      ? (_openBlock(), _createBlock(_component_v_btn, {
                          key: 3,
                          icon: "",
                          class: "mx-2 text-medium-emphasis",
                          size: "xsmall",
                          density: "compact",
                          variant: "text",
                          onClick: ($event: any) => (_ctx.editTask(index))
                        }, {
                          default: _withCtx(() => [
                            _createVNode(_component_v_icon, { size: "xsmall" }, {
                              default: _withCtx(() => [
                                _createTextVNode("fas fa-pencil")
                              ]),
                              _: 1
                            })
                          ]),
                          _: 2
                        }, 1032, ["onClick"]))
                      : _createCommentVNode("", true),
                    (_ctx.editingTaskIndex === index && _ctx.canDeleteTask(task))
                      ? (_openBlock(), _createBlock(_component_v_btn, {
                          key: 4,
                          icon: "",
                          class: "mr-5 text-medium-emphasis",
                          size: "xsmall",
                          density: "compact",
                          variant: "text",
                          onClick: ($event: any) => (_ctx.deleteTask(index))
                        }, {
                          default: _withCtx(() => [
                            _createVNode(_component_v_icon, { size: "xsmall" }, {
                              default: _withCtx(() => [
                                _createTextVNode("fas fa-trash-can")
                              ]),
                              _: 1
                            })
                          ]),
                          _: 2
                        }, 1032, ["onClick"]))
                      : _createCommentVNode("", true)
                  ]),
                  _createVNode(_component_v_list_item_subtitle, null, {
                    default: _withCtx(() => [
                      _createTextVNode(" erstellt: " + _toDisplayString(task.createdBy) + " " + _toDisplayString(_ctx.formatDate(task.created)) + " " + _toDisplayString(task.completedBy ? " | bearbeitet: " : "") + " " + _toDisplayString(task.completedBy), 1)
                    ]),
                    _: 2
                  }, 1024)
                ]),
                _createVNode(_component_v_checkbox, {
                  density: "compact",
                  class: "mt-0 pa-0",
                  modelValue: task.completed,
                  "onUpdate:modelValue": [($event: any) => ((task.completed) = $event), ($event: any) => (_ctx.setCompletedBy(task, index))]
                }, null, 8, ["modelValue", "onUpdate:modelValue"])
              ])
            ]),
            _: 2
          }, 1024))
        }), 128))
      ]),
      _: 1
    }),
    (_ctx.showNewTaskInput)
      ? (_openBlock(), _createBlock(_component_v_text_field, {
          key: 1,
          modelValue: _ctx.newTask,
          "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.newTask) = $event)),
          class: "mx-3",
          density: "compact",
          variant: "outlined",
          label: "Neue Aufgabe zur Checkliste",
          onKeydown: _cache[4] || (_cache[4] = _withKeys(($event: any) => (_ctx.addTask(_ctx.newTask)), ["enter"]))
        }, null, 8, ["modelValue"]))
      : _createCommentVNode("", true)
  ], 64))
}