<!--src/components/config/software-integration/ConfigZvooveOne.vue-->
<template>
  <v-container>
    <h2 class="mb-5">
      <v-icon size="small" class="mr-2">fa-solid fa-link</v-icon>Konfiguration
      für ERP System zvoove One
    </h2>
    <v-form @submit.prevent="handleSubmit">
      <v-row>
        <v-col cols="12" md="9"></v-col>
        <v-col cols="12" md="3">
          <v-switch
            color="var(--color-primary)"
            v-model="localConfig.softwareIntegration.erpAutoDocu"
            label="automatische Dokumentation"
          ></v-switch>
        </v-col>
        <v-col cols="12" md="6">
          <v-text-field
            v-model="localConfig.softwareIntegration.zvooveOneLink"
            label="zvoove One Link"
            variant="outlined"
          ></v-text-field>
        </v-col>
        <v-col cols="12" md="6">
          <v-text-field
            v-model="localConfig.apiKeys.baseURLZvooveOne"
            label="Base URL API"
            variant="outlined"
          ></v-text-field>
        </v-col>
        <v-col cols="12" md="6">
          <v-text-field
            v-model="localConfig.apiKeys.xCoreClientIdZvooveOne"
            label="X-Core-Client-Id"
            variant="outlined"
          ></v-text-field>
        </v-col>
      </v-row>
      <v-btn class="mt-10" type="submit" color="green-darken-3"
        >Speichern</v-btn
      >
    </v-form>
  </v-container>
</template>

<script lang="ts">
import { defineComponent, PropType } from "vue";
import { CompanyConfig } from "@/models/company-config.model";

export default defineComponent({
  name: "ConfigZvooveOne",
  props: {
    modelValue: {
      type: Object as PropType<CompanyConfig>,
      required: true,
    },
  },
  data() {
    return {
      localConfig: { ...this.modelValue },
    };
  },
  watch: {
    modelValue(newValue: CompanyConfig) {
      this.localConfig = { ...newValue };
    },
  },
  methods: {
    handleSubmit() {
      this.$emit("update:modelValue", this.localConfig);
      this.$emit("save");
    },
  },
});
</script>
