// src/enums/demand-states.enum.ts
export enum DemandStates {
  Passive = 0,
  Active = 1,
  Urgent = 2,
  Priority = 3,
  ExclusiveConfidential = 4,
}

const demandStateLabels: { [key in DemandStates]: string } = {
  [DemandStates.Passive]: "Passive Anfrage",
  [DemandStates.Active]: "Aktive Anfrage",
  [DemandStates.Urgent]: "Dringende Anfrage",
  [DemandStates.Priority]: "Priorisierte Anfrage",
  [DemandStates.ExclusiveConfidential]: "Exklusiver vertraulicher Auftrag",
};

export const getDemandStates = (enumObj: {
  [key: number]: string | number;
}): { [key: number]: string } => {
  const entries = Object.entries(enumObj);
  const filteredEntries = entries.filter(([key]) => !isNaN(Number(key)));
  return filteredEntries.reduce<{ [key: number]: string }>(
    (obj, [key, value]) => {
      const enumKey = Number(key) as DemandStates;
      if (typeof value === "string") {
        obj[enumKey] = demandStateLabels[enumKey] || value;
      }
      return obj;
    },
    {}
  );
};
