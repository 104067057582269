import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_text_field = _resolveComponent("v-text-field")!
  const _component_v_col = _resolveComponent("v-col")!
  const _component_v_row = _resolveComponent("v-row")!
  const _component_v_form = _resolveComponent("v-form")!

  return (_openBlock(), _createBlock(_component_v_form, null, {
    default: _withCtx(() => [
      _createVNode(_component_v_row, null, {
        default: _withCtx(() => [
          _createVNode(_component_v_col, null, {
            default: _withCtx(() => [
              _createVNode(_component_v_text_field, {
                label: "server",
                modelValue: _ctx.emailConfigCopy.server,
                "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.emailConfigCopy.server) = $event)),
                variant: "outlined"
              }, null, 8, ["modelValue"]),
              _createVNode(_component_v_text_field, {
                label: "port",
                modelValue: _ctx.emailConfigCopy.port,
                "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.emailConfigCopy.port) = $event)),
                type: "number",
                variant: "outlined"
              }, null, 8, ["modelValue"]),
              _createVNode(_component_v_text_field, {
                label: "username",
                modelValue: _ctx.emailConfigCopy.username,
                "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.emailConfigCopy.username) = $event)),
                variant: "outlined"
              }, null, 8, ["modelValue"]),
              _createVNode(_component_v_text_field, {
                label: "from",
                modelValue: _ctx.emailConfigCopy.from,
                "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.emailConfigCopy.from) = $event)),
                variant: "outlined"
              }, null, 8, ["modelValue"]),
              _createVNode(_component_v_text_field, {
                label: "password",
                modelValue: _ctx.emailConfigCopy.password,
                "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.emailConfigCopy.password) = $event)),
                variant: "outlined"
              }, null, 8, ["modelValue"])
            ]),
            _: 1
          })
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}