// src/enums/employee-states.enum.ts
export enum EmployeeStates {
  Applicant = 1,
  Employee = 2,
  DesignatedEmployee = 3,
  RetiredEmployee = 4,
  RejectedApplicant = 6,
}

const employeeStateLabels: { [key in EmployeeStates]: string } = {
  [EmployeeStates.Applicant]: "Bewerber",
  [EmployeeStates.Employee]: "Mitarbeiter",
  [EmployeeStates.DesignatedEmployee]: "zukünftiger Mitarbeiter",
  [EmployeeStates.RetiredEmployee]: "Ehemaliger Mitarbeiter",
  [EmployeeStates.RejectedApplicant]: "erledigter Bewerber",
};

export const getEmployeeStates = (enumObj: {
  [key: number]: string | number;
}): { [key: number]: string } => {
  const entries = Object.entries(enumObj);
  const filteredEntries = entries.filter(([key]) => !isNaN(Number(key)));
  return filteredEntries.reduce<{ [key: number]: string }>(
    (obj, [key, value]) => {
      const enumKey = Number(key) as EmployeeStates;
      if (typeof value === "string") {
        obj[enumKey] = employeeStateLabels[enumKey] || value;
      }
      return obj;
    },
    {}
  );
};
