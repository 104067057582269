<!-- src/components/whatsapp/WhatsAppMessenger.vue -->
<template>
  <div class="d-flex">
    <v-btn
      v-if="whatsAppConfig.whatsAppMode === WhatsAppMode.experimental"
      icon
      variant="text"
      class="mr-2"
      @click="checkStatus()"
      ><v-icon size="x-large">fa-solid fa-arrows-rotate</v-icon
      ><v-tooltip activator="parent" location="end"
        >Verbindung zu WhatsApp aktualisieren</v-tooltip
      ></v-btn
    >
    <v-select
      v-model="sessionName"
      :items="whatsAppConfig.accounts"
      item-title="description"
      item-value="_id"
      label="WhatsApp Account"
      variant="outlined"
      density="compact"
      class="mb-2"
      @update:model-value="checkStatus()"
    ></v-select>

    <div
      v-if="whatsAppConfig.whatsAppMode === WhatsAppMode.experimental"
      class="mx-3"
    >
      <v-badge
        :color="isAuthenticated ? 'green-darken-3' : 'red-darken-4'"
        :content="isAuthenticated ? '✔' : '✘'"
        overlap
        offset-x="0"
      >
        <v-icon size="x-large"> fab fa-whatsapp </v-icon>
      </v-badge>
      <v-tooltip activator="parent" location="end">
        {{
          isAuthenticated
            ? "Verbindung zu WhatsApp hergestellt"
            : "Keine Verbindung zu WhatsApp Account"
        }}
      </v-tooltip>
    </div>
  </div>
  <ChatMessages :messages="chatMessages" />

  <QrCode
    @checkStatus="checkStatus()"
    ref="qrCodeComponent"
    :sessionName="sessionName"
  />
  <v-form v-if="isAuthenticated">
    <v-text-field
      v-if="!whatsAppNumber"
      v-model="localWhatsAppNumber"
      label="WhatsApp Nummer"
      variant="outlined"
      density="compact"
    ></v-text-field>
    <v-textarea
      v-if="!message"
      v-model="localMessage"
      label="Nachricht"
      variant="outlined"
      density="compact"
    ></v-textarea>
    <div v-if="!message" class="d-flex justify-end">
      <v-btn icon @click="sendMessage" color="var(--color-primary)"
        ><v-icon>fa-regular fa-paper-plane</v-icon></v-btn
      >
    </div>
  </v-form>
</template>

<script lang="ts">
import { defineComponent, PropType } from "vue";
import QrCode from "../whatsapp/QrCode.vue";
import ChatMessages from "../whatsapp/ChatMessages.vue";
import { WhatsAppConfig } from "@/services/api/whatsApp.service";
import { WhatsApp } from "@/models/whatsapp.model";
import { mapGetters } from "vuex";
import { WhatsAppMode } from "@/enums/whatsapp.enum";
import ToastService from "@/services/toast.service";
import debounce from "lodash/debounce";
import { WhatsAppExperimentalService } from "@/services/api/whatsapp-experimental.service";

export default defineComponent({
  name: "SendMessages",
  emits: ["checkTimeline"],
  components: {
    QrCode,
    ChatMessages,
  },
  props: {
    whatsAppNumber: {
      type: String as PropType<string>,
      default: "",
    },
    message: {
      type: String as PropType<string>,
    },
  },
  data() {
    return {
      localWhatsAppNumber: this.whatsAppNumber,
      localMessage: "",
      chatMessages: [] as any[],
      isAuthenticated: false,
      sessionName: "",
      WhatsAppMode: WhatsAppMode,
      whatsAppConfigApi: new WhatsAppConfig(),
      whatsAppConfig: {} as WhatsApp,
      whatsAppExperimentalService: new WhatsAppExperimentalService(),
    };
  },
  computed: {
    ...mapGetters({
      softwareIntegration: "softwareIntegration",
    }),
  },
  watch: {
    localWhatsAppNumber: {
      handler: debounce(function (this: any) {
        this.checkChat();
      }, 1500),
      immediate: false,
    },
    whatsAppNumber(newVal: string) {
      this.localWhatsAppNumber = newVal;
    },
    message(newVal: string) {
      this.localMessage = newVal;
    },
  },

  mounted() {
    this.loadWhatsAppConfig().then(() => {
      if (
        this.whatsAppConfig.accounts[0]._id &&
        this.whatsAppConfig.whatsAppMode === WhatsAppMode.experimental
      ) {
        this.sessionName = this.whatsAppConfig.accounts[0]._id;
        this.checkStatus().then(() => {
          if (this.message) this.localMessage = this.message;
          if (this.localWhatsAppNumber) this.checkChat();
        });
      }
    });
  },
  methods: {
    async sendMessage() {
      if (this.whatsAppConfig.whatsAppMode === WhatsAppMode.official) {
        ToastService.showReminder(
          "WhatsApp Mode official kann noch keine Nachricht senden"
        );
        //TODO: implement for whatsapp business sdk
      } else if (
        this.whatsAppConfig.whatsAppMode === WhatsAppMode.experimental
      ) {
        try {
          const jid = this.formatPhoneNumberToJid(this.whatsAppNumber);
          await this.whatsAppExperimentalService.sendMessage(
            this.sessionName,
            jid,
            this.localMessage
          );
        } catch (error) {
          console.error(error);
        }
      }
    },
    async checkChat() {
      if (this.whatsAppConfig.whatsAppMode === WhatsAppMode.official) {
        ToastService.showReminder(
          "WhatsApp Mode official kann noch keinen Chatverlauf laden"
        );
        //TODO: implement for whatsapp business sdk
      } else if (
        this.whatsAppConfig.whatsAppMode === WhatsAppMode.experimental
      ) {
        try {
          const jid = this.formatPhoneNumberToJid(this.whatsAppNumber);
          const response = await this.whatsAppExperimentalService.checkChat(
            this.sessionName,
            jid
          );

          this.chatMessages = response;
          this.$emit("checkTimeline", response);
        } catch (error) {
          console.error(error);
        }
      }
    },
    formatPhoneNumberToJid(phoneNumber: string) {
      return phoneNumber.replace(/\D/g, "") + "@c.us";
    },
    async checkStatus() {
      this.isAuthenticated = false;
      try {
        const response = await this.whatsAppExperimentalService.checkStatus(
          this.sessionName
        );
        this.isAuthenticated = response.isConnected;
        if (!response.isConnected && response.qrCode) {
          const qrCodeComponent = this.$refs.qrCodeComponent as any;
          qrCodeComponent.showQrCode(response.qrCode);
        } else {
          const qrCodeComponent = this.$refs.qrCodeComponent as any;
          qrCodeComponent.closeDialog();
        }
      } catch (error) {
        console.error(error);
      }
    },
    async loadWhatsAppConfig() {
      this.whatsAppConfig = await this.whatsAppConfigApi.get();
    },
  },
});
</script>
