// src/services/api/payflow.service.ts
import { PayFlow } from "@/models/payflow.model";
import { Api } from "./api.class";

export class PayFlowService {
  private api = Api.getInstance();
  private urlPath = "/payflow";

  async getAllByPayrollMonth(payrollMonth: string, mandants?: string[]) {
    try {
      const params: any = { payrollMonth };
      if (mandants && mandants.length > 0) {
        params.mandants = mandants;
      }

      const response = await this.api
        .getBackendApi()
        .get(`${this.urlPath}/all-by-payrollmonth`, {
          params,
        });
      return response.data.response;
    } catch (error) {
      console.error("Error fetching PayFlows by payrollMonth:", error);
      return { data: null, error };
    }
  }

  async getPayFlows(employeeNumber: string, payrollMonth?: string) {
    try {
      const params = { employeeNumber, payrollMonth };
      const response = await this.api
        .getBackendApi()
        .get(this.urlPath, { params });
      return response.data.response;
    } catch (error) {
      console.error("Error fetching PayFlow:", error);
      return { data: null, error };
    }
  }

  async addPayFlow(payFlow: PayFlow) {
    try {
      const response = await this.api
        .getBackendApi()
        .post(this.urlPath, payFlow);
      return response.data.response;
    } catch (error) {
      console.error("Error adding PayFlow:", error);
      return { data: null, error };
    }
  }

  async updatePayFlow(payFlow: PayFlow) {
    try {
      const response = await this.api
        .getBackendApi()
        .put(`${this.urlPath}/${payFlow._id}`, payFlow);
      return response.data.response;
    } catch (error) {
      console.error("Error updating PayFlow:", error);
      return { data: null, error };
    }
  }

  async removePayFlow(id: string) {
    try {
      const response = await this.api
        .getBackendApi()
        .delete(`${this.urlPath}/${id}`);
      return response.data.response;
    } catch (error) {
      console.error("Error removing PayFlow:", error);
      return { data: null, error };
    }
  }
}
