import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_MainHeader = _resolveComponent("MainHeader")!
  const _component_JobMatrix = _resolveComponent("JobMatrix")!
  const _component_JobList = _resolveComponent("JobList")!
  const _component_JobFunnels = _resolveComponent("JobFunnels")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(_component_MainHeader, {
      onTabSelected: _ctx.handleTabSelection,
      showRecruitTabs: true
    }, null, 8, ["onTabSelected"]),
    (_ctx.currentTab === _ctx.HeaderTab.JobMatrix)
      ? (_openBlock(), _createBlock(_component_JobMatrix, { key: 0 }))
      : _createCommentVNode("", true),
    (_ctx.currentTab === _ctx.HeaderTab.JobList)
      ? (_openBlock(), _createBlock(_component_JobList, { key: 1 }))
      : _createCommentVNode("", true),
    (_ctx.currentTab === _ctx.HeaderTab.Funnels)
      ? (_openBlock(), _createBlock(_component_JobFunnels, { key: 2 }))
      : _createCommentVNode("", true)
  ]))
}