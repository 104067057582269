<!--src/components/config/software-integration/ConfigTimelineEntries.vue-->
<template>
  <v-container>
    <h2 class="mb-5">
      <v-icon size="small" class="mr-2">fa-solid fa-timeline</v-icon
      >Kontaktarten
    </h2>
    <v-form @submit.prevent="handleSubmit">
      <v-row>
        <v-col
          cols="12"
          md="4"
          v-for="(entry, key) in filteredTimelineEntryTypes"
          :key="key"
        >
          <v-card variant="flat">
            <v-row class="mt-1">
              <v-col cols="9">
                <v-text-field
                  v-model="(localConfig.timelineEntryTypes as any)[key].description"
                  :label="getReadableName(key)"
                  density="compact"
                  variant="outlined"
                ></v-text-field>
              </v-col>
              <v-col cols="3">
                <v-text-field
                  v-model="(localConfig.timelineEntryTypes as any)[key].artZvoove"
                  label="Art"
                  density="compact"
                  variant="outlined"
                ></v-text-field>
              </v-col>
            </v-row>
          </v-card>
        </v-col>
      </v-row>
      <v-btn class="mt-10" type="submit" color="green-darken-3"
        >Speichern</v-btn
      >
    </v-form>
  </v-container>
</template>

<script lang="ts">
import { defineComponent, PropType } from "vue";
import { CompanyConfig } from "@/models/company-config.model";

export default defineComponent({
  name: "ConfigTimelineEntries",
  props: {
    modelValue: {
      type: Object as PropType<CompanyConfig>,
      required: true,
    },
  },
  data() {
    return {
      localConfig: { ...this.modelValue },
    };
  },
  computed: {
    filteredTimelineEntryTypes() {
      const timelineEntryTypes = this.localConfig.timelineEntryTypes as Record<
        string,
        { description: string; artZvoove: string }
      >;
      return Object.keys(timelineEntryTypes)
        .filter((key) => key !== "_id")
        .reduce(
          (
            acc: Record<string, { description: string; artZvoove: string }>,
            key
          ) => {
            acc[key] = timelineEntryTypes[key];
            return acc;
          },
          {}
        );
    },
  },
  watch: {
    modelValue(newValue: CompanyConfig) {
      this.localConfig = { ...newValue };
    },
  },
  methods: {
    getReadableName(key: string) {
      const keyNames: { [key: string]: string } = {
        phoneCallCandidate: "Telefonat Kandidat",
        phoneCallCustomer: "Telefonat Firma",
        phoneCallEmployee: "Telefonat Mitarbeiter",
        emailCandidate: "E-Mail Kandidat",
        eMailCustomer: "E-Mail Firma",
        eMailEmployee: "E-Mail Mitarbeiter",
        whatsAppCandidate: "WhatsApp Kandidat",
        whatsAppEmployee: "WhatsApp Mitarbeiter",
        note: "Notiz",
        addition: "Nachtrag",
        meetingOutside: "Außendienstbesuch",
        meetingInhouse: "Gespräch i. d. Niederlassung",
        candidateSuggestion: "Profil verschickt",
      };
      return keyNames[key] || key;
    },
    handleSubmit() {
      this.$emit("update:modelValue", this.localConfig);
      this.$emit("save");
    },
  },
});
</script>
