import { vShow as _vShow, createElementVNode as _createElementVNode, withDirectives as _withDirectives, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock, withCtx as _withCtx, createVNode as _createVNode, withModifiers as _withModifiers, createTextVNode as _createTextVNode, toDisplayString as _toDisplayString, normalizeStyle as _normalizeStyle, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = (n: any) => (_pushScopeId("data-v-a32854d4"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "column" }
const _hoisted_2 = { class: "spacer-dispo-column" }
const _hoisted_3 = { class: "header-dispo-column" }
const _hoisted_4 = { class: "status-options" }
const _hoisted_5 = { class: "mandants-options" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_checkbox = _resolveComponent("v-checkbox")!
  const _component_FilterMenu = _resolveComponent("FilterMenu")!
  const _component_DialogAddEditDemand = _resolveComponent("DialogAddEditDemand")!
  const _component_DemandItem = _resolveComponent("DemandItem")!
  const _component_draggable = _resolveComponent("draggable")!
  const _component_v_icon = _resolveComponent("v-icon")!
  const _component_v_list_item = _resolveComponent("v-list-item")!
  const _component_v_list = _resolveComponent("v-list")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _withDirectives(_createElementVNode("div", _hoisted_2, null, 512), [
        [_vShow, !_ctx.isActive]
      ]),
      _withDirectives(_createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", null, [
          _createVNode(_component_FilterMenu, null, {
            "filter-menu-left-column": _withCtx(() => [
              _createElementVNode("div", _hoisted_4, [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.demandStates, (label, key) => {
                  return (_openBlock(), _createBlock(_component_v_checkbox, {
                    density: "compact",
                    key: key,
                    modelValue: _ctx.selectedStatuses,
                    "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.selectedStatuses) = $event)),
                    label: label,
                    value: String(key),
                    class: "ma-0 pa-0"
                  }, null, 8, ["modelValue", "label", "value"]))
                }), 128))
              ])
            ]),
            "filter-menu-right-column": _withCtx(() => [
              _createElementVNode("div", _hoisted_5, [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.mandants, (mandant) => {
                  return (_openBlock(), _createBlock(_component_v_checkbox, {
                    density: "compact",
                    key: mandant.uuid,
                    modelValue: _ctx.selectedMandants,
                    "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.selectedMandants) = $event)),
                    label: mandant.name,
                    value: mandant.uuid,
                    class: "ma-0 pa-0"
                  }, null, 8, ["modelValue", "label", "value"]))
                }), 128))
              ])
            ]),
            _: 1
          })
        ]),
        _createElementVNode("div", null, [
          _createVNode(_component_DialogAddEditDemand, {
            modalMode: _ctx.modalAddOrEditMode,
            onLoadDemands: _cache[2] || (_cache[2] = ($event: any) => (_ctx.loadDemands())),
            onInsertUpdatedDemand: _ctx.insertUpdatedDemand,
            ref: "modalAddEditDemandComponent"
          }, null, 8, ["modalMode", "onInsertUpdatedDemand"])
        ])
      ], 512), [
        [_vShow, _ctx.isActive]
      ]),
      _createVNode(_component_draggable, {
        list: _ctx.demands,
        group: "demand",
        onStart: _ctx.dragStart,
        onEnd: _ctx.dragEnd,
        "item-key": "demandId"
      }, {
        item: _withCtx(({ element }) => [
          (_openBlock(), _createBlock(_component_DemandItem, {
            class: "longpress",
            demand: element,
            key: element.demandId,
            isFullyCollapsed: _ctx.minimizeAllItems,
            onContextmenu: _withModifiers(($event: any) => (_ctx.openContextMenu($event, element)), ["prevent"]),
            onLongpress: ($event: any) => (_ctx.openContextMenu($event, element)),
            onLoadDemands: _cache[3] || (_cache[3] = ($event: any) => (_ctx.loadDemands())),
            onDelete: _ctx.deleteDemand
          }, null, 8, ["demand", "isFullyCollapsed", "onContextmenu", "onLongpress", "onDelete"]))
        ]),
        _: 1
      }, 8, ["list", "onStart", "onEnd"])
    ]),
    (_ctx.showContextMenu)
      ? (_openBlock(), _createElementBlock("div", {
          key: 0,
          style: _normalizeStyle({
      top: _ctx.contextMenuPosition.y + 'px',
      left: _ctx.contextMenuPosition.x + 'px',
    }),
          class: "context-menu",
          ref: "ContextMenu",
          onClick: _cache[7] || (_cache[7] = _withModifiers(() => {}, ["stop"]))
        }, [
          _createVNode(_component_v_list, { dense: "" }, {
            default: _withCtx(() => [
              _createVNode(_component_v_list_item, {
                onClick: _cache[4] || (_cache[4] = ($event: any) => (_ctx.editDemand(_ctx.clickedDemand)))
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_v_icon, {
                    class: "text-medium-emphasis mr-1",
                    size: "xs"
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode(" fa-solid fa-pen-to-square ")
                    ]),
                    _: 1
                  }),
                  _createTextVNode(" bearbeiten ")
                ]),
                _: 1
              }),
              _createVNode(_component_v_list_item, {
                onClick: _cache[5] || (_cache[5] = ($event: any) => (_ctx.confirmDeleteDemand(_ctx.clickedDemand)))
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_v_icon, {
                    class: "text-medium-emphasis mr-1",
                    size: "xs"
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode(" fa-solid fa-trash-can ")
                    ]),
                    _: 1
                  }),
                  _createTextVNode(" löschen ")
                ]),
                _: 1
              }),
              _createVNode(_component_v_list_item, {
                onClick: _cache[6] || (_cache[6] = ($event: any) => (_ctx.toggleItemsMinimized()))
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_v_icon, {
                    class: "text-medium-emphasis mr-1",
                    size: "xs"
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(_ctx.minimizeAllItems
              ? "fa-solid fa-chevron-up"
              : "fa-solid fa-chevron-down"), 1)
                    ]),
                    _: 1
                  }),
                  _createTextVNode(" " + _toDisplayString(_ctx.minimizeAllItems ? "Karten normal" : "Karten minimieren"), 1)
                ]),
                _: 1
              })
            ]),
            _: 1
          })
        ], 4))
      : _createCommentVNode("", true)
  ], 64))
}