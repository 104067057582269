<template>
  <div></div>
</template>
<script lang="ts">
import { LoginService } from "@/services/api/login.service";
import { useRouter } from "vue-router";

export default {
  name: "LogoutView",
  mounted() {
    const router = useRouter();
    const loginService = new LoginService();

    loginService.logout().then(() => {
      router.push("/");
      sessionStorage.removeItem("vuexState");
    });
  },
};
</script>
