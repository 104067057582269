import axios from "axios";
import router from "@/router";
import store from "../../store/store";
import { UserHelperService } from "../user-helper.service";

export class Api {
  private static instance: Api;
  protected backendApi;
  private userHelperService = UserHelperService.getInstance();

  private constructor() {
    this.backendApi = axios.create({
      baseURL: `${store.getters.getEnv.VUE_APP_BACKEND_URI}`,
      withCredentials: true,
    });
    this.setInterceptor();
  }

  public static getInstance(): Api {
    if (!Api.instance) {
      Api.instance = new Api();
    }
    return Api.instance;
  }

  setHeaderContentTypeJson() {
    this.backendApi.defaults.headers.common["Content-Type"] =
      "application/json";
  }

  setHeaderApiKey(apikey: string) {
    this.backendApi.defaults.headers.common["X-ApiKey"] = apikey;
  }

  getBackendApi() {
    return this.backendApi;
  }

  setInterceptor() {
    this.backendApi.interceptors.request.use(
      (config) => {
        const accessToken = localStorage.getItem("accessToken");
        if (
          config.url === "/login" ||
          config.url === "/logout" ||
          accessToken
        ) {
          config.headers.Authorization = `Bearer ${accessToken}`;
          return config;
        } else {
          return Promise.reject(new Error("Kein Zugriffstoken vorhanden"));
        }
      },
      async (error) => {
        if (error.response && error.response.status === 401) {
          localStorage.removeItem("accessToken");
          this.userHelperService.updateIsLoggedIn(); // update logged in status of user
          router.push("/");
        }
        if (error.response && error.response.status === 404) {
          console.error("Anfrage fehlgeschlagen: ", error);
        }
      }
    );
  }
}
