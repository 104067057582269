import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, Transition as _Transition, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = (n: any) => (_pushScopeId("data-v-db47fcaa"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["src"]
const _hoisted_2 = ["src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", null, [
    _createElementVNode("div", null, [
      _createVNode(_Transition, { name: "fade" }, {
        default: _withCtx(() => [
          (_ctx.linkAnimation142x120)
            ? (_openBlock(), _createElementBlock("img", {
                key: 0,
                src: _ctx.linkAnimation142x120,
                class: "size1",
                onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.hideImage && _ctx.hideImage(...args)))
              }, null, 8, _hoisted_1))
            : _createCommentVNode("", true)
        ]),
        _: 1
      }),
      _createVNode(_Transition, { name: "fade" }, {
        default: _withCtx(() => [
          (_ctx.linkAnimation296x120)
            ? (_openBlock(), _createElementBlock("img", {
                key: 0,
                src: _ctx.linkAnimation296x120,
                class: "size2",
                onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.hideImage && _ctx.hideImage(...args)))
              }, null, 8, _hoisted_2))
            : _createCommentVNode("", true)
        ]),
        _: 1
      })
    ])
  ]))
}