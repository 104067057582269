//src/services/api/demand.service.ts
import { Demand } from "@/models/demand.model";
import { Api } from "./api.class";

export class DemandService {
  private api = Api.getInstance();
  private urlPath = "/demand";

  async getAllDemands(mandantUuids?: string[]) {
    try {
      let url = this.urlPath;
      if (mandantUuids && mandantUuids.length > 0) {
        const params = mandantUuids
          .map((uuid) => `mandantUuids=${uuid}`)
          .join("&");
        url += `?${params}`;
      }
      const response = await this.api.getBackendApi().get(url);
      return response.data.response || [];
    } catch (error) {
      console.error("Error fetching demands:", error);
      return { data: null, error };
    }
  }

  async getDemandById(id: string) {
    try {
      const response = await this.api
        .getBackendApi()
        .get(`${this.urlPath}/id/${id}`);
      return response.data;
    } catch (error) {
      console.error("Error fetching demand by id:", error);
      return { data: null, error };
    }
  }

  async addDemand(demand: Demand) {
    try {
      const response = await this.api
        .getBackendApi()
        .post(this.urlPath, demand);
      return response.data;
    } catch (error) {
      console.error("Error adding demand:", error);
      return { data: null, error };
    }
  }

  async updateDemand(demand: Demand) {
    try {
      const response = await this.api
        .getBackendApi()
        .put(`${this.urlPath}/${demand._id}`, demand);
      return response.data;
    } catch (error) {
      console.error("Error updating demand:", error);
      return { data: null, error };
    }
  }

  async removeDemand(id: string) {
    try {
      const response = await this.api
        .getBackendApi()
        .delete(`${this.urlPath}/${id}`);
      return response.data;
    } catch (error) {
      console.error("Error removing demand:", error);
      return { data: null, error };
    }
  }
}
