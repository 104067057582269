<!--src/components/disposition/elements/Assignments.vue-->
<template>
  <v-card-title class="d-flex">
    <v-icon class="mt-1 mr-2 text-medium-emphasis" size="xsmall"
      >fas fa-user-clock</v-icon
    >
    Einsätze
    <v-spacer></v-spacer>
    <v-btn icon variant="text" density="compact" @click="updateAssignments()">
      <v-icon size="small">fas fa-arrows-rotate</v-icon>
      <v-tooltip activator="parent" location="bottom"
        >Einsätze anhand vom ERP System aktualisieren</v-tooltip
      >
    </v-btn>
  </v-card-title>
  <v-card-text>
    <div v-if="isEmployee">
      <v-list-item
        @click="askColumnsForMatches(currentPlacement.clientName ?? '')"
      >
        <v-card-subtitle>
          <h3>
            {{ currentPlacement.clientName }} als
            {{ currentPlacement.jobTitle }}
          </h3></v-card-subtitle
        >
        <v-card-subtitle v-if="currentPlacement.to">
          {{ formatDate(currentPlacement.from) }} bis
          {{ formatDate(currentPlacement.to) }}</v-card-subtitle
        >
      </v-list-item>
    </div>
    <div v-if="isCustomer">
      <v-list-item
        v-for="assignment in assignments"
        :key="assignment.assignmentId"
        :class="{ 'text-disabled': isExpired(assignment.to) }"
        @click="
          askColumnsForMatches(`${assignment.firstName} ${assignment.lastName}`)
        "
      >
        <v-card-subtitle>
          <h3>
            {{ assignment.firstName }} {{ assignment.lastName }} als
            {{ assignment.jobTitle }}
          </h3></v-card-subtitle
        >
        <v-card-subtitle>
          von {{ formatDate(assignment.from) }} bis
          {{ formatDate(assignment.to) }}</v-card-subtitle
        >
      </v-list-item>
    </div>
  </v-card-text>
  <v-divider></v-divider>
</template>

<script lang="ts">
import { Assignment } from "@/models/assignment.model";
import { defineComponent, PropType } from "vue";
import moment from "moment";
import { InterComponentMessage } from "@/enums/inter-component-messagin.enum";
import { mapMutations } from "vuex";
import { MutationType } from "@/enums/vuex-mutationtype.enum";

export default defineComponent({
  name: "AssingmentsList",
  emits: ["updateAssignments"],
  props: {
    assignments: {
      type: Array as PropType<Assignment[]>,
      required: false,
      default: () => [],
    },

    isEmployee: {
      type: Boolean,
      required: false,
    },
    isCustomer: {
      type: Boolean,
      required: false,
    },
  },
  data() {
    return {
      isExpanded: false,
    };
  },
  computed: {
    //...mapGetters(["mandants", "softwareIntegration"]),
    currentPlacement() {
      const today = moment();
      const current = this.assignments?.find(
        (placement) =>
          moment(placement.from).isSameOrBefore(today) &&
          moment(placement.to).isSameOrAfter(today)
      );

      if (current) {
        return current;
      } else {
        return {
          clientName: "Kein Einsatz",
          jobTitle: "Zeitarbeiter",
          from: "",
          to: "",
        };
      }
    },
  },
  methods: {
    ...mapMutations({
      setICM: MutationType.setICM,
    }),
    askColumnsForMatches(searchTerm: string) {
      if (searchTerm === "Kein Einsatz") return;
      this.setICM({
        message: InterComponentMessage.matchMeFromGlobalSearch,
        payload: {
          searchTerm: searchTerm,
        },
      });
    },
    formatDate(date: string) {
      return date ? moment(date).format("DD.MM.YYYY") : "";
    },
    isExpired(date: string) {
      return date && moment(date).isBefore(moment());
    },
    updateAssignments() {
      this.$emit("updateAssignments");
    },
  },
});
</script>
