<template>
  <div class="main-header-menu">
    <router-link
      to="/dashboard"
      @mouseover="hover = true"
      @mouseleave="hover = false"
      data-tid="header-link-dashboard"
    >
      <img class="logo" src="@/assets/logo.png" alt="Logo" />
    </router-link>
    <div class="panels">
      <router-link
        to="/recruiting"
        class="mainbutton recruiting"
        @mouseover="hover = true"
        @mouseleave="hover = false"
        data-tid="header-link-recruiting"
      >
        <i class="fa-solid fa-people-arrows panel-icon"></i>
        <p>Recruiting</p>
      </router-link>

      <router-link
        to="/disposition"
        class="mainbutton"
        @mouseover="hover = true"
        @mouseleave="hover = false"
        data-tid="header-link-disposition"
      >
        <i class="fa-solid fa-headset panel-icon"></i>
        <p>Disposition</p>
      </router-link>

      <router-link
        to="/data"
        class="mainbutton"
        @mouseover="hover = true"
        @mouseleave="hover = false"
      >
        <i class="fa-regular fa-folder-open panel-icon"></i>
        <p>Verwaltung</p>
      </router-link>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
  name: "MainHeaderMenu",
  data() {
    return {
      hover: false,
    };
  },
});
</script>

<style scoped lang="scss">
@import "./scss/variables.scss";
.main-header-menu {
  display: flex;
  align-items: center;
  transition: all 0.3s ease;
}

.logo {
  margin-right: 2rem;
  margin-left: 1rem;
  transition: all 1s ease;
  width: 9rem;
  height: 9rem;
}
.panel-icon {
  font-size: 4rem;
}
.logo:hover {
  scale: var(--medium-scale-up);
}
.panels {
  height: 9rem;
  display: flex;
  justify-content: center;
  transition: all 1s ease;
}

.panels:hover {
  scale: var(--xs-scale-up);
}

.mainbutton {
  align-items: center;
  width: 8rem;
  background-color: var(--color-primary);
  border-radius: 8px;
  border: 2px solid var(--color-accent);
  color: white;
  display: flex;
  justify-content: center;
  flex-direction: column;
  margin: 5px;
  padding: 0.1rem 1rem;
  text-decoration: none;
  transition: all 0.3s ease;
}

.mainbutton:hover {
  transform: scale(1.1);
  background-color: var(--color-hover);
  border-color: var(--color-hover);
  color: black;
}
@media (max-width: $breakpointS) {
  .logo {
    display: none;
  }

  .panel-icon {
    font-size: 1.5rem;
  }

  .panels {
    flex-direction: column;
  }

  .mainbutton {
    width: 3rem;
    height: 2.5rem;
    padding: 0.2rem;
    font-size: 0.8rem;
  }

  .mainbutton p {
    display: none;
  }
}
</style>
