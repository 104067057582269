// src/services/ai.service.ts
import { ChatGPT, updateOpenAiConfig } from "./openai.service";
import { MistralGDPR } from "./mistral-ai.service";
import store from "../store/store";
import ToastService from "./toast.service";
import { CareerStep } from "@/models/candidate.model";

updateOpenAiConfig();

// const AiModelCheapest = "gpt-4o-mini"; NEW NEW NEW
//const AiModelBasic = "gpt-3.5-turbo-16k";
const AiModelBasic = "gpt-4o-mini"; //TESCHT if good :)
const AiModelTurbo = "gpt-4"; //we'll need that later
const AiModelHighCap = "gpt-4o";
const saveAiModelTiny = "mistral-tiny-latest";
const saveAiModelSmall = "mistral-small-latest";
const saveAiModelLarge = "mistral-large-latest";

export const promptGlobal = {
  formatTitleTextJson:
    `Du gibst Deine Antwort exakt in diesem JSON-Format aus, da Deine Antwort direkt in ein Programm geht und anders nicht verstanden wird:` +
    `{` +
    ` "title": "Deine Antwort"\n` +
    ` "text": "Deine Antwort"\n` +
    `}`,
  formatCvDataBeforeBirthdate:
    `Bearbeite bitte die nachfolgenden Lebenslaufdaten eines Kandidaten so, dass der Lebenslauf konsistent und lückenlos ist. ` +
    `Wenn dir Daten fehlen, wie z.B. Schulzeit, ergänze diese bitte, wie sie in dem jeweiligen Land üblich sind. Passe bitte auch immer die ` +
    `Beginn- und Enddaten so an, dass alles lückenlos aneinander reicht und ordne die Einträge chronologisch so, dass immer das Neueste ` +
    `als erstes steht. Wenn du z.B. Daten hast, die aufgrund des Geburtsdatums des Kandidaten nicht möglich sind,  passe diese bitte an, ` +
    `sodass sie logisch sind und zum Alter des Kandidaten passen. Entferne auch alle Rechtschreibfehler.` +
    `Du gibst Deine Antwort exakt in diesem JSON-Format aus, da Deine Antwort direkt in ein Programm geht und anders nicht verstanden wird:` +
    `Das Geburtsdatum des Kandidaten ist:`,
  formatCvDataBeforeJson:
    `Nutze bei der Ausgabe exakt diese Format` +
    `[\n` +
    `  {\n` +
    `    "at": "Deine Antwort",\n` +
    `    "until": "YYYY-MM-DDTHH:mm:ss.SSS[Z]",\n` +
    `    "details": "Deine Antwort",\n` +
    `    "title": "Deine Antwort",\n` +
    `    "type": {\n` +
    `      "sortOrder": 1,\n` +
    `      "systemName": "employed",\n` +
    `      "description": "Anstellung"\n` +
    `    },\n` +
    `    "from": "YYYY-MM-DDTHH:mm:ss.SSS[Z]"\n` +
    `  },\n` +
    `  {\n` +
    `    "at": "Deine Antwort",\n` +
    `    "until": "YYYY-MM-DDTHH:mm:ss.SSS[Z]",\n` +
    `    "details": "Deine Antwort",\n` +
    `    "title": "Deine Antwort",\n` +
    `    "type": {\n` +
    `      "sortOrder": 2,\n` +
    `      "systemName": "professional_training",\n` +
    `      "description": "Berufsausbildung"\n` +
    `    },\n` +
    `    "from": "YYYY-MM-DDTHH:mm:ss.SSS[Z]"\n` +
    `  }\n` +
    ` ]\n` +
    ` Schreibe diesen Lebenslauf konsistent und lückenlos und gibt in mir wieder als Array mit JSON Objekten im exakt gleichen Format aus. Wenn du Information zu einer beruflichen Station hast muss diese erhalten bleiben! Schreib das basierend auf diesen Daten:\n`,

  generateDashboardBeforeName:
    "Du schreibst was auf das Dashboard von unserem Mitarbeiter",
  anonymizedUserFirstName: "Horst",
  anonymizedUserLastName: "Lokal-Maier",
  generateDashboardBeforeKPIs:
    `1. Ein Satz mit maximal 6 Worten die ihn motivieren\n` +
    `2. Eine sehr kurze, leicht verständliche Analyse zu aktuellen Situation basierend auf den Kennzahlen. Nutze dabei aber nur die Anzahl der Telefonate zu Kunden und Kandidaten, die Erreichbarkeit dieser. Geh auf die Vorstellungsgespräche bei uns ein und wieviel abgesagt werden. Außerdem schau wie die Kandidaten zu den Kunden geschickt wurden (Profile verschickt), wieviele zum Vorstellungsgespräche oder Probearbeiten eingeladen wurden!\n` +
    `3. 5 knackige motivierenden Tipps für sein fortkommen basieren auf den aktuellen Kennzahlen\n` +
    `Du gibst deine Antwort als JSON in exakt diesem Format aus:\n` +
    `{\n` +
    `"salutation":  "Motivierenden Anrede",\n` +
    `"motivation": "Deine Antwort auf 1. der motivierenden Satz nach der Anrede basierend auf der aktuellen Kennzahlensitution"\n` +
    `"analyse": "die sehr kurze Analyse basierend auf der aktuellen Kennzahlensituation, schön formatiert mit Html-Tags, gerne auch wichtige Dinge mit <strong> hervorheben"\n` +
    `"tips": "die Tipps von 3. basierend auf der aktuellen Kennzahlensituation, schön formatiert mit Html-Tags, gerne auch wichtige Dinge mit <strong> hervorheben"\n` +
    `}\n` +
    `Nutze diese Kennzahlen:\n` +
    `das sind die Vertriebskennzahlen von Horst (user = Horst, mandant = seine Niederlassung und global = unsere gesamte Firma):`,
  generateDashboardAfterKPIs:
    "Das sind die die Personalkennzahlen seiner Niederlassung:",
  generateWpPluginProfile:
    `Du schreibst jetzt ein extrem kurzes Profil von unserem Kandidaten, dass wir auf unser Webseite stellen.` +
    `Da es online gestellte wird müssen wir auf Datenschutz achten, das bedeutet wir veröffentlichen keine personenbezogenen Daten` +
    `Du gibst deine Antwort als JSON in exakt diesem Format aus:\n` +
    `{\n` +
    `"title":  "Titel der den Kandidat beschreibt",\n` +
    `"qualification": "ganz kurz das Qualifikationsniveu des Kandidaten basierend auf seinem Lebenslauf"\n` +
    `"licenses": "ganz kurz Führerscheine oder Zertifikate"\n` +
    `"experience": "nur ganz kurz die Berufsbezeichnungen kommaseperiert aus seinem Lebenslauf"\n` +
    `}\n` +
    `Du schreibst nichts anderes außer das JSON, weil Deine Antwort direkt in ein Programm geht und sonst nicht verstanden wird.\n` +
    `Nutze die Daten von diesem anonymisierten Lebenslauf dafür:`,
  generateSubject: "Schreib mir einen kurzen Betreff zu diesem Text:",
  writingStyle: "Nutze dafür bitte meinen Schreibstil. Ich schreibe so:",
  answerFormatArrayWithStrings: `Das Format Deiner Antwort muss ein Array mit Strings sein: ["Tag1","Tag2"]\nSchreib sonst nichts in Deiner Antwort, weil Deine Antwort direkt in ein Programm geht, dass nur dieses Format verarbeiten kann.`,
  extractCustomerData:
    `Du extrahiert alle passenden Kundendaten und bringst die in folgendens JSON-Format\n\n` +
    `{\n` +
    `  "customerNumber": "0815",\n` +
    `  "mandant": {\n` +
    `    "uuid": [],\n` +
    `  },\n` +
    `  tags: null,\n` +
    `  "generalData": {\n` +
    `    "name": "Testfirma GmbH",\n` +
    `    "name2": null,\n` +
    `    "name3": null,\n` +
    `    "searchTerm": "Testfirma",\n` +
    `    "costCenter": null,\n` +
    `    "customerSince": "10.01.2024",\n` +
    `    "accountsReceivable": null,\n` +
    `    "createdOn": "22.04.2020",\n` +
    `    "status": "Kunde"\n` +
    `  },\n` +
    `  "addressAndCommunication": {\n` +
    `    "street": "Schlätterstraße 2",\n` +
    `    "postalCode": "54321",\n` +
    `    "city": "Entenhausen",\n` +
    `    "country": "Deutschland",\n` +
    `    "state": "Baden-Württemberg",\n` +
    `    "phone1": "+49 1533 54154-0",\n` +
    `    "phone2": null,\n` +
    `    "email": null,\n` +
    `    "website": null\n` +
    `  },\n` +
    `  "furtherInformation": {\n` +
    `    "dispatcher": null,\n` +
    `    "industries": null\n` +
    `    "WZ08": null\n` +
    `    "Siret": null\n` +
    `  },\n` +
    `  "additionalInfo": {\n` +
    `    "info1": "hoher Bargeldbestand",\n` +
    `    "info2": null,\n` +
    `    "info3": null,\n` +
    `    "indexLink": null,\n` +
    `    "careerSiteLink": null,\n` +
    `    "dataProtectionConsent": null\n` +
    `  },\n` +
    `  "reporting": {},\n` +
    `  "contacts": [],\n` +
    `  "jobAds": []\n` +
    `}\n` +
    `Gib deine Antwort exakt in diesem JSON Format aus, weil die Antwort direkt in ein Programm geht und das nichts anderes verstehen kann.\n` +
    `Nutze diese Kundendaten um die Informationen zu extrahieren:\n`,

  extractCustomerContacts:
    `Du extrahiert alle passenden Ansprechpartner von meinem Kunden machst die als  Objekte in folgendes Array\n` +
    `[\n` +
    `  {\n` +
    `    "salutation": "Herr",\n` +
    `    "title": "",\n` +
    `    "role": "",\n` +
    `    "firstName": "Dagobert",\n` +
    `    "lastName": "Duck",\n` +
    `    "phone": "+49 1533 54154 41",\n` +
    `    "email": "dagobert@duck.de",\n` +
    `    "status": "aktiv"\n` +
    `  }\n` +
    `  {\n` +
    `    "salutation": "Herr",\n` +
    `    "title": "",\n` +
    `    "role": "",\n` +
    `    "firstName": "Donald",\n` +
    `    "lastName": "Duck",\n` +
    `    "phone": "+49 1533 54154 39",\n` +
    `    "email": "donald@duck.de",\n` +
    `    "status": "inaktiv"\n` +
    `  }\n` +
    `]\n` +
    `Gib deine Antwort exakt in diesem Array mit Ansprechpartner-Objekten aus, weil die Antwort direkt in ein Programm geht und das nichts anderes verstehen kann.\n` +
    `Nutze diese Kundendaten um die Ansprechpartner zu extrahieren:\n`,

  extractJobTasksAndProfil:
    `Du extrahiert alle Aufgaben der Stellenanzeige und das Profil des gesuchten Kandidaten und und gibst mir das ganz sehr kurz und knapp stichpunktartig in folgendem JSON-Objekt aus:\n` +
    `  {\n` +
    `    "tasks": "Stichpunkt Aufgabe 1, Stichpunkt Aufgabe 2, usw.",\n` +
    `    "profile": "Stichpunkt Anforderung 1, Stichpunkt Anforderung 2, usw.",\n` +
    `    "bfaCategories": ["Array mit 3 String", "die den passensten Jobbezeichnungen", "der Bundesagentur für Arbeit entstprechen"],\n` +
    `  }\n` +
    `Gib deine Antwort exakt in diesem JSON Objekt aus, weil die Antwort direkt in ein Programm geht und das nichts anderes verstehen kann.\n` +
    `Nutze diesen Stellenanzeigentext:\n`,

  profileBeforeCandidate: `Anweisung: Schreib mir bitte ein Kurzprofil für einen Kandidaten den wir gerne einem Kunden vorschlagen würden. Anweisung: Benutze diese Information über den Kandidaten:\n`,
  profileBeforeInterviewNote: `Zusammenfassung der Eindrücke beim Vorstellungsgespräch:\n`,
  profileBeforeJobAd: `Anweisung: Nutze folgende Stellenbeschreibung und stelle die Fähigkeiten und Kompetenzen des genannten Kandidaten heraus, die auf diese Stelle passen könnten:\n`,
  profileCustomerTags: `Anweisung: Nutze folgende Tags was die Kunde in der Regel an Mitarbeitern sucht und stelle die Fähigkeiten und Kompetenzen des genannten Kandidaten heraus, die auf eine oder mehrere Tags passen könnten:\n`,
  jsonFormatProfile:
    `Anweisung für die Ausgabe: Nutze zwingend dieses JSON Format:\n` +
    `{` +
    `  "languageSkills": [` +
    `    {` +
    `      "language": "Deutsch",` +
    `      "level": "█ █ █ █ █ █ █ █"` +
    `    },` +
    `    {` +
    `      "language": "Bezeichnung Sprache 2",` +
    `      "level": "█ █ █ █ "` +
    `    },` +
    `    {` +
    `      "language": "Bezeichnung Sprache 3",` +
    `      "level": "█ █ "` +
    `    }` +
    `  ],` +
    `  "headline": "Berufsbezeichnung als Überschrift",` +
    `  "overTheCandidate": "Kurze Beschreibung vom Kandidaten in zwei Sätzen, warum der auf die Stelle passt",` +
    `  "skills": "` +
    `    <li> Skill1</li>` +
    `    <li>Skill2</li>` +
    `    <li>Skill3</li>` +
    `    <li>Skill4</li>` +
    `",` +
    `  "softSkills": "` +
    `    <li>SoftSkill1</li >` +
    `    <li>SoftSkill2</li>` +
    `    <li>SoftSkill3</li>` +
    `",` +
    `"education": "` +
    `  <li>01.09.2000 bis 30.06.2009 <strong>Beispielschulabschluss</strong><br>bei Bespielschule in 54321 Musterstadt</li>` +
    `  <li>01.09.2009 bis 30.06.2011 <strong>Berufsausbildung zum Beispielberuf</strong><br>bei Ausbildungsbetrieb in 54321 Musterstadt` +
    `    <ul style="padding-left:1rem">` +
    `     <li>Detail</li>` +
    `     <li>Detail</li>` +
    `    </ul>` +
    `  </li>` +
    `",` +
    `"curiculumVitae": "` +
    `  <li>01.07.2011 bis 31.12.2019 <strong>Beispielberuf></strong><br>bei Betrieb in 54321 Musterstadt` +
    `    <ul style="padding-left:1rem">` +
    `     <li>Detail</li>` +
    `     <li>Detail</li>` +
    `    </ul>` +
    `  </li>` +
    `  <li>01.07.2011 bis 31.12.2019 <strong>Beispielberuf</strong><br>bei Betrieb in 54321 Musterstadt` +
    `    <ul style="padding-left:1rem">` +
    `     <li>Detail</li>` +
    `     <li>Detail<br>&nbsp;</li>` +
    `    </ul>` +
    `  </li>` +
    `",` +
    `"summary": "Hier schreibst Du ein kurze Zusammenfassung älterer oder irrelevanter Stationen im Lebenslauf"` +
    `}` +
    `Du intepretierst auch die Sprachfähigkeiten des Kandidaten: Die erste Sprache ist immer Deutsch und darunter kommen zwei andere Sprachen.` +
    `Das Sprachniveu geht von ganz schlecht: '█' bis perfekte Sprachkenntnisse: '█ █ █ █ █ █ █ █'. Wenn Du keine drei Fremdsprachen findest machst du einfacht bei languageSkills[1].language und .niveu ein leeren string rein, dass immer 3 Objekte bei languageSkills im Array sind.\n` +
    `Anweisung: Fasse bei Werdegang bitte die Beschäftigungen, die für die Stelle nicht relevant sind oder lange in der Vergangenheit liegen so zusammen, damit alles auf eine DinA4 Seite passt. Dennoch soll die Information über die Art der Beschäftigung nicht verloren gehen.\n` +
    `Anweisung: In Beschreibungen nenne den Kandidaten immer 'Unser Kandidat' bzw. 'Unsere Kandidatin' statt mit Name!` +
    `Nutze für die Antwort unbedingt das vorgegebene JSON Format und in den Strings die HTML Tags, weil Deine Antwort direkt in ein Programm geht und sonst nicht verstanden wird`,
  MailProfileBeforeSalutation:
    "Der Ansprechpartner des Kunden ist Herr Rocky Balboa! Und wir haben ein Profil von dem Kandidaten",
  MailProfileAnonymizedName: "Huber-Maier",
  MailProfileAfterSalutation: ` erstellt und als PDF angehängt. Schreib bitte auch zwei nette Sätze über den Kandidaten, das sind seine Daten:\n`,
  jsonFormatMail:
    ` Gibt Deine Antwort exakt in folgendem JSON - Format aus halte Dich exakt daran, weil Deine Antwort direkt in ein Programm geht und nur so verstanden wird.\n` +
    `{` +
    `  "subject": "Betreff der Email"` +
    `  "salutation": "Grußformel der Email"` +
    `  "text": "AUSSCHLIEßLICH Inhalt der Email schön mit HTML-Tags (<br>, <p> und <strong> <ul> <li>) formatiert"` +
    `  "signature": "mit freundlichen Grüßen,\nunsere Signatur"` +
    `}`,
  generateIndexQuery:
    `Bitte gib Suchbegriffe für Stellentitel und Anzeigentext an, die bei der Suche nach Stellenangeboten für einen spezifischen Kandidaten verwendet werden sollen. Die Suchbegriffe für die Stellentitel sowie die im Anzeigentext verwendeten Begriffe müssen jeweils mit einem "OR" zwischen den Begriffen formatiert werden.` +
    `Es ist entscheidend, dass die Antwort exakt in folgendem JSON-Format gegeben wird, da sie direkt in ein Programm eingespeist wird und in anderer Form nicht verarbeitet werden kann:` +
    `{` +
    `{` +
    ` "jobTitle": "Job1 OR Job2 OR Job3",` +
    ` "searchstrings": "Wort1 OR Wort2 OR Wort3"` +
    `}\n` +
    `Nutze diese Daten vom Kandidaten für möglichst gute Suchstrings:`,
  generateIndexQueryHint:
    "Beachte, dass jegliche Abweichung von diesem Format dazu führen kann, dass die Daten nicht korrekt gelesen oder verarbeitet werden können. Bitte stelle sicher, dass deine Antwort genau dem vorgebenen JSON entspricht.",
};

export const generateDashboardText = async (
  salesKPIs: any,
  branchKPIs: any
) => {
  const prompt = `${store.state.company.aiData.prompt.company.weAreShort}\n
  ${promptGlobal.generateDashboardBeforeName} ${promptGlobal.anonymizedUserFirstName} ${promptGlobal.anonymizedUserLastName}\n
  ${promptGlobal.generateDashboardBeforeKPIs}\n${salesKPIs}\n\n${promptGlobal.generateDashboardAfterKPIs}\n${branchKPIs}`;
  try {
    const response = await ChatGPT({
      prompt: prompt,
      model: AiModelHighCap,
      temperature: 0.7,
      role: "system",
    });

    const matches = response.choices[0].message.content.match(/{(.*?)}/s);
    if (matches && matches[1]) {
      let dashboardText = `{${matches[1]}}`;

      const firstNameRegex = new RegExp(
        promptGlobal.anonymizedUserFirstName,
        "g"
      );
      const lastNameRegex = new RegExp(
        promptGlobal.anonymizedUserLastName,
        "g"
      );

      dashboardText = dashboardText.replace(
        firstNameRegex,
        store.state.company.loggedInUser.forename
      );
      dashboardText = dashboardText.replace(
        lastNameRegex,
        store.state.company.loggedInUser.lastname
      );

      const dashboardData = JSON.parse(dashboardText);
      return dashboardData;
    } else {
      throw new Error("Kein gültiges JSON-Format in der AI-Antwort gefunden.");
    }
  } catch (error) {
    console.error("Fehler bei der Textgenerierung fürs Dashboard", error);
    throw error;
  }
};

export const generateMessage = async (
  message: string,
  additionalPrompt: string
) => {
  const promptText = `${store.state.company.aiData.prompt.company.weAreShort}\n${store.state.company.aiData.prompt.company.whatsAppCandidate}\n${promptGlobal.writingStyle}[${store.state.company.loggedInUser.config.aiData.whatsAppStyle}]\n${additionalPrompt}\nSchreibe nur den Text der Nachricht neu:${message}`;
  const AiModel = AiModelBasic;
  const temperature = 0.7;

  try {
    const response = await ChatGPT({
      prompt: promptText,
      model: AiModel,
      temperature: temperature,
      role: "system",
    });

    return response.choices[0].message.content;
  } catch (error) {
    console.error("Fehler bei der Generierung der Nachricht", error);
    throw error;
  }
};

export const generateMessageGDPRsave = async (
  message: string,
  additionalPrompt: string
) => {
  const promptText = `${store.state.company.aiData.prompt.company.weAreShort}\n${store.state.company.aiData.prompt.company.whatsAppCandidate}\n${promptGlobal.writingStyle}[${store.state.company.loggedInUser.config.aiData.whatsAppStyle}]\n${additionalPrompt}\\nNutze diese Daten:${message}`;
  const AiModel = saveAiModelSmall;
  const temperature = 0.7;

  try {
    const response = await MistralGDPR({
      prompt: promptText,
      model: AiModel,
      temperature: temperature,
      role: "system",
    });

    return response.choices[0].message.content;
  } catch (error) {
    console.error("Fehler bei der Generierung der Nachricht", error);
    throw error;
  }
};

export const generateEmail = async (
  message: string,
  messageType: string,
  additionalPrompt: string
) => {
  let promptText = message;
  let AiModel = AiModelBasic;
  let temperature = 0.5;
  if (messageType === "mailCandidate") {
    promptText = `${store.state.company.aiData.prompt.company.weAreShort}\n${store.state.company.aiData.prompt.company.mailCandidate}\n${promptGlobal.writingStyle}[${store.state.company.loggedInUser.config.aiData.mailStyle}]\n${additionalPrompt}\n${promptGlobal.jsonFormatMail}\nNutze diese Daten:${message}`;
    AiModel = AiModelHighCap;
    temperature = 0.7;
  }
  if (messageType === "mailCustomer") {
    promptText = `${store.state.company.aiData.prompt.company.weAreShort}\n${store.state.company.aiData.prompt.company.mailCustomer}\n${promptGlobal.writingStyle}[${store.state.company.loggedInUser.config.aiData.mailStyle}]n${additionalPrompt}\n${promptGlobal.jsonFormatMail}\nNutze diese Daten:${message}`;
    AiModel = AiModelHighCap;
    temperature = 0.7;
  }
  try {
    const response = await ChatGPT({
      prompt: promptText,
      model: AiModel,
      temperature: temperature,
      role: "system",
    });

    const matches = response.choices[0].message.content.match(/{(.*?)}/s);
    if (matches && matches[1]) {
      let dashboardText = `{${matches[1]}}`;

      const firstNameRegex = new RegExp(
        promptGlobal.anonymizedUserFirstName,
        "g"
      );
      const lastNameRegex = new RegExp(
        promptGlobal.anonymizedUserLastName,
        "g"
      );

      dashboardText = dashboardText.replace(
        firstNameRegex,
        store.state.company.loggedInUser.forename
      );
      dashboardText = dashboardText.replace(
        lastNameRegex,
        store.state.company.loggedInUser.lastname
      );

      const dashboardData = JSON.parse(dashboardText);
      return dashboardData;
    } else {
      throw new Error("Kein gültiges JSON-Format in der AI-Antwort gefunden.");
    }
  } catch (error) {
    console.error("Fehler bei der Textgenerierung fürs Dashboard", error);
    throw error;
  }
};

export const generateEmailGDPRsave = async (
  message: string,
  messageType: string,
  additionalPrompt: string
) => {
  let promptText = message;
  let AiModel = saveAiModelTiny;
  let temperature = 0.5;
  if (messageType === "mailCandidate") {
    promptText = `${store.state.company.aiData.prompt.company.weAreShort}\n${store.state.company.aiData.prompt.company.mailCandidate}\n${promptGlobal.writingStyle}[${store.state.company.loggedInUser.config.aiData.mailStyle}]\n${additionalPrompt}\n${promptGlobal.jsonFormatMail}\nNutze diese Daten:${message}`;
    AiModel = saveAiModelLarge;
    temperature = 0.7;
  }
  if (messageType === "mailCustomer") {
    promptText = `${store.state.company.aiData.prompt.company.weAreShort}\n${store.state.company.aiData.prompt.company.mailCustomer}\n${promptGlobal.writingStyle}[${store.state.company.loggedInUser.config.aiData.mailStyle}]\n${additionalPrompt}\n${promptGlobal.jsonFormatMail}\nNutze diese Daten:${message}`;
    AiModel = saveAiModelLarge;
    temperature = 0.7;
  }
  try {
    const response = await MistralGDPR({
      prompt: promptText,
      model: AiModel,
      temperature: temperature,
      role: "system",
    });

    const matches = response.choices[0].message.content.match(/{(.*?)}/s);
    if (matches && matches[1]) {
      let dashboardText = `{${matches[1]}}`;

      const firstNameRegex = new RegExp(
        promptGlobal.anonymizedUserFirstName,
        "g"
      );
      const lastNameRegex = new RegExp(
        promptGlobal.anonymizedUserLastName,
        "g"
      );

      dashboardText = dashboardText.replace(
        firstNameRegex,
        store.state.company.loggedInUser.forename
      );
      dashboardText = dashboardText.replace(
        lastNameRegex,
        store.state.company.loggedInUser.lastname
      );

      const dashboardData = JSON.parse(dashboardText);
      return dashboardData;
    } else {
      throw new Error("Kein gültiges JSON-Format in der AI-Antwort gefunden.");
    }
  } catch (error) {
    console.error("Fehler bei der Textgenerierung fürs Dashboard", error);
    throw error;
  }
};

export const translateMessageGDPRsave = async (
  promptText: string,
  targetLanguage: string
) => {
  const translationPrompt = `Übersetze diesen Text ins ${targetLanguage}: ${promptText}`;
  try {
    const response = await MistralGDPR({
      prompt: translationPrompt,
      model: saveAiModelTiny,
      temperature: 0.7,
      role: "system",
    });
    return response.choices[0].message.content;
  } catch (error) {
    console.error("Fehler bei der Übersetzung:", error);
    throw error;
  }
};

export const translateMessage = async (
  promptText: string,
  targetLanguage: string
) => {
  const translationPrompt = `Übersetze diesen Text ins ${targetLanguage}: ${promptText}`;
  try {
    const response = await ChatGPT({
      prompt: translationPrompt,
      model: AiModelBasic,
      temperature: 0.7,
      role: "system",
    });
    return { text: response.choices[0].message.content };
  } catch (error) {
    console.error("Fehler bei der Übersetzung:", error);
    throw error;
  }
};

export const translateBugReport = async (
  reportTitle: string,
  reportText: string,
  reportTyp: string
) => {
  const translationPrompt = `Übersetze dieses Gitlab Ticket vom Typ"${reportTyp}" in Englisch und schreib den Text strukturiet und leicht verständlich.\n${promptGlobal.formatTitleTextJson}\nNutze diese Daten::\nTitel: ${reportTitle}\nText:${reportText}`;
  try {
    const response = await ChatGPT({
      prompt: translationPrompt,
      model: AiModelBasic,
      temperature: 0.7,
      role: "system",
    });
    const jsonMatch = response.choices[0].message.content.match(/{.*}/s);
    if (!jsonMatch) {
      throw new Error("no JSON format in ai anwer");
    }
    const jsonString = jsonMatch[0];
    const parsedResponse = JSON.parse(jsonString);

    if (
      typeof parsedResponse === "object" &&
      parsedResponse !== null &&
      "title" in parsedResponse
    ) {
      return parsedResponse;
    } else {
      throw new Error("ai answer in wrong format");
    }
  } catch (error) {
    console.error("Fehler bei der Übersetzung:", error);
    throw error;
  }
};

export const generateCandidateTags = async (anonymizedResume: string) => {
  const promptText = `${store.state.company.aiData.prompt.company.tagsCandidate} ${promptGlobal.answerFormatArrayWithStrings} Nutze diese Lebenslaufdaten vom Kandidaten: ${anonymizedResume}`;

  try {
    const response = await ChatGPT({
      prompt: promptText,
      model: AiModelBasic,
      temperature: 0.7, // Slightly higher temperature setting to encourage creative responses
      role: "system",
    });

    // Extract the response part that is expected to be in the exact array format
    const rawResponse = response.choices[0].message.content;
    const startIndex = rawResponse.indexOf("[");
    const endIndex = rawResponse.lastIndexOf("]") + 1;
    const arrayResponse = rawResponse.substring(startIndex, endIndex);

    try {
      const parsedArray = JSON.parse(arrayResponse);
      if (Array.isArray(parsedArray)) {
        // Ensure that all elements are strings
        return parsedArray.filter((tag) => typeof tag === "string");
      }
      throw new Error("Parsed content is not an array.");
    } catch (parseError) {
      console.error("Error parsing the array response:", parseError);
      // Return an empty array or handle the error as appropriate
      return [];
    }
  } catch (error) {
    console.error("Error generating candidate tags:", error);
    throw error;
  }
};

export const generateCustomerTags = async (customerTagsData: string) => {
  const promptText = `${store.state.company.aiData.prompt.company.tagsCompany} ${promptGlobal.answerFormatArrayWithStrings} Nutze diese Kundendaten und die Liste mit den Stellenanzeigen des Kunden: ${customerTagsData}`;

  try {
    const response = await ChatGPT({
      prompt: promptText,
      model: AiModelBasic,
      temperature: 0.7,
      role: "system",
    });

    const rawResponse = response.choices[0].message.content;
    const startIndex = rawResponse.indexOf("[");
    const endIndex = rawResponse.lastIndexOf("]") + 1;
    const arrayResponse = rawResponse.substring(startIndex, endIndex);

    try {
      const parsedArray = JSON.parse(arrayResponse);
      if (Array.isArray(parsedArray)) {
        return parsedArray.filter((tag) => typeof tag === "string");
      }
      throw new Error("Parsed content is not an array.");
    } catch (parseError) {
      console.error("Error parsing the array response:", parseError);
      return [];
    }
  } catch (error) {
    console.error("Error generating customer tags:", error);
    throw error;
  }
};

export const extractCustomerData = async (dataInput: string) => {
  const promptText = `${promptGlobal.extractCustomerData}${dataInput}`;

  try {
    const response = await ChatGPT({
      prompt: promptText,
      model: AiModelBasic,
      temperature: 0.7,
      role: "system",
    });
    const jsonMatch = response.choices[0].message.content.match(/{.*}/s);
    if (!jsonMatch) {
      throw new Error("no JSON format in ai answer");
    }
    const jsonString = jsonMatch[0];
    const parsedResponse = JSON.parse(jsonString);

    if (
      typeof parsedResponse === "object" &&
      parsedResponse !== null &&
      "customerNumber" in parsedResponse
    ) {
      return parsedResponse;
    } else {
      throw new Error("ai answer in wrong format");
    }
  } catch (error) {
    console.error("extracting Data Error:", error);
    throw error;
  }
};

export const extractCustomerContacts = async (dataInput: string) => {
  const promptText = `${promptGlobal.extractCustomerContacts}${dataInput}`;
  try {
    const response = await ChatGPT({
      prompt: promptText,
      model: AiModelBasic,
      temperature: 0.7,
      role: "system",
    });

    // Search for the content between the first '[' and the last ']' in the response string
    // This is necessary because sometimes the AI might prepend or append non-JSON content to the response,
    // like introductory text or summaries, which are not part of the JSON data structure.
    // Using this approach, we ensure that only the JSON array part of the response is parsed,
    // avoiding errors that could arise from attempting to parse the entire response as JSON
    // when it includes non-JSON content before the first '[' or after the last ']'.

    const matches = response.choices[0].message.content.match(/\[(.*)\]/s);
    if (matches && matches[0]) {
      const parsedResponse = JSON.parse(matches[0]);
      if (Array.isArray(parsedResponse) && parsedResponse.length > 0) {
        return parsedResponse;
      } else {
        throw new Error("AI-Antwort ist kein gültiges JSON-Array.");
      }
    } else {
      throw new Error("Kein gültiges JSON-Format in der AI-Antwort gefunden.");
    }
  } catch (error) {
    console.error("Fehler beim Extrahieren der Daten:", error);
    throw error;
  }
};

export const extractJobAdData = async (jobAdText: string) => {
  const promptText = `${promptGlobal.extractJobTasksAndProfil} ${jobAdText}`;

  try {
    const response = await ChatGPT({
      prompt: promptText,
      model: AiModelBasic,
      temperature: 0.5,
      role: "system",
    });
    const matches = response.choices[0].message.content.match(/{(.*?)}/s);
    if (matches && matches[1]) {
      const parsedResponse = JSON.parse(`{${matches[1]}}`);
      return parsedResponse;
    } else {
      throw new Error("Kein gültiges JSON-Format in der AI-Antwort gefunden.");
    }
  } catch (error) {
    console.error("Fehler beim Extrahieren der Daten:", error);
    throw error;
  }
};

export const generateProfile = async (
  anonymizedResume: string,
  anonymizedNote: string,
  jobAdOrTags: string,
  basedOnTags?: boolean
) => {
  let profilePrompt = `${store.state.company.aiData.prompt.company.weAreShort}\n${promptGlobal.profileBeforeCandidate}${anonymizedResume}\n${promptGlobal.profileBeforeInterviewNote}${anonymizedNote}\n${promptGlobal.profileBeforeJobAd}${jobAdOrTags}\n${promptGlobal.jsonFormatProfile}`;
  if (basedOnTags)
    profilePrompt = `${store.state.company.aiData.prompt.company.weAreShort}\n${promptGlobal.profileBeforeCandidate}${anonymizedResume}\n${promptGlobal.profileBeforeInterviewNote}${anonymizedNote}\n${promptGlobal.profileCustomerTags}${jobAdOrTags}\n${promptGlobal.jsonFormatProfile}`;

  const parseJSON = (responseContent: string): any => {
    const jsonStartIndex = responseContent.indexOf("{");
    const jsonEndIndex = responseContent.lastIndexOf("}");
    if (jsonStartIndex === -1 || jsonEndIndex === -1) {
      throw new Error("Kein gültiges JSON-Format in der AI-Antwort gefunden.");
    }
    const jsonString = responseContent.substring(
      jsonStartIndex,
      jsonEndIndex + 1
    );
    return JSON.parse(jsonString);
  };

  const extractJSONWithRegex = (responseContent: string): any => {
    const jsonMatch = responseContent.match(/{.*}/s);
    if (!jsonMatch) {
      throw new Error("Kein gültiges JSON-Format in der AI-Antwort gefunden.");
    }
    return JSON.parse(jsonMatch[0]);
  };

  const getAIResponse = async (): Promise<string> => {
    const response = await ChatGPT({
      prompt: profilePrompt,
      model: AiModelHighCap,
      temperature: 0.7,
      role: "system",
    });
    return response.choices[0].message.content;
  };

  for (let attempt = 0; attempt < 2; attempt++) {
    try {
      ToastService.showReminder(
        "die Profilgenierung wird noch ein wenig mehr Zeit in Anspruch nehmen ..."
      );
      const responseContent = await getAIResponse();
      try {
        return parseJSON(responseContent);
      } catch {
        return extractJSONWithRegex(responseContent);
      }
    } catch (error) {
      console.error(`Fehler beim Versuch ${attempt + 1}:`, error);
    }
  }

  try {
    const responseContent = await getAIResponse();
    for (let attempt = 2; attempt < 4; attempt++) {
      try {
        return parseJSON(responseContent);
      } catch {
        return extractJSONWithRegex(responseContent);
      }
    }
  } catch (error) {
    console.error("Fehler bei der erneuten AI-Abfrage:", error);
  }

  throw new Error("Fehler bei der Profilerstellung nach mehreren Versuchen.");
};

export const generateEmailforProfile = async (
  salutation: string,
  anonymizedResume: string,
  anonymizedNote: string
) => {
  const profileMailPrompt = `${store.state.company.aiData.prompt.company.weAreShort}\n
  ${store.state.company.aiData.prompt.company.mailCustomer}\n${promptGlobal.MailProfileBeforeSalutation} ${salutation} ${promptGlobal.MailProfileAnonymizedName} ${promptGlobal.MailProfileAfterSalutation}\n${anonymizedResume}\n Eindrücke im Vorstellungsgespräch: ${anonymizedNote}\n${promptGlobal.jsonFormatMail}`;
  try {
    const response = await ChatGPT({
      prompt: profileMailPrompt,
      model: AiModelBasic,
      temperature: 0.7,
      role: "system",
    });

    const matches = response.choices[0].message.content.match(/{(.*?)}/s);
    if (matches && matches[1]) {
      const profileMailData = JSON.parse(`{${matches[1]}}`);
      return profileMailData;
    }
  } catch (error) {
    console.error("Fehler bei der Mailgenerierung", error);
    throw error;
  }
};

export const generateJobAdLeadsQuery = async (anonymizedResume: string) => {
  const jobAdLeadsQuery = `${store.state.company.aiData.prompt.company.weAreShort}\n
  ${promptGlobal.generateIndexQuery}\n${anonymizedResume}\n
  ${promptGlobal.generateIndexQueryHint} `;
  try {
    const response = await ChatGPT({
      prompt: jobAdLeadsQuery,
      model: AiModelTurbo,
      temperature: 0.7,
      role: "system",
    });

    const matches = response.choices[0].message.content.match(/{(.*?)}/s);
    if (matches && matches[1]) {
      const jobLeadQuery = JSON.parse(`{${matches[1]}}`);
      return jobLeadQuery;
    }
  } catch (error) {
    console.error(
      "Fehler bei der generierung des Index Anzeigedaten Suchauftrags",
      error
    );
    throw error;
  }
};

export const generateWpPluginProfile = async (anonymizedResume: string) => {
  const jobAdLeadsQuery = `${store.state.company.aiData.prompt.company.weAreShort}\n
  ${promptGlobal.generateWpPluginProfile}\n${anonymizedResume}`;
  try {
    const response = await ChatGPT({
      prompt: jobAdLeadsQuery,
      model: AiModelBasic,
      temperature: 0.7,
      role: "system",
    });

    const matches = response.choices[0].message.content.match(/{(.*?)}/s);
    if (matches && matches[1]) {
      const jobLeadQuery = JSON.parse(`{${matches[1]}}`);
      return jobLeadQuery;
    }
  } catch (error) {
    console.error(
      "Fehler bei der generierung des Kurzprofils für die Webseite",
      error
    );
    throw error;
  }
};

export const formatCv = async (birthdate: string, cvData: CareerStep[]) => {
  const cvDataText = JSON.stringify(cvData, null, 2);
  const promptText = `${store.state.company.aiData.prompt.company.weAreShort}\n ${promptGlobal.formatCvDataBeforeBirthdate} ${birthdate}\n${promptGlobal.formatCvDataBeforeJson}\n${cvDataText}`;
  try {
    const response = await ChatGPT({
      prompt: promptText,
      model: AiModelBasic,
      temperature: 0.5,
      role: "system",
    });

    const matches = response.choices[0].message.content.match(/\[.*\]/s);
    if (matches && matches[0]) {
      const parsedResponse = JSON.parse(matches[0]);
      return parsedResponse;
    } else {
      throw new Error("Kein gültiges JSON-Format in der AI-Antwort gefunden.");
    }
  } catch (error) {
    console.error("Fehler beim Extrahieren der Daten:", error);
    throw error;
  }
};
