<!--src/components/disposition/elements/InterviewCompletedForm.vue-->
<template>
  <v-container v-if="skillsDialog || interviewDialog">
    <v-dialog
      v-if="skillsDialog"
      v-model="skillsDialog"
      persistent
      :max-width="smAndDown ? 'var(--d-w-sd)' : 'var(--d-w-l)'"
    >
      <v-card>
        <v-card-title>Daten ergänzen ... </v-card-title>
        <v-card-text>
          <v-row>
            <v-col cols="12" md="6">
              <v-card variant="outlined">
                <v-card-subtitle class="my-4"
                  >Kandidaten bearbeiten,</v-card-subtitle
                >
                <v-btn
                  variant="text"
                  block
                  style="color: var(--color-primary)"
                  @click="$emit('openEditCandidateDialog')"
                  >Bearbeiten</v-btn
                >
              </v-card>
            </v-col>

            <v-col cols="12" md="6">
              <v-card variant="outlined">
                <v-card-subtitle class="my-4">
                  oder in ATS eintragen!</v-card-subtitle
                >
                <v-btn
                  variant="text"
                  block
                  style="color: var(--color-primary)"
                  @click="openZvoove('stammdaten')"
                  >1. Stammdaten</v-btn
                >
                <v-btn
                  variant="text"
                  block
                  style="color: var(--color-primary)"
                  @click="openZvoove('perfectmatch/skills')"
                  >2. Skills</v-btn
                >
                <v-btn
                  variant="text"
                  block
                  style="color: var(--color-primary)"
                  @click="openZvoove('vita')"
                  >3. Lebenslauf</v-btn
                >
                <v-divider></v-divider>
                <v-btn
                  variant="text"
                  block
                  color="green-darken-3"
                  @click="handleAtsRecruitConfirm"
                  >OK und aus ATS aktualisieren</v-btn
                >
              </v-card>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="grey" @click="closeSkillsModal">Abbrechen</v-btn>
          <v-btn color="green-darken-3" @click="handleEditConfirm"
            >Weiter</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- Zweites Modal für die Zusammenfassung des Vorstellungsgesprächs -->
    <v-dialog
      v-if="interviewDialog"
      v-model="interviewDialog"
      persistent
      max-width="600px"
    >
      <v-card>
        <v-card-title>Zusammenfassung des Vorstellungsgesprächs</v-card-title>
        <v-card-text>
          <v-textarea
            variant="outlined"
            v-model="interviewSummary"
            label="Bitte gibt eine Zusammenfassung ein."
          ></v-textarea>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="grey" @click="closeInterviewModal">Abbrechen</v-btn>
          <v-btn
            color="green-darken-3"
            style="color: var(--color-primary)"
            @click="handleInterviewConfirm"
            >OK</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script lang="ts">
import { PropType, defineComponent } from "vue";
import { useDisplay } from "vuetify";

export default defineComponent({
  name: "MultiStepForm",
  emits: ["openEditCandidateDialog", "fetchRecruitData", "saveResumeInterview"],
  props: {
    zvooveRecruitMitarbeiterUuid: String as PropType<string>,
  },
  data() {
    const { smAndDown } = useDisplay();
    return {
      smAndDown,
      skillsDialog: false,
      interviewDialog: false,
      interviewSummary: "",
    };
  },
  methods: {
    openSkillsModal() {
      this.skillsDialog = true;
    },
    closeSkillsModal() {
      this.skillsDialog = false;
    },
    handleAtsRecruitConfirm() {
      this.skillsDialog = false;
      this.$emit("fetchRecruitData");
      this.openInterviewModal();
    },
    handleEditConfirm() {
      this.skillsDialog = false;
      this.openInterviewModal();
    },
    openInterviewModal() {
      this.interviewDialog = true;
    },
    closeInterviewModal() {
      this.interviewDialog = false;
    },
    handleInterviewConfirm() {
      this.interviewDialog = false;
      this.$emit("saveResumeInterview", this.interviewSummary);
    },
    openZvoove(target: string) {
      const externalLink = `${this.$store.state.company.softwareIntegration.zvooveRecruitLink}/bewerber/${this.zvooveRecruitMitarbeiterUuid}/${target}`;
      window.open(externalLink, "_blank", "width=950,height=900"); //Width 950 supress zvoove Recruits Side Menu and TODO: use responsive dimensions from service
    },
  },
});
</script>
