<template>
  <v-card>
    <v-tabs
      v-model="activeTab"
      align-tabs="start"
      bg-color="var(--color-primary)"
      style="color: var(--color-font-light)"
      slider-color="var(--color-primary)"
    >
      <v-tab
        v-for="item in items"
        :key="item"
        :value="item"
        @click="navigateTo(item)"
      >
        {{ item }}
      </v-tab>
    </v-tabs>
  </v-card>
</template>

<script lang="ts">
import { UserRole } from "@/enums/user-role.enum";
import { UserHelperService } from "@/services/user-helper.service";
import { defineComponent } from "vue";

export default defineComponent({
  name: "AdminMenu",
  data() {
    const userRole = UserHelperService.getInstance().getUserRole();
    const items = [
      "Benutzer",
      "Niederlassungen",
      "Software & Schnittstellen",
      "Recurion.ai Einstellungen",
    ];
    if (userRole === UserRole.UberAdmin) {
      items.unshift("Firmenkunden");
    }
    return {
      activeTab: "Benutzer",
      items,
      userRole,
    };
  },
  methods: {
    navigateTo(item: string) {
      switch (item) {
        case "Firmenkunden":
          if (this.userRole === UserRole.UberAdmin) {
            this.$router.push("/config/company");
          }
          break;
        case "Benutzer":
          this.$router.push("/config/user");
          break;
        case "Niederlassungen":
          this.$router.push("/config/mandant");
          break;
        case "Software & Schnittstellen":
          this.$router.push("/config/software-integration");
          break;
        case "Recurion.ai Einstellungen":
          this.$router.push("/config/ai");
          break;
        default:
      }
    },
  },
});
</script>
