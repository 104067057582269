import { Company } from "@/models/company.model";
import { Api } from "./api.class";
import { User } from "@/models/user.model";

export class CompanyService {
  private static instance: CompanyService;
  private api = Api.getInstance();
  private urlPath = "/company";

  public static getInstance(): CompanyService {
    if (!CompanyService.instance) {
      CompanyService.instance = new CompanyService();
    }
    return CompanyService.instance;
  }

  async getCompanies() {
    const response = await this.api.getBackendApi().get(`${this.urlPath}`);
    return response.data;
  }

  async addCompany(company: Company, user: Partial<User>) {
    const response = await this.api
      .getBackendApi()
      .post<Company>(`${this.urlPath}`, { company, user });
    return response.data;
  }

  async editCompany(company: Company) {
    const response = await this.api
      .getBackendApi()
      .put(`${this.urlPath}`, company);
    return response.data;
  }

  async removeCompany(id: string) {
    const response = await this.api
      .getBackendApi()
      .delete(`${this.urlPath}/${id}`);
    return response.data;
  }
}
