import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = (n: any) => (_pushScopeId("data-v-5565824e"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "video-container" }
const _hoisted_2 = { key: 0 }
const _hoisted_3 = ["src"]
const _hoisted_4 = { key: 1 }
const _hoisted_5 = ["src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_card = _resolveComponent("v-card")!
  const _component_v_container = _resolveComponent("v-container")!

  return (_openBlock(), _createBlock(_component_v_container, { class: "py-3" }, {
    default: _withCtx(() => [
      _createVNode(_component_v_card, {
        class: "mt-15",
        variant: "outlined"
      }, {
        default: _withCtx(() => [
          _createElementVNode("div", _hoisted_1, [
            (!_ctx.videoEnded)
              ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
                  _createElementVNode("video", {
                    ref: "videoPlayer",
                    src: _ctx.videoSrc,
                    autoplay: "",
                    onEnded: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.onVideoEnded && _ctx.onVideoEnded(...args))),
                    onError: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.onVideoError && _ctx.onVideoError(...args)))
                  }, " Your browser does not support the video tag. ", 40, _hoisted_3)
                ]))
              : (_openBlock(), _createElementBlock("div", _hoisted_4, [
                  _createElementVNode("img", {
                    src: _ctx.logoSrc,
                    class: "logo",
                    alt: "Logo"
                  }, null, 8, _hoisted_5)
                ]))
          ])
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}