<!--src/components/subscription/feature-overview.vue-->
<template>
  <v-tooltip bottom>
    <template v-slot:activator="{ props }">
      <v-card
        flat
        class="price-selection"
        :class="{ selected: subscription.selected }"
        @click="$emit('click')"
        v-bind="props"
      >
        <v-card-title>
          <i class="fa-solid fa-box"></i> {{ subscription.name }}
        </v-card-title>
        <v-card-subtitle>
          Ø
          {{
            monthly
              ? subscription.price.monthly
              : calculateMonthlyPrice(subscription.price)
          }}€ im Monat
        </v-card-subtitle>
        <v-card-text>
          <strong
            >{{
              monthly ? subscription.price.monthly : subscription.price.yearly
            }}€ {{ monthly ? "monatlich" : "jährlich" }}</strong
          >
          pro Nutzer
        </v-card-text>
      </v-card>
    </template>
    <span v-html="subscription.tooltip"></span>
  </v-tooltip>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { Price } from "@/models/subcriptions.model";

export default defineComponent({
  name: "PriceSelection",
  props: {
    subscription: {
      type: Object,
      required: true,
    },
    appname: {
      type: String,
      required: true,
    },
    monthly: {
      type: Boolean,
      required: true,
    },
  },
  methods: {
    calculateMonthlyPrice(price: Price) {
      const yearlyPrice = price.yearly;
      const monthlyPrice = yearlyPrice / 12;
      return monthlyPrice.toLocaleString("de-DE", {
        minimumFractionDigits: 0,
        maximumFractionDigits: 2,
      });
    },
  },
});
</script>

<style scoped></style>
