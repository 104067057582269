<template>
  <v-container>
    <v-btn icon variant="text" @click="toggleFilters">
      <v-icon size="xlarge">
        {{
          showFilters
            ? "fa-solid fa-arrow-up filter-icon filter-close"
            : "fa-solid fa-sliders filter-icon"
        }}
      </v-icon>
    </v-btn>
    <v-scroll-y-transition class="filter-menu">
      <v-card v-if="showFilters" class="mt-15">
        <v-card-title class="d-flex">
          Filtern nach ...
          <v-spacer></v-spacer>
          <v-btn icon variant="text" @click="toggleFilters">
            <v-icon>fa-solid fa-xmark</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text>
          <v-row class="mt-2">
            <!-- Slots for columns-->
            <v-col cols="12" md="6" class="pl-5 pt-2 pb-2">
              <slot name="filter-menu-left-column"></slot>
            </v-col>
            <v-col cols="12" md="6" class="pr-5 pt-2 pb-2">
              <slot name="filter-menu-right-column"></slot>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-scroll-y-transition>
  </v-container>
</template>

<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
  name: "FilterMenu",
  data() {
    return {
      showFilters: false,
    };
  },
  methods: {
    toggleFilters() {
      this.showFilters = !this.showFilters;
    },
  },
});
</script>
<style scoped>
.filter-menu {
  position: absolute;
  top: 8.3rem;
  width: 40rem;
  z-index: 100;
  margin-left: 5px;
  margin-right: 5px;
  padding: 5px;
  border-right: 2px solid var(--color-primary);
  border-left: 2px solid var(--color-primary);
  border-bottom: 2px solid var(--color-primary);
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
  box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
}
@media (max-width: 600px) {
  .filter-menu {
    width: 85vw;
  }
}
</style>
