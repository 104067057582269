import DialogService from "@/services/dialog.service";
import { FunnelService } from "./api/funnel.service";

export class UploadService {
  private companyName = "";
  private funnelId = "";
  private funnelService = new FunnelService();

  openFilePicker(inputIdSuffix: string) {
    const inputId = `file-input-${inputIdSuffix}`;
    const fileInput = document.getElementById(inputId);
    if (fileInput) {
      fileInput.click();
    }
  }

  async uploadImage(fileType: string, thankYouPageImageName = "") {
    const inputId = `file-input-${fileType}`;
    const fileInput = document.getElementById(inputId) as HTMLInputElement;

    if (fileInput && fileInput.files && fileInput.files.length > 0) {
      const file = fileInput?.files[0];

      // We overwrite the images in the Funnel source Folder so we need to get the filename for the uploadable image
      let fileName = "";
      if (fileType.startsWith("step-")) {
        fileName = thankYouPageImageName;
      } else {
        fileName = `${fileType}.png`;
      }

      try {
        await this.funnelService.uploadImage(this.funnelId, file, fileName);
        DialogService.alert(`${fileName} erfolgreich hochgeladen`);
      } catch (error: any) {
        console.error(`Fehler beim Hochladen von ${fileName}`, error);
        DialogService.alert(`Fehler beim Hochladen von ${fileName}`);
      }
    } else {
      console.log("Keine Datei ausgewählt oder Dateieingabe nicht gefunden");
    }
  }

  setCompanyName(companyName: string) {
    this.companyName = companyName;
  }

  getFunnelId() {
    return this.funnelId;
  }

  setFunnelId(funnelId: string) {
    this.funnelId = funnelId;
  }
}
