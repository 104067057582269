<!--src/components/disposition/elements/DialogAddEmployee.vue-->
<template>
  <div>
    <v-dialog
      v-model="showModal"
      persistent
      :max-width="smAndDown ? 'var(--d-w-sd)' : 'var(--d-w-s)'"
    >
      <v-card>
        <v-card-title>{{ dialog.title }}</v-card-title>
        <v-card-text
          :class="{
            'is-expanded': filteredEmployeeList.length > 0,
            'is-contracted': filteredEmployeeList.length === 0,
          }"
        >
          <v-text-field
            v-if="dialog.inputLabel"
            v-model="localInput"
            :label="dialog.inputLabel"
            variant="outlined"
          ></v-text-field>
          <v-label v-if="filteredEmployeeList.length > 0"
            >In Zvoove gefunden:</v-label
          >
          <v-list dense class="scrollable-list">
            <v-list-item
              v-for="(filteredEmployee, index) in filteredEmployeeList"
              :key="index"
              class="clickable"
              @click="displayEmployeeNumber(filteredEmployee.employeeNumber)"
            >
              <div>
                <v-list-item-title>{{
                  filteredEmployee.name
                }}</v-list-item-title>
                <v-list-item-subtitle>{{
                  filteredEmployee.address
                }}</v-list-item-subtitle>
              </div>
            </v-list-item>
          </v-list>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="red-darken-4" @click="closeModals">Abbrechen</v-btn>
          <v-btn color="green-darken-3" @click="submitModal">{{
            dialog.actionButtonText
          }}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="resultsModal"
      persistent
      :max-width="smAndDown ? 'var(--d-w-sd)' : 'var(--d-w-m)'"
    >
      <v-card>
        <v-card-title>Ergebnisse auswählen</v-card-title>
        <v-card-text>
          <v-list>
            <v-list-item
              v-for="(result, index) in results"
              :key="index"
              @click="selectResult(result)"
            >
              <div>
                <v-list-item-title
                  >{{ result.firstName }}
                  {{ result.lastName }}</v-list-item-title
                >
                <v-list-item-subtitle>
                  {{ result.address.street }}, {{ result.address.postalCode }},
                  {{ result.address.city }}
                </v-list-item-subtitle>
              </div>
            </v-list-item>
          </v-list>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="red-darken-4" @click="closeModals">Schließen</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { mapGetters } from "vuex";
import DialogService from "@/services/dialog.service";
import { useDisplay } from "vuetify";
import { Employee, EmployeeList } from "@/models/employee.model";

export default defineComponent({
  name: "ModalAddEmployee",
  data() {
    const { smAndDown } = useDisplay();
    return {
      smAndDown,
      showModal: false,
      resultsModal: false,
      results: [] as Employee[],
      localInput: "",
      dialog: {
        title: "",
        type: "",
        input: "",
        inputLabel: "",
        actionButtonText: "",
      },
      filteredEmployeeList: [] as EmployeeList[],
    };
  },
  computed: {
    ...mapGetters(["softwareIntegration", "employeeList"]),
    listHeightClass() {
      return {
        "is-expanded": this.filteredEmployeeList.length > 0,
      };
    },
  },
  watch: {
    localInput(newValue) {
      this.filterZvooveEmployeeList(newValue);
    },
  },
  methods: {
    filterZvooveEmployeeList(searchterm: string) {
      if (
        this.softwareIntegration.zvooveOne ||
        this.softwareIntegration.pdHub
      ) {
        if (
          this.employeeList.length > 0 &&
          searchterm &&
          searchterm.length >= 3
        ) {
          const filteredEmployees = this.employeeList.filter((employee: any) =>
            employee.name.toLowerCase().includes(searchterm.toLowerCase())
          );
          this.filteredEmployeeList = filteredEmployees;
        } else {
          this.filteredEmployeeList = [];
        }
      }
    },
    displayEmployeeNumber(employeeNumber: string) {
      let modalTypeText;

      if (this.softwareIntegration.zvooveOne) {
        modalTypeText = `Personaldaten von ${employeeNumber} übernehmen?`;
      } else if (this.softwareIntegration.pdHub) {
        modalTypeText = `Personaldaten von ${employeeNumber} übernehmen?`;
      } else {
        return;
      }
      this.confirmAction(modalTypeText, employeeNumber);
    },

    confirmAction(message: string, employeeNumber: string) {
      DialogService.confirm(message, "Abbruch", "Ja").then((confirmed) => {
        if (confirmed) this.$emit("submit", employeeNumber);
      });
      this.closeModals();
    },
    closeModals() {
      this.showModal = false;
      this.resultsModal = false;
    },
    openModal(dialog: {
      title: string;
      type: string;
      input: string;
      inputLabel: string;
      actionButtonText: string;
    }) {
      this.dialog = dialog;
      this.showModal = true;
    },
    openResultsModal(result: Employee) {
      this.results = [result];
      this.resultsModal = true;
    },
    submitModal() {
      this.$emit("submit", this.localInput);

      this.closeModals();
    },
    selectResult(result: Employee) {
      this.$emit("selectedResult", result);
      this.closeModals();
    },
  },
});
</script>

<style scoped>
.scrollable-list {
  transition: max-height 0.5s ease-in-out;
  overflow-y: auto;
  max-height: 0;
}

.v-card-text.is-expanded .scrollable-list {
  max-height: 15rem;
}

.v-card-text.is-expanded {
  max-height: 30rem;
}

.v-card-text.is-contracted {
  max-height: 10rem;
}
</style>
: string | undefined: string | undefined: boolean: string | undefined
