import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode, Fragment as _Fragment } from "vue"

const _hoisted_1 = { class: "d-flex" }
const _hoisted_2 = {
  key: 1,
  class: "mx-3"
}
const _hoisted_3 = {
  key: 2,
  class: "d-flex justify-end"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_icon = _resolveComponent("v-icon")!
  const _component_v_tooltip = _resolveComponent("v-tooltip")!
  const _component_v_btn = _resolveComponent("v-btn")!
  const _component_v_select = _resolveComponent("v-select")!
  const _component_v_badge = _resolveComponent("v-badge")!
  const _component_ChatMessages = _resolveComponent("ChatMessages")!
  const _component_QrCode = _resolveComponent("QrCode")!
  const _component_v_text_field = _resolveComponent("v-text-field")!
  const _component_v_textarea = _resolveComponent("v-textarea")!
  const _component_v_form = _resolveComponent("v-form")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      (_ctx.whatsAppConfig.whatsAppMode === _ctx.WhatsAppMode.experimental)
        ? (_openBlock(), _createBlock(_component_v_btn, {
            key: 0,
            icon: "",
            variant: "text",
            class: "mr-2",
            onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.checkStatus()))
          }, {
            default: _withCtx(() => [
              _createVNode(_component_v_icon, { size: "x-large" }, {
                default: _withCtx(() => [
                  _createTextVNode("fa-solid fa-arrows-rotate")
                ]),
                _: 1
              }),
              _createVNode(_component_v_tooltip, {
                activator: "parent",
                location: "end"
              }, {
                default: _withCtx(() => [
                  _createTextVNode("Verbindung zu WhatsApp aktualisieren")
                ]),
                _: 1
              })
            ]),
            _: 1
          }))
        : _createCommentVNode("", true),
      _createVNode(_component_v_select, {
        modelValue: _ctx.sessionName,
        "onUpdate:modelValue": [
          _cache[1] || (_cache[1] = ($event: any) => ((_ctx.sessionName) = $event)),
          _cache[2] || (_cache[2] = ($event: any) => (_ctx.checkStatus()))
        ],
        items: _ctx.whatsAppConfig.accounts,
        "item-title": "description",
        "item-value": "_id",
        label: "WhatsApp Account",
        variant: "outlined",
        density: "compact",
        class: "mb-2"
      }, null, 8, ["modelValue", "items"]),
      (_ctx.whatsAppConfig.whatsAppMode === _ctx.WhatsAppMode.experimental)
        ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
            _createVNode(_component_v_badge, {
              color: _ctx.isAuthenticated ? 'green-darken-3' : 'red-darken-4',
              content: _ctx.isAuthenticated ? '✔' : '✘',
              overlap: "",
              "offset-x": "0"
            }, {
              default: _withCtx(() => [
                _createVNode(_component_v_icon, { size: "x-large" }, {
                  default: _withCtx(() => [
                    _createTextVNode(" fab fa-whatsapp ")
                  ]),
                  _: 1
                })
              ]),
              _: 1
            }, 8, ["color", "content"]),
            _createVNode(_component_v_tooltip, {
              activator: "parent",
              location: "end"
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.isAuthenticated
            ? "Verbindung zu WhatsApp hergestellt"
            : "Keine Verbindung zu WhatsApp Account"), 1)
              ]),
              _: 1
            })
          ]))
        : _createCommentVNode("", true)
    ]),
    _createVNode(_component_ChatMessages, { messages: _ctx.chatMessages }, null, 8, ["messages"]),
    _createVNode(_component_QrCode, {
      onCheckStatus: _cache[3] || (_cache[3] = ($event: any) => (_ctx.checkStatus())),
      ref: "qrCodeComponent",
      sessionName: _ctx.sessionName
    }, null, 8, ["sessionName"]),
    (_ctx.isAuthenticated)
      ? (_openBlock(), _createBlock(_component_v_form, { key: 0 }, {
          default: _withCtx(() => [
            (!_ctx.whatsAppNumber)
              ? (_openBlock(), _createBlock(_component_v_text_field, {
                  key: 0,
                  modelValue: _ctx.localWhatsAppNumber,
                  "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.localWhatsAppNumber) = $event)),
                  label: "WhatsApp Nummer",
                  variant: "outlined",
                  density: "compact"
                }, null, 8, ["modelValue"]))
              : _createCommentVNode("", true),
            (!_ctx.message)
              ? (_openBlock(), _createBlock(_component_v_textarea, {
                  key: 1,
                  modelValue: _ctx.localMessage,
                  "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.localMessage) = $event)),
                  label: "Nachricht",
                  variant: "outlined",
                  density: "compact"
                }, null, 8, ["modelValue"]))
              : _createCommentVNode("", true),
            (!_ctx.message)
              ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
                  _createVNode(_component_v_btn, {
                    icon: "",
                    onClick: _ctx.sendMessage,
                    color: "var(--color-primary)"
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_v_icon, null, {
                        default: _withCtx(() => [
                          _createTextVNode("fa-regular fa-paper-plane")
                        ]),
                        _: 1
                      })
                    ]),
                    _: 1
                  }, 8, ["onClick"])
                ]))
              : _createCommentVNode("", true)
          ]),
          _: 1
        }))
      : _createCommentVNode("", true)
  ], 64))
}