import { KPIRequest, Timeline } from "@/models/timeline.model";
import { Api } from "./api.class";
import { ApiResponse } from "@/models/api-response.model";

export class TimelineService {
  private api = Api.getInstance();
  private urlPath = "/timeline";

  async getTimeline(): Promise<Timeline[]> {
    const response = await this.api
      .getBackendApi()
      .get<ApiResponse>("/timeline");
    return response.data.response;
  }

  async getTimelineByUuid(uuid: string): Promise<Timeline[]> {
    const response = await this.api
      .getBackendApi()
      .get<ApiResponse>(`${this.urlPath}/uuid/${uuid}`);
    return response.data.response;
  }

  async getTimelineByEmployeeId(employee_id: string): Promise<Timeline[]> {
    const response = await this.api
      .getBackendApi()
      .get<ApiResponse>(`${this.urlPath}/employee-id/${employee_id}`);
    return response.data.response;
  }

  async getTimelineByCustomerId(customerNumber: string): Promise<Timeline[]> {
    const response = await this.api
      .getBackendApi()
      .get<ApiResponse>(`${this.urlPath}/customer-id/${customerNumber}`);
    return response.data.response;
  }

  async postTimeline(timelineEntry: Timeline): Promise<Timeline> {
    const response = await this.api
      .getBackendApi()
      .post<ApiResponse>(`${this.urlPath}`, timelineEntry);
    return response.data.response;
  }

  async getKPIs(kpiRequest: KPIRequest) {
    const response = await this.api
      .getBackendApi()
      .post<any>("/timeline/kpi", kpiRequest);
    return response.data;
  }
}
