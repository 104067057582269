<!--src/components/disposition/EmployeeColumn.vue-->
<template>
  <div class="column" @drop="handleDrop">
    <div v-if="!isActive" class="spacer-dispo-column"></div>
    <div v-if="isActive" class="header-dispo-column">
      <div>
        <FilterMenu>
          <template #filter-menu-left-column>
            <div class="mandants-options">
              <v-checkbox
                density="compact"
                v-for="(label, key) in employeeStates"
                :key="key"
                v-model="selectedStatuses"
                :label="label"
                :value="String(key)"
                class="ma-0 pa-0"
              ></v-checkbox>
            </div>
            <div class="d-flex">
              <v-text-field
                v-model="searchTerm"
                density="compact"
                variant="outlined"
                label="Suchbegriff"
                @keydown.enter="filterSearchterm(searchTerm)"
              >
                <v-tooltip activator="parent" location="top"
                  >Einen oder mehrere Suchbegriffe eingeben und mit Enter
                  bestätigen</v-tooltip
                >
              </v-text-field>
              <v-btn
                class="mx-2"
                density="compact"
                variant="text"
                icon
                @click="loadEmployees()"
              >
                <v-icon> fa-solid fa-arrows-rotate </v-icon>
                <v-tooltip activator="parent" location="top"
                  >alle Firmen wieder anzeigen</v-tooltip
                >
              </v-btn>
            </div>
          </template>
          <template #filter-menu-right-column>
            <div class="mandants-options">
              <v-checkbox
                density="compact"
                v-for="mandant in mandants"
                :key="mandant.uuid"
                v-model="selectedMandants"
                :label="mandant.name"
                :value="mandant.branchNumber"
                class="ma-0 pa-0"
              ></v-checkbox>
            </div>
          </template>
        </FilterMenu>
      </div>
      <v-menu v-if="user.role <= 10">
        <template v-slot:activator="{ props }">
          <v-btn icon variant="text" v-bind="props"
            ><v-icon size="large" class="filter-icon"
              >fa-solid fa-circle-plus</v-icon
            >
            <v-tooltip activator="parent" location="top left"
              >Personaldaten anlegen</v-tooltip
            ></v-btn
          >
        </template>
        <v-card>
          <v-card-title class="">Personaldaten</v-card-title>
          <v-card-subtitle class="mb-2">aus ERP importieren:</v-card-subtitle>
          <v-list dense>
            <v-list-item
              v-if="softwareIntegration.zvooveOne"
              @click="openAddEmployeeDialog()"
            >
              <div>
                <img
                  src="@/assets/icon-zvoove-one.svg"
                  class="context-menu-icons"
                />
                ERP One
              </div>
            </v-list-item>
            <v-list-item
              v-if="softwareIntegration.pdHub"
              @click="openAddEmployeeDialog()"
            >
              <div>
                <img
                  src="@/assets/icon-pd-hub.svg"
                  class="context-menu-icons"
                />
                PD-Hub
              </div>
            </v-list-item>
            <v-list-item
              class="mt-10"
              v-if="
                softwareIntegration.zvooveOne &&
                loggedInMandantUuids.length > 0 &&
                user.role <= 10
              "
              @click="getAllEmployeesFromErp"
            >
              <div>
                <img
                  src="@/assets/icon-zvoove-one.svg"
                  class="context-menu-icons"
                />
                alle aus ERP One
              </div>
            </v-list-item>
            <v-list-item
              class="mt-10"
              v-if="
                softwareIntegration.pdHub &&
                loggedInMandantUuids.length > 0 &&
                user.role <= 10
              "
              @click="getAllEmployeesFromErp"
            >
              <div>
                <img
                  src="@/assets/icon-pd-hub.svg"
                  class="context-menu-icons"
                />
                alle aus PD-Hub
              </div>
            </v-list-item>
          </v-list>
        </v-card>
      </v-menu>
    </div>
    <draggable :list="employees" group="employee" item-key="id">
      <template #item="{ element }">
        <EmployeeItem
          class="longpress"
          :employee="element"
          :key="element.id"
          :isActive="isActive"
          :isFullyCollapsed="minimizeAllItems"
          :user="user"
          @insertUpdatedEmployee="insertUpdatedEmployee"
          @contextmenu.prevent="openContextMenu($event, element)"
          @longpress="openContextMenu($event, element)"
        />
      </template>
    </draggable>
    <div v-if="employees.length === 0" @drop.prevent @dragover.prevent>
      <v-card border="dashed" variant="outlined">
        <v-card-title>Keine Personaldaten </v-card-title>
        <v-card-subtitle>unter diesem Filter</v-card-subtitle>
        <v-card-text>
          Kandidat auf dieses Feld ziehen zum Anlegen im ERP System
        </v-card-text>
      </v-card>
    </div>
  </div>
  <div
    v-if="showContextMenu"
    :style="{
      top: contextMenuPosition.y + 'px',
      left: contextMenuPosition.x + 'px',
    }"
    class="context-menu"
    ref="ContextMenu"
    @click.stop
  >
    <v-list dense>
      <v-list-item @click="editEmployee"
        ><v-icon class="text-medium-emphasis mr-1" size="xs">
          fa-solid fa-pen-to-square
        </v-icon>
        bearbeiten</v-list-item
      >
      <v-list-item @click="confirmDeleteemployee(employee)">
        <v-icon class="text-medium-emphasis mr-1" size="xs">
          fa-solid fa-trash-can
        </v-icon>
        löschen
      </v-list-item>
      <v-list-item @click="toggleItemsMinimized()">
        <v-icon class="text-medium-emphasis mr-1" size="xs">
          {{
            minimizeAllItems
              ? "fa-solid fa-chevron-up"
              : "fa-solid fa-chevron-down"
          }}
        </v-icon>
        {{ minimizeAllItems ? "Karten normal" : "Karten minimieren" }}
      </v-list-item>
    </v-list>
  </div>
  <DialogEditEmployee
    :employee="employee"
    ref="modalEditEmployeeComponent"
    @insertUpdatedEmployee="insertUpdatedEmployee"
  />
  <v-dialog
    v-model="showDialogCandidateToEmployee"
    persistent
    :max-width="smAndDown ? 'var(--d-w-sd)' : 'var(--d-w-l)'"
  >
    <v-card>
      <v-card-title class="d-flex">
        neuen Mitarbeiter im ERP System anlegen
        <v-spacer></v-spacer>
        <v-btn
          @click="closeModal()"
          icon
          size="s"
          variant="text"
          density="compact"
        >
          <v-icon> fa-solid fa-xmark close-icon </v-icon>
        </v-btn>
      </v-card-title>
      <v-card-text>
        <v-row>
          <v-col cols="12" md="2">
            <v-select
              v-model="candidateForErp.candidateData.salutationCatalogId"
              :items="salutations"
              item-title="label"
              item-value="value"
              label="Anrede"
              variant="outlined"
            />
          </v-col>
          <v-col cols="12" md="5">
            <v-text-field
              v-model="candidateForErp.candidateData.firstName"
              label="Vorname"
              variant="outlined"
            ></v-text-field>
          </v-col>
          <v-col cols="12" md="5">
            <v-text-field
              v-model="candidateForErp.candidateData.lastName"
              label="Nachname"
              variant="outlined"
            ></v-text-field>
          </v-col>
          <v-col cols="12" md="4">
            <v-text-field
              v-model="candidateForErp.candidateData.addressStreet"
              label="Straße"
              variant="outlined"
            />
          </v-col>
          <v-col cols="12" md="2">
            <v-text-field
              v-model="candidateForErp.candidateData.addressHouseNumber"
              label="Hausnummer"
              variant="outlined"
            />
          </v-col>
          <v-col cols="12" md="2">
            <v-text-field
              v-model="candidateForErp.candidateData.addressPostalCode"
              label="PLZ"
              variant="outlined"
            />
          </v-col>
          <v-col cols="12" md="4">
            <v-text-field
              v-model="candidateForErp.candidateData.addressCity"
              label="Ort"
              variant="outlined"
            />
          </v-col>
          <v-col cols="12" md="4">
            <v-select
              v-model="candidateForErp.candidateData.maritalStatus"
              :items="maritalStatus"
              item-title="value"
              item-value="value"
              label="Familienstand"
              variant="outlined"
            />
          </v-col>
          <v-col cols="12" md="3"></v-col>
          <v-col cols="12" md="5">
            <v-select
              v-model="candidateForErp.candidateMandantUuid"
              :items="mandants"
              item-title="name"
              item-value="uuid"
              label="anlegen unter:"
              variant="outlined"
            />
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions>
        <v-btn color="red-darken-4" @click="closeModal()">Abbrechen</v-btn>
        <v-spacer></v-spacer>
        <v-btn color="green-darken-3" @click="createEmployeeFromCandidateData()"
          >Mitarbeiter Anlegen</v-btn
        >
      </v-card-actions>
    </v-card>
  </v-dialog>
  <DialogAddEmployee
    ref="dialogAddEmployeeComponent"
    @submit="getEmployeeFromErp"
    @selectedResult="addEmployee"
  ></DialogAddEmployee>
</template>

<script lang="ts">
import { Candidate } from "@/models/candidate.model";
import { defineComponent, PropType } from "vue";
import { Employee } from "@/models/employee.model";
import { EmployeeService } from "@/services/api/employee.service";
import { getEnumOptions } from "@/helper/enum.helper";
import { InterComponentMessage } from "@/enums/inter-component-messagin.enum";
import { Mandant } from "@/models/mandant.model";
import { mapGetters } from "vuex";
import { MaritalStatus } from "@/enums/marital-status.enum";
import { PdHubService } from "@/services/api/pd-hub.service";
import { Salutation } from "@/enums/salutation.enum";
import { SoftwareIntegration } from "@/models/company-config.model";
import { useDisplay } from "vuetify";
import { User } from "@/models/user.model";
import { UserHelperService } from "@/services/user-helper.service";
import { UserService } from "@/services/api/user.service";
import { ZvooveOneEmployee } from "@/services/api/api-integration-one.service";
import DialogAddEmployee from "./elements/DialogAddEmployee.vue";
import DialogEditEmployee from "@/components/disposition/elements/DialogEditEmployee.vue";
import DialogService from "@/services/dialog.service";
import draggable from "vuedraggable";
import EmployeeItem from "@/components/disposition/EmployeeItem.vue";
import FilterMenu from "./elements/FilterMenu.vue";
import ToastService from "@/services/toast.service";
import { ModalMode } from "@/enums/dialog-action.enum";

export default defineComponent({
  name: "EmployeeColumn",
  components: {
    DialogAddEmployee,
    DialogEditEmployee,
    draggable,
    EmployeeItem,
    FilterMenu,
  },
  props: {
    isActive: {
      type: Boolean,
      required: true,
    },
    interComponentMessage: {
      type: Object as PropType<any>,
      required: true,
    },
    lastUpdateTimeline: {
      type: String,
      required: true,
    },
    loggedInMandantUuids: {
      type: Object as PropType<string[]>,
      required: true,
    },
    mandants: {
      type: Object as PropType<Mandant[]>,
      required: true,
    },
    softwareIntegration: {
      type: Object as PropType<SoftwareIntegration>,
      required: true,
    },
    user: {
      type: Object as PropType<User>,
      required: true,
    },
  },
  data() {
    const { smAndDown } = useDisplay();
    return {
      smAndDown,
      candidateForErp: {
        candidateMandantUuid: "",
        candidateData: {} as Candidate,
      },
      contextMenuPosition: { x: 0, y: 0 },
      drag: false,
      employee: {} as Employee,
      employees: [] as Employee[],
      employeeService: new EmployeeService(),
      ModalMode: ModalMode,
      minimizeAllItems: false,
      searchTerm: "",
      selectedMandants: [] as string[],
      selectedStatuses: [] as string[],
      showContextMenu: false,
      showDialogCandidateToEmployee: false,
      showFilters: false,
      userService: new UserService(),
      userHelperService: UserHelperService.getInstance(),
    };
  },
  computed: {
    ...mapGetters({
      employeeStates: "employeeStates",
    }),
    maritalStatus() {
      return getEnumOptions(MaritalStatus);
    },
    salutations() {
      return getEnumOptions(Salutation);
    },
    loggedInGeschaeftsstelleIds() {
      const ids = this.$store.getters.getLoggedInMandantBranchNumbers;
      const uniqueIds = [...new Set(ids)];
      return uniqueIds.map((id: any) => id.toString());
    },
  },
  created() {
    this.getFiltersettingsFromStore().then(() => {
      this.loadEmployees();
    });
  },
  mounted() {
    this.setSelectedMandantsFromLoggedInMandants();
    document.addEventListener("click", this.handleClickOutside);
  },
  beforeUnmount() {
    document.removeEventListener("click", this.handleClickOutside, true);
  },
  watch: {
    selectedStatuses: {
      handler(newValue, oldValue) {
        if (JSON.stringify(newValue) !== JSON.stringify(oldValue)) {
          this.loadEmployees();
          this.$store
            .dispatch("updateDispatcherBoardFilter", {
              columnName: "columnEmployee",
              property: "filterStatus",
              value: newValue,
            })
            .then(() => {
              this.userService.updateUserConfig(
                this.$store.state.company.loggedInUser.config
              );
            });
        }
      },
      deep: true,
    },
    selectedMandants: {
      handler(newValue, oldValue) {
        if (JSON.stringify(newValue) !== JSON.stringify(oldValue)) {
          this.loadEmployees();
          this.$store
            .dispatch("updateDispatcherBoardFilter", {
              columnName: "columnEmployee",
              property: "filterMandants",
              value: newValue,
            })
            .then(() => {
              this.userService.updateUserConfig(
                this.$store.state.company.loggedInUser.config
              );
            });
        }
      },
      deep: true,
    },
    interComponentMessage: {
      handler(newVal) {
        switch (newVal.message) {
          case InterComponentMessage.matchMeFromGlobalSearch:
            this.filterSearchterm(newVal.payload.searchTerm);
            break;
          default:
            break;
        }
      },
      deep: true,
    },
  },
  methods: {
    addEmployee(employee: Employee) {
      this.employeeService.addEmployee(employee).then(() => {
        this.loadEmployees();
      });
    },
    closeContextMenu() {
      this.showContextMenu = false;
    },
    closeModal() {
      this.showDialogCandidateToEmployee = false;
    },
    async confirmDeleteemployee(employee: Employee) {
      if (employee) {
        const confirmed = await DialogService.confirm(
          "Möchten Sie diesen Mitarbeiter wirklich löschen?",
          "Abbrechen",
          "Löschen"
        );

        if (confirmed && employee._id) {
          this.employeeService.deleteEmployee(employee._id).then(() => {
            this.loadEmployees();
          });
        }
      }
      this.closeContextMenu();
    },
    async createEmployeeFromCandidateData() {
      if (this.softwareIntegration.zvooveOne) {
        const zvooveOneEmployee = new ZvooveOneEmployee();
        const response = await zvooveOneEmployee.createEmployee(
          this.candidateForErp.candidateData,
          this.candidateForErp.candidateMandantUuid
        );

        if (response.id) {
          try {
            ToastService.showReminder(
              "Rufe alle Neuen Daten aus ERP System ab ..."
            );
            const employee = await zvooveOneEmployee.getByEmployeeNumber(
              response.id as string
            );
            if (employee) this.addEmployee(employee);
          } catch (error) {
            ToastService.showError("Fehler bei der Synchronisation:" + error);
          }
          const confirmed = await DialogService.confirm(
            `Bewerber unter der Personalnummer ${response.id} angelegt! Soll ich die Seite in ERP One öffen?`,
            "Nein",
            "Öffnen in ERP One"
          );
          if (confirmed) {
            const externalLink = `${this.softwareIntegration.zvooveOneLink}/personal/${response.id}/uebersicht`;

            window.open(externalLink, "_blank", "width=950,height=900"); //Width 950 supress zvoove Recruits Side Menu and TODO: use responsive dimensions from service
          }
          this.closeModal();
        } else {
          ToastService.showError("Anlage im ERP One hat nicht geklappt!");
        }
      } else if (this.softwareIntegration.pdHub) {
        const pdHubService = PdHubService.getInstance();
        const response = await pdHubService.addCandidate(
          this.candidateForErp.candidateData,
          this.candidateForErp.candidateMandantUuid
        );
        if (response.Data[0].PersonalNr) {
          const employeeNumber = response.Data[0].PersonalNr.toString();
          try {
            ToastService.showReminder(
              "Rufe alle Neuen Daten aus ERP System ab ..."
            );
            const employee = await pdHubService.getByEmployeeNumber(
              employeeNumber
            );
            if (employee) this.addEmployee(employee);
          } catch (error) {
            ToastService.showError("Fehler bei der Synchronisation:" + error);
          }
          DialogService.alert(
            `Bewerber unter der Personalnummer ${employeeNumber} im PD-Hub angelegt!`
          );
          this.closeModal();
        } else {
          ToastService.showError("Anlage im PD-Hub hat nicht geklappt!");
        }
      }
    },
    editEmployee() {
      if (this.$refs.modalEditEmployeeComponent) {
        (this.$refs.modalEditEmployeeComponent as any).openModal();
      }
    },
    async filterSearchterm(searchTerm: string) {
      if (searchTerm === "") {
        this.loadEmployees();
        return;
      }
      const terms = searchTerm.toLowerCase().split(" ");

      const filteredemployees = this.employees.filter((employee) => {
        const matches = terms.every((term) =>
          this.searchAllFields(employee, term)
        );

        return matches;
      });

      this.employees = filteredemployees;
    },
    async getAllEmployeesFromErp() {
      if (this.loggedInMandantUuids.length === 0) {
        ToastService.showError(
          "Bitte in mindestens eine Niederlassung einloggen um alle Kunden abzurufen!"
        );
        return;
      }
      if (this.softwareIntegration.zvooveOne) {
        const branchIds = this.$store.getters.getLoggedInMandantBranchNumbers;
        const zvooveOneEmployee = new ZvooveOneEmployee();
        try {
          const response = (await zvooveOneEmployee.getAll(
            2,
            branchIds
          )) as Employee[];
          if (response) this.initAllEmployeeData(response);
        } catch (error) {
          console.error("Error getting employees from ERP One: ", error);
        }
      } else if (this.softwareIntegration.pdHub) {
        const pdHubService = new PdHubService();
        try {
          const response = (await pdHubService.getAllEmployees()) as Employee[];
          if (response) this.initAllEmployeeData(response);
        } catch (error) {
          console.error("Error getting customers from PD-Hub: ", error);
        }
      }
    },
    async getFiltersettingsFromStore(): Promise<void> {
      return new Promise((resolve) => {
        this.selectedMandants =
          this.user.config.dispatcherBoard.columnEmployee.filterMandants;
        this.selectedStatuses =
          this.user.config.dispatcherBoard.columnEmployee.filterStatus;
        resolve();
      });
    },
    async getEmployeeFromErp(employeeNumber: string) {
      try {
        let result = {} as Employee;
        if (this.softwareIntegration.zvooveOne) {
          const zvooveOneEmployee = new ZvooveOneEmployee();
          result = await zvooveOneEmployee.getByEmployeeNumber(employeeNumber);
        } else if (this.softwareIntegration.pdHub) {
          const pdHubService = new PdHubService();
          result = (await pdHubService.getByEmployeeNumber(
            employeeNumber
          )) as Employee;
        } else {
          return;
        }
        if (this.$refs.dialogAddEmployeeComponent) {
          (this.$refs.dialogAddEmployeeComponent as any).openResultsModal(
            result
          );
        }
      } catch (error) {
        console.error("Error getting employee from ERP:", error);
      }
    },
    handleClickOutside(event: MouseEvent) {
      const contextMenu = this.$refs.ContextMenu as HTMLElement;
      const modalElement = document.querySelector(
        ".v-dialog.v-dialog--active"
      ) as HTMLElement;

      if (
        contextMenu &&
        !contextMenu.contains(event.target as Node) &&
        this.showContextMenu
      ) {
        if (modalElement && modalElement.contains(event.target as Node)) {
          // Klick innerhalb des Modals, nichts tun
          return;
        }

        this.closeContextMenu();
      }
    },
    handleDrop(event: DragEvent) {
      if (event.dataTransfer) {
        const candidateId = event.dataTransfer.getData(
          "application/candidate_id"
        );
        if (candidateId) {
          this.candidateForErp.candidateMandantUuid =
            this.$store.state.isDraggingItem.candidate.candidateMandantUuid;
          this.candidateForErp.candidateData =
            this.$store.state.isDraggingItem.candidate.candidateData;
          this.openCandidateToEmployeeModal();
        }
      }
    },
    async initAllEmployeeData(employees: Employee[]) {
      try {
        const response = await this.employeeService.addAllEmployees(employees);
        const { added, updated, skipped } = response;

        let successSummary = `${added} Kunde(n) erfolgreich hinzugefügt.`;
        if (updated > 0) {
          successSummary += `\n${updated} Kunde(n) erfolgreich aktualisiert.`;
        }
        if (skipped > 0) {
          successSummary += `\n${skipped} Kunde(n) wurden übersprungen, da sie bereits vorhanden waren.`;
        }
        ToastService.showReminder(successSummary);
      } catch (error) {
        ToastService.showError(
          "Fehler beim Hinzufügen oder Aktualisieren der Kunden."
        );
      }

      this.loadEmployees();
    },
    insertUpdatedEmployee(updatedEmployee: Employee) {
      const index = this.employees.findIndex(
        (employee) => employee._id === updatedEmployee._id
      );

      if (index !== -1) {
        this.employees[index] = updatedEmployee;
      }
    },
    searchAllFields(object: Employee, term: string): boolean {
      return Object.values(object).some((value) => {
        if (typeof value === "string") {
          return value.toLowerCase().includes(term);
        } else if (typeof value === "object" && value !== null) {
          return this.searchAllFields(value, term);
        } else if (Array.isArray(value)) {
          return value.some((item) => this.searchAllFields(item, term));
        }
        return false;
      });
    },

    async loadEmployees() {
      await this.$nextTick();
      const selectedStatusesNumbers = this.selectedStatuses.map(Number);
      const response = await this.employeeService.getAllFiltered(
        this.selectedMandants,
        selectedStatusesNumbers
      );
      this.employees = response ? response : [];
    },
    openContextMenu(event: MouseEvent, employee: Employee) {
      this.employee = employee;
      event.preventDefault();
      this.showContextMenu = true;
      this.contextMenuPosition = { x: event.clientX, y: event.clientY };
    },
    openCandidateToEmployeeModal() {
      this.showDialogCandidateToEmployee = true;
    },
    openAddEmployeeDialog() {
      const dialog = {
        title: "",
        type: "" as ModalMode,
        input: "",
        inputLabel: "Personalnummer",
        actionButtonText: "",
      };
      if (this.softwareIntegration.zvooveOne) {
        dialog.title = "Personaldaten in ERP One suchen";
        dialog.type = ModalMode.zvooveOne;
        dialog.actionButtonText = "In ERP One suchen";
      } else if (this.softwareIntegration.pdHub) {
        dialog.title = "Personaldaten in PD-Hub suchen";
        dialog.type = ModalMode.zvooveOne;
        dialog.actionButtonText = "In PD-Hub suchen";
      } else {
        return;
      }

      if (this.$refs.dialogAddEmployeeComponent) {
        (this.$refs.dialogAddEmployeeComponent as any).openModal(dialog);
      }
    },
    setSelectedMandantsFromLoggedInMandants() {
      if (this.selectedMandants && this.loggedInMandantUuids) {
        if (
          this.user.config.dispatcherBoard.columnEmployee.filterMandants
            .length > 0
        ) {
          // Do not set if other filters are set
          return;
        }
        this.selectedMandants = this.mandants
          .filter((mandant: Mandant) =>
            this.loggedInMandantUuids.includes(mandant.uuid)
          )
          .map((mandant: Mandant) => mandant.branchNumber)
          .filter(
            (branchNumber): branchNumber is string => branchNumber !== undefined
          );
      }
    },
    toggleItemsMinimized() {
      this.minimizeAllItems = !this.minimizeAllItems;
      this.closeContextMenu();
    },
  },
});
</script>

<style scoped>
.context-menu {
  position: fixed;
  z-index: 1000;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
  min-width: 10rem;
}

.context-menu .v-list-item:not(:last-child) {
  border-bottom: 0.1rem solid var(--table-hover);
}

.context-menu .v-list-item--active {
  background-color: var(--table-hover);
}

.context-menu-icons {
  width: 1rem;
  height: 1rem;
  margin-right: 0.5rem;
}
</style>
